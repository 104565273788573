import React from 'react';
import { Card, TextStyle, DisplayText } from '@shopify/polaris';
import { formatNumberToMoney } from 'utils/money';

export default function OrderProfit({ analysis, currency }) {
  return (
    <Card.Section title="Profit">
      <TextStyle variation={analysis?.gross < 0 ? 'negative' : 'positive'}>
        <DisplayText size="medium">
          {currency}{' '}
          {formatNumberToMoney(analysis?.gross, {
            category: currency,
            maximumFractionDigits: 10,
          })}
        </DisplayText>
      </TextStyle>
    </Card.Section>
  );
}
