import React, {
  useEffect,
  useState,
  Fragment,
  useMemo,
  useCallback,
} from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { debounce, sortBy } from 'lodash';

import {
  Page,
  Card,
  Filters,
  DataTable,
  TextStyle,
  Loading,
  Select,
  Stack,
  Badge,
} from '@shopify/polaris';
import Pagination from 'components/Pagination';

import { fetchBooks } from 'redux/books/actions';
import { capitalize } from 'utils/strings';
import { formatNumberToMoney } from 'utils/money';
import { formatDateToReadableString } from 'utils/date';
import LoadingPage from 'components/LoadingPage';
import LoadingSpinner from 'components/LoadingSpinner';
import EmptyTable from 'components/EmptyTable';
import useQueryParams from 'hooks/useQueryParams';
import './books.css';

function BookItem(book) {
  return [
    <Link to={`/books/${book.id}`} key={book.id}>
      <TextStyle variation="strong">{book.product}</TextStyle>
    </Link>,
    <Link to={`/books/${book.id}`} key={book.id}>
      {capitalize(book.exchange)}
    </Link>,
    <Link to={`/books/${book.id}`} key={book.id}>
      {formatDateToReadableString(new Date(book.updated_at))}
    </Link>,
    <Link to={`/books/${book.id}`} key={book.id}>
      {formatNumberToMoney(book.buy_price, {
        currencyCode: book.counter,
        category: book.category,
      })}
    </Link>,
    <Link to={`/books/${book.id}`} key={book.id}>
      {formatNumberToMoney(
        parseFloat(book.buy_price) - parseFloat(book.sell_price),
        { category: book.category },
      )}
    </Link>,
    <Link to={`/books/${book.id}`} style={{ paddingRight: 0 }} key={book.id}>
      {formatNumberToMoney(book.sell_price, {
        currencyCode: book.counter,
        category: book.category,
      })}
    </Link>,
  ];
}

function Books({ location, history }) {
  const dispatch = useDispatch();

  const { fetching, data: books, meta } = useSelector((state) => state.books);

  const [sortValue, setSortValue] = useState('created_at');

  const initialQueryParams = useMemo(() => {
    return {
      q: '',
      page: 1,
      limit: 50,
    };
  }, []);

  const { queryParams, clearAllFilters, updateQueryParams } =
    useQueryParams(initialQueryParams);

  function handlePreviousPage() {
    updateQueryParams('page', meta.page - 1);
  }

  function handleNextPage() {
    updateQueryParams('page', meta.page + 1);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    useCallback(() => {
      debounce(() => dispatch(fetchBooks(location.search)), 500)();
    }, [dispatch, location.search]),
    [location.search],
  );

  const rows = Object.values(sortBy(books, sortValue)).map((book) =>
    BookItem(book),
  );

  if (fetching && !books) {
    return <LoadingPage />;
  }

  return (
    <div id="books">
      <Page title="Books">
        {fetching && <Loading />}
        <Card>
          <Card.Section>
            <span className="search-help-text">
              Enter search text, then press <Badge>ENTER</Badge> or{' '}
              <Badge>RETURN</Badge> to search
            </span>
            <Filters
              queryValue={queryParams.q}
              queryPlaceholder="Filter books"
              onQueryChange={(query) => updateQueryParams('q', query)}
              filters={[]}
              onClearAll={clearAllFilters}
            />
          </Card.Section>
          {fetching ? (
            <LoadingSpinner />
          ) : (
            <Fragment>
              {rows.length > 0 ? (
                <Fragment>
                  {rows.length > 0 && (
                    <Card.Section>
                      <Stack distribution="equalSpacing" alignment="center">
                        <TextStyle variation="subdued">{`Showing ${meta.current_entries_size} book(s)`}</TextStyle>
                        <Select
                          label="Sort By"
                          options={[
                            { label: 'Date Created', value: 'created_at' },
                          ]}
                          onChange={(value) => setSortValue(value[0])}
                          value={sortValue}
                          labelInline={true}
                        />
                      </Stack>
                    </Card.Section>
                  )}
                  <DataTable
                    columnContentTypes={[
                      'text',
                      'text',
                      'text',
                      'text',
                      'text',
                      'numeric',
                    ]}
                    headings={[
                      'Product',
                      'Exchange',
                      'Updated',
                      'Buy Price',
                      'Spread',
                      'Sell Price',
                    ]}
                    rows={rows}
                  />
                </Fragment>
              ) : (
                <EmptyTable resourceName="books" />
              )}
            </Fragment>
          )}
          <Pagination
            resource={books}
            meta={meta}
            handlePreviousPage={handlePreviousPage}
            handleNextPage={handleNextPage}
          />
        </Card>
      </Page>
    </div>
  );
}

export default Books;
