import { GET_INVITE, CLEAR_INVITE, ACCEPT_INVITE } from './actionTypes';
import parseError from 'utils/parseError';

const INITIAL_STATE = {
  invite: {},
  fetching: false,
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_INVITE.pending:
    case ACCEPT_INVITE.pending:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case GET_INVITE.rejected:
    case ACCEPT_INVITE.rejected:
      return {
        ...state,
        fetching: false,
        error: parseError(action.payload),
      };

    case GET_INVITE.fulfilled:
      return {
        ...state,
        fetching: false,
        invite: action.payload.data,
      };

    case ACCEPT_INVITE.fulfilled:
      return {
        ...state,
        fetching: false,
      };

    case CLEAR_INVITE:
      return INITIAL_STATE;

    default:
      return state;
  }
};
