import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import store from '../redux';
import { dismissToast } from 'redux/toast/actions';
import { SHOW_TOAST } from 'redux/toast/actionTypes';
import { Toast, Frame } from '@shopify/polaris';

export const toast = (msg, variant) => {
  store.dispatch({ type: SHOW_TOAST, msg, variant });
};

function ToastComponent({ toastVisible, msg, dismissToast, variant }) {
  const TOAST_TIMEOUT_TIME = 5000;

  useEffect(() => {
    let timeOut;
    if (toastVisible) {
      timeOut = setTimeout(() => {
        dismissToast();
      }, TOAST_TIMEOUT_TIME);
    }
    return () => {
      if (timeOut) {
        clearTimeout(timeOut);
      }
    };
  }, [toastVisible, dismissToast]);

  if (toastVisible) {
    return (
      <Frame>
        <Toast
          content={String(msg)}
          error={variant === 'error'}
          onDismiss={dismissToast}
        />
      </Frame>
    );
  }
  return null;
}
const mapStateToProps = (state) => {
  return {
    toastVisible: state.toast.toastVisible,
    msg: state.toast.msg,
    variant: state.toast.variant,
  };
};

const mapDispatchToProps = { dismissToast };

export const Toaster = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ToastComponent);
