import React from 'react';
import { Card, Avatar, Stack, TextStyle } from '@shopify/polaris';
import { formatDateToReadableString } from 'utils/date';
import { getInitials } from 'utils/strings';

export default function AccountOwner({ accountOwner }) {
  const fullName = `${accountOwner.first_name} ${accountOwner.last_name}`;
  let lastLoginDate;
  if (
    accountOwner.login_activities &&
    accountOwner.login_activities.length > 0
  ) {
    lastLoginDate = new Date(accountOwner.login_activities[0].created_at);
  }
  return (
    <Card title="Account Owner" sectioned>
      <Stack alignment="center">
        <Avatar
          initials={getInitials(
            accountOwner.first_name,
            accountOwner.last_name,
          )}
          name={fullName}
          large
        />
        <Stack vertical spacing="extraTight">
          <TextStyle variation="strong">{fullName}</TextStyle>
          {lastLoginDate && (
            <TextStyle variation="subdued">
              {`Last login was ${formatDateToReadableString(lastLoginDate)}`}
            </TextStyle>
          )}
        </Stack>
      </Stack>
    </Card>
  );
}
