import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Page, Layout, DisplayText } from '@shopify/polaris';

import { ReactComponent as BushaLogoSvg } from 'assets/img/busha-logo.svg';
import InvitePageSkeleton from './components/InvitePageSkeleton';
import InvitePageEmptyState from './components/InvitePageEmptyState';
import InviteForm from './components/InviteForm';

import { getInvite, acceptInvite, clearInvite } from 'redux/invite/actions';
import { logout } from 'redux/auth/actions';
import {
  getInviteSelector,
  getInviteFetchingSelector,
} from 'redux/invite/selectors';

import { inviteFormSchema } from 'utils/validationSchemas';
import { toast } from 'utils/toast';

const Invite = ({
  loading,
  logout,
  invite,
  acceptInvite,
  clearInvite,
  getInvite,
  match,
  history,
}) => {
  const handleAcceptInvite = (values) => {
    acceptInvite(values, match.params.token)
      .then(() => clearInvite())
      .then(() => {
        toast('Account created, please login');
        history.push('/login');
      });
  };

  useEffect(() => {
    logout();
    getInvite(match.params.token);
  }, [getInvite, match, logout]);

  if (loading && Object.values(invite).length < 1) {
    return <InvitePageSkeleton />;
  } else if (Object.values(invite).length > 0) {
    return (
      <Page narrowWidth>
        <BushaLogoSvg width="20%" height="100" />
        <DisplayText size="extraLarge">{`Hi, ${invite.first_name}`}</DisplayText>
        <DisplayText size="medium">
          You've been invited to create a staff account with BushaGrey
        </DisplayText>
        <br />
        <Layout>
          <Layout.AnnotatedSection
            title="Create a staff account"
            description="Finalize your account details to gain access to BushaGrey."
          >
            <InviteForm
              invite={invite}
              handleAcceptInvite={handleAcceptInvite}
              schema={inviteFormSchema}
              loading={loading}
              history={history}
            />
          </Layout.AnnotatedSection>
        </Layout>
      </Page>
    );
  } else {
    return <InvitePageEmptyState />;
  }
};

const mapStateToProps = (state) => {
  return {
    invite: getInviteSelector(state),
    loading: getInviteFetchingSelector(state),
  };
};

const mapDispatchToProps = {
  acceptInvite,
  clearInvite,
  getInvite,
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Invite);
