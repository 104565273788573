import { format } from 'date-fns/esm';
import api from 'utils/api';
import {
  FETCH_COMPLETION_RATE_REPORT,
  FETCH_UNSETTLED_SELLS_REPORT,
  FETCH_UNSETTLED_BUYS_REPORT,
} from './actionTypes';

/**
 *
 * @param {string} product
 * @param {object} query
 * @param {Date} query.from
 * @param {Date} query.to
 * @returns { GenericDispatchAction }
 */

export const fetchCompletionRateReport = (product, { from, to } = {}) => (
  dispatch,
) => {
  let query = '';
  if (from) query = `from=${format(from, 'yyyy-MM-dd')}T00:00:00`;
  if (to) query = `${query}&to=${format(to, 'yyyy-MM-dd')}T23:59:59`;

  const payload = api.get(
    `/v1.1/orders/chain/reports/${product}/completion_rate?${query}`,
  );
  return dispatch({
    type: FETCH_COMPLETION_RATE_REPORT.default,
    payload,
    meta: { product },
  });
};

/**
 *
 * @param {string} product
 * @param {object} query
 * @param {Date} query.from
 * @param {Date} query.to
 * @returns { GenericDispatchAction }
 */

export const fetchUnsettledSellsReport = (product, { from, to } = {}) => (
  dispatch,
) => {
  let query = '';
  if (from) query = `from=${format(from, 'yyyy-MM-dd')}T00:00:00`;
  if (to) query = `${query}&to=${format(to, 'yyyy-MM-dd')}T23:59:59`;

  const payload = api.get(
    `/v1.1/orders/chain/reports/${product}/unsettled_sells?${query}`,
  );
  return dispatch({
    type: FETCH_UNSETTLED_SELLS_REPORT.default,
    payload,
    meta: { product },
  });
};

/**
 *
 * @param {string} product
 * @param {object} query
 * @param {Date} query.from
 * @param {Date} query.to
 * @returns { GenericDispatchAction }
 */

export const fetchUnsettledBuysReport = (product, { from, to } = {}) => (
  dispatch,
) => {
  let query = '';
  if (from) query = `from=${format(from, 'yyyy-MM-dd')}T00:00:00`;
  if (to) query = `${query}&to=${format(to, 'yyyy-MM-dd')}T23:59:59`;

  const payload = api.get(
    `/v1.1/orders/chain/reports/${product}/unsettled_buys?${query}`,
  );
  return dispatch({
    type: FETCH_UNSETTLED_BUYS_REPORT.default,
    payload,
    meta: { product },
  });
};
