import { SHOW_TOAST, DISMISS_TOAST } from './actionTypes';

/**
 * Type definitions
 *
 * @typedef { import('../types').GenericDispatchAction } GenericDispatchAction
 * @typedef { import('redux').Dispatch } Dispatch
 */

/**
 *
 * @param {string} msg
 * @param { string } variant
 * @returns { (dispatch : Dispatch ) => dispatch({ type : string; msg : string; variant : string }) }
 */
export const showToast = (msg, variant = 'default') => (dispatch) => {
  return dispatch({ type: SHOW_TOAST, msg, variant });
};

/**
 * @returns { GenericDispatchAction }
 */
export const dismissToast = () => (dispatch) => {
  return dispatch({ type: DISMISS_TOAST });
};
