import React from 'react';
import { useFormik } from 'formik';
import { useHistory, useParams, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Page,
  PageActions,
  Layout,
  Card,
  TextStyle,
  Stack,
  Checkbox,
} from '@shopify/polaris';

import StaffProfileDetailsForm from './components/StaffProfileDetailsForm';
import ChangeStaffPasswordForm from './components/ChangeStaffPasswordForm';
import StaffAccountActions from './components/StaffAccountActions';

import {
  fetchStaffProfile,
  updateStaff,
  activateStaff,
  deactivateStaff,
  changeStaffPassword,
  deleteStaff,
} from 'redux/staff/actions';

import { useConfirmation } from 'utils/confirmationService';
import LoadingPage from 'components/LoadingPage';

function ViewAccount() {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();

  const {
    updating,
    fetching,
    error,
    data: { [params.id]: profile },
  } = useSelector((state) => state.staff);

  const validate = (values) => {
    const errors = {};

    Object.keys(values).forEach((key) => {
      if (['full_access', 'bio', 'phone'].includes(key)) return;
      if (!values[key]) {
        errors[key] = 'Field is required';
      }
    });

    return errors;
  };

  const form = useFormik({
    initialValues: {
      first_name: profile?.first_name,
      last_name: profile?.last_name,
      email: profile?.email,
      bio: profile?.bio,
      phone: profile?.phone,
      full_access: profile?.full_access,
    },
    onSubmit(values) {
      let payload = { ...values };
      if (!values.full_access) {
        // if the user is not to be given full access, set the permission as home
        payload = { ...values, full_access: undefined, permissions: ['home'] };
      }
      dispatch(updateStaff(params.id, payload));
    },
    validate,
    enableReinitialize: true,
    validateOnChange: false,
  });

  const [newPassword, setNewPassword] = React.useState('');

  const onNewPasswordChange = (value) => setNewPassword(value);

  const handleChangeStaffPassword = () => {
    dispatch(changeStaffPassword(params.id, newPassword)).then(() => {
      setNewPassword('');
    });
  };

  const confirm = useConfirmation();

  const toggleAccountStatus = () => {
    confirm({
      variant: profile?.active ? 'danger' : 'info',
      title: `${profile?.active ? 'Deactivate' : 'Activate'} ${
        profile?.first_name
      }'s staff account`,
      description: `Are you sure you want to ${
        profile?.active ? 'Deactivate' : 'Activate'
      } the staff account for ${profile?.first_name}?`,
    }).then(() =>
      profile?.active
        ? dispatch(deactivateStaff(params.id))
        : dispatch(activateStaff(params.id)),
    );
  };

  const deleteStaffAccount = () => {
    confirm({
      variant: 'danger',
      title: `Delete ${profile?.first_name}'s staff account`,
      description: `Are you sure you want to delete the staff account for ${profile?.first_name}? This action cannot be reversed.`,
    }).then(() =>
      dispatch(deleteStaff(params.id)).then(() =>
        history.push('/settings/accounts'),
      ),
    );
  };

  React.useEffect(() => {
    dispatch(fetchStaffProfile(params.id));
  }, [dispatch, params.id]);

  if (error) {
    return <Redirect to="/settings/accounts" />;
  }
  if (fetching && !profile) {
    return <LoadingPage />;
  }

  const fullName = `${profile?.first_name} ${profile?.last_name}`;
  return (
    <Page
      breadcrumbs={[{ content: 'Account', url: '/settings/accounts' }]}
      primaryAction={{
        content: 'Save Edits',
        loading: updating,
        onAction: form.handleSubmit,
      }}
      title={fullName}
    >
      <Layout>
        <Layout.AnnotatedSection title="Account Information">
          <Card sectioned>
            <Card.Section title="Staff profile">
              <StaffProfileDetailsForm form={form} profile={profile} />
            </Card.Section>
            <Card.Section title="PASSWORD RESET">
              <ChangeStaffPasswordForm
                fullName={fullName}
                handleChangeStaffPassword={handleChangeStaffPassword}
                newPassword={newPassword}
                onNewPasswordChange={onNewPasswordChange}
                loading={updating}
              />
            </Card.Section>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title="Account Permissions">
          <Card sectioned>
            <Card.Section title="CUSTOMIZE PERMISSIONS">
              <Stack vertical>
                <TextStyle variation="subdued">
                  {`Enable or restrict ${fullName}'s access to various parts of this
                    site.`}
                </TextStyle>
                <Checkbox
                  label="Full access"
                  checked={form.values.full_access}
                  onChange={(checked) =>
                    form.setFieldValue('full_access', checked)
                  }
                  helpText="This gives the user full access to all the features on this platform"
                />
              </Stack>
            </Card.Section>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title="Account Actions">
          <StaffAccountActions
            profile={profile}
            loading={updating}
            toggleAccountStatus={toggleAccountStatus}
            deleteStaffAccount={deleteStaffAccount}
          />
        </Layout.AnnotatedSection>
      </Layout>
      <PageActions
        primaryAction={{
          content: 'Save Edits',
          loading: updating,
          onAction: form.handleSubmit,
        }}
      />
    </Page>
  );
}

export default ViewAccount;
