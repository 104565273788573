import React from 'react';
import { Modal, Stack, TextStyle, Link } from '@shopify/polaris';
import { formatDateToReadableString } from 'utils/date';
import {
  isCryptoPayout,
  isFiatDeposit,
  isFiatPayout,
  isCryptoDeposit,
  isInternalTransfer,
  isExchange,
  isOrder,
} from 'utils/transactions';
import StatusBadge from 'components/StatusBadge';
import { formatNumberToMoney } from 'utils/money';

export default function TransactionModal({ transaction, active, onClose }) {
  let path;
  if (
    isCryptoPayout(transaction.reference) ||
    isFiatPayout(transaction.reference) ||
    isInternalTransfer(transaction.reference)
  ) {
    path = '/payouts/groot';
  }
  if (isFiatDeposit(transaction.reference)) {
    path = '/deposits/cash-deposits/groot';
  }
  if (isCryptoDeposit(transaction.reference)) {
    path = '/deposits/crypto-deposits/groot';
  }
  if (isExchange(transaction.reference) || isOrder(transaction.reference)) {
    path = '/orders/groot';
  }

  const receiver = transaction.sub_description?.includes('@')
    ? transaction.sub_description.slice(
        transaction.sub_description.indexOf('@'),
      )
    : undefined;

  return (
    <Modal open={active} onClose={onClose} title={`Transaction Details`}>
      <Modal.Section>
        <Stack vertical spacing="loose">
          {transaction.description && (
            <Stack alignment="center" distribution="equalSpacing">
              <TextStyle>Description</TextStyle>
              <TextStyle>{transaction.description}</TextStyle>
            </Stack>
          )}
          {transaction.sub_description && (
            <Stack alignment="center" distribution="equalSpacing">
              <TextStyle>Sub-description</TextStyle>
              {receiver ? (
                <Link url={`/customers?q=${receiver.replace('@', '')}`}>
                  {transaction.sub_description}
                </Link>
              ) : (
                <TextStyle>{transaction.sub_description}</TextStyle>
              )}
            </Stack>
          )}
          {transaction.reference && (
            <Stack alignment="center" distribution="equalSpacing">
              <TextStyle>Reference</TextStyle>
              <TextStyle variation="strong">
                {path ? (
                  <Link url={`${path}?q=${transaction.reference}`}>
                    {transaction.reference}
                  </Link>
                ) : (
                  transaction.reference
                )}
              </TextStyle>
            </Stack>
          )}
          {transaction.meta.debit && (
            <Stack alignment="center" distribution="equalSpacing">
              <TextStyle>Debit</TextStyle>
              <TextStyle variation="negative">
                {transaction.meta.debit}
              </TextStyle>
            </Stack>
          )}
          {transaction.meta.credit && (
            <Stack alignment="center" distribution="equalSpacing">
              <TextStyle>Credit</TextStyle>
              <TextStyle variation="positive">
                {transaction.meta.credit}
              </TextStyle>
            </Stack>
          )}
          {transaction.available_balance && (
            <Stack alignment="center" distribution="equalSpacing">
              <TextStyle>Available Balance</TextStyle>
              <TextStyle>
                {formatNumberToMoney(transaction.available_balance, {
                  category: transaction.amount_currency,
                  currencyCode: transaction.amount_currency,
                })}
              </TextStyle>
            </Stack>
          )}
          {transaction.pending_balance && (
            <Stack alignment="center" distribution="equalSpacing">
              <TextStyle>Pending Balance</TextStyle>
              <TextStyle>
                {formatNumberToMoney(transaction.pending_balance, {
                  category: transaction.amount_currency,
                  currencyCode: transaction.amount_currency,
                })}
              </TextStyle>
            </Stack>
          )}
          {transaction.hold && (
            <Stack alignment="center" distribution="equalSpacing">
              <TextStyle>In Hold</TextStyle>
              <TextStyle>
                {formatNumberToMoney(transaction.hold, {
                  category: transaction.amount_currency,
                  currencyCode: transaction.amount_currency,
                })}
              </TextStyle>
            </Stack>
          )}
          {transaction.total_balance && (
            <Stack alignment="center" distribution="equalSpacing">
              <TextStyle>Total Balance</TextStyle>
              <TextStyle>
                {formatNumberToMoney(transaction.total_balance, {
                  category: transaction.amount_currency,
                  currencyCode: transaction.amount_currency,
                })}
              </TextStyle>
            </Stack>
          )}
          {transaction.status && (
            <Stack alignment="center" distribution="equalSpacing">
              <TextStyle>Status</TextStyle>
              <StatusBadge status={transaction.status} />
            </Stack>
          )}
          {transaction.timestamp && (
            <Stack alignment="center" distribution="equalSpacing">
              <TextStyle>Date</TextStyle>
              <TextStyle>
                {formatDateToReadableString(new Date(transaction.timestamp))}
              </TextStyle>
            </Stack>
          )}
        </Stack>
      </Modal.Section>
    </Modal>
  );
}
