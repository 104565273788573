import actionTypes from '../actionTypes';

export const FETCH_ORDERS = actionTypes('FETCH_ORDERS');
export const SET_ORDERS_APP = 'SET_ORDERS_APP';
export const FETCH_ORDER = actionTypes('FETCH_ORDER');
export const CREATE_CHAIN_ORDER = actionTypes('CREATE_CHAIN_ORDER');
export const CREATE_TRADE = actionTypes('CREATE_TRADE');
export const COMPLETE_TRADES = actionTypes('COMPLETE_TRADES');
export const DELETE_TRADE = actionTypes('DELETE_TRADE');
export const UPLOAD_TRADEs = actionTypes('UPLOAD_TRADES');
