import styled from 'styled-components';

const MarketPlaceListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 10px;
  justify-items: start;
  align-items: start;
  & > * {
    /* white-space: nowrap;
    box-sizing: border-box; */
  }

  &.heading {
    padding: 12px 20px 12px 20px;

    &--with-checkbox {
      padding: 12px 20px 12px 53px;
    }
    @media only screen and (max-width: 1360px) {
      display: none;
    }
  }
`;

export default MarketPlaceListContainer;
