import React from 'react';
import { Modal, Stack, Button, TextStyle } from '@shopify/polaris';
import ImageGallery from 'components/ImageGallery';
import { renderStatusBadge } from 'utils/formatter';

export default function DocumentModal({
  document,
  active,
  approveVerification,
  handleClose,
  loading,
}) {
  return (
    <Modal open={active} onClose={handleClose} title="Document details">
      <Modal.Section>
        <ImageGallery images={document.images} />
      </Modal.Section>
      <Modal.Section>
        <Stack horizontal distribution="equalSpacing" alignment="center">
          <TextStyle>Level</TextStyle>
          <TextStyle variation="strong">{document.level}</TextStyle>
        </Stack>
      </Modal.Section>
      <Modal.Section>
        <Stack horizontal distribution="equalSpacing" alignment="center">
          {renderStatusBadge(document.status)}
          {document.status.toLowerCase() === 'pending' && (
            <Button primary loading={loading} onClick={approveVerification}>
              Approve
            </Button>
          )}
        </Stack>
      </Modal.Section>
    </Modal>
  );
}
