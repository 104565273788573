import api from '../../utils/api';
import {
  FETCH_ORDERS,
  FETCH_ORDER,
  CREATE_TRADE,
  COMPLETE_TRADES,
  DELETE_TRADE,
  CREATE_CHAIN_ORDER,
  UPLOAD_TRADEs,
} from './actionTypes';

/**
 * Type definitions
 *
 * @typedef { import('../types').GenericDispatchAction } GenericDispatchAction
 */

/**
 * Fetch all orders for an app
 * @param {string} app - App name
 * @param {string} queryString
 * @returns { { type : string; payload : { app : string; queryString : string } } }
 */
export const fetchOrders = (app, queryString) => {
  return {
    type: FETCH_ORDERS.default,
    payload: { app, queryString },
  };
};

/**
 * Fetch an order
 * @param {string} app - App name
 * @param {string} orderId
 * @returns { GenericDispatchAction }
 */
export const fetchOrder = (app, orderId) => (dispatch) => {
  return dispatch({
    type: FETCH_ORDER.default,
    payload: api.get(`/v1.1/orders/${app}/${orderId}`),
  });
};

/**
 * Create a chain order
 * @param {string} app - App name
 * @param {string} orderId
 * @returns { GenericDispatchAction }
 */

export const createChainOrder = (app, orderId) => (dispatch) => {
  return dispatch({
    type: CREATE_CHAIN_ORDER.default,
    payload: api.post(`/v1.1/orders/chain`, {
      app,
      app_order_id: orderId,
    }),
  }).then((res) => {
    dispatch(fetchOrder(app, orderId));
    return res;
  });
};

/**
 * Create a trade for an an order
 * @param {string} app - App name
 * @param {string} orderId
 * @param {Object} payload
 * @param {number} payload.price
 * @param {number} payload.base_volume
 * @param {number} payload.fee
 * @returns { GenericDispatchAction }
 */
export const createOrderTrade = (app, orderId, { price, base_volume, fee }) => (
  dispatch,
) => {
  return dispatch({
    type: CREATE_TRADE.default,
    payload: api.post(`/v1.1/orders/${app}/${orderId}/trades`, {
      price,
      base_volume,
      fee,
    }),
  }).then((res) => {
    dispatch(fetchOrder(app, orderId));
    return res;
  });
};

/**
 * Create trades needed to complete an order
 * @param {string} app - App name
 * @param {string} orderId
 * @returns { GenericDispatchAction }
 */

export const completeOrderTrades = (app, orderId) => (dispatch) => {
  return dispatch({
    type: COMPLETE_TRADES.default,
    payload: api.post(`/v1.1/orders/${app}/${orderId}/settle`),
  }).then((res) => {
    dispatch(fetchOrder(app, orderId));
    return res;
  });
};

/**
 * Delete an order trade
 * @param {string} app - App name
 * @param {string} orderId
 * @param {string} tradeId
 * @returns { GenericDispatchAction }
 */
export const deleteOrderTrade = (app, orderId, tradeId) => (dispatch) => {
  return dispatch({
    type: DELETE_TRADE.default,
    payload: api.delete(`/v1.1/orders/${app}/${orderId}/trades/${tradeId}`),
  }).then((res) => {
    dispatch(fetchOrder(app, orderId));
    return res;
  });
};

/**
 *
 * @param {string} app
 * @param {File} file
 * @returns
 */

export const uploadTrades = (app, file) => (dispatch) => {
  const formData = new FormData();
  formData.append('file', file);

  return dispatch({
    type: UPLOAD_TRADEs.default,
    payload: api.post(`/v1.1/orders/${app}/trades/bulk`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    }),
  }).then((res) => {
    const urlParams = new URLSearchParams(window.location.search);
    dispatch(fetchOrders(app, `?${urlParams}`));
    return res;
  });
};
