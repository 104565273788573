import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import useQueryParams from 'hooks/useQueryParams';
import { addCryptoDeposit, fetchDeposits } from 'redux/deposits/actions';
import { fetchAssets } from 'redux/assets/actions';
import { formateDateToISOWithoutSSSZ } from 'utils/date';
import useDateFilter from 'hooks/useDateFilter';
import useUpdateEffect from 'hooks/useUpdatedEffect';
import { periodDatesAndLabels } from 'utils/period';
import { isInitialQuerySetWithRange } from 'utils/helpers';

const useDeposit = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { base, app } = useParams();

  const initialQueryParams = useMemo(() => {
    const initialURLQueryHasRangeSet = isInitialQuerySetWithRange();

    return {
      status: '',
      fulfilment: '',
      channel: '',
      provider: '',
      q: '',
      page: 1,
      asset: '',
      period: 'day',
      for: !initialURLQueryHasRangeSet
        ? formateDateToISOWithoutSSSZ(new Date())
        : '',
      from: '',
      to: '',
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [base]);

  const { fetching, data, meta, addingDeposit, assetData } = useSelector(
    (state) => ({
      ...state.deposits,
      assetData: state.assets.data,
    }),
  );

  const dateFilters = useDateFilter();

  const { queryParams, updateQueryParams, clearAllFilters } =
    useQueryParams(initialQueryParams);

  const [modals, setModals] = React.useState({
    addDeposit: false,
  });

  const toggleModal = (modal) => {
    setModals((m) => ({ ...m, [modal]: !m[modal] }));
  };

  function handlePreviousPage() {
    updateQueryParams('page', meta.page - 1);
  }

  function handleNextPage() {
    updateQueryParams('page', meta.page + 1);
  }

  const handleAddDeposit = (hash) => {
    dispatch(addCryptoDeposit(hash)).then(() => {
      toggleModal('addDeposit');
    });
  };

  React.useEffect(() => {
    dispatch(fetchDeposits({ base, app, query: location.search }));
  }, [location.search, base, app, dispatch]);

  React.useEffect(() => {
    dispatch(fetchAssets());
  }, [dispatch]);

  useUpdateEffect(() => {
    if (dateFilters.selectedDates.to !== dateFilters.selectedDates.from) {
      updateQueryParams({
        from: formateDateToISOWithoutSSSZ(dateFilters.selectedDates?.from),
        to: formateDateToISOWithoutSSSZ(dateFilters.selectedDates?.to),
        period: 'range',
        for: '',
      });
    }
  }, [dateFilters.selectedDates]);

  useUpdateEffect(() => {
    switch (dateFilters.selectedPeriod) {
      case 'today':
      case 'yesterday':
        updateQueryParams({
          from: '',
          to: '',
          period: 'day',
          for: formateDateToISOWithoutSSSZ(
            periodDatesAndLabels[
              dateFilters.selectedPeriod
            ]?.getStartAndEndDates().from,
          ),
        });
        break;
      case 'last-7-days':
      case 'last-30-days':
        updateQueryParams({
          period: 'range',
          for: '',
        });

        break;

      default:
        break;
    }
  }, [dateFilters.selectedPeriod]);

  const reset = () => {
    clearAllFilters();
    dateFilters.handleChangeInSelectedPeriod('day');
  };

  useUpdateEffect(() => {
    reset();
  }, [base]);

  return {
    updateQueryParams,
    clearAllFilters,
    handlePreviousPage,
    handleNextPage,
    handleAddDeposit,
    toggleModal,
    base,
    app,
    queryParams,
    fetching,
    addingDeposit,
    meta,
    modals,
    deposits: Object.values(data),
    assetData: Object.keys(assetData),
    ...dateFilters,
  };
};

export default useDeposit;
