import Login from '../pages/Login';
import Invite from '../pages/Invite';
import AppLayout from '../components/AppLayout';

export default [
  {
    path: '/invite/accept/:token',
    component: Invite,
    requiredAuth: false,
  },
  {
    path: '/login',
    component: Login,
    requiredAuth: false,
  },
  {
    path: '/',
    component: AppLayout,
    requiredAuth: true,
  },
];
