import React from 'react';
import { Card, TextStyle, Stack } from '@shopify/polaris';
import { formatISODateToDayMonthYear } from 'utils/date';

export default function CustomerProfile({ customer, toggleEditProfileModal }) {
  const {
    email,
    profile: { date_of_birth, gender, username },
  } = customer;

  return (
    <Card>
      <Card.Section
        title="Profile"
        sectioned
        actions={[{ content: 'Edit', onAction: toggleEditProfileModal }]}
      >
        <Stack vertical spacing="tight">
          {username && <TextStyle>b-tag: {username}</TextStyle>}
          {email && <TextStyle>{email}</TextStyle>}
          {gender && <TextStyle>{gender}</TextStyle>}
          {date_of_birth && (
            <TextStyle>{`Born ${formatISODateToDayMonthYear(
              new Date(date_of_birth),
            )}`}</TextStyle>
          )}
        </Stack>
      </Card.Section>
    </Card>
  );
}
