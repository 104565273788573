import React from 'react';
import { Badge } from '@shopify/polaris';
import { capitalize } from './strings';

export const renderStatusBadge = (status = '', { dark = false } = {}) => {
  switch (status.toLowerCase()) {
    case 'success':
    case 'accepted':
    case 'settled':
    case 'completed':
    case 'fulfilled':
    case 'active':
    case 'verified':
    case 'disputed':
    case 'created':
    case 'online':
      return (
        <Badge status={dark ? undefined : 'success'} progress="complete">
          {capitalize(status)}
        </Badge>
      );
    case 'pending':
    case 'unfulfilled':
    case 'unsettled':
    case 'processing':
      return (
        <Badge
          status={dark ? undefined : 'attention'}
          progress="partiallyComplete"
        >
          {capitalize(status)}
        </Badge>
      );
    case 'unverified':
    case 'rejected':
    case 'inactive':
    case 'cancelled':
    case 'failed':
    case 'abandoned':
    case 'flagged':
    case 'canceled':
    case 'expired':
    case 'expired quote':
    case 'stopped':
    case 'offline':
      return (
        <Badge status={dark ? undefined : 'warning'} progress="incomplete">
          {capitalize(status)}
        </Badge>
      );
    default:
      return <Badge status="default">{capitalize(status)}</Badge>;
  }
};
