import React from 'react';
import { Card, Stack, Checkbox } from '@shopify/polaris';

export default function CustomerActions({
  payout,
  trade,
  deposit,
  p2p,
  toggleAction,
  loading,
}) {
  return (
    <Card>
      <Card.Section title={`User Actions`} sectioned>
        <Stack vertical spacing="extraTight">
          <Checkbox
            label="Payout"
            checked={payout}
            disabled={loading}
            onChange={() => toggleAction('payout')}
          />
          <Checkbox
            label="Deposit"
            disabled={loading}
            checked={deposit}
            onChange={() => toggleAction('deposit')}
          />
          <Checkbox
            label="Trade"
            disabled={loading}
            checked={trade}
            onChange={() => toggleAction('trade')}
          />
          <Checkbox
            label="P2P"
            disabled={loading}
            checked={p2p}
            onChange={() => toggleAction('p2p')}
          />
        </Stack>
      </Card.Section>
    </Card>
  );
}
