import {
  FETCH_DOCUMENT,
  FETCH_DOCUMENTS,
  UPDATE_DOCUMENT,
  CLEAR_DOCUMENT,
  FETCH_COUNTRIES,
  FETCH_DOCUMENTS_TYPES,
} from './actionTypes';
import parseError from 'utils/parseError';
import { mapKeys } from 'lodash';

const INITIAL_STATE = {
  data: {},
  document: {},
  types: [],
  countries: [],
  meta: {},
  fetching: false,
  editing: false,
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_DOCUMENTS.pending:
    case FETCH_COUNTRIES.pending:
    case FETCH_DOCUMENTS_TYPES.pending:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case FETCH_DOCUMENT.pending:
      return {
        ...state,
        fetching: true,
        error: null,
        document: {},
      };

    case FETCH_DOCUMENTS.rejected:
    case FETCH_DOCUMENT.rejected:
    case FETCH_COUNTRIES.rejected:
    case FETCH_DOCUMENTS_TYPES.rejected:
      return {
        ...state,
        fetching: false,
        error: parseError(action.payload),
      };

    case FETCH_DOCUMENTS.fulfilled:
      return {
        ...state,
        fetching: false,
        data: mapKeys(action.payload.data.data, 'id'),
        meta: action.payload.data.meta,
      };

    case FETCH_DOCUMENT.fulfilled:
      return {
        ...state,
        fetching: false,
        document: action.payload.data,
      };

    case FETCH_COUNTRIES.fulfilled:
      return {
        ...state,
        fetching: false,
        countries: action.payload.data,
      };

    case FETCH_DOCUMENTS_TYPES.fulfilled:
      return {
        ...state,
        fetching: false,
        types: action.payload?.data?.[0]?.document_type || [],
      };

    case UPDATE_DOCUMENT.pending:
      return {
        ...state,
        editing: true,
      };

    case UPDATE_DOCUMENT.rejected:
      return {
        ...state,
        editing: false,
      };

    case UPDATE_DOCUMENT.fulfilled:
      return {
        ...state,
        editing: false,
      };

    case CLEAR_DOCUMENT.fulfilled:
      return {
        ...state,
        document: {},
      };

    default:
      return state;
  }
};
