import axios from 'axios';

import api from 'utils/api';
import { GET_INVITE, ACCEPT_INVITE, CLEAR_INVITE } from './actionTypes';

/**
 * Type definitions
 *
 * @typedef { import('../types').GenericDispatchAction } GenericDispatchAction
 * @typedef { import('./types').AcceptInviteUserDetails } AcceptInviteUserDetails
 */

/**
 *
 * @param { string } jwt
 * @returns { GenericDispatchAction }
 */
export const getInvite = (jwt) => (dispatch) => {
  const payload = api.get(`/v1/invite?jwt=${jwt}`);
  return dispatch({ type: GET_INVITE.default, payload });
};

/**
 * @returns { GenericDispatchAction }
 */

export const clearInvite = () => (dispatch) => {
  return dispatch({ type: CLEAR_INVITE });
};

/**
 *
 * @param { AcceptInviteUserDetails } userDetails
 * @param { string } token
 * @returns { GenericDispatchAction }
 */

export const acceptInvite = (userDetails, token) => (dispatch) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const payload = axios.post(`${BASE_URL}/v1/invite/accept`, userDetails);
  return dispatch({ type: ACCEPT_INVITE.default, payload });
};
