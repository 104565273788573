/* eslint-disable react/display-name */
/* eslint-disable no-undef */
import React from 'react';
import { ResourceList, TextStyle } from '@shopify/polaris';
import { formatNumberToMoney } from 'utils/money';
import { renderStatusBadge } from 'utils/formatter';
import { formatISODateToTime, formatISODateToDayMonthYear } from 'utils/date';

export default function TransactionItem(
  transactionsFetching,
  modifyTransactionStatus,
  onSelect,
) {
  return (transaction) => {
    const { description, reference, status, meta, timestamp } = transaction;
    const shortcutActions =
      status === 'pending'
        ? [
            {
              content: 'Complete Transaction',
              loading: transactionsFetching,
              onAction: () => {
                const status = 'success';
                modifyTransactionStatus(reference, status);
              },
            },
          ]
        : null;

    let amount = 0;
    let currency = '';

    if (meta.credit) {
      [currency, amount] = meta.credit.split(' ');
    }
    if (meta.debit) {
      [currency, amount] = meta.debit.split(' ');
    }

    return (
      <ResourceList.Item
        id={reference}
        accessibilityLabel={`View details for ${reference}`}
        shortcutActions={shortcutActions}
        onClick={() => onSelect(transaction)}
      >
        <div className="Txn_Main">
          <div className="Txn_Description">
            <TextStyle>{description}</TextStyle> {reference && ' - '}
            <TextStyle variation="code"> {reference} </TextStyle>
          </div>
          <div className="Txn_Date">
            <TextStyle variation="subdued">
              {`${formatISODateToDayMonthYear(new Date(timestamp))}  at 
              ${formatISODateToTime(new Date(timestamp)).toLowerCase()}`}
            </TextStyle>
          </div>
          <div className="Txn_Status">
            <TextStyle>{renderStatusBadge(status)}</TextStyle>
          </div>
          <div className="Txn_Value">
            <TextStyle variation="strong">
              {meta.credit
                ? formatNumberToMoney(amount, {
                    currencyCode: currency,
                    category: currency,
                    codePosition: 'back',
                  })
                : formatNumberToMoney(amount, {
                    currencyCode: currency,
                    category: currency,
                    codePosition: 'back',
                  })}
            </TextStyle>
          </div>
        </div>
      </ResourceList.Item>
    );
  };
}
