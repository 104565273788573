import React from 'react';
import { useLocation } from 'react-router-dom';
import usePrevious from 'hooks/usePrevious';

export const PreviousLocationContext = React.createContext({
  previousLocation: null,
});

function PreviousLocationProvider({ children }) {
  const location = useLocation();
  const previousLocation = usePrevious(location);

  return (
    <PreviousLocationContext.Provider
      value={{
        previousLocation:
          previousLocation?.pathname !== location.pathname
            ? previousLocation
            : null,
      }}
    >
      {children}
    </PreviousLocationContext.Provider>
  );
}

export default PreviousLocationProvider;
