import React from 'react';
import { EmptyState } from '@shopify/polaris';

const InvitePageEmptyState = () => {
  return (
    <EmptyState
      heading="Your invite link might have expired or you might have a network issue."
      action={{ content: 'Login', url: '/login' }}
    >
      <p>Login if you already have an account</p>
    </EmptyState>
  );
};
export default InvitePageEmptyState;
