import { mapKeys } from 'lodash';
import {
  FETCH_PAYOUTS,
  FETCH_PAYOUT,
  UPDATE_PAYOUT_STATUS,
  RETRY_PAYOUT,
  SET_CURRENT_ACTION,
  CLEAR_MESSAGE,
} from './actionTypes';
import parseError from 'utils/parseError';

const INITIAL_STATE = {
  data: {},
  meta: {},
  fetching: false,
  loading: false,
  error: null,
  message: null,
  action: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_PAYOUTS.pending:
      return {
        ...state,
        fetching: true,
      };
    case FETCH_PAYOUTS.rejected:
      return {
        ...state,
        fetching: false,
      };
    case FETCH_PAYOUTS.fulfilled:
      return {
        ...state,
        fetching: false,
        data: mapKeys(action.payload.data.data, 'id'),
        meta: action.payload.data.meta,
      };
    case FETCH_PAYOUT.pending:
      return {
        ...state,
        fetching: true,
        error: null,
      };
    case FETCH_PAYOUT.rejected:
      return {
        ...state,
        fetching: false,
        error: parseError(action.payload),
      };
    case FETCH_PAYOUT.fulfilled:
      return {
        ...state,
        fetching: false,
        error: null,
        data: { ...state.data, [action.payload.data.id]: action.payload.data },
      };
    case UPDATE_PAYOUT_STATUS.pending:
    case RETRY_PAYOUT.pending:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PAYOUT_STATUS.rejected:
    case RETRY_PAYOUT.rejected:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_PAYOUT_STATUS.fulfilled:
    case RETRY_PAYOUT.fulfilled:
      return {
        ...state,
        loading: false,
        action: '',
      };
    case SET_CURRENT_ACTION.fulfilled:
      return {
        ...state,
        action: action.payload,
      };
    case CLEAR_MESSAGE.fulfilled:
      return {
        ...state,
        message: null,
        error: null,
      };
    default:
      return state;
  }
};
