import React from 'react';
import { Card, Avatar, Badge, Link, Stack } from '@shopify/polaris';
import { getInitials } from 'utils/strings';

export default function AccountStaff({ staff }) {
  const fullName = `${staff.first_name} ${staff.last_name}`;
  return (
    <Card.Section>
      <Stack distribution="equalSpacing">
        <Stack alignment="center">
          <Avatar
            initials={getInitials(staff.first_name, staff.last_name)}
            name={fullName}
            large
          />
          <Stack vertical spacing="tight">
            <Link url={`/settings/accounts/${staff.id}`}>{fullName}</Link>
            <Badge status={staff.active ? 'success' : 'warning'}>
              {staff.active ? 'Active' : 'Inactive'}
            </Badge>
          </Stack>
        </Stack>
        <span>{staff.full_access ? 'Full access' : 'Limited access'}</span>
      </Stack>
    </Card.Section>
  );
}
