import React from 'react';
import { isValid as isValidDate } from 'date-fns';
import { Card, TextStyle, Stack, Loading } from '@shopify/polaris';
import { capitalize } from 'utils/strings';
import { formatISODateToDayMonthYear } from 'utils/date';

export default function CustomerAddress({
  customer,
  toggleEditPhoneModal,
  toggleEditAddressModal,
  toggleUpdateBvnModal,
  bvnDetails,
  bvnDetailsError,
  bvnDetailsFetching,
}) {
  const { profile, phone } = customer;

  return (
    <Card>
      <Card.Section
        title="Address"
        sectioned
        actions={[{ content: 'Edit', onAction: toggleEditAddressModal }]}
      >
        <Stack vertical spacing="tight">
          <TextStyle>
            {profile.street_address_1 && `${profile.street_address_1}, `}
            {profile.street_address_2 && `${profile.street_address_2}, `}
            {profile.city && profile.city}
          </TextStyle>
          {profile.state && <TextStyle>{profile.state}</TextStyle>}
          {profile.country && <TextStyle>{profile.country}</TextStyle>}
        </Stack>
      </Card.Section>
      <Card.Section
        title="Bank Verification no"
        sectioned
        actions={[{ content: 'Edit', onAction: toggleUpdateBvnModal }]}
      >
        {profile.bvn ? (
          <Stack vertical spacing="tight">
            <TextStyle>{profile.bvn}</TextStyle>
            {bvnDetailsFetching && <Loading />}
            {bvnDetails && (
              <Stack vertical spacing="tight">
                <TextStyle>
                  {capitalize(bvnDetails.first_name || '')}{' '}
                  {capitalize(bvnDetails.last_name || '')}
                </TextStyle>
                <TextStyle>
                  {bvnDetails.dob && isValidDate(new Date(bvnDetails.dob))
                    ? formatISODateToDayMonthYear(new Date(bvnDetails.dob))
                    : ''}
                </TextStyle>
                <TextStyle>{bvnDetails?.mobile}</TextStyle>
              </Stack>
            )}
            {bvnDetailsError &&
              bvnDetailsError.response &&
              String(bvnDetailsError.response.status) === '404' && (
                <TextStyle variation="negative">No BVN record found</TextStyle>
              )}
          </Stack>
        ) : (
          <TextStyle variation="subdued">No BVN</TextStyle>
        )}
      </Card.Section>
      <Card.Section
        title="Phone"
        sectioned
        actions={[{ content: 'Edit', onAction: toggleEditPhoneModal }]}
      >
        {phone.number ? (
          phone.number
        ) : (
          <TextStyle variation="subdued">No Phone</TextStyle>
        )}
      </Card.Section>
    </Card>
  );
}
