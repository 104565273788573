import api from 'utils/api';
import {
  FETCH_CUSTOMERS,
  FETCH_CUSTOMER,
  CLEAR_MESSAGE,
  CUSTOMER_ACTION,
  FETCH_TRANSACTIONS,
  EDIT_TRANSACTION_STATUS,
  FETCH_WALLETS,
  EDIT_CUSTOMER_WALLET_BALANCE,
  FETCH_CUSTOMER_ACCOUNT_STATEMENT,
  FETCH_CUSTOMER_ACCOUNT_STATEMENT_SUMMARY,
  CLEAR_CUSTOMER_ACCOUNT_STATEMENT,
  EDIT_CUSTOMER_PHONE,
  EDIT_CUSTOMER_PROFILE,
  EDIT_CUSTOMER_ADDRESS,
  UPDATE_CUSTOMER_LEVEL,
  TOGGLE_CUSTOMER_FEATURE,
  FETCH_CUSTOMER_DOCUMENTS,
  FETCH_CUSTOMER_BANKS,
  FETCH_CUSTOMER_CARDS,
  FETCH_CUSTOMER_CRYPTO_ADDRESSES,
  RESOLVE_CUSTOMER_BVN,
  FETCH_CUSTOMER_ACTIVITIES,
  FETCH_CUSTOMER_DEVICES,
  FETCH_CUSTOMER_MOBILE_APPLICATIONS,
  FETCH_CUSTOMER_WEB_SESSIONS,
  REMOVE_CUSTOMER_DEVICE,
  REMOVE_CUSTOMER_MOBILE_APPLICATION,
  SIGN_OUT_CUSTOMER_WEB_SESSION,
  UPDATE_CUSTOMER_BVN,
  EDIT_CUSTOMER_BVN,
  FETCH_WHITELISTED_NAMES,
  DELETE_WHITELISTED_NAME,
  FETCH_CUSTOMER_RECURRING_ORDERS,
  DELETE_CUSTOMER_RECURRING_ORDER,
  REMOVE_CUSTOMER_BVN,
  FETCH_CUSTOMER_INACTIVE_WALLETS,
  ACTIVATE_CUSTOMER_INACTIVE_WALLET,
} from './actionTypes';

/**
 * Type definitions
 *
 * @typedef { import('../types').GenericDispatchAction } GenericDispatchAction
 * @typedef { import('./types').EditTransactionStatusParameter } EditTransactionStatusParamter
 * @typedef { import('./types').FetchCustomerAccountsStatementParameter } FetchCustomerAccountsStatementSummaryParameter
 * @typedef { import('./types').FetchCustomerAccountsStatementParameter } FetchCustomerAccountsStatement
 * @typedef { import('./types').ToggleCustomerFeatureParameter } ToggleCustomerFeatureParameter
 * @typedef { import('./types').EditCustomerWalletBalanceParameter } EditCustomerWalletBalanceParameter
 * @typedef { import('./types').EditCustomerPhoneNumberParameter } EditCustomerPhoneNumberParameter
 * @typedef { import('./types').EditCustomerProfileParameter } EditCustomerProfileParameter
 * @typedef { import('./types').EditCustomerAddressParameter } EditCustomerAddressParameter
 * @typedef { import('./types').UpdateCustomerLevelParameter } UpdateCustomerLevelParameter
 * @typedef { import('./types').EditCustomerBvnDetailsParameter  } EditCustomerBvnDetailsParameter
 * @typedef { import('./types').ActivateCustomerInactiveWalletsParameter  } ActivateCustomerInactiveWalletsParameter
 */

/**
 *
 * @param {string} query - A string that determines the customers to be returned from the endpoint
 * @description - Fetches customers corresponding to the query strings
 * @returns { { type : string; payload : { query : string } } }
 */

export const fetchCustomers = (query) => {
  return {
    type: FETCH_CUSTOMERS.default,
    payload: { query },
  };
};

/**
 *
 * @param {string} id - A string that represents the id of the customer to be fetched
 * @description Fetches the customer with the corresponding id
 * @returns { GenericDispatchAction }
 */

export const fetchCustomer = (id) => (dispatch) => {
  return dispatch({
    type: FETCH_CUSTOMER.default,
    payload: api.get(`/v1.1/customers/${id}`),
  });
};

/**
 *
 * @param {string} id - A string that represents the id of the customer to be fetched
 * @description Fetches the customer's document with the corresponding id
 * @returns { GenericDispatchAction }
 */

export const fetchCustomerDocuments = (id) => (dispatch) => {
  return dispatch({
    type: FETCH_CUSTOMER_DOCUMENTS.default,
    payload: api.get(`/v1.1/customers/${id}/documents`),
  });
};

/**
 *
 * @param {string} id - A string that represents the id of the customer to be fetched
 * @description Fetches the customer's cards with the corresponding id
 * @returns { GenericDispatchAction }
 */

export const fetchCustomerCards = (id) => (dispatch) => {
  return dispatch({
    type: FETCH_CUSTOMER_CARDS.default,
    payload: api.get(`/v1.1/customers/${id}/cards`),
  });
};

/**
 *
 * @param {string} id - A string that represents the id of the customer to be fetched
 * @description Fetches the customer's banks with the corresponding id
 * @returns { GenericDispatchAction }
 */

export const fetchCustomerBanks = (id) => (dispatch) => {
  return dispatch({
    type: FETCH_CUSTOMER_BANKS.default,
    payload: api.get(`/v1.1/customers/${id}/banks`),
  });
};

/**
 *
 * @param {string} id - A string that represents the id of the customer to be fetched
 * @description Fetches the customer's crypto addresses with the corresponding id
 * @returns { GenericDispatchAction }
 */

export const fetchCustomerCryptoAddresses = (id) => (dispatch) => {
  return dispatch({
    type: FETCH_CUSTOMER_CRYPTO_ADDRESSES.default,
    payload: api.get(`/v1.1/customers/${id}/crypto_addresses`),
  });
};

/**
 *
 * @param {string} id - A string that represents the id of the customer to be fetched
 * @param { number } page - Represents the page number to be fetched
 * @param { number } limit - Represents the number of transactions to fetch
 * @description Fetches the customer's transactions with the corresponding id
 * @returns { GenericDispatchAction }
 */

export const fetchTransactions = (id, page = 1, limit = 10) => (dispatch) => {
  const queryString = `?page=${page}&limit=${limit}`;
  return dispatch({
    type: FETCH_TRANSACTIONS.default,
    payload: api.get(`/v1.1/customers/${id}/transactions${queryString}`),
  });
};

/**
 *
 * @param { EditTransactionStatusParamter } param0  - An object that contains properties to attach to the api endpoint
 * @description Fetches the customer's document with the corresponding id
 * @returns { GenericDispatchAction }
 */

export const editTransactionStatus = ({ customerId, reference, status }) => (
  dispatch,
) => {
  const payload = api.put(
    `/v1.1/customers/${customerId}/transactions/${reference}/status`,
    { status },
  );
  return dispatch({
    type: EDIT_TRANSACTION_STATUS.default,
    payload,
  });
};

/**
 *
 * @param {string} id - A string that represents the id of the customer whose wallets are to be fetched
 * @description Fetches the customer's(with the corresponding id) walltets
 * @returns { GenericDispatchAction }
 */

export const fetchWallets = (id, page = 1) => (dispatch) => {
  const queryString = `?page=${page}`;
  return dispatch({
    type: FETCH_WALLETS.default,
    payload: api.get(`/v1.1/customers/${id}/wallets${queryString}`),
  });
};

/**
 *
 * @param {FetchCustomerAccountsStatementSummaryParameter} param0
 * @description Fetches the customer's account summary
 * @returns { GenericDispatchAction }
 */

export const fetchCustomerAccountStatementSummary = ({
  customerId,
  currency,
  query,
}) => (dispatch) => {
  return dispatch({
    type: FETCH_CUSTOMER_ACCOUNT_STATEMENT_SUMMARY.default,
    payload: api.get(
      `/v1.1/customers/${customerId}/accounts/${currency}/summary${query}`,
    ),
  });
};

/**
 *
 * @param {FetchCustomerAccountsStatement} param0
 * @description Fetches the account statement(corresponding to the query) of the customer with the corresponding id
 * @returns { GenericDispatchAction }
 */
export const fetchCustomerAccountStatement = ({
  customerId,
  currency,
  query = '',
}) => (dispatch) => {
  return dispatch({
    type: FETCH_CUSTOMER_ACCOUNT_STATEMENT.default,
    payload: api.get(
      `/v1.1/customers/${customerId}/accounts/${currency}/statement${query}`,
    ),
  });
};

/**
 * @description - clears customer account statement state data
 * @returns { GenericDispatchAction }
 */
export const clearCustomerAccountStatement = () => {
  return (dispatch) =>
    dispatch({
      type: CLEAR_CUSTOMER_ACCOUNT_STATEMENT.fulfilled,
    });
};

/**
 *
 * @param {string} id - Represents the id of the customer whose status is to be set
 * @description sets the status of the customer with the corresponding id
 * @returns { GenericDispatchAction }
 */
export const setCustomerStatus = (id) => (dispatch) => {
  return dispatch({
    type: CUSTOMER_ACTION.default,
    payload: api.put(`/v1.1/customers/${id}/state`),
  }).then((res) => {
    dispatch(fetchCustomer(id));
    return res;
  });
};

/**
 *
 * @param { ToggleCustomerFeatureParameter } param0
 * @returns { GenericDispatchAction }
 */

export const toggleCustomerFeature = ({ id, feature }) => (dispatch) => {
  return dispatch({
    type: TOGGLE_CUSTOMER_FEATURE.default,
    payload: api.put(`/v1.1/customers/${id}/${feature}`),
  }).then((res) => {
    dispatch(fetchCustomer(id));
    return res;
  });
};

/**
 *
 * @param {string} id - Represents the id of the customer
 * @description resets the customer's (with the corresponding id) password
 * @returns { GenericDispatchAction }
 */

export const resetCustomerPassword = (id) => (dispatch) => {
  return dispatch({
    type: CUSTOMER_ACTION.default,
    payload: api.put(`/v1.1/customers/${id}/password`),
  }).then((res) => {
    dispatch(fetchCustomer(id));
    return res;
  });
};

/**
 *
 * @param {string} id represents the id of the customer
 * @returns { GenericDispatchAction }
 */
export const resendCustomerVerificationEmail = (id) => (dispatch) => {
  return dispatch({
    type: CUSTOMER_ACTION.default,
    payload: api.put(`/v1.1/customers/${id}/verify`),
  }).then((res) => {
    dispatch(fetchCustomer(id));
    return res;
  });
};

/**
 * @param { EditCustomerWalletBalanceParameter } param0
 * @description edits customer's (whose id matches the customerId) wallet balance
 * @returns { GenericDispatchAction }
 */
export const editCustomerWalletBalance = ({
  customerId,
  currency,
  amount,
  action,
}) => (dispatch) => {
  const payload = api.post(`/v1/wallets/${customerId}/fund`, {
    currency,
    amount,
    action,
  });
  return dispatch({ type: EDIT_CUSTOMER_WALLET_BALANCE.default, payload })
    .then((res) => {
      dispatch(fetchWallets(customerId));
      return res;
    })
    .then((res) => {
      dispatch(fetchTransactions(customerId));
      return res;
    });
};

/**
 *
 * @param { EditCustomerPhoneNumberParameter } param0
 * @description Edits customer's (whose id matches the customerId) phone number
 * @return { GenericDispatchAction }
 */

export const editCustomerPhoneNumber = ({ customerId, phone }) => (
  dispatch,
) => {
  return dispatch({
    type: EDIT_CUSTOMER_PHONE.default,
    payload: api.put(`/v1.1/customers/${customerId}/phone`, { phone }),
  }).then((res) => {
    dispatch(fetchCustomer(customerId));
    return res;
  });
};

/**
 *
 * @param { EditCustomerProfileParameter } param0
 * @description edit customer's (whose id matches the customerId) profile
 * @returns { GenericDispatchAction }
 */

export const editCustomerProfile = ({ customerId, data }) => (dispatch) => {
  return dispatch({
    type: EDIT_CUSTOMER_PROFILE.default,
    payload: api.put(`/v1.1/customers/${customerId}/profile`, data),
  }).then((res) => {
    dispatch(fetchCustomer(customerId));
    return res;
  });
};

/**
 *
 * @param { EditCustomerAddressParameter } param0
 * @description edit customer's(whose id matches the customerId) address with the data given
 * @returns { GenericDispatchAction }
 */
export const editCustomerAddress = ({ customerId, data }) => (dispatch) => {
  return dispatch({
    type: EDIT_CUSTOMER_ADDRESS.default,
    payload: api.put(`/v1.1/customers/${customerId}/address`, data),
  }).then((res) => {
    dispatch(fetchCustomer(customerId));
    return res;
  });
};

/**
 *
 * @param { UpdateCustomerLevelParameter } param0
 * @description Updates the customer's level and then fetches the customer
 * @returns { GenericDispatchAction }
 */
export const updateCustomerLevel = ({ customerId, level }) => (dispatch) => {
  return dispatch({
    type: UPDATE_CUSTOMER_LEVEL.default,
    payload: api.put(`/v1.1/customers/${customerId}/level`, { level }),
  }).then((res) => {
    dispatch(fetchCustomer(customerId));
    return res;
  });
};

/**
 *
 * @param {string} bvn
 * @returns { GenericDispatchAction }
 */

export const resolveCustomerBvn = (bvn) => (dispatch) => {
  return dispatch({
    type: RESOLVE_CUSTOMER_BVN.default,
    payload: api.get(`/v1.1/customers/resolve/${bvn}`),
  });
};

/**
 *
 * @param {string} customerId
 * @param { string } bvn
 * @returns { GenericDispatchAction }
 */

export const updateCustomerBvn = (customerId, bvn) => (dispatch) => {
  return dispatch({
    type: UPDATE_CUSTOMER_BVN.default,
    payload: api.put(`/v1.1/customers/${customerId}/bvn`, { bvn }),
  }).then(() => {
    dispatch(resolveCustomerBvn(bvn));
  });
};

/**
 *
 * @param { EditCustomerBvnDetailsParamater } param0
 * @description edit customer's bvn details with the data passed
 * @returns { GenericDispatchAction }
 */

export const editCustomerBvnDetails = ({ bvn, mobile, dob }) => (dispatch) => {
  return dispatch({
    type: EDIT_CUSTOMER_BVN.default,
    payload: api.put(`/v1.1/customers/bvn`, { bvn, mobile, dob }),
  }).then(() => {
    dispatch(resolveCustomerBvn(bvn));
  });
};

export const removeCustomerBvn = (id) => (dispatch) => {
  return dispatch({
    type: REMOVE_CUSTOMER_BVN.default,
    payload: api.delete(`/v1.1/customers/${id}/bvn`),
  });
};

/**
 *
 * @param {string} id
 * @param { number } page
 * @description fetches customer's activities according to the page parameter passed
 * @returns { GenericDispatchAction }
 */
export const fetchCustomerActivities = (id, page = 1) => (dispatch) => {
  return dispatch({
    type: FETCH_CUSTOMER_ACTIVITIES.default,
    payload: api.get(`/v1.1/customers/${id}/activities?page=${page}`),
  });
};

/**
 *
 * @param {string} id
 * @param { number } page
 * @param { number } limit
 * @description fetches the customer devices according to the pagination data passed
 * @returns { GenericDispatchAction }
 */

export const fetchCustomerDevices = (id, page = 1, limit = 10) => (
  dispatch,
) => {
  return dispatch({
    type: FETCH_CUSTOMER_DEVICES.default,
    payload: api.get(
      `/v1.1/customers/${id}/devices?page=${page}&limit=${limit}`,
    ),
  });
};

/**
 *
 * @param {string} id
 * @param { number } page
 * @param { number } limit
 * @description fetches the customer's web sessions according to the pagination data passed
 * @returns { GenericDispatchAction }
 */
export const fetchCustomerWebSessions = (id, page = 1, limit = 10) => (
  dispatch,
) => {
  return dispatch({
    type: FETCH_CUSTOMER_WEB_SESSIONS.default,
    payload: api.get(
      `/v1.1/customers/${id}/sessions?page=${page}&limit=${limit}`,
    ),
  });
};

/**
 *
 * @param {string} id
 * @param { number } page
 * @param { number } limit
 * @description fetches customer's mobile applications according to the pagination data passed
 * @returns { GenericDispatchAction }
 */

export const fetchCustomerMobileApplications = (id, page = 1, limit = 10) => (
  dispatch,
) => {
  return dispatch({
    type: FETCH_CUSTOMER_MOBILE_APPLICATIONS.default,
    payload: api.get(
      `/v1.1/customers/${id}/applications?page=${page}&limit=${limit}`,
    ),
  });
};

/**
 *
 * @param {string} id
 * @param { string } deviceId
 * @description removes the customer's device with the id corresponding to the deviceId passed
 * @returns { GenericDispatchAction }
 */

export const removeCustomerDevice = (id, deviceId) => (dispatch) => {
  return dispatch({
    type: REMOVE_CUSTOMER_DEVICE.default,
    payload: api.delete(`/v1.1/customers/${id}/devices/${deviceId}`),
  });
};

/**
 *
 * @param {string} id
 * @param { string } sessionsId
 * @description signs customer out of the session with the id corresponding to the sessiondId passed
 * @returns { GenericDispatchAction }
 */

export const signOutCustomerSession = (id, sessionsId) => (dispatch) => {
  return dispatch({
    type: SIGN_OUT_CUSTOMER_WEB_SESSION.default,
    payload: api.delete(`/v1.1/customers/${id}/sessions/${sessionsId}`),
  });
};

/**
 *
 * @param {string} id
 * @param { string } applicationId
 * @returns { GenericDispatchAction }
 */

export const removeCustomerApplications = (id, applicationId) => (dispatch) => {
  return dispatch({
    type: REMOVE_CUSTOMER_MOBILE_APPLICATION.default,
    payload: api.delete(`/v1.1/customers/${id}/applications/${applicationId}`),
  });
};

/**
 *
 * @param {string} customerId
 * @param { number } page
 * @param { number } limit
 * @description fetches the name a customer has white listed
 * @returns { GenericDispatchAction }
 */

export const fetchWhitelistedNames = (customerId, page = 1, limit = 25) => (
  dispatch,
) => {
  return dispatch({
    type: FETCH_WHITELISTED_NAMES.default,
    payload: api.get(
      `/v1.1/customers/${customerId}/whitelisted_names?page=${page}&limit=${limit}`,
    ),
    meta: {
      customerId,
    },
  });
};

/**
 *
 * @param { string } customerId
 * @param { string } nameId
 * @description deletes the names the customer has white listed
 * @returns { GenericDispatchAction }
 */

export const deleteWhitelistedName = (customerId, nameId) => (dispatch) => {
  return dispatch({
    type: DELETE_WHITELISTED_NAME.default,
    payload: api.delete(
      `/v1.1/customers/${customerId}/whitelisted_names/${nameId}`,
    ),
  }).then((res) => {
    dispatch(fetchWhitelistedNames(customerId));
    return res;
  });
};

/**
 *
 * @param { string } customerId
 * @description fetches the customer's recurring orders
 * @returns { GenericDispatchAction }
 */

export const fetchCustomerRecurringOrders = (customerId) => (dispatch) => {
  return dispatch({
    type: FETCH_CUSTOMER_RECURRING_ORDERS.default,
    payload: api.get(`/v1.1/customers/${customerId}/recurring_orders`),
    meta: {
      customerId,
    },
  });
};

/**
 *
 * @param {string} customerId
 * @param { string } orderId
 * @description deletes customer's recurring order
 * @returns { GenericDispatchAction }
 */

export const deleteCustomerRecurringOrder = (customerId, orderId) => (
  dispatch,
) => {
  return dispatch({
    type: DELETE_CUSTOMER_RECURRING_ORDER.default,
    payload: api.delete(
      `/v1.1/customers/${customerId}/recurring_orders/${orderId}`,
    ),
    meta: {
      customerId,
      orderId,
    },
  });
};

/**
 *
 * @param {string} customerId
 * @description Fetches a customer's inactive wallets
 * @returns { GenericDispatchAction }
 */

export const fetchCustomerInactiveWallets = (customerId) => (dispatch) => {
  return dispatch({
    type: FETCH_CUSTOMER_INACTIVE_WALLETS.default,
    payload: api.get(`/v1.1/customers/${customerId}/accounts/inactive`),
    meta: {
      customerId,
    },
  });
};

/**
 *
 * @param {string} customerId
 * @param { string []} currency
 * @description Activates an inactive customer currency wallet
 * @returns { GenericDispatchAction }
 */

export const activateCustomerInactiveWallet = (customerId, currencies) => (
  dispatch,
) => {
  return dispatch({
    type: ACTIVATE_CUSTOMER_INACTIVE_WALLET.default,
    payload: api
      .put(`/v1.1/customers/${customerId}/accounts/inactive`, {
        currencies,
      })
      .then((res) => {
        dispatch(fetchCustomerInactiveWallets(customerId));
        dispatch(fetchWallets(customerId));
        return res;
      }),
    meta: {
      customerId,
      currencies,
    },
  });
};

/**
 * @returns { GenericDispatchAction }
 */
export const clearMessage = () => {
  return (dispatch) =>
    dispatch({
      type: CLEAR_MESSAGE.fulfilled,
    });
};
