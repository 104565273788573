import { format, formatDistanceToNow } from 'date-fns';

function getDateWordsDistance(ISODate) {
  return formatDistanceToNow(ISODate);
}

function formatISODateToTime(ISODate) {
  return format(ISODate, 'h:mm aa');
}

function formatDateToReadableString(ISODate) {
  return format(ISODate, "MMMM d, yyyy 'at' h:mm aa");
}

function formatDateToPaystackFormat(ISODate) {
  return format(ISODate, 'dd-MMM-yy');
}

const formatISODateToDayMonthYear = (ISODate) =>
  format(ISODate, 'do MMM, yyyy');

const getStartOfDays = (days) =>
  new Date(Date.now() - days * 24 * 60 * 60 * 1000);

const formateDateToISOWithoutSSSZ = (date) => {
  return new Date(date).toISOString().split('.')[0];
};

const formateDateToISOWithoutSSSZAndTime = (date) => {
  const withoutSSSZDate = formateDateToISOWithoutSSSZ(date);
  return withoutSSSZDate.split('T')[0];
};

export {
  formatDateToReadableString,
  formatISODateToDayMonthYear,
  getDateWordsDistance,
  formatISODateToTime,
  formatDateToPaystackFormat,
  getStartOfDays,
  formateDateToISOWithoutSSSZ,
  formateDateToISOWithoutSSSZAndTime,
};
