import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Layout,
  Page,
  Card,
  Stack,
  DisplayText,
  TextStyle,
  Button,
  Popover,
  FormLayout,
  Select,
} from '@shopify/polaris';
import DatePicker from 'react-datepicker';
import { CalendarMinor } from '@shopify/polaris-icons';

import LoadingSpinner from 'components/LoadingSpinner';
import {
  fetchCompletionRateReport,
  fetchUnsettledBuysReport,
  fetchUnsettledSellsReport,
} from 'redux/reports/actions';
import { formatNumberToMoney } from 'utils/money';
import './ViewReport.css';
import { calculatePercentage } from 'utils/helpers';
import ReportCardPlaceHolder from './components/ReportCardPlaceHolder';
import { getStartOfDays } from 'utils/date';
import { PreviousLocationContext } from 'providers/PreviousLocationProvider';

const filterPeriods = [
  {
    label: 'Today',
    value: 'today',
  },
  {
    label: 'Yesterday',
    value: 'yesterday',
  },
  {
    label: 'Last 7 days',
    value: 'last-7-days',
  },
  {
    label: 'Last 30 days',
    value: 'last-30-days',
  },
  {
    label: 'Custom Range',
    value: 'custom-range',
  },
];

const periodDatesAndLabels = {
  today: {
    label: 'Today',
    getStartAndEndDates: () => ({ from: new Date(), to: new Date() }),
  },
  yesterday: {
    label: 'Yesterday',
    getStartAndEndDates: () => ({
      from: getStartOfDays(1),
      to: getStartOfDays(1),
    }),
  },
  'last-7-days': {
    label: 'Last 7 days',
    getStartAndEndDates: () => ({
      from: getStartOfDays(7),
      to: new Date(),
    }),
  },
  'last-30-days': {
    label: 'Last 30 days',
    getStartAndEndDates: () => ({
      from: getStartOfDays(30),
      to: new Date(),
    }),
  },
};

function ViewReport() {
  const dispatch = useDispatch();
  const { product } = useParams();
  const { previousLocation } = React.useContext(PreviousLocationContext);

  const [selectedDates, setSelectedDates] = useState({
    from: new Date(),
    to: new Date(),
  });

  const [selectedPeriod, setSelectedPeriod] = useState('today');

  const handleSelectPeriodChange = (value) => {
    setSelectedPeriod(value);
    if (value !== 'custom-range') {
      const startAndEndDates =
        periodDatesAndLabels[value]?.getStartAndEndDates();
      if (startAndEndDates) {
        setSelectedDates(startAndEndDates);
      }
    }
  };

  const handleResetSelectedDates = () =>
    setSelectedDates({ from: new Date(), to: new Date() });

  const [popoverActive, setPopoverActive] = useState(false);

  const togglePopoverActive = () =>
    setPopoverActive((popoverActive) => !popoverActive);

  const closePopover = () => {
    handleResetSelectedDates();
    setPopoverActive(false);
  };

  const {
    completionRatesFetching,
    unsettledSellsFetching,
    unsettledBuysFetching,
    data: {
      completionRates: { [product]: productCompletionRates },
      unsettledSells: { [product]: productUnsettledSells },
      unsettledBuys: { [product]: productUnsettledBuys },
    },
  } = useSelector((state) => state.reports);

  const settledOrdersPercentage = calculatePercentage(
    Number(productCompletionRates?.settled_order_count),
    Number(productCompletionRates?.total_order_count),
  );
  const unsettledOrdersPercentage = calculatePercentage(
    Number(productCompletionRates?.unsettled_order_count),
    Number(productCompletionRates?.total_order_count),
  );
  const partiallySettledOrdersPercentage = calculatePercentage(
    Number(productCompletionRates?.partially_settled_order_count),
    Number(productCompletionRates?.total_order_count),
  );

  const selectedPeriodLabel =
    periodDatesAndLabels[selectedPeriod]?.label || 'Custom Range';

  useEffect(() => {
    if (!popoverActive) {
      dispatch(fetchCompletionRateReport(product, selectedDates));
      dispatch(fetchUnsettledBuysReport(product, selectedDates));
      dispatch(fetchUnsettledSellsReport(product, selectedDates));
    }
  }, [product, dispatch, selectedDates, popoverActive]);

  return (
    <div id="report">
      <Page
        title={`${product} Overview Dashboard`}
        fullWidth
        breadcrumbs={[
          {
            content: 'Reports',
            url: previousLocation
              ? `${previousLocation.pathname}${previousLocation.search}`
              : '/orders/reports',
          },
        ]}
      >
        <div className="page-actions">
          <Popover
            active={popoverActive}
            activator={
              <Button icon={CalendarMinor} onClick={togglePopoverActive}>
                {selectedPeriodLabel}
              </Button>
            }
            onClose={togglePopoverActive}
            ariaHaspopup={false}
            preferredAlignment="left"
            sectioned
            fluidContent
          >
            <Popover.Pane fixed>
              <Popover.Section>
                <FormLayout>
                  <Select
                    label="Date range"
                    options={filterPeriods}
                    onChange={handleSelectPeriodChange}
                    value={selectedPeriod}
                  />
                  {selectedPeriod === 'custom-range' && (
                    <FormLayout.Group>
                      <div>
                        <label htmlFor="date_of_birth">Starting</label>
                        <DatePicker
                          selected={selectedDates.from}
                          onChange={(date) =>
                            setSelectedDates((dates) => ({
                              ...dates,
                              from: date,
                            }))
                          }
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          dateFormat="dd-MM-yyyy"
                        />
                      </div>
                      <div>
                        <label htmlFor="date_of_birth">Ending</label>
                        <DatePicker
                          selected={selectedDates.to}
                          onChange={(date) =>
                            setSelectedDates((dates) => ({
                              ...dates,
                              to: date,
                            }))
                          }
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          dateFormat="dd-MM-yyyy"
                        />
                      </div>
                    </FormLayout.Group>
                  )}
                </FormLayout>
              </Popover.Section>
              <Popover.Section>
                <Stack alignment="center" distribution="equalSpacing">
                  <Button onClick={closePopover}>Cancel</Button>
                  <Button primary onClick={togglePopoverActive}>
                    Apply
                  </Button>
                </Stack>
              </Popover.Section>
            </Popover.Pane>
          </Popover>
        </div>
        <Layout>
          <Layout.Section oneHalf>
            {completionRatesFetching && !productCompletionRates ? (
              <LoadingSpinner />
            ) : (
              <Card title="Orders completion rate" sectioned>
                {completionRatesFetching ? (
                  <ReportCardPlaceHolder />
                ) : (
                  <Stack vertical spacing="tight">
                    <DisplayText>
                      <TextStyle variation="strong">
                        {productCompletionRates?.completion_rate} %
                      </TextStyle>
                    </DisplayText>
                    <br />
                    <div className="list-row">
                      <TextStyle>Settled orders</TextStyle>
                      <TextStyle>
                        {productCompletionRates?.settled_order_count}
                      </TextStyle>
                      <TextStyle>{settledOrdersPercentage}%</TextStyle>
                    </div>
                    <div className="list-row">
                      <TextStyle>Unsettled orders</TextStyle>
                      <TextStyle>
                        {productCompletionRates?.unsettled_order_count}
                      </TextStyle>
                      <TextStyle>{unsettledOrdersPercentage}%</TextStyle>
                    </div>
                    <div className="list-row">
                      <TextStyle>Partially settled orders</TextStyle>
                      <TextStyle>
                        {productCompletionRates?.partially_settled_order_count}
                      </TextStyle>
                      <TextStyle>{partiallySettledOrdersPercentage}%</TextStyle>
                    </div>
                  </Stack>
                )}
              </Card>
            )}
          </Layout.Section>
          <Layout.Section oneHalf>
            {unsettledBuysFetching && !productUnsettledBuys ? (
              <LoadingSpinner />
            ) : (
              <Card title="Unsettled Buys" sectioned>
                {unsettledBuysFetching ? (
                  <ReportCardPlaceHolder />
                ) : (
                  <Stack vertical spacing="tight">
                    <DisplayText>
                      <TextStyle variation="strong">
                        {formatNumberToMoney(
                          Number(productUnsettledBuys?.total_volume),
                          {
                            category: productUnsettledBuys?.base,
                            currencyCode: productUnsettledBuys?.base,
                            codePosition: 'back',
                          },
                        )}
                      </TextStyle>
                    </DisplayText>
                    <br />
                    <Stack alignment="center" distribution="equalSpacing">
                      <TextStyle>Count</TextStyle>
                      <TextStyle>{productUnsettledBuys?.count}</TextStyle>
                    </Stack>
                    <Stack alignment="center" distribution="equalSpacing">
                      <TextStyle>Average Price</TextStyle>
                      <TextStyle>
                        {formatNumberToMoney(
                          Number(productUnsettledBuys?.average_price),
                          {
                            category: productUnsettledBuys?.counter,
                            currencyCode: productUnsettledBuys?.counter,
                            codePosition: 'back',
                          },
                        )}
                      </TextStyle>
                    </Stack>
                    <Stack alignment="center" distribution="equalSpacing">
                      <TextStyle>Estimated Value</TextStyle>
                      <TextStyle>
                        {formatNumberToMoney(
                          Number(productUnsettledBuys?.estimated_value),
                          {
                            category: productUnsettledBuys?.counter,
                            currencyCode: productUnsettledBuys?.counter,
                            codePosition: 'back',
                          },
                        )}
                      </TextStyle>
                    </Stack>
                  </Stack>
                )}
              </Card>
            )}
          </Layout.Section>
          <Layout.Section oneHalf>
            {unsettledSellsFetching && !productUnsettledSells ? (
              <LoadingSpinner />
            ) : (
              <Card title="Unsettled Sells" sectioned>
                {unsettledSellsFetching ? (
                  <ReportCardPlaceHolder />
                ) : (
                  <Stack vertical spacing="tight">
                    <DisplayText>
                      <TextStyle variation="strong">
                        {formatNumberToMoney(
                          Number(productUnsettledSells?.total_volume),
                          {
                            category: productUnsettledSells?.base,
                            currencyCode: productUnsettledSells?.base,
                            codePosition: 'back',
                          },
                        )}
                      </TextStyle>
                    </DisplayText>
                    <br />
                    <Stack alignment="center" distribution="equalSpacing">
                      <TextStyle>Count</TextStyle>
                      <TextStyle>{productUnsettledSells?.count}</TextStyle>
                    </Stack>
                    <Stack alignment="center" distribution="equalSpacing">
                      <TextStyle>Average Price</TextStyle>
                      <TextStyle>
                        {formatNumberToMoney(
                          Number(productUnsettledSells?.average_price),
                          {
                            category: productUnsettledSells?.counter,
                            currencyCode: productUnsettledSells?.counter,
                            codePosition: 'back',
                          },
                        )}
                      </TextStyle>
                    </Stack>
                    <Stack alignment="center" distribution="equalSpacing">
                      <TextStyle>Estimated Value</TextStyle>
                      <TextStyle>
                        {formatNumberToMoney(
                          Number(productUnsettledSells?.estimated_value),
                          {
                            category: productUnsettledSells?.counter,
                            currencyCode: productUnsettledSells?.counter,
                            codePosition: 'back',
                          },
                        )}
                      </TextStyle>
                    </Stack>
                  </Stack>
                )}
              </Card>
            )}
          </Layout.Section>
          <Layout.Section oneHalf />
        </Layout>
      </Page>
    </div>
  );
}

export default ViewReport;
