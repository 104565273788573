import React from 'react';
import { Card, TextStyle, Stack } from '@shopify/polaris';

export default function TradeAdDetails({ ad }) {
  return (
    <Card.Section
      title="Ad"
      actions={[
        {
          content: 'View',
          url: `/marketplace/ads/${ad.id}`,
        },
      ]}
    >
      <Stack vertical spacing="tight">
        <TextStyle>
          Sell {ad.minimum} - {ad.maximum} {ad.asset} at <br />
          {ad.rate} {ad.currency} / {ad.base_currency}
        </TextStyle>
      </Stack>
    </Card.Section>
  );
}
