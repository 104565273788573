import styled from 'styled-components';

const Clickable = styled.button`
  background: none;
  border: none;
  color: none;
  padding: 0 !important;
  margin: 0 !important;
  cursor: pointer;
  font-size: 1.3rem;
  &:focus,
  &:hover {
    outline: none;
    box-shadow: none;
  }
`;

export default Clickable;
