import React from 'react';
import { Card, TextStyle } from '@shopify/polaris';

export default function AdExchange({ exchange }) {
  return (
    <Card.Section title="Reference Exchange">
      <TextStyle variation="subdued">{exchange}</TextStyle>
    </Card.Section>
  );
}
