import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Page,
  Layout,
  Card,
  Stack,
  Button,
  Heading,
  TextStyle,
} from '@shopify/polaris';
import LoadingPage from 'components/LoadingPage';
import ImageGallery from 'components/ImageGallery';
import StatusBadge from 'components/StatusBadge';
import CustomerDetails from './components/CustomerDetails';
import CommentModal from './components/CommentModal';
import ImageGalleryPlaceHolder from './components/ImageGalleryPlaceHolder';
import { fetchCustomer } from 'redux/customers/actions';
import {
  fetchDocument,
  updateDocument,
  clearDocument,
} from 'redux/documents/actions';
import { formatDateToReadableString } from 'utils/date';
import { getS3document, getS3DocumentFileType } from 'utils/helpers';
import { PreviousLocationContext } from 'providers/PreviousLocationProvider';

function ViewDocument() {
  const params = useParams();
  const dispatch = useDispatch();
  const { previousLocation } = React.useContext(PreviousLocationContext);

  const [images, setImages] = React.useState([]);
  const [PDFs, setPDFs] = React.useState([]);
  const [imagesLoading, setImagesLoading] = React.useState(false);

  const [showCommentModal, setShowCommentModal] = React.useState(false);

  const toggleCommentModal = () =>
    setShowCommentModal((previousState) => !previousState);

  const {
    document,
    fetching: fetchingDocument,
    editing,
  } = useSelector((state) => state.documents);
  const {
    data: { [params.customer_id]: customer },
    fetchingCustomer,
  } = useSelector((state) => state.customers);

  const handleAcceptDocument = () => {
    if (document?.id) {
      dispatch(updateDocument(document.id, { status: 'accepted' }));
    }
  };

  const handleRejectDocument = (comment) => {
    if (document?.id) {
      dispatch(
        updateDocument(document.id, { status: 'rejected', comment }),
      ).then(() => setShowCommentModal(false));
    }
  };

  React.useEffect(() => {
    dispatch(fetchCustomer(params.customer_id));
    dispatch(fetchDocument(params.document_id));
    return () => {
      dispatch(clearDocument());
    };
  }, [dispatch, params.customer_id, params.document_id]);

  React.useEffect(() => {
    async function getDoumentImages() {
      setImagesLoading(true);
      const imageFiles = [];
      const PDFFiles = [];

      if (document?.file) {
        const documentType = getS3DocumentFileType(document.file);
        const file = await getS3document(document?.file);

        if (documentType?.toLowerCase() === 'pdf') {
          PDFFiles.push(file);
        } else {
          imageFiles.push(file);
        }
      }

      if (document?.with_selfie) {
        const documentType = getS3DocumentFileType(document.with_selfie);
        const file = await getS3document(document?.with_selfie);

        if (documentType?.toLowerCase() === 'pdf') {
          PDFFiles.push(file);
        } else {
          imageFiles.push(file);
        }
      }

      if (document?.with_written_text) {
        const documentType = getS3DocumentFileType(document.with_written_text);
        const file = await getS3document(document?.with_written_text);

        if (documentType?.toLowerCase() === 'pdf') {
          PDFFiles.push(file);
        } else {
          imageFiles.push(file);
        }
      }

      setImages(imageFiles);
      setPDFs(PDFFiles);
      setImagesLoading(false);
    }

    if (images.length < 1) {
      getDoumentImages();
    }
  }, [document, images.length]);

  if ((fetchingCustomer || fetchingDocument) && !(!!customer && !!document)) {
    return <LoadingPage />;
  }

  const customerName = `${customer?.profile?.first_name} ${customer?.profile?.last_name}`;

  return (
    <Page
      title={document?.reference}
      breadcrumbs={[
        {
          content: 'Documents',
          url: previousLocation
            ? `${previousLocation.pathname}${previousLocation.search}`
            : '/customers/documents',
        },
      ]}
      titleMetadata={
        <Stack>
          {document?.created_at && (
            <TextStyle>
              {formatDateToReadableString(new Date(document.created_at))}
            </TextStyle>
          )}
          {document?.status && <StatusBadge status={document.status} />}
        </Stack>
      }
    >
      <Layout>
        <Layout.Section>
          <Card
            title={
              <Stack distribution="equalSpacing" alignment="center">
                <Heading>{customerName}</Heading>
                <Stack alignment="center">
                  {document?.status !== 'rejected' && (
                    <Button onClick={toggleCommentModal} loading={editing}>
                      Reject
                    </Button>
                  )}
                  {document?.status !== 'accepted' && (
                    <Button
                      primary
                      onClick={handleAcceptDocument}
                      loading={editing}
                    >
                      Approve
                    </Button>
                  )}
                </Stack>
              </Stack>
            }
            sectioned
          >
            {imagesLoading ? (
              <ImageGalleryPlaceHolder />
            ) : (
              <>
                {PDFs.length > 0 &&
                  PDFs.map((pdf, index) => (
                    <object
                      key={index}
                      data={pdf}
                      type="application/pdf"
                      className="document-viewer"
                    >
                      <p>
                        Your web browser doesn`t have a PDF plugin. Instead you
                        can{' '}
                        <a href={pdf}>click here to download the PDF file.</a>
                      </p>
                    </object>
                  ))}
                <ImageGallery {...{ images }} />
              </>
            )}
          </Card>
        </Layout.Section>
        <Layout.Section secondary>
          <Card>
            <Card.Section title="ID NO">
              {document?.id_number ? (
                <TextStyle>{document?.id_number}</TextStyle>
              ) : (
                <TextStyle variation="subdued">No ID number</TextStyle>
              )}
            </Card.Section>
            <Card.Section title="Comment">
              {document?.comment ? (
                <TextStyle>{document?.comment}</TextStyle>
              ) : (
                <TextStyle variation="subdued">No comment</TextStyle>
              )}
            </Card.Section>
          </Card>
          <CustomerDetails customer={customer} />
        </Layout.Section>
      </Layout>
      <CommentModal
        isOpen={showCommentModal}
        toggleModal={toggleCommentModal}
        handleRejectDocument={handleRejectDocument}
        loading={editing}
      />
    </Page>
  );
}

export default ViewDocument;
