import { all, call, put, takeLatest } from 'redux-saga/effects';

import { FETCH_ORDERS, SET_ORDERS_APP } from './actionTypes';
import api from 'utils/api';

function* fetchOrdersSaga(action) {
  try {
    const { app, queryString } = action.payload;
    yield put({ type: SET_ORDERS_APP, payload: app });
    yield put({ type: FETCH_ORDERS.pending });
    const res = yield call(
      api.get,
      `/v1.1/orders/${app}${
        queryString ? queryString + '&limit=25' : '?limit=25'
      }`,
    );
    yield put({ type: FETCH_ORDERS.fulfilled, payload: res });
  } catch (error) {
    yield put({ type: FETCH_ORDERS.rejected, payload: error });
  }
}

export default function* allOrderSagas() {
  yield all([takeLatest(FETCH_ORDERS.default, fetchOrdersSaga)]);
}
