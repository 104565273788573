import * as Yup from 'yup';

function equalTo(ref, msg) {
  return Yup.mixed().test({
    name: 'equalTo',
    exclusive: false,
    message: msg || `${ref.path} must be the same as ${ref}`,
    params: {
      reference: ref.path,
    },
    test: function(value) {
      return value === this.resolve(ref);
    },
  });
}

Yup.addMethod(Yup.string, 'equalTo', equalTo);

export const stringValidation = Yup.string();
export const emailValidation = Yup.string().email();
export const passwordValidation = Yup.string().min(6);
export const phoneValidation = Yup.number('Field must be a number');
export const numberValidation = Yup.number('Field must be a number');
export const dateValidation = Yup.date('Field must be a date');

export const requiredField = (field) => field.required('Field is Required');

const inviteFormSchema = Yup.object().shape({
  first_name: requiredField(stringValidation),
  last_name: requiredField(stringValidation),
  phone: requiredField(numberValidation),
  password: requiredField(passwordValidation),
  confirm_password: requiredField(
    passwordValidation.equalTo(Yup.ref('password'), 'Passwords must match'),
  ),
  email: requiredField(emailValidation),
});

const editWalletBalanceFormSchema = Yup.object().shape({
  action: requiredField(stringValidation),
  amount: requiredField(numberValidation),
});

const editCustomerPhoneSchema = Yup.object().shape({
  phone: requiredField(numberValidation),
});

const editVerificationNoteSchema = Yup.object().shape({
  note: requiredField(stringValidation),
});

const editCustomerProfileSchema = Yup.object().shape({
  first_name: requiredField(stringValidation),
  last_name: requiredField(stringValidation),
  gender: requiredField(stringValidation),
  date_of_birth: requiredField(dateValidation),
  email: requiredField(emailValidation),
});

const editCustomerAddressSchema = Yup.object().shape({
  street_address_1: requiredField(stringValidation),
  street_address_2: requiredField(stringValidation),
  city: requiredField(stringValidation),
  postal_code: requiredField(numberValidation),
  state: requiredField(stringValidation),
  country: requiredField(stringValidation),
  country_code: requiredField(stringValidation),
});

const editBookDetailsSchema = Yup.object().shape({
  buy_mark_up: requiredField(numberValidation),
  sell_mark_down: requiredField(numberValidation),
  unliquidated_buy_allowance: requiredField(numberValidation),
  unliquidated_sell_allowance: requiredField(numberValidation),
  minimum_purchase: requiredField(numberValidation),
  minimum_sale: requiredField(numberValidation),
  buy: requiredField(Yup.boolean()),
  sell: requiredField(Yup.boolean()),
});

const createOrderTradeSchema = Yup.object().shape({
  price: requiredField(numberValidation),
  base_volume: requiredField(numberValidation),
  fee: requiredField(numberValidation),
});

export {
  inviteFormSchema,
  editWalletBalanceFormSchema,
  editCustomerPhoneSchema,
  editVerificationNoteSchema,
  editCustomerAddressSchema,
  editCustomerProfileSchema,
  editBookDetailsSchema,
  createOrderTradeSchema,
};
