import api from '../../utils/api';
import { FETCH_PROFILE } from './actionTypes';
import storage from '../../utils/storage';
import { logout } from '../auth/actions';

/**
 * Type definitions
 *
 * @typedef { import('../types').GenericDispatchAction } GenericDispatchAction
 */

/**
 * @returns { GenericDispatchAction }
 */

export const fetchProfile = () => (dispatch) => {
  return dispatch({
    type: FETCH_PROFILE.default,
    payload: api.get('/v1/me'),
  })
    .then((res) => {
      storage.set('profile', res.value.data);
      return res;
    })
    .catch(() => {
      dispatch(logout());
    });
};
