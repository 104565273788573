import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reduxThunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import rootSagas from './rootSagas';
import rootReducer from './rootReducer';
import asyncHandler from './middleware/asyncHandler';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const saga = createSagaMiddleware();

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(saga, asyncHandler, reduxThunk, promise)),
);

saga.run(rootSagas);

export default store;
