import React from 'react';
import { Card, TextContainer, TextStyle } from '@shopify/polaris';
import { capitalize } from 'utils/strings';

function DepositFromAddress({ from_address }) {
  return (
    <Card.Section title="From address" sectioned>
      <TextContainer>
        {from_address ? (
          <p>{capitalize(from_address)}</p>
        ) : (
          <TextStyle variation="subdued">No address</TextStyle>
        )}
      </TextContainer>
    </Card.Section>
  );
}

export default DepositFromAddress;
