import React from 'react';
import { Card, TextStyle, Stack, Heading, Button } from '@shopify/polaris';
import Countries from 'data/countriesAndStates.json';
import { capitalize } from 'utils/strings';
import { formatNumberToMoney } from 'utils/money';

export default function AdDetails({
  ad,
  editing,
  fetching,
  handleSetAdStatus,
}) {
  const countryName = (countryCode) => {
    const country = Countries.find((_country) => _country.iso2 === countryCode);
    if (country) {
      return country.name;
    }
    return countryCode;
  };

  const feePercentage = `${ad.fee_percentage * 100} %`;
  const rate = `${formatNumberToMoney(ad.rate, { category: ad.currency })} ${
    ad.currency
  }/${ad.base_currency}`;

  return (
    <Card
      title={
        <Stack distribution="equalSpacing" alignment="center">
          <Heading>Advert Details</Heading>
          {ad.status !== 'closed' && (
            <Stack distribution="equalSpacing" alignment="center">
              <Button
                primary={ad.status === 'inactive'}
                loading={editing}
                disabled={fetching}
                onClick={() =>
                  handleSetAdStatus(
                    ad.status === 'active' ? 'inactive' : 'active',
                  )
                }
              >
                {ad.status === 'active' ? 'Deactivate' : 'Activate'}
              </Button>
              <Button
                destructive
                loading={editing}
                disabled={fetching}
                onClick={() => handleSetAdStatus('closed')}
              >
                Close
              </Button>
            </Stack>
          )}
        </Stack>
      }
      sectioned
    >
      <Stack vertical>
        <Stack spacing="loose" distribution="equalSpacing">
          <TextStyle>Country</TextStyle>
          <TextStyle>{countryName(ad.country_code)}</TextStyle>
        </Stack>
        <Stack spacing="loose" distribution="equalSpacing">
          <TextStyle>{capitalize(ad.side)}</TextStyle>
          <TextStyle>
            {ad.minimum} - {ad.maximum} {ad.asset}
          </TextStyle>
        </Stack>
        <Stack spacing="loose" distribution="equalSpacing">
          <TextStyle>Adjusted amount</TextStyle>
          <TextStyle>
            {ad.adjusted_minimum} - {ad.adjusted_maximum} {ad.asset}
          </TextStyle>
        </Stack>
        <Stack spacing="loose" distribution="equalSpacing">
          <TextStyle>With</TextStyle>
          <TextStyle>{ad.currency}</TextStyle>
        </Stack>
        <Stack spacing="loose" distribution="equalSpacing">
          <TextStyle>Fee</TextStyle>
          <TextStyle>{feePercentage}</TextStyle>
        </Stack>
        <Stack spacing="loose" distribution="equalSpacing">
          <TextStyle>Rate</TextStyle>
          <TextStyle>{rate}</TextStyle>
        </Stack>
        <Stack spacing="loose" distribution="equalSpacing">
          <TextStyle>Price type</TextStyle>
          <TextStyle>{ad.price_type}</TextStyle>
        </Stack>
        <Stack spacing="loose" distribution="equalSpacing">
          <TextStyle>Payment method</TextStyle>
          <TextStyle>{ad.payment_method}</TextStyle>
        </Stack>
      </Stack>
    </Card>
  );
}
