import React from 'react';
import {
  Card,
  Stack,
  Link,
  Avatar,
  Heading,
  TextContainer,
} from '@shopify/polaris';
import { getInitials } from 'utils/strings';

export default function PayoutCustomer({ sender_name, sender_id }) {
  return (
    <Card.Section
      title={
        <Stack distribution="equalSpacing">
          <Stack.Item>
            <Heading>Customer</Heading>
          </Stack.Item>
          <Stack.Item>
            <Link url={`/customers/${sender_id}`}>
              <Avatar
                initials={getInitials(sender_name)}
                name={sender_name}
                size="small"
              ></Avatar>
            </Link>
          </Stack.Item>
        </Stack>
      }
      sectioned
    >
      <TextContainer>
        <Link url={`/customers/${sender_id}`}>{sender_name}</Link>
      </TextContainer>
    </Card.Section>
  );
}
