import React from 'react';
import {
  Page,
  Card,
  ResourceList,
  ChoiceList,
  Filters,
  TextStyle,
  Badge,
} from '@shopify/polaris';
import { ExportMinor } from '@shopify/polaris-icons';
import Pagination from 'components/Pagination';
import PayoutListItem from './components/PayoutListItem';
import PayoutListHeader from './components/PayoutListHeader';
import usePayouts from './usePayouts';

const Payouts = () => {
  const {
    queryParams,
    updateQueryParams,
    clearAllFilters,
    handlePreviousPage,
    handleNextPage,
    fetching,
    meta,
    payouts,
    assetData,
  } = usePayouts();

  const filters = [
    {
      key: 'status',
      label: 'Payout Status',
      filter: (
        <ChoiceList
          title="Status"
          titleHidden
          choices={[
            { label: 'Completed', value: 'completed' },
            { label: 'Processing', value: 'processing' },
            { label: 'Pending', value: 'pending' },
            { label: 'Cancelled', value: 'cancelled' },
            { label: 'Failed', value: 'failed' },
          ]}
          selected={queryParams.status || ''}
          onChange={(value) => updateQueryParams('status', value)}
        />
      ),
      shortcut: true,
    },
    {
      key: 'category',
      label: 'Payout Category',
      filter: (
        <ChoiceList
          title="Payout Category"
          titleHidden
          choices={[
            { label: 'Fiat', value: 'fiat' },
            { label: 'Crypto', value: 'crypto' },
          ]}
          selected={queryParams.category || ''}
          onChange={(value) => updateQueryParams('category', value)}
        />
      ),
      shortcut: true,
    },
    {
      key: 'processor',
      label: 'Payout Provider',
      filter: (
        <ChoiceList
          title="Payout Provider"
          titleHidden
          choices={[
            { label: 'Paystack', value: 'paystack' },
            { label: 'Rave', value: 'rave' },
            { label: 'Walltes', value: 'wallets' },
            { label: 'Jochebed', value: 'jbed' },
          ]}
          selected={queryParams.processor}
          onChange={(value) => updateQueryParams('processor', value)}
        />
      ),
    },
    {
      key: 'asset',
      label: 'Asset',
      filter: (
        <ChoiceList
          title="Asset"
          titleHidden
          choices={assetData.map((key) => ({ label: key, value: key }))}
          selected={queryParams.asset}
          onChange={(value) => updateQueryParams('asset', value)}
        />
      ),
    },
  ];

  const appliedFilters = [];

  if (queryParams.status) {
    appliedFilters.push({
      key: 'status',
      label: `Status ${queryParams.status}`,
      onRemove: () => updateQueryParams('status', ''),
    });
  }
  if (queryParams.category) {
    appliedFilters.push({
      key: 'category',
      label: `Category ${queryParams.category}`,
      onRemove: () => updateQueryParams('category', ''),
    });
  }

  if (queryParams.processor) {
    appliedFilters.push({
      key: 'processor',
      label: `Provider ${queryParams.processor}`,
      onRemove: () => updateQueryParams('processor', ''),
    });
  }

  if (queryParams.asset) {
    appliedFilters.push({
      key: 'asset',
      label: `Asset ${queryParams.asset}`,
      onRemove: () => updateQueryParams('asset', ''),
    });
  }

  return (
    <Page
      fullWidth
      title="Payouts"
      secondaryActions={[
        {
          content: 'Export',
          icon: ExportMinor,
        },
      ]}
    >
      <Card>
        <Card.Section>
          <span className="search-help-text">
            Enter search text, then press <Badge>ENTER</Badge> or{' '}
            <Badge>RETURN</Badge> to search
          </span>
          <Filters
            queryValue={queryParams.q}
            filters={filters}
            appliedFilters={appliedFilters}
            onQueryChange={(value) => updateQueryParams('q', value)}
            onQueryClear={() => updateQueryParams('q', '')}
            onClearAll={clearAllFilters}
          />
          <br />
          <TextStyle variation="subdued">
            {fetching
              ? 'Loading payouts...'
              : `Showing ${meta?.current_entries_size || 0} payout(s)`}
          </TextStyle>
        </Card.Section>
        <Card.Section>
          {!fetching && payouts.length > 0 && <PayoutListHeader />}
        </Card.Section>
        <ResourceList
          loading={fetching}
          resourceName={{ singular: 'payout', plural: 'payouts' }}
          items={payouts}
          renderItem={(item) => <PayoutListItem {...item} />}
          showHeader={false}
        />
        <Pagination
          resource={payouts}
          meta={meta}
          handlePreviousPage={handlePreviousPage}
          handleNextPage={handleNextPage}
        />
      </Card>
    </Page>
  );
};

export default Payouts;
