import actionTypes from '../actionTypes';

export const FETCH_STAFF = actionTypes('FETCH_STAFF');
export const FETCH_STAFF_PROFILE = actionTypes('FETCH_STAFF_PROFILE');
export const ADD_NEW_STAFF = actionTypes('ADD_NEW_STAFF');
export const ACTIVATE_STAFF = actionTypes('ACTIVATE_STAFF');
export const DEACTIVATE_STAFF = actionTypes('DEACTIVATE_STAFF');
export const UPDATE_STAFF = actionTypes('UPDATE_STAFF');
export const CHANGE_STAFF_PASSWORD = actionTypes('CHANGE_STAFF_PASSWORD');
export const DELETE_STAFF = actionTypes('DELETE_STAFF');
