import { mapKeys } from 'lodash';
import {
  FETCH_DEPOSITS,
  FETCH_DEPOSIT,
  ADD_DEPOSIT,
  CLEAR_MESSAGE,
  APPROVE_DEPOSIT,
  FULFILL_DEPOSIT,
  DECLINE_DEPOSIT,
  CHECK_DEPOSIT_STATUS,
  CLEAR_DEPOSIT_STATUS,
  FLAG_DEPOSIT,
} from './actionTypes';

const INITIAL_STATE = {
  data: {},
  meta: {},
  fetching: false,
  updating: false,
  addingDeposit: false,
  approvingDeposit: false,
  decliningDeposit: false,
  fulfillingDeposit: false,
  flaggingDeposit: false,
  error: null,
  message: null,
  statusMessage: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_DEPOSITS.pending:
      return {
        ...state,
        fetching: true,
      };
    case FETCH_DEPOSITS.rejected:
      return {
        ...state,
        fetching: false,
      };
    case FETCH_DEPOSITS.fulfilled:
      return {
        ...state,
        fetching: false,
        data: mapKeys(action.payload.data.data, 'id'),
        meta: action.payload.data.meta,
      };

    case FETCH_DEPOSIT.pending:
      return {
        ...state,
        fetching: true,
      };
    case FETCH_DEPOSIT.rejected:
      return {
        ...state,
        fetching: false,
      };
    case FETCH_DEPOSIT.fulfilled:
      return {
        ...state,
        fetching: false,
        data: { [action.payload.data.id]: action.payload.data },
      };

    case ADD_DEPOSIT.pending:
      return {
        ...state,
        addingDeposit: true,
      };
    case ADD_DEPOSIT.rejected:
      return {
        ...state,
        addingDeposit: false,
      };

    case ADD_DEPOSIT.fulfilled:
      return {
        ...state,
        addingDeposit: false,
      };
    case APPROVE_DEPOSIT.pending:
      return {
        ...state,
        approvingDeposit: true,
      };
    case APPROVE_DEPOSIT.rejected:
      return {
        ...state,
        approvingDeposit: false,
      };
    case APPROVE_DEPOSIT.fulfilled:
      return {
        ...state,
        approvingDeposit: false,
      };

    case FULFILL_DEPOSIT.pending:
      return {
        ...state,
        fulfillingDeposit: true,
      };
    case FULFILL_DEPOSIT.rejected:
      return {
        ...state,
        fulfillingDeposit: false,
      };
    case FULFILL_DEPOSIT.fulfilled:
      return {
        ...state,
        fulfillingDeposit: false,
      };

    case DECLINE_DEPOSIT.pending:
      return {
        ...state,
        decliningDeposit: true,
      };
    case DECLINE_DEPOSIT.rejected:
      return {
        ...state,
        decliningDeposit: false,
      };
    case DECLINE_DEPOSIT.fulfilled:
      return {
        ...state,
        decliningDeposit: false,
      };

    case CHECK_DEPOSIT_STATUS.pending:
      return {
        ...state,
        updating: true,
      };
    case CHECK_DEPOSIT_STATUS.rejected:
      return {
        ...state,
        updating: false,
      };
    case CHECK_DEPOSIT_STATUS.fulfilled:
      return {
        ...state,
        updating: false,
        statusMessage: action.payload.data.message,
      };

    case FLAG_DEPOSIT.pending:
      return {
        ...state,
        flaggingDeposit: true,
      };
    case FLAG_DEPOSIT.rejected:
      return {
        ...state,
        flaggingDeposit: false,
      };
    case FLAG_DEPOSIT.fulfilled:
      return {
        ...state,
        flaggingDeposit: false,
      };

    case CLEAR_MESSAGE.fulfilled:
      return {
        ...state,
        message: null,
        error: null,
      };

    case CLEAR_DEPOSIT_STATUS.fulfilled:
      return {
        ...state,
        statusMessage: null,
      };
    default:
      return state;
  }
};
