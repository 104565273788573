import { ProfileMajor, ChannelsMajor } from '@shopify/polaris-icons';

export default function Settingsroutes(full_access = false) {
  return [
    {
      title: 'Accounts',
      subtitle: 'Manage your accounts and permissions',
      path: '/settings/accounts',
      icon: ProfileMajor,
      disabled: !full_access,
    },
    {
      title: 'Assets',
      subtitle: 'Manage crypto assets',
      path: '/settings/assets',
      icon: ChannelsMajor,
      disabled: !full_access,
    },
    {
      title: 'Payout Providers',
      subtitle: 'Manage payout providers',
      path: '/settings/providers/payouts',
      icon: ChannelsMajor,
      disabled: true,
    },
    {
      title: 'Deposit Providers',
      subtitle: 'Manage deposit providers',
      path: '/settings/providers/deposits',
      icon: ChannelsMajor,
      disabled: true,
    },
  ];
}
