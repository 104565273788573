import React from 'react';
import { Card, TextStyle } from '@shopify/polaris';

export default function OrderComment({ comment }) {
  return (
    <Card.Section title="Comment">
      <TextStyle variation="subdued">
        {comment
          ? comment
          : 'There aren’t any conversion details available for this order'}
      </TextStyle>
    </Card.Section>
  );
}
