import { put, call, all, takeLatest } from 'redux-saga/effects';

import api from 'utils/api';
import { FETCH_VERIFICATIONS } from './actionTypes';
function* fetchVerificationsSaga(action) {
  try {
    const { queryString } = action.payload;
    yield put({ type: FETCH_VERIFICATIONS.pending });
    const res = yield call(api.get, `/v1/verification${queryString}`);
    yield put({ type: FETCH_VERIFICATIONS.fulfilled, payload: res });
  } catch (error) {
    yield put({ type: FETCH_VERIFICATIONS.rejected, payload: error });
  }
}

export default function* allVerificationSaga() {
  yield all([takeLatest(FETCH_VERIFICATIONS.default, fetchVerificationsSaga)]);
}
