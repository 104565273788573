import actionTypes from '../actionTypes';

export const FETCH_COMPLETION_RATE_REPORT = actionTypes(
  'FETCH_COMPLETION_RATE_REPORT',
);

export const FETCH_UNSETTLED_SELLS_REPORT = actionTypes(
  'FETCH_UNSETTLED_SELLS_REPORT',
);

export const FETCH_UNSETTLED_BUYS_REPORT = actionTypes(
  'FETCH_UNSETTLED_BUYS_REPORT',
);
