import React from 'react';
import { Stack, Card, Link, Heading } from '@shopify/polaris';
import AvatarInitials from 'components/AvatarInitials';
import { capitalize } from 'utils/strings';

export default function OrderApp({ app }) {
  return (
    <Card.Section
      title={
        <Stack distribution="equalSpacing">
          <Stack.Item>
            <Heading>App</Heading>
          </Stack.Item>
          <Stack.Item>
            <AvatarInitials firstName={app.toUpperCase()} size="small" />
          </Stack.Item>
        </Stack>
      }
    >
      <Link url={`/orders/${app}`}>{capitalize(app)}</Link>
    </Card.Section>
  );
}
