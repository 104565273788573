import React, { Fragment, useState } from 'react';
import { Card, ResourceList } from '@shopify/polaris';
import Pagination from 'components/Pagination';
import TransactionItem from './TransactionItem';
import TransactionModal from './TransactionModal';

export default function CustomerTransactions({
  transactionsFetching,
  transactions,
  transactionsMeta,
  handleTxnNextPage,
  handleTxnPreviousPage,
  modifyTransactionStatus,
}) {
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [showModal, setShowModal] = useState(false);

  function toggleModal() {
    setShowModal(!showModal);
  }
  return (
    <Fragment>
      <Card title="Transactions">
        <Fragment>
          <ResourceList
            loading={transactionsFetching}
            resourceName={{ singular: 'transaction', plural: 'transactions' }}
            items={transactions || []}
            renderItem={TransactionItem(
              transactionsFetching,
              modifyTransactionStatus,
              function onSelect(transaction) {
                setSelectedTransaction(transaction);
                toggleModal();
              },
            )}
          />
          <Pagination
            resource={transactions}
            meta={transactionsMeta}
            handlePreviousPage={handleTxnPreviousPage}
            handleNextPage={handleTxnNextPage}
          />
        </Fragment>
      </Card>
      {selectedTransaction && (
        <TransactionModal
          active={showModal}
          transaction={selectedTransaction}
          onClose={toggleModal}
        />
      )}
    </Fragment>
  );
}
