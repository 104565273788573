import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Card, Button, Stack, TextStyle } from '@shopify/polaris';
import { getNamePronoun } from 'utils/strings';

export default function CustomerActivitiesAndDevices({ name }) {
  const match = useRouteMatch();
  return (
    <Card>
      <Card.Section title="Account Activities">
        <Stack vertical>
          <TextStyle>
            View all {getNamePronoun(name)} account actions such as login,
            password change e.t.c
          </TextStyle>
          <Button url={`${match.url}/activities`}>View Activities</Button>
        </Stack>
      </Card.Section>
      <Card.Section title="Devices">
        <Stack vertical>
          <TextStyle>
            View all {getNamePronoun(name)} devices including web sessions &
            mobile applications.
          </TextStyle>
          <Button url={`${match.url}/devices`}>View Devices</Button>
        </Stack>
      </Card.Section>
    </Card>
  );
}
