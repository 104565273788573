import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, TextStyle } from '@shopify/polaris';
import { copyToClipboard } from 'utils/helpers';
import styled from 'styled-components';

export default function CopiableText({ text, variation }) {
  return (
    <Tooltip content="Click to copy">
      <CopiableText.Button onClick={() => copyToClipboard(text)}>
        <TextStyle variation={variation}>{text}</TextStyle>
      </CopiableText.Button>
    </Tooltip>
  );
}

CopiableText.Button = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  max-width: 100%;
  font-size: 1.2rem;
  box-sizing: border-box;
  word-break: break-all;
  text-align: left;
`;

CopiableText.propTypes = {
  text: PropTypes.string,
  variation: PropTypes.oneOf([
    'subdued',
    'negative',
    'positive',
    'strong',
    'code',
  ]),
};
