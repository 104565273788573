import React from 'react';
import { Card, TextStyle, Stack } from '@shopify/polaris';
import { formatNumberToMoney } from 'utils/money';

export default function TradeAnalysis({ analysis, ad }) {
  return (
    <Card sectioned title="Trade Analysis">
      <Stack vertical>
        <Stack spacing="loose" distribution="equalSpacing">
          <TextStyle>Buyer will pay</TextStyle>
          <TextStyle>
            {formatNumberToMoney(analysis.buyer_will_pay, {
              category: ad.currency,
              currencyCode: ad.currency,
            })}
          </TextStyle>
        </Stack>
        <Stack spacing="loose" distribution="equalSpacing">
          <TextStyle>Seller will receive</TextStyle>
          <TextStyle>
            {formatNumberToMoney(analysis.seller_will_receive, {
              category: ad.currency,
              currencyCode: ad.currency,
            })}
          </TextStyle>
        </Stack>
        <Stack spacing="loose" distribution="equalSpacing">
          <TextStyle>Buyer will receive</TextStyle>
          <TextStyle>
            {formatNumberToMoney(analysis.buyer_will_receive, {
              category: ad.asset,
              currencyCode: ad.asset,
            })}
          </TextStyle>
        </Stack>
        <Stack spacing="loose" distribution="equalSpacing">
          <TextStyle>Fee</TextStyle>
          <TextStyle>
            {formatNumberToMoney(analysis.fee, {
              category: ad.asset,
              currencyCode: ad.asset,
            })}
          </TextStyle>
        </Stack>
        <Stack spacing="loose" distribution="equalSpacing">
          <TextStyle>Seller will sell</TextStyle>
          <TextStyle>
            {formatNumberToMoney(analysis.seller_will_sell, {
              category: ad.asset,
              currencyCode: ad.asset,
            })}
          </TextStyle>
        </Stack>
      </Stack>
    </Card>
  );
}
