import api from 'utils/api';
import {
  FETCH_DOCUMENT,
  FETCH_DOCUMENTS,
  UPDATE_DOCUMENT,
  CLEAR_DOCUMENT,
  FETCH_COUNTRIES,
  FETCH_DOCUMENTS_TYPES,
} from './actionTypes';

/**
 * Type definitions
 *
 * @typedef { import('../types').GenericDispatchAction } GenericDispatchAction
 * @typedef { import('./types').UpdateDocumentParameter2 } UpdateDocumentParameter2
 */

/**
 *
 * @param {string}[queryString='']
 * @description Fetches all documents
 * @returns { { type : string; paylaod : { queryString : string } } }
 */

export const fetchDocuments = (queryString = '') => {
  return { type: FETCH_DOCUMENTS.default, payload: { queryString } };
};

/**
 *
 * @param {string} id
 * @description Fetches the document with the corresponding id
 * @returns { GenericDispatchAction }
 */

export const fetchDocument = (id) => (dispatch) => {
  const payload = api.get(`/v1.1/customers/documents/${id}`);
  return dispatch({ type: FETCH_DOCUMENT.default, payload });
};

/**
 *
 * @param { string } id
 * @param { UpdateDocumentParameter2 } param1
 * @returns { GenericDispatchAction }
 */

export const updateDocument = (
  id,
  { status, comment, notify_customer = true },
) => (dispatch) => {
  const payload = api.put(`/v1.1/customers/documents/${id}`, {
    status,
    comment,
    notify_customer,
  });
  return dispatch({ type: UPDATE_DOCUMENT.default, payload }).then((res) => {
    dispatch(fetchDocument(id));
    return res;
  });
};

/**
 * @returns { GenericDispatchAction }
 */

export const fetchCountries = () => (dispatch) => {
  const payload = api.get(`/v1.1/customers/countries`);
  return dispatch({ type: FETCH_COUNTRIES.default, payload });
};

/**
 *
 * @param {string} country
 * @returns { GenericDispatchAction }
 */
export const fetchDocumentTypes = (country) => (dispatch) => {
  const payload = api.get(
    `/v1.1/customers/documents/types${country ? '?country=' + country : ''}`,
  );
  return dispatch({ type: FETCH_DOCUMENTS_TYPES.default, payload });
};

/**
 * @returns { GenericDispatchAction }
 */
export const clearDocument = () => (dispatch) => {
  return dispatch({ type: CLEAR_DOCUMENT.fulfilled });
};
