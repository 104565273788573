import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Page, Layout, Card, Stack, TextStyle } from '@shopify/polaris';
import { SoundMajor } from '@shopify/polaris-icons';

import LoadingPage from 'components/LoadingPage';
import StatusBadge from 'components/StatusBadge';
import ChatDialog from 'components/ChatDialog';

import TradeDetails from './components/TradeDetails';
import TradeAnalysis from './components/TradeAnalysis';
import TradeTraders from './components/TradeTraders';
import TradeAdDetails from './components/TradeAdDetails';
import TradeBankDetails from './components/TradeBankDetails';
import TradeEvents from './components/TradeEvents';

import { fetchTradeDetails, setTradeStatus } from 'redux/trades/actions';
import { formatDateToReadableString } from 'utils/date';
import { PreviousLocationContext } from 'providers/PreviousLocationProvider';

function ViewTrade({ match }) {
  const dispatch = useDispatch();

  const { previousLocation } = React.useContext(PreviousLocationContext);

  const {
    fetching,
    editing,
    error,
    data: { [match.params.id]: trade },
  } = useSelector((state) => state.trades);

  const handleSetAdStatus = (status) => {
    dispatch(setTradeStatus(trade.id, status));
  };

  const [muteChatDialog, setMuteChatDialog] = React.useState(() =>
    Boolean(JSON.parse(window.localStorage.getItem('muteChatDialog'))),
  );

  const toggleMuteDialog = () => {
    setMuteChatDialog((v) => {
      localStorage.setItem('muteChatDialog', JSON.stringify(!v));
      return !v;
    });
  };

  React.useEffect(
    React.useCallback(() => {
      if (!trade) {
        dispatch(fetchTradeDetails(match.params.id));
      }
    }, [match.params.id, dispatch, trade]),
    [],
  );

  if (error) {
    return <Redirect to="/marketplace/trades" />;
  }
  if (fetching && !trade) {
    return <LoadingPage />;
  }

  const date =
    trade?.created_at && formatDateToReadableString(new Date(trade.created_at));

  return (
    <Page
      title={trade?.reference}
      breadcrumbs={[
        {
          content: 'Trades',
          url: previousLocation
            ? `${previousLocation.pathname}${previousLocation.search}`
            : '/marketplace/trades',
        },
      ]}
      titleMetadata={
        <Stack>
          <TextStyle>{date}</TextStyle>
          {trade?.status && <StatusBadge status={trade.status} />}
        </Stack>
      }
      secondaryActions={[
        {
          content: muteChatDialog ? 'Unmute Chat dialog' : 'Mute Chat Dialog',
          icon: SoundMajor,
          onAction: toggleMuteDialog,
        },
      ]}
    >
      {trade && (
        <>
          <Layout>
            <Layout.Section>
              <TradeDetails
                {...{ editing, fetching, trade, handleSetAdStatus }}
              />
              <TradeAnalysis analysis={trade.analysis} ad={trade.ad} />
              <TradeEvents events={trade?.events || []} />
            </Layout.Section>
            <Layout.Section secondary>
              <Card>
                <TradeTraders buyer={trade.buyer} seller={trade.seller} />
                <TradeAdDetails ad={trade.ad} />
                <TradeBankDetails bank={trade.bank} />
              </Card>
            </Layout.Section>
          </Layout>
          {trade.dispute?.channel && (
            <ChatDialog channel={trade.dispute.channel} mute={muteChatDialog} />
          )}
        </>
      )}
    </Page>
  );
}

export default ViewTrade;
