import React from 'react';
import { useFormik } from 'formik';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Page,
  Card,
  Layout,
  FormLayout,
  TextField,
  Select,
  Checkbox,
  Stack,
  Button,
} from '@shopify/polaris';
import LoadingPage from 'components/LoadingPage';
import {
  fetchAsset,
  updateAsset,
  fetchPaymentProviders,
} from 'redux/assets/actions';

export default function ViewAsset() {
  const params = useParams();
  const dispatch = useDispatch();
  const {
    data: { [params.code]: asset },
    providers,
    fetching,
    updating,
    error,
  } = useSelector((state) => state.assets);

  const form = useFormik({
    initialValues: {
      website: asset?.website,
      description: asset?.description,
      tag_name: asset?.tag_name,
      empty_tag_message: asset?.empty_tag_message,
      status: asset?.status,
      minimum_trade_amount: asset?.minimum_trade_amount,
      deposit_status: asset?.deposit_status,
      deposit_fee: asset?.deposit_fee,
      deposit_fee_description: asset?.deposit_fee_description,
      payout_status: asset?.payout_status,
      payout_provider: asset?.payout_provider,
      payout_fee: asset?.payout_fee,
      minimum_payout_amount: asset?.minimum_payout_amount,
      daily_payout_limit: asset?.daily_payout_limit,
      payout_fee_description: asset?.payout_fee_description,
      marketplace_status: asset?.marketplace_status,
      marketplace_fee: asset?.marketplace_fee,
      bushapay_status: asset?.bushapay_status,
      bushapay_fee: asset?.bushapay_fee,
      vip_payout_status: asset?.vip_payout_status,
    },
    onSubmit(values) {
      dispatch(updateAsset(asset?.code, values));
    },
    enableReinitialize: true,
    validateOnChange: false,
  });

  const statusOptions = [
    {
      label: 'Offline',
      value: 'offline',
    },
    {
      label: 'Online',
      value: 'online',
    },
  ];

  const providerOptions = providers.map((provider) => ({
    label: provider.name,
    value: provider.code,
  }));

  React.useEffect(() => {
    if (!asset) {
      dispatch(fetchAsset(params.code));
    }
  }, [asset, dispatch, params.code]);

  React.useEffect(() => {
    dispatch(fetchPaymentProviders(params.code));
  }, [dispatch, params.code]);

  if (error) {
    return <Redirect to="/settings/assets" />;
  }

  if (fetching) {
    return <LoadingPage />;
  }

  return (
    <Page
      title={asset?.name}
      breadcrumbs={[{ content: 'Assets', url: '/settings/assets' }]}
      primaryAction={{ content: 'Update Asset', onAction: form.handleSubmit }}
    >
      <form onSubmit={form.handleSubmit}>
        <Layout>
          <Layout.AnnotatedSection title="General">
            <Card sectioned>
              <div style={{ maxWidth: 400 }}>
                <FormLayout>
                  <TextField label="Name" value={asset?.name} readOnly />
                  <TextField label="Code" value={asset?.code} readOnly />
                  <TextField label="Symbol" value={asset?.symbol} readOnly />
                  <TextField label="Type" value={asset?.type} readOnly />
                  <Checkbox
                    checked={asset?.use_tag}
                    label="Uses Tag"
                    disabled={true}
                  />
                </FormLayout>
              </div>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection title="Info">
            <Card sectioned>
              <div style={{ maxWidth: 400 }}>
                <FormLayout>
                  <TextField
                    label="Website"
                    name="website"
                    value={form.values.website}
                    onChange={(v) => form.setFieldValue('website', v)}
                    onBlur={() => form.handleBlur('website')}
                  />
                  <TextField
                    label="Description"
                    name="description"
                    value={form.values.description}
                    onChange={(v) => form.setFieldValue('description', v)}
                    onBlur={() => form.handleBlur('description')}
                    multiline={3}
                  />
                  <TextField
                    label="Tag name"
                    name="tag_name"
                    value={form.values.tag_name}
                    onChange={(v) => form.setFieldValue('tag_name', v)}
                    onBlur={() => form.handleBlur('tag_name')}
                  />
                  <TextField
                    label="Empty tag message"
                    name="empty_tag_message"
                    value={form.values.empty_tag_message}
                    onChange={(v) => form.setFieldValue('empty_tag_message', v)}
                    onBlur={() => form.handleBlur('empty_tag_message')}
                  />
                </FormLayout>
              </div>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection title="Status">
            <Card sectioned>
              <div style={{ maxWidth: 400 }}>
                <FormLayout>
                  <Select
                    label="Status"
                    placeholder="Select Status"
                    name="status"
                    options={statusOptions}
                    value={form.values.status}
                    onChange={(v) => form.setFieldValue('status', v)}
                    onBlur={() => form.handleBlur('status')}
                  />
                  <TextField
                    label="Minimum payout amount"
                    placeholder="Minimum payout amount"
                    name="minimum_payout_amount"
                    type="number"
                    value={form.values.minimum_payout_amount}
                    onChange={(v) =>
                      form.setFieldValue('minimum_payout_amount', v)
                    }
                    onBlur={() => form.handleBlur('minimum_payout_amount')}
                  />
                </FormLayout>
              </div>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection title="Deposit">
            <Card sectioned>
              <div style={{ maxWidth: 400 }}>
                <FormLayout>
                  <Checkbox
                    checked={form.values.deposit_status}
                    label="Enable Deposit"
                    onChange={(checked) =>
                      form.setFieldValue('deposit_status', checked)
                    }
                  />
                  <TextField
                    label="Deposit Fee"
                    placeholder="Deposit Fee"
                    name="deposit_fee"
                    type="number"
                    value={form.values.deposit_fee}
                    onChange={(v) => form.setFieldValue('deposit_fee', v)}
                    onBlur={() => form.handleBlur('deposit_fee')}
                  />
                  <TextField
                    label="Deposit Fee Description"
                    placeholder="Deposit Fee Description"
                    name="deposit_fee_description"
                    value={form.values.deposit_fee_description}
                    onChange={(v) =>
                      form.setFieldValue('deposit_fee_description', v)
                    }
                    onBlur={() => form.handleBlur('deposit_fee_description')}
                  />
                </FormLayout>
              </div>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection title="Payout">
            <Card sectioned>
              <div style={{ maxWidth: 400 }}>
                <FormLayout>
                  <Checkbox
                    checked={form.values.payout_status}
                    label="Enable Payout"
                    onChange={(checked) =>
                      form.setFieldValue('payout_status', checked)
                    }
                  />
                  <Checkbox
                    checked={form.values.vip_payout_status}
                    label="Enable VIP Payout"
                    onChange={(checked) =>
                      form.setFieldValue('vip_payout_status', checked)
                    }
                  />
                  <Select
                    label="Payout provider"
                    placeholder="Select Payout provider"
                    name="payout_provider"
                    options={providerOptions}
                    value={form.values.payout_provider}
                    onChange={(v) => form.setFieldValue('payout_provider', v)}
                    onBlur={() => form.handleBlur('payout_provider')}
                  />
                  <TextField
                    label="Payout Fee"
                    placeholder="Payout Fee"
                    name="payout_fee"
                    type="number"
                    value={form.values.payout_fee}
                    onChange={(v) => form.setFieldValue('payout_fee', v)}
                    onBlur={() => form.handleBlur('payout_fee')}
                  />
                  <TextField
                    label="Payout Fee Description"
                    placeholder="Payout Fee Description"
                    name="payout_fee_description"
                    value={form.values.payout_fee_description}
                    onChange={(v) =>
                      form.setFieldValue('payout_fee_description', v)
                    }
                    onBlur={() => form.handleBlur('payout_fee_description')}
                  />
                  <TextField
                    label="Minimum payout amount"
                    placeholder="Minimum payout amount"
                    name="minimum_payout_amount"
                    type="number"
                    value={form.values.minimum_payout_amount}
                    onChange={(v) =>
                      form.setFieldValue('minimum_payout_amount', v)
                    }
                    onBlur={() => form.handleBlur('minimum_payout_amount')}
                  />
                  <TextField
                    label="Daily payout limit"
                    placeholder="Daily payout limit"
                    name="daily_payout_limit"
                    type="number"
                    value={form.values.daily_payout_limit}
                    onChange={(v) =>
                      form.setFieldValue('daily_payout_limit', v)
                    }
                    onBlur={() => form.handleBlur('daily_payout_limit')}
                  />
                </FormLayout>
              </div>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection title="Market Place">
            <Card sectioned>
              <div style={{ maxWidth: 400 }}>
                <FormLayout>
                  <Checkbox
                    checked={form.values.marketplace_status}
                    label="Enable Market Place"
                    onChange={(checked) =>
                      form.setFieldValue('marketplace_status', checked)
                    }
                  />
                  <TextField
                    label="Market Place Fee"
                    placeholder="Market Place Fee"
                    name="marketplace_fee"
                    type="number"
                    value={form.values.marketplace_fee}
                    onChange={(v) => form.setFieldValue('marketplace_fee', v)}
                    onBlur={() => form.handleBlur('marketplace_fee')}
                  />
                </FormLayout>
              </div>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection title="Busha Pay">
            <Card sectioned>
              <div style={{ maxWidth: 400 }}>
                <FormLayout>
                  <Checkbox
                    checked={form.values.bushapay_status}
                    label="Enable Busha Pay"
                    onChange={(checked) =>
                      form.setFieldValue('bushapay_status', checked)
                    }
                  />
                  <TextField
                    label="Busha Pay Fee"
                    placeholder="Busha Pay Fee"
                    name="bushapay_fee"
                    type="number"
                    value={form.values.bushapay_fee}
                    onChange={(v) => form.setFieldValue('bushapay_fee', v)}
                    onBlur={() => form.handleBlur('bushapay_fee')}
                  />
                </FormLayout>
              </div>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection>
            <Stack distribution="trailing">
              <Button
                primary
                loading={updating}
                type="submit"
                onClick={form.handleSubmit}
              >
                Update Asset
              </Button>
            </Stack>
          </Layout.AnnotatedSection>
        </Layout>
      </form>
    </Page>
  );
}
