import React from 'react';
import Carousel from 'react-image-gallery';
import Lightbox from 'react-image-lightbox';
import { ReactComponent as FullScreenIcon } from 'assets/img/fullScreen.svg';
import 'react-image-lightbox/style.css';

function ImageGallery({ images = [] }) {
  const carouselRef = React.useRef();

  const [isFullScreen, setIsFullScreen] = React.useState(false);
  const [photoIndex, setPhotoIndex] = React.useState(0);

  const toggleFullScreen = () => setIsFullScreen(!isFullScreen);

  const handleMovetoPreviousImage = () => {
    const currentIndex = (photoIndex + images.length - 1) % images.length;
    setPhotoIndex(currentIndex);
    carouselRef.current.slideToIndex(currentIndex);
  };

  const handleMoveToNextImage = () => {
    const currentIndex = (photoIndex + 1) % images.length;
    setPhotoIndex(currentIndex);
    carouselRef.current.slideToIndex(currentIndex);
  };

  const carouselImages = images.map((image) => ({
    original: image,
    thumbnail: image,
    originalClass: 'gallery-image',
  }));

  return (
    <>
      <Carousel
        ref={carouselRef}
        items={carouselImages}
        showPlayButton={false}
        lazyLoad
        showNav={false}
        onSlide={setPhotoIndex}
        onClick={toggleFullScreen}
        renderFullscreenButton={() =>
          carouselImages?.length > 0 && (
            <button
              className="icon-button image-gallery-fullscreen-button"
              onClick={toggleFullScreen}
            >
              <FullScreenIcon />
            </button>
          )
        }
      />
      {isFullScreen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={toggleFullScreen}
          onMovePrevRequest={handleMovetoPreviousImage}
          onMoveNextRequest={handleMoveToNextImage}
        />
      )}
    </>
  );
}

export default ImageGallery;
