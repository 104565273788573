import React from 'react';
import { Badge, ResourceList, Stack, TextStyle } from '@shopify/polaris';

function ReportItem(book) {
  const url = `/orders/reports/${book?.product}`;

  return (
    <ResourceList.Item
      id={book.id}
      url={url}
      accessibilityLabel={`View details for ${book.product}`}
    >
      <Stack distribution="equalSpacing" alignment="center">
        <TextStyle>{book.product}</TextStyle>
        <div style={{ width: 150 }}>
          <Stack distribution="equalSpacing" alignment="center">
            <Badge status={book.buy ? 'success' : 'new'}>
              {book.buy ? 'Online' : 'Offline'}
            </Badge>
            <Badge status={book.sell ? 'success' : 'new'}>
              {book.sell ? 'Online' : 'Offline'}
            </Badge>
          </Stack>
        </div>
      </Stack>
    </ResourceList.Item>
  );
}

export default ReportItem;
