import React from 'react';
import { Card, Stack, Checkbox } from '@shopify/polaris';

export default function CustomerSecurity({
  twofaStatus,
  deactivate2FA,
  loading,
}) {
  return (
    <Card>
      <Card.Section title={`Security`} sectioned>
        <Stack vertical spacing="extraTight">
          <Checkbox
            label="Authenticator"
            checked={twofaStatus}
            disabled={!twofaStatus || loading}
            onChange={deactivate2FA}
          />
        </Stack>
      </Card.Section>
    </Card>
  );
}
