import React from 'react';
import CustomerListItem from './components/CustomerListItem';
import {
  Page,
  Card,
  ResourceList,
  ChoiceList,
  Filters,
  Badge,
} from '@shopify/polaris';

import Pagination from 'components/Pagination';

import { ExportMinor } from '@shopify/polaris-icons';

import useCustomer from './useCustomer';

const Customers = () => {
  const {
    queryParams,
    updateQueryParams,
    clearAllFilters,
    handlePreviousPage,
    handleNextPage,
    fetching,
    meta,
    customers,
  } = useCustomer();

  const filters = [
    {
      key: 'status',
      label: 'Status',
      filter: (
        <ChoiceList
          title={'Status'}
          titleHidden
          choices={[
            { label: 'Active', value: 'active' },
            { label: 'Inactive', value: 'inactive' },
          ]}
          selected={queryParams.status || ''}
          onChange={(value) => updateQueryParams('status', value)}
        />
      ),
      shortcut: true,
    },
    {
      key: 'level',
      label: 'Level',
      filter: (
        <ChoiceList
          title={'Level'}
          titleHidden
          choices={[
            { label: 'Level 0', value: '0' },
            { label: 'Level 1', value: '1' },
            { label: 'Level 2', value: '2' },
            { label: 'Level 3', value: '3' },
          ]}
          selected={queryParams.level || ''}
          onChange={(value) => updateQueryParams('level', value)}
          allowMultiple
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [];

  if (queryParams.status) {
    appliedFilters.push({
      key: 'status',
      label: `Status ${queryParams.status}`,
      onRemove: () => updateQueryParams('status', ''),
    });
  }
  if (queryParams.level) {
    appliedFilters.push({
      key: 'level',
      label: `Level ${queryParams.level}`,
      onRemove: () => updateQueryParams('level', ''),
    });
  }

  return (
    <Page
      title="Customers"
      secondaryActions={[
        {
          content: 'Export',
          icon: ExportMinor,
        },
      ]}
    >
      <Card>
        <ResourceList
          loading={fetching}
          resourceName={{
            singular: 'customer',
            plural: 'customers',
          }}
          items={customers}
          renderItem={(item) => <CustomerListItem {...item} />}
          filterControl={
            <>
              <span className="search-help-text">
                Enter search text, then press <Badge>ENTER</Badge> or{' '}
                <Badge>RETURN</Badge> to search
              </span>
              <Filters
                queryValue={queryParams.q}
                filters={filters}
                appliedFilters={appliedFilters}
                onQueryChange={(value) => updateQueryParams('q', value)}
                onQueryClear={() => updateQueryParams('q', '')}
                onClearAll={clearAllFilters}
              />
            </>
          }
          sortValue={queryParams.order}
          sortOptions={[
            { label: 'Newest first', value: 'date_created_desc' },
            { label: 'Oldest first', value: 'date_created_asc' },
            {
              label: 'Most spent',
              value: 'total_spent_desc',
            },
            { label: 'Most Orders', value: 'orders_count_desc' },
          ]}
          onSortChange={(value) => updateQueryParams('order', value)}
        />
        <Pagination
          resource={customers}
          meta={meta}
          handlePreviousPage={handlePreviousPage}
          handleNextPage={handleNextPage}
        />
      </Card>
    </Page>
  );
};

export default Customers;
