import api from 'utils/api';
import {
  FETCH_BOOKS,
  FETCH_BOOK_DETAILS,
  UPDATE_BOOK_DETAILS,
} from './actionTypes';

/**
 *
 * @typedef { import('../types').GenericDispatchAction } GenericDispatchAction
 * @typedef { import('./types').UpdateBookDetailsData } UpdateBookDetailsData
 */

/**
 *
 * @param {string=} queryString A string that determines the type of books returned from the backend
 * @description Fetches the books corresponding to the query string from the books api endpoint
 * @returns { GenericDispatchAction }
 */

export const fetchBooks = (queryString = '') => (dispatch) => {
  const payload = api.get(`/v1.1/books${queryString}`);
  return dispatch({ type: FETCH_BOOKS.default, payload });
};

/**
 *
 * @param {string} id A string that represents the id of the book to be fetched
 * @description Fetches the details of the book with the corresponding id
 * @returns { GenericDispatchAction }
 */

export const fetchBookDetails = (id) => (dispatch) => {
  const payload = api.get(`/v1.1/books/${id}`);
  return dispatch({ type: FETCH_BOOK_DETAILS.default, payload });
};

/**
 *
 * @param {string} id A string that represents the id of the book to be updated
 * @param { UpdateBookDetailsData } data
 * @description updates the details of the book with the corresponding id and adds the data
 * @returns { GenericDispatchAction }
 */

export const updateBookDetails = (id, data) => (dispatch) => {
  const payload = api.put(`/v1.1/books/${id}`, data);
  return dispatch({ type: UPDATE_BOOK_DETAILS.default, payload }).then(
    (res) => {
      dispatch(fetchBookDetails(id));
      return res;
    },
  );
};
