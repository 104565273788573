import React from 'react';
import {
  Card,
  SkeletonBodyText,
  SkeletonThumbnail,
  TextContainer,
} from '@shopify/polaris';
import FadeIn from 'animations/FadeIn';

const CardThumbnailListSkeleton = () => {
  return (
    <FadeIn>
      <Card sectioned>
        <TextContainer>
          <SkeletonThumbnail />
          <SkeletonBodyText lines={2} />
        </TextContainer>
      </Card>
      <Card sectioned>
        <TextContainer>
          <SkeletonThumbnail />
          <SkeletonBodyText lines={2} />
        </TextContainer>
      </Card>
      <Card sectioned>
        <TextContainer>
          <SkeletonThumbnail />
          <SkeletonBodyText lines={2} />
        </TextContainer>
      </Card>
    </FadeIn>
  );
};

export default CardThumbnailListSkeleton;
