function formatNumberToMoney(
  number = 0,
  {
    countryCode = 'US',
    currencyCode,
    category = 'fiat',
    codePosition = 'front',
    maximumFractionDigits,
  } = {},
) {
  const decimalPoints = getDecimalPointsBasedOnCategory(category);

  const formatter = new Intl.NumberFormat(`en-${countryCode}`, {
    maximumFractionDigits: maximumFractionDigits || decimalPoints,
  });

  const formattedCurrency = formatter.format(number);

  if (currencyCode) {
    if (decimalPoints > 2 || codePosition === 'back') {
      return `${formattedCurrency} ${currencyCode}`;
    }

    return `${currencyCode} ${formattedCurrency}`;
  }

  return formattedCurrency;
}

const getDecimalPointsBasedOnCategory = (category) => {
  switch (category) {
    case 'fiat':
    case 'NGN':
    case 'USD': {
      return 2;
    }
    default: {
      return 8;
    }
  }
};

export { formatNumberToMoney };
