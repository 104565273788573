import React, { Fragment } from 'react';
import { useConfirmation } from 'utils/confirmationService';
import { Redirect, useParams } from 'react-router-dom';
import { Page, Layout, Card, TextStyle } from '@shopify/polaris';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from 'redux/deposits/actions';
import LoadingPage from 'components/LoadingPage';
import { formatDateToReadableString } from 'utils/date';
import { toast } from 'utils/toast';

import DepositBreakDown from './components/DepositBreakDown';
import DepositReceiver from './components/DepositReceiver';
import DepositProcessor from './components/DepositProcessor';
import DepositFromAddress from './components/DepositFromAddress';
import DepositAddress from './components/DepositAddress';
import DepositHash from './components/DepositHash';
import DepositBankDetails from './components/DepositBankDetails';
import DepositProvider from './components/DepositProvider';
import DepositChannel from './components/DepositChannel';
import DepositCardDetails from './components/DepositCardDetails';
import DepositProfit from './components/DepositProfit';
import DepositUser from './components/DepositUser';
import StatusBadge from 'components/StatusBadge';
import { PreviousLocationContext } from 'providers/PreviousLocationProvider';

function ViewDeposit() {
  const confirm = useConfirmation();

  const { base, app, id: depositId } = useParams();

  const dispatch = useDispatch();
  const { previousLocation } = React.useContext(PreviousLocationContext);

  const {
    data: { [depositId]: deposit },
    statusMessage,
    approvingDeposit,
    decliningDeposit,
    fulfillingDeposit,
    flaggingDeposit,
    updating,
    fetching,
    error,
  } = useSelector((state) => state.deposits);

  const handleApproveDeposit = () => {
    confirm({
      title: `White list deposits`,
      onSubmitText: 'Allow',
      onCancelText: 'Flag',
      description: `In the future, Do you want to automatically allow deposits to this user's wallet from accounts in this name?`,
      onCancel: () =>
        dispatch(
          actions.approveCashDeposit({ depositId, app, white_list: false }),
        ),
    }).then(() =>
      dispatch(
        actions.approveCashDeposit({ depositId, app, white_list: true }),
      ),
    );
  };

  const handleFufillDeposit = () => {
    dispatch(actions.fulfillCashDeposit(depositId, app));
  };

  const handleDeclineDeposit = () => {
    dispatch(actions.declineCashDeposit(depositId, app));
  };

  const handleCheckStatus = () => {
    dispatch(actions.checkDepositStatus(base, app, depositId));
  };

  const handleFlagDeposit = () => {
    dispatch(actions.flagDeposit(base, app, depositId)).then(() => {
      toast('Deposit Successfully Flagged');
    });
  };

  React.useEffect(() => {
    dispatch(actions.fetchDeposit(base, app, depositId));
  }, [app, base, depositId, dispatch]);

  React.useEffect(
    React.useCallback(() => {
      if (statusMessage) {
        toast(statusMessage);
        dispatch(actions.clearDepositStatus());
      }
    }, [statusMessage, dispatch]),
    [statusMessage],
  );

  if (error) {
    return <Redirect to={`/deposits/${base}/${app}`} />;
  }
  if (fetching && !deposit) {
    return <LoadingPage />;
  }

  return (
    <Page
      breadcrumbs={[
        {
          content: 'Deposits',
          url: previousLocation
            ? `${previousLocation.pathname}${previousLocation.search}`
            : `/deposits/${base}/${app}`,
        },
      ]}
      title={deposit?.reference}
      primaryAction={
        base === 'cash-deposits'
          ? deposit?.fulfilment?.toLowerCase() !== 'flagged'
            ? {
                content: 'Flag Deposit',
                loading: flaggingDeposit,
                onAction: handleFlagDeposit,
                primary: false,
              }
            : undefined
          : {
              content: 'Check Status',
              loading: updating,
              onAction: handleCheckStatus,
              primary: false,
            }
      }
      subtitle={
        <>
          <TextStyle>
            {deposit?.created_at &&
              formatDateToReadableString(new Date(deposit.created_at))}
          </TextStyle>
          {deposit?.status && <StatusBadge status={deposit.status} />}
          {deposit?.fulfilment && <StatusBadge status={deposit.fulfilment} />}
        </>
      }
    >
      <Layout>
        <Layout.Section>
          <DepositBreakDown
            {...(deposit || {})}
            base={base}
            {...{
              handleApproveDeposit,
              handleDeclineDeposit,
              handleFufillDeposit,
              approvingDeposit,
              fulfillingDeposit,
              decliningDeposit,
            }}
          />
        </Layout.Section>
        <Layout.Section secondary>
          <Card>
            {base === 'cash-deposits' && (
              <Fragment>
                <DepositUser user={deposit?.user} />
                <DepositProfit
                  fee={deposit?.fee}
                  feeDiscount={deposit?.fee_discount}
                  providerFee={deposit?.provider_fee}
                  currency={deposit?.currency}
                />
                {(deposit?.card?.last_4 || deposit?.card?.last4) && (
                  <DepositCardDetails card={deposit.card} />
                )}
                <DepositProvider provider={deposit?.provider} />
                <DepositChannel channel={deposit?.channel} />
                {deposit?.source_bank?.number && (
                  <DepositBankDetails source_bank={deposit?.source_bank} />
                )}
              </Fragment>
            )}
            {base === 'crypto-deposits' && (
              <Fragment>
                <DepositReceiver
                  receiver_id={deposit?.receiver_id}
                  receiver_name={deposit?.receiver_name}
                />
                <DepositHash
                  hash={deposit?.hash}
                  currency={deposit?.currency}
                />
                <DepositProcessor processor={deposit?.processor} />
                <DepositFromAddress from_address={deposit?.from_address} />
                <DepositAddress address={deposit?.address} />
              </Fragment>
            )}
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

export default ViewDeposit;
