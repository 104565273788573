import React from 'react';
import { TextStyle, Stack, Card } from '@shopify/polaris';

export default function OrderCustomerDetails({ user }) {
  return (
    <Card.Section title="Contact Information" actions={[{ content: 'View' }]}>
      <Stack vertical spacing="tight">
        {user?.email ? (
          <a className="anchor" href={`mailto:${user?.email}`}>
            {user?.email}
          </a>
        ) : (
          <TextStyle variation="subdued">No email provided</TextStyle>
        )}
        {user?.phone ? (
          <a className="anchor" href={`tel:${user?.phone}`}>
            {user?.phone}
          </a>
        ) : (
          <TextStyle variation="subdued">No phone provided</TextStyle>
        )}
      </Stack>
    </Card.Section>
  );
}
