import React from 'react';
import { Card, Stack, Heading, TextStyle, Button } from '@shopify/polaris';
import { formatNumberToMoney } from 'utils/money';

export default function PayoutBreakDown({
  payout,
  loading,
  onPayoutStatusChange,
  onRetryPayout,
  toggleModal,
  action,
}) {
  const {
    fee,
    actual_fee,
    currency,
    amount,
    status,
    fulfilment_status,
  } = payout;

  const profit = Number(fee) - Number(actual_fee);

  const showCancelButton = Boolean(
    status?.toLowerCase() === 'pending' ||
      fulfilment_status?.toLowerCase() === 'failed',
  );

  const showRetryButton = Boolean(
    ['pending', 'failed', ''].includes(status?.toLowerCase()) ||
      ['pending', 'failed', ''].includes(fulfilment_status?.toLowerCase()) ||
      !status ||
      !fulfilment_status,
  );

  const showUnqueueButton = Boolean(
    fulfilment_status?.toLowerCase() === 'queued',
  );

  const showCompleteButton =
    !['completed', 'fulfilled', 'cancelled'].includes(status?.toLowerCase()) ||
    !['completed', 'fulfilled', 'cancelled'].includes(
      fulfilment_status?.toLowerCase(),
    );

  return (
    <Card
      title={
        <Stack>
          <Heading>Payout Details</Heading>
        </Stack>
      }
    >
      <Card.Section>
        <Card.Subsection>
          <Stack vertical spacing="loose">
            <Stack distribution="equalSpacing">
              <TextStyle>Amount</TextStyle>
              <TextStyle>
                {formatNumberToMoney(amount, {
                  currencyCode: currency,
                  category: currency,
                  codePosition: 'back',
                })}
              </TextStyle>
            </Stack>
            <Stack distribution="equalSpacing">
              <TextStyle>Fees paid by customer</TextStyle>
              <TextStyle>
                {formatNumberToMoney(fee, {
                  currencyCode: currency,
                  category: currency,
                  codePosition: 'back',
                })}
              </TextStyle>
            </Stack>
            <Stack distribution="equalSpacing">
              <TextStyle>Actual fees </TextStyle>
              <TextStyle>
                {formatNumberToMoney(actual_fee, {
                  currencyCode: currency,
                  category: currency,
                  codePosition: 'back',
                })}
              </TextStyle>
            </Stack>
            <Stack distribution="equalSpacing">
              <TextStyle variation="strong">Total</TextStyle>
              <TextStyle variation="strong">
                {formatNumberToMoney(amount + fee, {
                  currencyCode: currency,
                  category: currency,
                  codePosition: 'back',
                })}
              </TextStyle>
            </Stack>
          </Stack>
        </Card.Subsection>
        <Card.Subsection>
          <Stack vertical spacing="loose">
            <Stack distribution="equalSpacing">
              <TextStyle variation={profit < 0 ? 'negative' : 'positive'}>
                {profit < 0 ? 'Loss' : 'Profit'}
              </TextStyle>
              <TextStyle variation={profit < 0 ? 'negative' : 'positive'}>
                {formatNumberToMoney(profit, {
                  currencyCode: currency,
                  category: currency,
                  codePosition: 'back',
                })}
              </TextStyle>
            </Stack>
            <Stack distribution="trailing" spacing="tight" alignment="center">
              {showCancelButton && (
                <Button
                  destructive
                  onClick={() => onPayoutStatusChange({ status: 'cancelled' })}
                  loading={loading && action === 'cancel'}
                >
                  Cancel
                </Button>
              )}
              {showRetryButton && (
                <Button
                  primary
                  onClick={onRetryPayout}
                  loading={loading && action === 'retry'}
                >
                  Retry
                </Button>
              )}
              {showUnqueueButton && (
                <Button
                  primary
                  onClick={() => onPayoutStatusChange({ status: 'pending' })}
                  loading={loading && action === 'unqueue'}
                >
                  Unqueue
                </Button>
              )}
              {showCompleteButton && (
                <Button
                  onClick={toggleModal}
                  loading={loading && action === 'complete'}
                >
                  Complete
                </Button>
              )}
            </Stack>
          </Stack>
        </Card.Subsection>
      </Card.Section>
    </Card>
  );
}
