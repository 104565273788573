import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';

import { Loading, Frame } from '@shopify/polaris';

import { logout } from '../redux/auth/actions';
import { fetchProfile } from '../redux/profile/actions';

import SideBar from './SideBar';
import TopBar from './TopBar';
import appRoutes from '../routes/app';
import useUpdateEffect from 'hooks/useUpdatedEffect';

/**
 *
 * @param { { isLoading: boolean; showMobileNavigation: boolean; storeName : string; page : string } } state
 * @param { { type : 'isLoading' | 'showMobileNavigation' | 'storeName' | 'page'; value ?: any } } action
 * @returns { state }
 */

const reducer = (state, action) => {
  const { type, value } = action;
  switch (type) {
    case 'isLoading':
    case 'showMobileNavigation':
      return { ...state, [type]: !state[type] };

    case 'page':
    case 'storeName':
      return { ...state, [type]: value };
    default:
      return state;
  }
};

const App = () => {
  const { location, push } = useHistory();
  const [state, internalDispatch] = React.useReducer(reducer, {
    isLoading: false,
    showMobileNavigation: false,
    storeName: 'Grey',
    page: location.pathname,
  });

  const { authData, profile } = useSelector((state) => ({
    authData: state.auth.data,
    profile: state.profile.data,
  }));

  const reduxDispatch = useDispatch();

  const toggleBar = (key) => {
    return () => {
      internalDispatch({ type: key });
    };
  };

  const handleLogout = () => {
    reduxDispatch(logout());
  };

  const loadingMarkup = React.useMemo(
    () => (state.isLoading ? <Loading /> : null),
    [state.isLoading],
  );

  React.useEffect(
    React.useCallback(() => {
      reduxDispatch(fetchProfile());
    }, [reduxDispatch]),
    [],
  );

  useUpdateEffect(() => {
    if (!authData) {
      push('/login');
    }
  }, [authData]);

  return (
    <Frame
      topBar={
        <TopBar
          onNavigationToggle={toggleBar('showMobileNavigation')}
          storeName={state.storeName}
          handleLogout={handleLogout}
          profile={profile}
        />
      }
      navigation={<SideBar />}
      showMobileNavigation={state.showMobileNavigation}
      onNavigationDismiss={toggleBar('showMobileNavigation')}
    >
      {loadingMarkup}
      <Switch>
        {appRoutes.map(({ path, ...rest }) => {
          return <Route key={path} path={path} {...rest} />;
        })}
        <Redirect from="/" to="/customers" />
      </Switch>
    </Frame>
  );
};

export default App;
