import {FETCH_PROFILE} from './actionTypes';
import storage from '../../utils/storage';
import parseError from '../../utils/parseError';

const INITIAL_STATE = {
  data: storage.get('profile'),
  fetching: false,
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_PROFILE.pending:
      return {
        ...state,
        fetching: true,
        error: null,
      };
    case FETCH_PROFILE.rejected:
      return {
        ...state,
        fetching: false,
        error: parseError(action.payload),
      };
    case FETCH_PROFILE.fulfilled:
      return {
        ...state,
        fetching: false,
        data: action.payload.data,
      };
    default:
      return state;
  }
};
