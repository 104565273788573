import React from 'react';
import { Modal, FormLayout, TextField } from '@shopify/polaris';

function AddDepositModal({ isOpen, toggleModal, handleAddDeposit, loading }) {
  const [hash, setHash] = React.useState('');

  return (
    <Modal
      open={isOpen}
      onClose={toggleModal}
      title="Add Crypto Deposit"
      primaryAction={{
        content: 'Submit',
        disabled: !hash,
        loading,
        onAction: () => handleAddDeposit(hash),
      }}
    >
      <Modal.Section>
        <FormLayout>
          <TextField
            name="hash"
            label="Hash"
            value={hash}
            onChange={(value) => setHash(value)}
          />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}

export default AddDepositModal;
