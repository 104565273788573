import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  display: grid;
  padding: 20px;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
`;

export const SettingsCard = styled(Link)(
  (props) => `
  display: flex;
  padding: 15px;
  cursor: pointer;
  border-radius: 3px;
  align-items: flex-start;
  list-style-type: none;

  :hover {
    background: #f9fafb;

    .Polaris-Icon {
      background: #dfe3e7;
    }
  }

  .Polaris-Icon {
    display: flex;
    justify-content: center;
    margin: 0;
    align-items: center;
    min-width: 40px;
    height: 40px;
    background: #f4f6f8;
    border-radius: 3px;

    svg {
      width: 18px;
      height: 18px;
    }
  }
  .content {
    margin-left: 20px;
    .title {
      color: #007ace;
      font-size: 14px;
      font-weight: 600;
    }

    .subtitle {
      font-size: 14px;
      color: #637381;
      padding: 0;
      margin: 0;
      font-weight: 400;
    }
  }
  ${
    props.disabled &&
    css`
      color: #919eab;
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.5;

      .Polaris-Icon {
        svg {
          path {
            fill: #919eab;
          }
        }
      }
    `
  }
`,
);
