const getTradesFetchingSelector = (state) => state.trades.fetching;
const getTradesEditLoadingSelector = (state) => state.trades.editing;
const getTradeDetailsSelector = (state, id) => state.trades.data[id];
const getTradeSummarySelector = (state) => state.trades.summary;
const getTradesSelector = (state) => Object.values(state.trades.data);
const getTradesMetaSelector = (state) => state.trades.meta;
const getTradesErrorSelector = (state) => state.trades.error;

export {
  getTradesFetchingSelector,
  getTradesEditLoadingSelector,
  getTradesSelector,
  getTradesMetaSelector,
  getTradeDetailsSelector,
  getTradeSummarySelector,
  getTradesErrorSelector,
};
