import React from 'react';
import { Card, TextStyle, Stack, Heading, Button } from '@shopify/polaris';
import { formatNumberToMoney } from 'utils/money';

export default function TradeDetails({
  trade,
  fetching,
  editing,
  handleSetAdStatus,
}) {
  const feePercentage = `${trade.fee * 100} %`;

  return (
    <Card
      sectioned
      title={
        <Stack distribution="equalSpacing" alignment="center">
          <Heading>Trade Details</Heading>
          {trade.status !== 'canceled' && trade.status !== 'completed' && (
            <Stack>
              <Button
                disabled={fetching || editing}
                onClick={() => handleSetAdStatus('completed')}
              >
                Resolve
              </Button>
              <Button
                destructive
                disabled={fetching || editing}
                onClick={() => handleSetAdStatus('canceled')}
              >
                Cancel
              </Button>
            </Stack>
          )}
        </Stack>
      }
    >
      <Stack vertical>
        <Stack spacing="loose" distribution="equalSpacing">
          <TextStyle>Buyer is buying</TextStyle>
          <TextStyle>
            {formatNumberToMoney(trade.buyer_amount, {
              category: trade.ad.asset,
              currencyCode: trade.ad.asset,
            })}
          </TextStyle>
        </Stack>
        <Stack spacing="loose" distribution="equalSpacing">
          <TextStyle>Seller is selling</TextStyle>
          <TextStyle>
            {formatNumberToMoney(trade.seller_amount, {
              category: trade.ad.currency,
              currencyCode: trade.ad.currency,
            })}
          </TextStyle>
        </Stack>
        <Stack spacing="loose" distribution="equalSpacing">
          <TextStyle>Fee</TextStyle>
          <TextStyle>{feePercentage}</TextStyle>
        </Stack>
        <Stack spacing="loose" distribution="equalSpacing">
          <TextStyle>Rate</TextStyle>
          <TextStyle>
            {trade.ad.currency} {trade.ad.rate} / {trade.ad.base_currency}
          </TextStyle>
        </Stack>
        <Stack spacing="loose" distribution="equalSpacing">
          <TextStyle>Price</TextStyle>
          <TextStyle>
            {formatNumberToMoney(trade.price, {
              category: trade.ad.base_currency,
              currencyCode: trade.ad.base_currency,
            })}
          </TextStyle>
        </Stack>
      </Stack>
    </Card>
  );
}
