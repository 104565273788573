import React from 'react';
import { useSelector } from 'react-redux';
import { Page, Card, Icon } from '@shopify/polaris';
import { Container, SettingsCard } from './styles';
import SettingsRoutes from 'routes/settings';

const Settings = () => {
  const hasFullAccess = useSelector(
    (state) => state.profile?.data?.full_access,
  );

  return (
    <Page title="Settings">
      <Card>
        <Container>
          {SettingsRoutes(hasFullAccess).map(
            ({ icon, path, subtitle, title, disabled }, key) => (
              <SettingsCard key={key} disabled={disabled} to={path}>
                <Icon source={icon} />
                <div className="content">
                  <h3 className="title">{title}</h3>
                  <p className="subtitle">{subtitle}</p>
                </div>
              </SettingsCard>
            ),
          )}
        </Container>
      </Card>
    </Page>
  );
};

export default Settings;
