import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Card, Button, Stack, TextStyle } from '@shopify/polaris';
import { getNamePronoun } from 'utils/strings';

export default function CustomerRecurringOrder({ name }) {
  const match = useRouteMatch();
  return (
    <Card>
      <Card.Section title="Recurring Purchases">
        <Stack vertical>
          <TextStyle>
            View all {getNamePronoun(name)} recurring purchases
          </TextStyle>
          <Button url={`${match.url}/recurring-orders`}>View Purchases</Button>
        </Stack>
      </Card.Section>
    </Card>
  );
}
