import React from 'react';
import { useFormik } from 'formik';
import {
  Modal,
  FormLayout,
  TextField,
  Stack,
  Button,
  TextStyle,
  Checkbox,
} from '@shopify/polaris';
import DatePicker from 'react-datepicker';
import { getNamePronoun } from 'utils/strings';
import { formatDateToPaystackFormat } from 'utils/date';

export default function UpdateBvnModal({
  open,
  handleClose,
  handleUpdateBvn,
  handleEditBvn,
  bvnDetails,
  name,
  loading,
  handleRemoveBvn,
  bvnDetailsDeleting,
}) {
  const [updateDob, setUpdateDob] = React.useState(false);

  const validateAddNewBvnForm = (values) => {
    const errors = {};
    if (!values.bvn) {
      errors.bvn = 'BVN field is required';
    } else if (isNaN(values.bvn)) {
      errors.bvn = 'BVN can only consist of digits';
    } else if (String(values.bvn).length !== 11) {
      errors.bvn = 'BVN can only consist of 11 digits';
    }
    return errors;
  };

  const onSubmitAddNewBvnForm = (values) => handleUpdateBvn(values);

  const addNewBvnForm = useFormik({
    initialValues: {
      bvn: '',
    },
    onSubmit: onSubmitAddNewBvnForm,
    validate: validateAddNewBvnForm,
    validateOnChange: false,
  });

  const onSubmitEditBvnForm = (values) =>
    handleEditBvn({
      ...values,
      dob: updateDob ? formatDateToPaystackFormat(values.dob) : bvnDetails?.dob,
    });

  const editBvnForm = useFormik({
    initialValues: {
      bvn: bvnDetails?.bvn,
      mobile: bvnDetails?.mobile,
      dob: bvnDetails?.dob ? new Date(bvnDetails?.dob) : '',
    },
    onSubmit: onSubmitEditBvnForm,
    validateOnChange: false,
    enableReinitialize: true,
  });

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={`Update ${getNamePronoun(name)} bank verification number`}
    >
      <Modal.Section>
        <form>
          <Stack vertical>
            <TextStyle>Add new bvn number</TextStyle>
            <FormLayout>
              <TextField
                name="bvn"
                label="Bank Verification Number"
                value={addNewBvnForm.values.bvn}
                onChange={(value) => addNewBvnForm.setFieldValue('bvn', value)}
                error={addNewBvnForm.errors.bvn}
              />
            </FormLayout>
            <Stack distribution="trailing">
              {bvnDetails && (
                <Button
                  destructive
                  onClick={handleRemoveBvn}
                  loading={bvnDetailsDeleting}
                >
                  Remove bvn
                </Button>
              )}
              <Button
                primary
                onClick={addNewBvnForm.handleSubmit}
                loading={loading}
              >
                Update bvn
              </Button>
            </Stack>
          </Stack>
        </form>
      </Modal.Section>
      <Modal.Section>
        <form>
          <Stack vertical spacing="extraLoose">
            <TextStyle>Update current bvn details</TextStyle>
            <FormLayout>
              <TextField
                name="mobile"
                label="Phone number"
                type="tel"
                value={editBvnForm.values.mobile}
                onChange={(value) => editBvnForm.setFieldValue('mobile', value)}
                error={editBvnForm.errors.mobile}
              />
            </FormLayout>
            <FormLayout>
              <Checkbox
                checked={updateDob}
                onChange={(checked) => setUpdateDob(checked)}
                label="Update Date of birth"
              />
              {updateDob && (
                <DatePicker
                  selected={editBvnForm.values.dob}
                  onChange={(date) => editBvnForm.setFieldValue('dob', date)}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  dateFormat="dd-MM-yyyy"
                />
              )}
            </FormLayout>
            <Stack distribution="trailing">
              <Button
                primary
                onClick={editBvnForm.handleSubmit}
                loading={loading}
              >
                Update details
              </Button>
            </Stack>
          </Stack>
        </form>
      </Modal.Section>
    </Modal>
  );
}
