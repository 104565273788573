import React, { useMemo } from 'react';
import { Form, FormLayout, TextField, Select } from '@shopify/polaris';
import { getSelectedValueFromInput } from 'utils/helpers';
import countriesAndStates from 'data/countriesAndStates.json';

export default function EditAddressForm({
  values,
  handleInputChange,
  handleSubmit,
  errors,
  touched,
}) {
  const countries = useMemo(
    () =>
      countriesAndStates.map((country) => `${country.name} - ${country.iso2}`),
    [],
  );
  const states = useMemo(() => {
    const selectedCountry = countriesAndStates.find(
      (country) => country.name === values.country,
    );
    return selectedCountry ? selectedCountry.states : [];
  }, [values.country]);

  return (
    <Form onSubmit={handleSubmit}>
      <FormLayout>
        <TextField
          value={values.street_address_1}
          onChange={handleInputChange('street_address_1')}
          label="Street Address 1"
          name="street_address_1"
          error={
            errors.street_address_1 && touched.street_address_1
              ? errors.street_address_1
              : ''
          }
        />
        <TextField
          value={values.street_address_2}
          onChange={handleInputChange('street_address_2')}
          label="Street Address 2"
          name="street_address_2"
          error={
            errors.street_address_2 && touched.street_address_2
              ? errors.street_address_2
              : ''
          }
        />
        <FormLayout.Group>
          <TextField
            value={values.city}
            onChange={handleInputChange('city')}
            label="City"
            name="city"
            error={errors.city && touched.city ? errors.city : ''}
          />
          <TextField
            value={values.postal_code}
            onChange={handleInputChange('postal_code')}
            label="Postal Code"
            name="postal_code"
            error={
              errors.postal_code && touched.postal_code
                ? errors.postal_code
                : ''
            }
          />
        </FormLayout.Group>
        <FormLayout.Group>
          <Select
            label="Country"
            name="country_and_code"
            options={countries}
            placeholder="-- Pick a Country --"
            onChange={(value) => {
              let val = getSelectedValueFromInput(value);
              const [country, country_code] = val.split(' - ');
              handleInputChange('country')(country);
              handleInputChange('country_code')(country_code);
            }}
            value={`${values.country} - ${values.country_code}`}
            error={errors.country && touched.country ? errors.country : ''}
          />
          <Select
            label="State/Province"
            options={states}
            onChange={(value) =>
              handleInputChange('state')(getSelectedValueFromInput(value))
            }
            value={values.state}
            placeholder="-- Pick a state --"
            name="state"
            error={errors.state && touched.state ? errors.state : ''}
          />
        </FormLayout.Group>
      </FormLayout>
    </Form>
  );
}
