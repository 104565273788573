import React from 'react';
import { TextStyle } from '@shopify/polaris';

import MarketPlaceListContainer from 'components/MarketPlacecContainerList';

const headings = [
  'Trade',
  'Date',
  'Buyer',
  'Side',
  'Amount',
  'Asset',
  'Seller',
  'Price',
];
const TradeItemHeader = () => {
  return (
    <MarketPlaceListContainer className="heading">
      {headings.map((title) => (
        <TextStyle key={title} variation="strong">
          {title}{' '}
        </TextStyle>
      ))}
    </MarketPlaceListContainer>
  );
};

export default TradeItemHeader;
