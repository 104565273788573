import {
  FETCH_STAFF,
  FETCH_STAFF_PROFILE,
  ADD_NEW_STAFF,
  ACTIVATE_STAFF,
  DEACTIVATE_STAFF,
  UPDATE_STAFF,
  CHANGE_STAFF_PASSWORD,
  DELETE_STAFF,
} from './actionTypes';
import parseError from 'utils/parseError';
import { mapKeys } from 'lodash';
const INITIAL_STATE = {
  data: {},
  fetching: false,
  updating: false,
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_STAFF.pending:
    case FETCH_STAFF_PROFILE.pending:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case FETCH_STAFF.rejected:
    case FETCH_STAFF_PROFILE.rejected:
      return {
        ...state,
        fetching: false,
        error: parseError(action.payload),
      };

    case FETCH_STAFF.fulfilled:
      return {
        ...state,
        fetching: false,
        data: mapKeys(action.payload.data, 'id'),
      };

    case FETCH_STAFF_PROFILE.fulfilled:
      return {
        ...state,
        fetching: false,
        data: {
          ...state.data,
          [action.payload.data.id]: action.payload.data,
        },
      };

    case ADD_NEW_STAFF.pending:
    case ACTIVATE_STAFF.pending:
    case DEACTIVATE_STAFF.pending:
    case UPDATE_STAFF.pending:
    case CHANGE_STAFF_PASSWORD.pending:
    case DELETE_STAFF.pending:
      return {
        ...state,
        updating: true,
        error: null,
      };

    case ADD_NEW_STAFF.rejected:
    case ACTIVATE_STAFF.rejected:
    case DEACTIVATE_STAFF.rejected:
    case UPDATE_STAFF.rejected:
    case CHANGE_STAFF_PASSWORD.rejected:
    case DELETE_STAFF.rejected:
      return {
        ...state,
        updating: false,
        error: parseError(action.payload),
      };

    case ADD_NEW_STAFF.fulfilled:
    case ACTIVATE_STAFF.fulfilled:
    case DEACTIVATE_STAFF.fulfilled:
    case UPDATE_STAFF.fulfilled:
    case CHANGE_STAFF_PASSWORD.fulfilled:
    case DELETE_STAFF.fulfilled:
      return {
        ...state,
        updating: false,
      };
    default:
      return state;
  }
};
