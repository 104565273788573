import React from 'react';
import FormModal from 'components/FormModal';
import EditNoteForm from './EditNoteForm';
import { editVerificationNoteSchema } from 'utils/validationSchemas';

export default function EditNoteModal({
  open,
  handleClose,
  onAction,
  note,
  loading,
}) {
  return (
    <FormModal
      Form={EditNoteForm}
      open={open}
      handleClose={handleClose}
      initialValues={{
        note: note,
      }}
      validationSchema={editVerificationNoteSchema}
      onAction={onAction}
      loading={loading}
      title={`Decline submission`}
    />
  );
}
