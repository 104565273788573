const getVericationsFetching = (state) => state.verifications.fetching;
const getVericationsEditLoading = (state) => state.verifications.editing;
const getVericationsDetails = (state, id) => state.verifications.data[id];
const getVerifications = (state) => Object.values(state.verifications.data);
const getVerificationsMeta = (state) => state.verifications.meta;
const getVerificationsError = (state) => state.verifications.error;

export {
  getVericationsFetching,
  getVericationsEditLoading,
  getVerifications,
  getVerificationsMeta,
  getVericationsDetails,
  getVerificationsError,
};
