import React from 'react';
import { Card, Stack, TextStyle, Avatar } from '@shopify/polaris';
import { formatISODateToDayMonthYear } from 'utils/date';

export default function RefereeTransaction({ transafer, refereeName }) {
  const currencyIcon = (currency) =>
    `https://res.cloudinary.com/dwoc5fknz/image/upload/v1545995759/crypto_assets/${currency}.svg`;

  const transactionDate = (date) => formatISODateToDayMonthYear(new Date(date));
  return (
    <Card title="Referee's first transaction" sectioned>
      {transafer ? (
        <Stack distribution="equalSpacing" alignment="center">
          <Stack alignment="center">
            <Avatar
              name={transafer.currency}
              source={currencyIcon(transafer.currency)}
              size="small"
            />
            <TextStyle variation="strong">{transafer.currency}</TextStyle>
          </Stack>
          <TextStyle variation="positive">
            {transafer.currency} {transafer.amount}
          </TextStyle>
          <TextStyle>{transactionDate(transafer.created_at)}</TextStyle>
        </Stack>
      ) : (
        <Stack distribution="center">
          <TextStyle variation="subdued">
            {refereeName} has not made any transaction
          </TextStyle>
        </Stack>
      )}
    </Card>
  );
}
