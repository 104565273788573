import React, { useState, useContext } from 'react';
import { TopBar as PTopBar } from '@shopify/polaris';
import { DarkModeSwitch } from 'react-toggle-dark-mode';
import { LogOutMinor } from '@shopify/polaris-icons';
import { ThemeProvider } from 'App';

function TopBar({ onNavigationToggle, storeName, handleLogout, profile }) {
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const toggleMenuOpen = () => setUserMenuOpen(!userMenuOpen);
  const { colorScheme, toggleColorScheme } = useContext(ThemeProvider);

  const userMenuMarkup = (
    <PTopBar.UserMenu
      actions={[
        {
          id: '123',
          items: [
            { content: 'Log out', icon: LogOutMinor, onAction: handleLogout },
          ],
        },
      ]}
      name={
        profile
          ? `${profile?.first_name} ${profile?.last_name}`
          : 'Administrator'
      }
      detail={storeName}
      initials={profile ? `${profile.first_name[0]}` : 'A'}
      open={userMenuOpen}
      onToggle={toggleMenuOpen}
    />
  );

  const secondaryMenuMarkup = (
    <PTopBar.Menu
      activatorContent={
        <DarkModeSwitch
          onChange={toggleColorScheme}
          checked={colorScheme === 'dark'}
          size={20}
          sunColor="#ffffff"
          moonColor="#ffffff"
        />
      }
    />
  );

  return (
    <PTopBar
      showNavigationToggle={true}
      userMenu={userMenuMarkup}
      onNavigationToggle={onNavigationToggle}
      secondaryMenu={secondaryMenuMarkup}
    />
  );
}

export default TopBar;
