import React from 'react';
import { Card, Link, Stack, Heading, TextStyle } from '@shopify/polaris';
import AvatarInitials from 'components/AvatarInitials';

export default function AdTrader({ trader }) {
  return (
    <>
      <Card.Section
        title={
          <Stack distribution="equalSpacing">
            <Stack.Item>
              <Heading>Customer</Heading>
            </Stack.Item>
            <Stack.Item>
              <Link url={`/customers/${trader.id}`}>
                <AvatarInitials firstName={trader.username} size="small" />
              </Link>
            </Stack.Item>
          </Stack>
        }
      >
        <Stack spacing="tight" vertical>
          <Link url={`/customers/${trader.id}`}>{trader.username}</Link>
          <TextStyle>{trader.trades_count} trades</TextStyle>
        </Stack>
      </Card.Section>
      <Card.Section title="Contact Information">
        <Stack spacing="tight" vertical>
          <TextStyle>{trader.email || 'No email provided'}</TextStyle>
        </Stack>
      </Card.Section>
    </>
  );
}
