import React, { useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import qs from 'qs';
import { pickBy } from 'lodash';
import { capitalize } from 'utils/strings';

import {
  Page,
  ResourceList,
  Card,
  Filters,
  ChoiceList,
  Layout,
} from '@shopify/polaris';
import CustomerItem from './components/CustomerItem';
import Pagination from 'components/Pagination';

import { fetchVerifications } from 'redux/verifications/actions';
import {
  getVericationsFetching,
  getVerifications,
  getVerificationsMeta,
} from 'redux/verifications/selectors';

function Verifications({
  fetchVerifications,
  fetching,
  verifications,
  meta,
  location,
  history,
}) {
  const [searchQuery, updateSearchQuery] = useState('');
  const [status, updateStatus] = useState([]);
  const [level, updateLevel] = useState([]);
  const [appliedFilters, updateAppliedFilters] = useState([]);

  const updatePageHistory = useCallback(
    (key, value) => {
      const pathname = location.pathname;
      const searchParams = new URLSearchParams(location.search);
      if (key !== 'page') {
        searchParams.set('page', 1);
      }
      searchParams.set(key, value);
      history.push({
        pathname: pathname,
        search: qs.stringify(pickBy(qs.parse(searchParams.toString()))),
      });
    },
    [history, location.pathname, location.search],
  );

  const disambiguateLabel = (key, value) => {
    return value.map((val) => `${capitalize(key)} ${val}`).join(', ');
  };

  function handlePreviousPage() {
    updatePageHistory('page', meta.page - 1);
  }

  function handleNextPage() {
    updatePageHistory('page', meta.page + 1);
  }

  function handleClearAllFilters() {
    updateSearchQuery('');
    updateLevel([]);
    updateStatus([]);
  }

  const resourceName = {
    singular: 'user',
    plural: 'users',
  };

  const filters = [
    {
      key: 'status',
      label: 'Status',
      filter: (
        <ChoiceList
          title={'Status'}
          titleHidden
          choices={[
            { label: 'Accepted', value: 'accepted' },
            { label: 'Pending', value: 'pending' },
            { label: 'Rejected', value: 'rejected' },
          ]}
          selected={status || []}
          onChange={(value) => updateStatus(value)}
        />
      ),
      shortcut: true,
    },
    {
      key: 'level',
      label: 'Level',
      filter: (
        <ChoiceList
          title={'Level'}
          titleHidden
          choices={[
            { label: 'Level 0', value: '0' },
            { label: 'Level 1', value: '1' },
            { label: 'Level 2', value: '2' },
            { label: 'Level 3', value: '3' },
          ]}
          selected={level || []}
          onChange={(value) => updateLevel(value)}
        />
      ),
      shortcut: true,
    },
  ];

  useEffect(() => {
    fetchVerifications(location.search);
  }, [fetchVerifications, location.search]);

  useEffect(
    useCallback(() => {
      updatePageHistory('q', searchQuery);
    }, [searchQuery, updatePageHistory]),
    [searchQuery],
  );

  useEffect(
    useCallback(() => {
      updatePageHistory('status', status);
    }, [status, updatePageHistory]),
    [status],
  );

  useEffect(
    useCallback(() => {
      updatePageHistory('level', level);
    }, [level, updatePageHistory]),
    [level],
  );

  useEffect(() => {
    let newAppliedFiters = [];
    if (level.length > 0) {
      newAppliedFiters = [
        ...newAppliedFiters,
        {
          key: 'level',
          label: disambiguateLabel('level', level),
          onRemove: () => updateLevel([]),
        },
      ];
    }
    if (status.length > 0) {
      newAppliedFiters = [
        ...newAppliedFiters,
        {
          key: 'status',
          label: disambiguateLabel('status', status),
          onRemove: () => updateStatus([]),
        },
      ];
    }
    updateAppliedFilters(newAppliedFiters);
  }, [level, status]);

  return (
    <Page title="Verifications">
      <Layout>
        <Layout.Section>
          <Card>
            <ResourceList
              loading={fetching}
              resourceName={resourceName}
              items={verifications}
              renderItem={CustomerItem}
              filterControl={
                <Filters
                  queryValue={searchQuery}
                  filters={filters}
                  appliedFilters={appliedFilters}
                  onQueryChange={(value) => updateSearchQuery(value)}
                  onQueryClear={() => updateSearchQuery('')}
                  onClearAll={handleClearAllFilters}
                />
              }
              showHeader
              totalItemsCount={meta.total_entries_size}
            />
            <Pagination
              resource={verifications}
              meta={meta}
              handlePreviousPage={handlePreviousPage}
              handleNextPage={handleNextPage}
            />
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
const mapStateToProps = (state) => {
  return {
    fetching: getVericationsFetching(state),
    verifications: getVerifications(state),
    meta: getVerificationsMeta(state),
  };
};

const mapDispatchToProps = {
  fetchVerifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(Verifications);
