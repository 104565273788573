import React from 'react';
import { useSelector } from 'react-redux';
import {
  Card,
  ResourceList,
  TextStyle,
  Stack,
  TextContainer,
  Button,
  Heading,
} from '@shopify/polaris';
import WalletsHeader from './WalletsHeader';
import WalletItem from './WalletItem';

export default function CustomerWallets({
  wallets,
  walletsFetching,
  toggleEditBalanceModal,
  toggleAddWalletModal,
  setSelectedWalletCurrency,
}) {
  const hasFullAccess = useSelector(
    (state) => state.profile?.data?.full_access,
  );

  return (
    <Card
      title={
        <Stack distribution="equalSpacing">
          <Heading>Wallets</Heading>
          <Button onClick={toggleAddWalletModal}>Add Wallet</Button>
        </Stack>
      }
    >
      <WalletsHeader />
      {wallets && wallets.length > 0 ? (
        <ResourceList
          loading={walletsFetching}
          resourceName={{ singular: 'wallet', plural: 'wallets' }}
          items={wallets}
          renderItem={(wallet) => (
            <WalletItem
              {...{
                wallet,
                hasFullAccess,
                toggleEditBalanceModal,
                setSelectedWalletCurrency,
              }}
            />
          )}
        />
      ) : (
        <Stack distribution="center">
          <TextContainer>
            <TextStyle> This customer has no wallets </TextStyle>
          </TextContainer>
        </Stack>
      )}
    </Card>
  );
}
