import { SHOW_TOAST, DISMISS_TOAST } from './actionTypes';

const INITIAL_STATE = {
  toastVisible: false,
  msg: '',
  variant: 'default',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOW_TOAST:
      return {
        ...state,
        msg: action.msg,
        toastVisible: true,
        variant: action.variant,
      };

    case DISMISS_TOAST:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};
