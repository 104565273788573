import React from 'react';
import { TextStyle, Stack, Card, Link } from '@shopify/polaris';
import { formatNumberToMoney } from 'utils/money';

export default function OrderTransferDetails({ transfer, app }) {
  return (
    <Card.Section title="Payment details">
      <Stack vertical spacing="loose">
        <Stack vertical spacing="tight">
          {transfer.bank_name && (
            <TextStyle variation="subdued">
              {transfer.bank_name.toUpperCase()}
            </TextStyle>
          )}
          {transfer.account_name && (
            <TextStyle variation="subdued">
              {transfer.account_name.toUpperCase()}
            </TextStyle>
          )}
          {transfer.account_number && (
            <TextStyle variation="subdued">{transfer.account_number}</TextStyle>
          )}
        </Stack>
        <TextStyle variation="strong">
          {formatNumberToMoney(transfer.amount, {
            category: transfer.currency,
            currencyCode: transfer.currency,
            maximumFractionDigits: 10,
          })}
        </TextStyle>
        <Link url={`/payouts/${app}/${transfer.id}`}>View Transaction</Link>
      </Stack>
    </Card.Section>
  );
}
