/* eslint-disable react/jsx-key */
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Page,
  Card,
  Filters,
  ChoiceList,
  Select,
  Tabs,
  TextStyle,
  Stack,
  ResourceList,
  FormLayout,
} from '@shopify/polaris';
import DatePicker from 'react-datepicker';

import Pagination from 'components/Pagination';
import EmptyTable from 'components/EmptyTable';

import useQueryParams from 'hooks/useQueryParams';
import {
  fetchAdProducts,
  fetchAds,
  setMultipleAdsStatus,
} from 'redux/ads/actions';

import Countries from 'data/countriesAndStates.json';
import AdListItem from './components/AdListItem';
import AdListHeader from './components/AdListHeader';
import useDateFilter from 'hooks/useDateFilter';
import { filterPeriods, periodDatesAndLabels } from 'utils/period';
import useUpdateEffect from 'hooks/useUpdatedEffect';
import { formateDateToISOWithoutSSSZ, getStartOfDays } from 'utils/date';
import { toast } from 'utils/toast';
import {
  getValueOfQueryStringKeyFromURL,
  isInitialQuerySetWithForValue,
} from 'utils/helpers';

function Adverts({ location }) {
  const dispatch = useDispatch();

  const { data, fetching, meta, editing, products } = useSelector(
    (state) => state.ads,
  );

  const [selectedItems, setSelectedItems] = useState([]);

  const intialQueryParams = useMemo(() => {
    const queryParamsHasForSet = isInitialQuerySetWithForValue();

    return {
      side: '',
      status: 'active',
      asset: '',
      country: '',
      page: 1,
      order: 'date_created_asc',
      q: '',
      period: 'range',
      from: queryParamsHasForSet
        ? ''
        : formateDateToISOWithoutSSSZ(getStartOfDays(90)),
      to: queryParamsHasForSet ? '' : formateDateToISOWithoutSSSZ(new Date()),
    };
  }, []);

  const { queryParams, updateQueryParams, clearAllFilters } =
    useQueryParams(intialQueryParams);

  const promotedBulkActions = [
    {
      content: 'Deactivate Ads',
      onAction: () => {
        dispatch(setMultipleAdsStatus(selectedItems, 'inactive'));
      },
      disabled: editing,
    },
  ];

  const tabs = [
    {
      id: 'active',
      content: 'Active',
    },
    {
      id: 'inactive',
      content: 'Inactive',
    },
    {
      id: 'cancelled',
      content: 'Cancelled',
    },
  ];

  const [selectedTabIndex, setSelectedTabIndex] = React.useState(() => {
    const initialStatus = getValueOfQueryStringKeyFromURL('status') || '';
    const indexOfInitialStatus = tabs.findIndex(
      ({ id }) => id === initialStatus,
    );
    return indexOfInitialStatus !== -1 ? indexOfInitialStatus : 0;
  });
  const {
    handleChangeInSelectedPeriod,
    selectedPeriod,
    selectedDates,
    setSelectedDates,
  } = useDateFilter();

  function handlePreviousPage() {
    updateQueryParams('page', meta.page - 1);
  }

  function handleNextPage() {
    updateQueryParams('page', meta.page + 1);
  }

  /**
   *
   * @param {Array<string>} items
   */
  function handleChangeInSelectedItems(items) {
    if (items.length <= 10) {
      return setSelectedItems(items);
    }
    return toast('Cannot select more than 10 adverts', 'error');
  }

  const filters = [
    {
      key: 'side',
      label: 'Side',
      filter: (
        <ChoiceList
          title="Side"
          titleHidden
          choices={[
            { label: 'Buy', value: 'buy' },
            { label: 'Sell', value: 'sell' },
          ]}
          selected={queryParams?.side}
          onChange={(value) => updateQueryParams('side', value)}
        />
      ),
      shortcut: true,
    },
    {
      key: 'asset',
      label: 'Asset',
      filter: (
        <ChoiceList
          title="Asset"
          titleHidden
          choices={products.map((product) => ({
            label: `${product.name}`,
            value: `${product.code}`,
          }))}
          selected={queryParams?.asset}
          onChange={(value) => updateQueryParams('asset', value)}
        />
      ),
      shortcut: true,
    },
    {
      key: 'country',
      label: 'Country',
      filter: (
        <Select
          options={Countries.map((country) => ({
            label: country.name,
            value: country.iso2,
          }))}
          value={queryParams?.country}
          onChange={(value) => updateQueryParams('country', value)}
        />
      ),
      shortcut: true,
    },
    {
      key: 'date',
      label: 'Date',
      shortcut: true,
      filter: (
        <FormLayout>
          <Select
            options={filterPeriods}
            label="Date range"
            onChange={handleChangeInSelectedPeriod}
            value={selectedPeriod}
          />
          {selectedPeriod === 'custom-range' && (
            <FormLayout.Group>
              <div>
                <label htmlFor="date_of_birth">Starting</label>
                <DatePicker
                  selected={selectedDates.from}
                  onChange={(date) =>
                    setSelectedDates((dates) => ({ ...dates, from: date }))
                  }
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  dateFormat="dd-MM-yyyy"
                />
              </div>
              <div>
                <label htmlFor="date_of_birth">Ending</label>
                <DatePicker
                  selected={selectedDates.to}
                  onChange={(date) =>
                    setSelectedDates((dates) => ({ ...dates, to: date }))
                  }
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  dateFormat="dd-MM-yyyy"
                />
              </div>
            </FormLayout.Group>
          )}
        </FormLayout>
      ),
    },
  ];

  const orderOptions = [
    {
      label: 'Newest',
      value: 'date_created_asc',
    },
    {
      label: 'Oldest',
      value: 'date_created_desc',
    },
  ];

  const appliedFilters = [];

  if (queryParams.side) {
    appliedFilters.push({
      key: 'side',
      label: `Side ${queryParams.side}`,
      onRemove: () => updateQueryParams('side', ''),
    });
  }
  if (queryParams.asset) {
    appliedFilters.push({
      key: 'asset',
      label: `Asset ${queryParams.asset}`,
      onRemove: () => updateQueryParams('asset', ''),
    });
  }
  if (queryParams.country) {
    appliedFilters.push({
      key: 'country',
      label: `Country ${queryParams.country}`,
      onRemove: () => updateQueryParams('country', ''),
    });
  }

  React.useEffect(() => {
    if (location.search) {
      dispatch(fetchAds(location.search));
    }
  }, [location.search, dispatch]);

  React.useEffect(() => {
    dispatch(fetchAdProducts());
  }, [dispatch]);

  React.useEffect(() => {
    setSelectedItems([]);
  }, [selectedTabIndex]);

  useUpdateEffect(() => {
    if (selectedDates.to !== selectedDates.from) {
      updateQueryParams({
        from: formateDateToISOWithoutSSSZ(selectedDates?.from),
        to: formateDateToISOWithoutSSSZ(selectedDates?.to),
        period: 'range',
        for: '',
      });
    }
  }, [selectedDates]);

  useUpdateEffect(() => {
    switch (selectedPeriod) {
      case 'today':
      case 'yesterday':
        updateQueryParams({
          from: '',
          to: '',
          period: 'day',
          for: formateDateToISOWithoutSSSZ(
            periodDatesAndLabels[selectedPeriod]?.getStartAndEndDates().from,
          ),
        });
        break;
      case 'last-7-days':
      case 'last-30-days':
        updateQueryParams({
          period: 'range',
          for: '',
        });

        break;

      default:
        break;
    }
  }, [selectedPeriod]);

  return (
    <Page title="Adverts" fullWidth>
      <Card>
        <Tabs
          tabs={tabs}
          selected={selectedTabIndex}
          onSelect={(index) => {
            setSelectedTabIndex(index);
            updateQueryParams('status', tabs[index].id);
          }}
        />
        <Card.Section>
          <Filters
            queryValue={queryParams.q}
            filters={filters}
            appliedFilters={appliedFilters}
            onQueryChange={(value) => updateQueryParams('q', value)}
            onQueryClear={() => updateQueryParams('q', '')}
            onClearAll={clearAllFilters}
          />
        </Card.Section>
        <Card.Section>
          <Stack alignment="center" distribution="equalSpacing">
            <TextStyle>
              {fetching
                ? 'Loading adverts'
                : `Showing ${meta?.current_entries_size || ''} adverts(s)`}
            </TextStyle>
            <Select
              label="Sort by"
              labelInline
              value={queryParams.order}
              onChange={(value) => updateQueryParams('order', value)}
              options={orderOptions}
            />
          </Stack>
        </Card.Section>
        <React.Fragment>
          <AdListHeader />
          <ResourceList
            loading={fetching}
            items={Object.values(data)}
            renderItem={(item) => <AdListItem {...item} />}
            promotedBulkActions={promotedBulkActions}
            selectedItems={selectedItems}
            onSelectionChange={handleChangeInSelectedItems}
            selectable
            emptyState={<EmptyTable resourceName="Ad" />}
          />
        </React.Fragment>
        <Pagination
          resource={Object.values(data)}
          meta={meta}
          handlePreviousPage={handlePreviousPage}
          handleNextPage={handleNextPage}
        />
      </Card>
    </Page>
  );
}

export default Adverts;
