import { mapKeys } from 'lodash';
import {
  FETCH_CUSTOMERS,
  FETCH_CUSTOMER,
  CLEAR_MESSAGE,
  CUSTOMER_ACTION,
  FETCH_TRANSACTIONS,
  EDIT_TRANSACTION_STATUS,
  FETCH_WALLETS,
  EDIT_CUSTOMER_WALLET_BALANCE,
  FETCH_CUSTOMER_ACCOUNT_STATEMENT,
  FETCH_CUSTOMER_ACCOUNT_STATEMENT_SUMMARY,
  CLEAR_CUSTOMER_ACCOUNT_STATEMENT,
  EDIT_CUSTOMER_PHONE,
  EDIT_CUSTOMER_PROFILE,
  EDIT_CUSTOMER_ADDRESS,
  UPDATE_CUSTOMER_LEVEL,
  TOGGLE_CUSTOMER_FEATURE,
  FETCH_CUSTOMER_DOCUMENTS,
  FETCH_CUSTOMER_BANKS,
  FETCH_CUSTOMER_CARDS,
  FETCH_CUSTOMER_CRYPTO_ADDRESSES,
  RESOLVE_CUSTOMER_BVN,
  UPDATE_CUSTOMER_BVN,
  EDIT_CUSTOMER_BVN,
  FETCH_CUSTOMER_ACTIVITIES,
  FETCH_CUSTOMER_DEVICES,
  FETCH_CUSTOMER_MOBILE_APPLICATIONS,
  FETCH_CUSTOMER_WEB_SESSIONS,
  REMOVE_CUSTOMER_DEVICE,
  REMOVE_CUSTOMER_MOBILE_APPLICATION,
  SIGN_OUT_CUSTOMER_WEB_SESSION,
  FETCH_WHITELISTED_NAMES,
  DELETE_WHITELISTED_NAME,
  FETCH_CUSTOMER_RECURRING_ORDERS,
  DELETE_CUSTOMER_RECURRING_ORDER,
  REMOVE_CUSTOMER_BVN,
  FETCH_CUSTOMER_INACTIVE_WALLETS,
  ACTIVATE_CUSTOMER_INACTIVE_WALLET,
} from './actionTypes';
import parseError from 'utils/parseError';

const INITIAL_STATE = {
  data: {},
  activities: {},
  devices: {},
  sessions: {},
  whitelistedNames: {},
  applications: {},
  documents: [],
  cards: [],
  banks: [],
  cryptoAddresses: [],
  transactions: {},
  wallets: {},
  recurringOrders: {},
  inactiveWallets: {},
  bvnDetails: null,
  bvnDetailsError: null,
  meta: {},

  accountStatement: {
    data: [],
    summary: {},
    meta: {},
    fetching: {},
    error: null,
  },

  fetching: false,
  loading: false,
  transactionsFetching: false,
  walletsFetching: false,
  activitiesFetching: false,
  devicesFetching: false,
  sessionsFetching: false,
  applicationsFetching: false,
  whitelistedNamesFetching: false,
  documentsFetching: false,
  banksFetching: false,
  cardsFetching: false,
  cryptoAddressesFetching: false,
  bvnDetailsFetching: false,
  bvnDetailsDeleting: false,
  recurringOrdersFetching: false,
  inactiveWalletsFetching: false,
  editing: false,

  error: null,
  message: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CUSTOMERS.pending:
      return {
        ...state,
        fetching: true,
      };
    case FETCH_CUSTOMERS.rejected:
      return {
        ...state,
        fetching: false,
      };
    case FETCH_CUSTOMERS.fulfilled:
      return {
        ...state,
        fetching: false,
        data: mapKeys(action.payload.data.data, 'id'),
        meta: action.payload.data.meta,
      };

    case FETCH_CUSTOMER.pending:
      return {
        ...state,
        fetching: true,
      };
    case FETCH_CUSTOMER.rejected:
      return {
        ...state,
        fetching: false,
      };
    case FETCH_CUSTOMER.fulfilled:
      return {
        ...state,
        fetching: false,
        data: { ...state.data, [action.payload.data.id]: action.payload.data },
      };

    case FETCH_TRANSACTIONS.pending:
      return {
        ...state,
        transactions: {},
        transactionsFetching: true,
      };
    case EDIT_TRANSACTION_STATUS.pending:
      return {
        ...state,
        transactionsFetching: true,
      };
    case FETCH_TRANSACTIONS.rejected:
    case EDIT_TRANSACTION_STATUS.rejected:
      return {
        ...state,
        transactionsFetching: false,
      };
    case FETCH_TRANSACTIONS.fulfilled:
      return {
        ...state,
        transactionsFetching: false,
        transactions: action.payload.data,
      };
    case EDIT_TRANSACTION_STATUS.fulfilled:
      return {
        ...state,
        transactionsFetching: false,
      };

    case FETCH_WALLETS.pending:
      return {
        ...state,
        walletsFetching: true,
      };
    case FETCH_WALLETS.rejected:
      return {
        ...state,
        walletsFetching: false,
      };
    case FETCH_WALLETS.fulfilled:
      return {
        ...state,
        walletsFetching: false,
        wallets: action.payload.data,
      };

    case FETCH_CUSTOMER_ACCOUNT_STATEMENT.pending:
      return {
        ...state,
        accountStatement: {
          ...state.accountStatement,
          fetching: true,
        },
      };
    case FETCH_CUSTOMER_ACCOUNT_STATEMENT.rejected:
      return {
        ...state,
        accountStatement: {
          ...state.accountStatement,
          fetching: false,
          error: parseError(action.payload),
        },
      };
    case FETCH_CUSTOMER_ACCOUNT_STATEMENT.fulfilled:
      return {
        ...state,
        accountStatement: {
          ...state.accountStatement,
          fetching: false,
          data: action.payload.data.data,
          meta: action.payload.data.paginator,
        },
      };

    case FETCH_CUSTOMER_ACCOUNT_STATEMENT_SUMMARY.pending:
      return {
        ...state,
        accountStatement: {
          ...state.accountStatement,
          fetching: true,
        },
      };
    case FETCH_CUSTOMER_ACCOUNT_STATEMENT_SUMMARY.rejected:
      return {
        ...state,
        accountStatement: {
          ...state.accountStatement,
          fetching: false,
          error: parseError(action.payload),
        },
      };
    case FETCH_CUSTOMER_ACCOUNT_STATEMENT_SUMMARY.fulfilled:
      return {
        ...state,
        accountStatement: {
          ...state.accountStatement,
          fetching: false,
          summary: action.payload.data,
        },
      };

    case CLEAR_CUSTOMER_ACCOUNT_STATEMENT.fulfilled:
      return {
        ...state,
        accountStatement: INITIAL_STATE.accountStatement,
      };

    case CUSTOMER_ACTION.pending:
      return {
        ...state,
        editing: true,
      };
    case CUSTOMER_ACTION.rejected:
      return {
        ...state,
        editing: false,
      };
    case CUSTOMER_ACTION.fulfilled:
      return {
        ...state,
        editing: false,
        message: action.payload.data.message,
      };

    case CLEAR_MESSAGE.fulfilled:
      return {
        ...state,
        message: null,
        error: null,
      };

    case EDIT_CUSTOMER_WALLET_BALANCE.pending:
      return {
        ...state,
        editing: true,
      };
    case EDIT_CUSTOMER_WALLET_BALANCE.rejected:
      return {
        ...state,
        editing: false,
      };
    case EDIT_CUSTOMER_WALLET_BALANCE.fulfilled:
      return {
        ...state,
        editing: false,
      };

    case FETCH_CUSTOMER_ACTIVITIES.pending:
      return {
        ...state,
        activitiesFetching: true,
      };
    case FETCH_CUSTOMER_ACTIVITIES.rejected:
      return {
        ...state,
        activitiesFetching: false,
      };
    case FETCH_CUSTOMER_ACTIVITIES.fulfilled: {
      const activities = { ...state.activities };
      if (action.payload.data.data && action.payload.data.data.length > 0) {
        activities[action.payload.data.data[0].user_id] = {
          data: action.payload.data.data,
          meta: action.payload.data.paginator,
        };
      }
      return {
        ...state,
        activitiesFetching: false,
        activities,
      };
    }

    case FETCH_CUSTOMER_DEVICES.pending:
      return {
        ...state,
        devicesFetching: true,
      };
    case FETCH_CUSTOMER_DEVICES.rejected:
      return {
        ...state,
        devicesFetching: false,
      };
    case FETCH_CUSTOMER_DEVICES.fulfilled: {
      const devices = { ...state.devices };
      if (action.payload.data.data && action.payload.data.data.length > 0) {
        devices[action.payload.data.data[0].user_id] = {
          data: action.payload.data.data,
          meta: action.payload.data.paginator,
        };
      }
      return {
        ...state,
        devicesFetching: false,
        devices,
      };
    }

    case FETCH_CUSTOMER_WEB_SESSIONS.pending:
      return {
        ...state,
        sessionsFetching: true,
      };
    case FETCH_CUSTOMER_WEB_SESSIONS.rejected:
      return {
        ...state,
        sessionsFetching: false,
      };
    case FETCH_CUSTOMER_WEB_SESSIONS.fulfilled: {
      const sessions = { ...state.sessions };
      if (action.payload.data.data && action.payload.data.data.length > 0) {
        sessions[action.payload.data.data[0].user_id] = {
          data: action.payload.data.data,
          meta: action.payload.data.paginator,
        };
      }
      return {
        ...state,
        sessionsFetching: false,
        sessions,
      };
    }

    case FETCH_CUSTOMER_MOBILE_APPLICATIONS.pending:
      return {
        ...state,
        applications: {},
        applicationsFetching: true,
      };
    case FETCH_CUSTOMER_MOBILE_APPLICATIONS.rejected:
      return {
        ...state,
        applications: {},
        applicationsFetching: false,
      };

    case FETCH_CUSTOMER_MOBILE_APPLICATIONS.fulfilled: {
      return {
        ...state,
        applicationsFetching: false,
        applications: {
          data: action.payload.data.data,
          meta: action.payload.data.paginator,
        },
      };
    }

    case FETCH_WHITELISTED_NAMES.pending:
      return {
        ...state,
        whitelistedNamesFetching: true,
      };
    case FETCH_WHITELISTED_NAMES.rejected:
      return {
        ...state,
        whitelistedNamesFetching: false,
      };
    case FETCH_WHITELISTED_NAMES.fulfilled: {
      return {
        ...state,
        whitelistedNamesFetching: false,
        whitelistedNames: {
          ...state.whitelistedNames,
          [action.meta.customerId]: {
            data: action.payload.data.data,
            meta: action.payload.data.meta,
          },
        },
      };
    }

    case FETCH_CUSTOMER_DOCUMENTS.pending:
      return {
        ...state,
        documentsFetching: true,
        documents: [],
      };
    case FETCH_CUSTOMER_DOCUMENTS.rejected:
      return {
        ...state,
        documentsFetching: false,
        documents: [],
      };
    case FETCH_CUSTOMER_DOCUMENTS.fulfilled:
      return {
        ...state,
        documentsFetching: false,
        documents: action.payload.data,
      };

    case FETCH_CUSTOMER_CARDS.pending:
      return {
        ...state,
        cardsFetching: true,
        cards: [],
      };
    case FETCH_CUSTOMER_CARDS.rejected:
      return {
        ...state,
        cardsFetching: false,
        cards: [],
      };
    case FETCH_CUSTOMER_CARDS.fulfilled:
      return {
        ...state,
        cardsFetching: false,
        cards: action.payload.data,
      };

    case FETCH_CUSTOMER_BANKS.pending:
      return {
        ...state,
        banksFetching: true,
        banks: [],
      };
    case FETCH_CUSTOMER_BANKS.rejected:
      return {
        ...state,
        banksFetching: false,
        banks: [],
      };
    case FETCH_CUSTOMER_BANKS.fulfilled:
      return {
        ...state,
        banksFetching: false,
        banks: action.payload.data,
      };

    case FETCH_CUSTOMER_CRYPTO_ADDRESSES.pending:
      return {
        ...state,
        cryptoAddressesFetching: true,
        cryptoAddresses: [],
      };
    case FETCH_CUSTOMER_CRYPTO_ADDRESSES.rejected:
      return {
        ...state,
        cryptoAddressesFetching: false,
        cryptoAddresses: [],
      };
    case FETCH_CUSTOMER_CRYPTO_ADDRESSES.fulfilled:
      return {
        ...state,
        cryptoAddressesFetching: false,
        cryptoAddresses: action.payload.data.data,
      };

    // Recurring orders
    case FETCH_CUSTOMER_RECURRING_ORDERS.pending:
      return {
        ...state,
        recurringOrdersFetching: true,
      };
    case FETCH_CUSTOMER_RECURRING_ORDERS.rejected:
      return {
        ...state,
        recurringOrdersFetching: false,
      };
    case FETCH_CUSTOMER_RECURRING_ORDERS.fulfilled: {
      return {
        ...state,
        recurringOrdersFetching: false,
        recurringOrders: {
          ...state.recurringOrders,
          [action.meta.customerId]: {
            ...mapKeys(action.payload.data.recurring_orders, 'id'),
          },
        },
      };
    }

    case DELETE_CUSTOMER_RECURRING_ORDER.fulfilled: {
      const {
        [action.state.orderId]: deletedOrder,
        ...remainingCustomerRecurringOrders
      } = state.recurringOrders[action.meta.customerId];

      return {
        ...state,
        editing: false,
        recurringOrders: {
          ...state.recurringOrders,
          [action.meta.customerId]: remainingCustomerRecurringOrders,
        },
      };
    }

    // Inactive wallets
    case FETCH_CUSTOMER_INACTIVE_WALLETS.pending:
      return {
        ...state,
        inactiveWalletsFetching: true,
      };
    case FETCH_CUSTOMER_INACTIVE_WALLETS.rejected:
      return {
        ...state,
        inactiveWalletsFetching: false,
      };
    case FETCH_CUSTOMER_INACTIVE_WALLETS.fulfilled: {
      return {
        ...state,
        inactiveWalletsFetching: false,
        inactiveWallets: {
          ...state.inactiveWallets,
          [action.meta.customerId]: action.payload.data,
        },
      };
    }

    // Inactive wallets
    case ACTIVATE_CUSTOMER_INACTIVE_WALLET.fulfilled: {
      const {
        [action.meta.customerId]: customersWallets,
      } = state.inactiveWallets;

      const remainingInactiveWallets = customersWallets?.filter(
        (wallet) => !action.meta?.currencies?.includes(wallet?.code),
      );

      return {
        ...state,
        editing: false,
        inactiveWallets: {
          ...state.inactiveWallets,
          [action.meta.customerId]: remainingInactiveWallets,
        },
      };
    }

    case EDIT_CUSTOMER_PHONE.pending:
    case UPDATE_CUSTOMER_LEVEL.pending:
    case EDIT_CUSTOMER_PROFILE.pending:
    case EDIT_CUSTOMER_ADDRESS.pending:
    case TOGGLE_CUSTOMER_FEATURE.pending:
    case REMOVE_CUSTOMER_DEVICE.pending:
    case REMOVE_CUSTOMER_MOBILE_APPLICATION.pending:
    case SIGN_OUT_CUSTOMER_WEB_SESSION.pending:
    case UPDATE_CUSTOMER_BVN.pending:
    case EDIT_CUSTOMER_BVN.pending:
    case DELETE_WHITELISTED_NAME.pending:
    case DELETE_CUSTOMER_RECURRING_ORDER.pending:
    case ACTIVATE_CUSTOMER_INACTIVE_WALLET.pending:
      return {
        ...state,
        editing: true,
      };
    case EDIT_CUSTOMER_PHONE.rejected:
    case UPDATE_CUSTOMER_LEVEL.rejected:
    case EDIT_CUSTOMER_PROFILE.rejected:
    case EDIT_CUSTOMER_ADDRESS.rejected:
    case TOGGLE_CUSTOMER_FEATURE.rejected:
    case REMOVE_CUSTOMER_DEVICE.rejected:
    case REMOVE_CUSTOMER_MOBILE_APPLICATION.rejected:
    case SIGN_OUT_CUSTOMER_WEB_SESSION.rejected:
    case UPDATE_CUSTOMER_BVN.rejected:
    case EDIT_CUSTOMER_BVN.rejected:
    case DELETE_WHITELISTED_NAME.rejected:
    case DELETE_CUSTOMER_RECURRING_ORDER.rejected:
    case ACTIVATE_CUSTOMER_INACTIVE_WALLET.rejected:
      return {
        ...state,
        editing: false,
      };
    case EDIT_CUSTOMER_PHONE.fulfilled:
    case UPDATE_CUSTOMER_LEVEL.fulfilled:
    case EDIT_CUSTOMER_PROFILE.fulfilled:
    case EDIT_CUSTOMER_ADDRESS.fulfilled:
    case TOGGLE_CUSTOMER_FEATURE.fulfilled:
    case REMOVE_CUSTOMER_DEVICE.fulfilled:
    case REMOVE_CUSTOMER_MOBILE_APPLICATION.fulfilled:
    case SIGN_OUT_CUSTOMER_WEB_SESSION.fulfilled:
    case UPDATE_CUSTOMER_BVN.fulfilled:
    case EDIT_CUSTOMER_BVN.fulfilled:
    case DELETE_WHITELISTED_NAME.fulfilled:
      return {
        ...state,
        editing: false,
      };

    case REMOVE_CUSTOMER_BVN.pending:
      return {
        ...state,
        bvnDetailsDeleting: true,
      };

    case REMOVE_CUSTOMER_BVN.rejected:
      return {
        ...state,
        bvnDetailsDeleting: false,
      };

    case REMOVE_CUSTOMER_BVN.fulfilled:
      return {
        ...state,
        bvnDetailsDeleting: false,
        bvnDetails: null,
      };

    case RESOLVE_CUSTOMER_BVN.pending:
      return {
        ...state,
        bvnDetailsFetching: true,
        bvnDetailsError: null,
        bvnDetails: null,
      };
    case RESOLVE_CUSTOMER_BVN.rejected:
      return {
        ...state,
        bvnDetailsFetching: false,
        bvnDetailsError: action.payload,
      };
    case RESOLVE_CUSTOMER_BVN.fulfilled:
      return {
        ...state,
        bvnDetailsFetching: false,
        bvnDetails: action.payload.data,
      };
    default:
      return state;
  }
};
