import { getStartOfDays } from './date';

export const filterPeriods = [
  {
    label: 'Today',
    value: 'today',
  },
  {
    label: 'Yesterday',
    value: 'yesterday',
  },
  {
    label: 'Last 7 days',
    value: 'last-7-days',
  },
  {
    label: 'Last 30 days',
    value: 'last-30-days',
  },
  {
    label: 'Last 90 days',
    value: 'last-90-days',
  },
  {
    label: 'Custom Range',
    value: 'custom-range',
  },
];

export const periodDatesAndLabels = {
  today: {
    label: 'Today',
    getStartAndEndDates: () => ({ from: new Date(), to: new Date() }),
  },
  yesterday: {
    label: 'Yesterday',
    getStartAndEndDates: () => ({
      from: getStartOfDays(1),
      to: getStartOfDays(1),
    }),
  },
  'last-7-days': {
    label: 'Last 7 days',
    getStartAndEndDates: () => ({
      from: getStartOfDays(7),
      to: new Date(),
    }),
  },
  'last-30-days': {
    label: 'Last 30 days',
    getStartAndEndDates: () => ({
      from: getStartOfDays(30),
      to: new Date(),
    }),
  },
  'last-90-days': {
    label: 'Last 90 days',
    getStartAndEndDates: () => ({
      from: getStartOfDays(90),
      to: new Date(),
    }),
  },
};
