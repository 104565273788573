import React from 'react';
import { Card, TextContainer, TextStyle } from '@shopify/polaris';
import { capitalize } from 'utils/strings';

function DepositProvider({ provider }) {
  return (
    <Card.Section title="Provider" sectioned>
      <TextContainer>
        {provider ? (
          <p>{capitalize(provider)}</p>
        ) : (
          <TextStyle variation="subdued">No provider</TextStyle>
        )}
      </TextContainer>
    </Card.Section>
  );
}

export default DepositProvider;
