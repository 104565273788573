import React from 'react';
import ellipsize from 'ellipsize';
import { ResourceList, TextStyle, Avatar } from '@shopify/polaris';
import ResourceItemContainer from 'containers/ResourceItemContainer';

import { getInitials } from 'utils/strings';
import { renderStatusBadge } from 'utils/formatter';
import { formatISODateToDayMonthYear } from 'utils/date';

export default function CustomerItem(item) {
  const { _id, reference, full_name, status, created_at } = item;

  const date = formatISODateToDayMonthYear(new Date(created_at));
  const media = (
    <Avatar size="medium" name={full_name} initials={getInitials(full_name)} />
  );

  return (
    <ResourceList.Item
      id={_id}
      url={`/customers/verifications/${_id}`}
      media={media}
    >
      <ResourceItemContainer>
        <TextStyle variation="strong">
          {ellipsize(full_name, 30, {
            chars: [' '],
          })}
        </TextStyle>
        <TextStyle variation="code">{reference}</TextStyle>
        {renderStatusBadge(status.toLowerCase())}
        <TextStyle>{date}</TextStyle>
      </ResourceItemContainer>
    </ResourceList.Item>
  );
}
