/* eslint-disable react/jsx-key */
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useQueryParams from 'hooks/useQueryParams';
import {
  Page,
  Card,
  Filters,
  ChoiceList,
  DataTable,
  Link,
  TextStyle,
  Select,
  Stack,
  Badge,
} from '@shopify/polaris';
import {
  fetchDocuments,
  fetchCountries,
  fetchDocumentTypes,
} from 'redux/documents/actions';
import LoadingSpinner from 'components/LoadingSpinner';
import Pagination from 'components/Pagination';
import EmptyTable from 'components/EmptyTable';
import { formatDateToReadableString } from 'utils/date';
import { debounce } from 'lodash';
import StatusBadge from 'components/StatusBadge';

const intialQueryParams = {
  country: '',
  type: '',
  status: '',
  level: '',
  page: 1,
  order: 'date_created_desc',
  q: '',
};

function Documents() {
  const dispatch = useDispatch();
  const location = useLocation();

  const { queryParams, updateQueryParams, clearAllFilters } = useQueryParams(
    intialQueryParams,
  );

  const { data, types, countries, meta, fetching } = useSelector(
    (state) => state.documents,
  );

  const _countries = React.useMemo(
    () =>
      countries.map((country) => ({ label: country.name, value: country.id })),
    [countries],
  );

  const _types = React.useMemo(
    () => types.map((type) => ({ label: type.name, value: type.id })),
    [types],
  );

  const orderOptions = [
    {
      label: 'Newest',
      value: 'date_created_asc',
    },
    {
      label: 'Oldest',
      value: 'date_created_desc',
    },
  ];

  const statuses = [
    {
      label: 'Accepted',
      value: 'accepted',
    },
    {
      label: 'Pending',
      value: 'pending',
    },
    {
      label: 'Rejected',
      value: 'rejected',
    },
  ];

  const levels = Array.from(Array(4), (_, x) => ({
    label: `Level ${x}`,
    value: String(x),
  }));

  const documents = React.useMemo(
    () =>
      Object.values(data).map((document, index) => [
        <TextStyle variation="strong" key={index}>
          <Link url={`${location.pathname}/${document.user_id}/${document.id}`}>
            {document?.reference}
          </Link>
        </TextStyle>,
        <Link
          monochrome={true}
          url={`${location.pathname}/${document.user_id}/${document.id}`}
        >
          {formatDateToReadableString(new Date(document.created_at))}
        </Link>,
        <Link
          monochrome={true}
          url={`${location.pathname}/${document.user_id}/${document.id}`}
        >
          {document.customer_name}
        </Link>,
        <Link
          monochrome={true}
          url={`${location.pathname}/${document.user_id}/${document.id}`}
        >
          {document.country}
        </Link>,
        <Link
          monochrome={true}
          url={`${location.pathname}/${document.user_id}/${document.id}`}
        >
          {document.document_name}
        </Link>,
        <Link url={`${location.pathname}/${document.user_id}/${document.id}`}>
          <StatusBadge status={document.level} key={index} />
        </Link>,
        <Link url={`${location.pathname}/${document.user_id}/${document.id}`}>
          <StatusBadge status={document.status} key={index} />
        </Link>,
      ]),
    [data, location.pathname],
  );

  const handleNextPage = () =>
    updateQueryParams('page', meta?.page ? meta.page + 1 : 1);

  const handlePreviousPage = () =>
    updateQueryParams('page', meta?.page ? meta.page - 1 : 1);

  const filters = [
    {
      key: 'country',
      label: 'Country',
      filter: (
        <ChoiceList
          name="country"
          choices={_countries}
          selected={queryParams?.country}
          onChange={(value) => updateQueryParams('country', value)}
        />
      ),
      shortcut: true,
    },
    {
      key: 'type',
      label: 'Type',
      filter: (
        <ChoiceList
          name="type"
          choices={_types}
          selected={queryParams?.type}
          onChange={(value) => updateQueryParams('type', value)}
        />
      ),
      shortcut: true,
    },
    {
      key: 'status',
      label: 'Status',
      filter: (
        <ChoiceList
          name="status"
          choices={statuses}
          selected={queryParams?.status}
          onChange={(value) => updateQueryParams('status', value)}
        />
      ),
      shortcut: true,
    },
    {
      key: 'level',
      label: 'Level',
      filter: (
        <ChoiceList
          name="level"
          choices={levels}
          selected={queryParams?.level}
          onChange={(value) => updateQueryParams('level', value)}
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [];

  if (queryParams.country) {
    appliedFilters.push({
      key: 'country',
      label: `Country ${queryParams.country}`,
      onRemove: () => updateQueryParams('country', ''),
    });
  }
  if (queryParams.type) {
    appliedFilters.push({
      key: 'type',
      label: `Type ${queryParams.type}`,
      onRemove: () => updateQueryParams('type', ''),
    });
  }
  if (queryParams.status) {
    appliedFilters.push({
      key: 'status',
      label: `Status ${queryParams.status}`,
      onRemove: () => updateQueryParams('status', ''),
    });
  }
  if (queryParams.level) {
    appliedFilters.push({
      key: 'level',
      label: `Level ${queryParams.level}`,
      onRemove: () => updateQueryParams('level', ''),
    });
  }

  React.useEffect(
    React.useCallback(() => {
      debounce(() => dispatch(fetchDocuments(location.search)), 500)();
    }, [dispatch, location.search]),
    [location.search],
  );

  React.useEffect(
    React.useCallback(() => {
      dispatch(fetchCountries());
    }, [dispatch]),
    [],
  );

  React.useEffect(() => {
    const country = queryParams.country;
    dispatch(fetchDocumentTypes(country));
  }, [dispatch, queryParams.country]);

  return (
    <Page title="Documents">
      <Card>
        <Card.Section>
          <span className="search-help-text">
            Enter search text, then press <Badge>ENTER</Badge> or{' '}
            <Badge>RETURN</Badge> to search
          </span>
          <Filters
            queryValue={queryParams.q}
            filters={filters}
            appliedFilters={appliedFilters}
            onQueryChange={(value) => updateQueryParams('q', value)}
            onQueryClear={() => updateQueryParams('q', '')}
            onClearAll={clearAllFilters}
          />
        </Card.Section>
        <Card.Section>
          <Stack alignment="center" distribution="equalSpacing">
            <TextStyle>
              {fetching
                ? 'Loading documents'
                : `Showing ${meta?.current_entries_size || ''} documents(s)`}
            </TextStyle>
            <Select
              label="Sort by"
              labelInline
              value={queryParams.order}
              onChange={(value) => updateQueryParams('order', value)}
              options={orderOptions}
            />
          </Stack>
        </Card.Section>
        {fetching ? (
          <LoadingSpinner />
        ) : (
          <React.Fragment>
            {documents.length > 0 ? (
              <DataTable
                columnContentTypes={[
                  'text',
                  'text',
                  'text',
                  'text',
                  'text',
                  'text',
                  'text',
                ]}
                headings={[
                  'Reference',
                  'Date',
                  'Customer',
                  'Country',
                  'Type',
                  'Level',
                  'Status',
                ]}
                rows={documents}
              />
            ) : (
              <EmptyTable resourceName="documents" />
            )}
          </React.Fragment>
        )}
        <Pagination
          resource={documents}
          meta={meta}
          handleNextPage={handleNextPage}
          handlePreviousPage={handlePreviousPage}
        />
      </Card>
    </Page>
  );
}

export default Documents;
