import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from 'utils/api';
import { FETCH_PAYOUTS } from './actionTypes';

function* fetchPayoutsSaga(action) {
  try {
    const { app, query } = action.payload;
    yield put({ type: FETCH_PAYOUTS.pending });
    const res = yield call(api.get, `/v1.1/payouts/${app}${query}`);
    yield put({ type: FETCH_PAYOUTS.fulfilled, payload: res });
  } catch (error) {
    yield put({ type: FETCH_PAYOUTS.rejected, error });
  }
}

export default function* allPayoutSagas() {
  yield all([takeLatest(FETCH_PAYOUTS.default, fetchPayoutsSaga)]);
}
