import React from 'react';
import { TextStyle, Stack, Card } from '@shopify/polaris';
import { capitalize } from 'utils/strings';
import { formatNumberToMoney } from 'utils/money';

export default function OrderLiquidationDetails({
  analysis,
  side,
  base,
  counter,
}) {
  return (
    <Card title="Liquidation details">
      <Card.Section>
        <Stack vertical spacing="tight">
          <Stack distribution="equalSpacing" alignment="center" spacing="none">
            <TextStyle>{capitalize(side || '')}</TextStyle>
            <TextStyle>
              {formatNumberToMoney(analysis?.filled_base, {
                category: base,
                currencyCode: base,
                codePosition: 'back',
                maximumFractionDigits: 10,
              })}
            </TextStyle>
          </Stack>
          <Stack distribution="equalSpacing" alignment="center" spacing="none">
            <TextStyle>Fee</TextStyle>
            <TextStyle>
              {formatNumberToMoney(analysis?.fee, {
                category: base,
                currencyCode: base,
                codePosition: 'back',
                maximumFractionDigits: 10,
              })}
            </TextStyle>
          </Stack>
          <Stack distribution="equalSpacing" alignment="center" spacing="none">
            <TextStyle>Avg. Price</TextStyle>
            <TextStyle>
              {formatNumberToMoney(analysis?.avg_price, {
                category: counter,
                currencyCode: counter,
                codePosition: 'back',
                maximumFractionDigits: 10,
              })}{' '}
            </TextStyle>
          </Stack>
          <Stack distribution="equalSpacing" alignment="center" spacing="none">
            <TextStyle variation="strong">Value</TextStyle>
            <TextStyle variation="strong">
              {formatNumberToMoney(analysis?.filled_counter, {
                category: counter,
                currencyCode: counter,
                codePosition: 'back',
                maximumFractionDigits: 10,
              })}
            </TextStyle>
          </Stack>
        </Stack>
      </Card.Section>
    </Card>
  );
}
