import actionTypes from '../actionTypes';

export const FETCH_DEPOSITS = actionTypes('FETCH_DEPOSITS');
export const FETCH_DEPOSIT = actionTypes('FETCH_DEPOSIT');
export const ADD_DEPOSIT = actionTypes('ADD_DEPOSIT');
export const APPROVE_DEPOSIT = actionTypes('APPROVE_DEPOSIT');
export const FULFILL_DEPOSIT = actionTypes('FULFILL_DEPOSIT');
export const DECLINE_DEPOSIT = actionTypes('DECLINE_DEPOSIT');
export const CHECK_DEPOSIT_STATUS = actionTypes('CHECK_DEPOSIT_STATUS');
export const CLEAR_DEPOSIT_STATUS = actionTypes('CLEAR_DEPOSIT_STATUS');
export const FLAG_DEPOSIT = actionTypes('FLAG_DEPOSIT');
export const CLEAR_MESSAGE = actionTypes('CLEAR_MESSAGE');
