const getStaffsSelector = (state) => Object.values(state.staff.data);

const getAccountOwnerSelector = (state) =>
  getStaffsSelector(state).find((staff) => staff.account_owner === true);

const getStaffFetchingSelector = (state) => state.staff.fetching;

const getStaffProfileSelector = (state, staffId) => state.staff.data[staffId];

const getStaffErrorSelector = (state) => state.staff.error;

export {
  getStaffsSelector,
  getAccountOwnerSelector,
  getStaffFetchingSelector,
  getStaffProfileSelector,
  getStaffErrorSelector,
};
