import React from 'react';
import { SkeletonThumbnail, Stack } from '@shopify/polaris';

function ImageGalleryPlaceHolder(props) {
  return (
    <Stack vertical alignment="center" spacing="extraLoose">
      <div className="image-gallery-placeholder">
        <Stack spacing="none">
          <SkeletonThumbnail size="large" />
          <SkeletonThumbnail size="large" />
          <SkeletonThumbnail size="large" />
          <SkeletonThumbnail size="large" />
          <SkeletonThumbnail size="large" />
        </Stack>
        <Stack spacing="none">
          <SkeletonThumbnail size="large" />
          <SkeletonThumbnail size="large" />
          <SkeletonThumbnail size="large" />
          <SkeletonThumbnail size="large" />
          <SkeletonThumbnail size="large" />
        </Stack>
        <Stack spacing="none">
          <SkeletonThumbnail size="large" />
          <SkeletonThumbnail size="large" />
          <SkeletonThumbnail size="large" />
          <SkeletonThumbnail size="large" />
          <SkeletonThumbnail size="large" />
        </Stack>
        <Stack spacing="none">
          <SkeletonThumbnail size="large" />
          <SkeletonThumbnail size="large" />
          <SkeletonThumbnail size="large" />
          <SkeletonThumbnail size="large" />
          <SkeletonThumbnail size="large" />
        </Stack>
      </div>
    </Stack>
  );
}

export default ImageGalleryPlaceHolder;
