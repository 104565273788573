import { mapKeys } from 'lodash';
import {
  FETCH_ORDERS,
  SET_ORDERS_APP,
  FETCH_ORDER,
  CREATE_CHAIN_ORDER,
  CREATE_TRADE,
  COMPLETE_TRADES,
  DELETE_TRADE,
  UPLOAD_TRADEs,
} from './actionTypes';
import parseError from '../../utils/parseError';

const INITIAL_STATE = {
  app: 'groot',
  data: {},
  meta: {},
  actionLoading: false,
  fetching: false,
  loading: false,
  error: null,
  uploading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ORDERS_APP:
      return {
        ...state,
        app: action.payload.app,
        data: action.payload.app === state.app ? state.data : {},
      };
    case FETCH_ORDERS.pending:
      return {
        ...state,
        fetching: true,
        error: null,
      };
    case FETCH_ORDERS.rejected:
      return {
        ...state,
        fetching: false,
        error: parseError(action.payload),
      };
    case FETCH_ORDERS.fulfilled:
      return {
        ...state,
        fetching: false,
        data: mapKeys(action.payload.data.data, 'id'),
        meta: action.payload.data.meta,
        error: null,
      };
    case FETCH_ORDER.pending:
      return {
        ...state,
        fetching: true,
        error: null,
      };
    case FETCH_ORDER.rejected:
      return {
        ...state,
        fetching: false,
        error: parseError(action.payload),
      };
    case FETCH_ORDER.fulfilled:
      return {
        ...state,
        fetching: false,
        data: { ...state.data, [action.payload.data.id]: action.payload.data },
        error: null,
      };
    case CREATE_TRADE.pending:
    case DELETE_TRADE.pending:
    case CREATE_CHAIN_ORDER.pending:
    case COMPLETE_TRADES.pending:
      return {
        ...state,
        actionLoading: true,
      };
    case CREATE_TRADE.rejected:
    case DELETE_TRADE.rejected:
    case CREATE_CHAIN_ORDER.rejected:
    case COMPLETE_TRADES.rejected:
      return {
        ...state,
        actionLoading: false,
      };
    case CREATE_TRADE.fulfilled:
    case DELETE_TRADE.fulfilled:
    case CREATE_CHAIN_ORDER.fulfilled:
    case COMPLETE_TRADES.fulfilled:
      return {
        ...state,
        actionLoading: false,
      };

    case UPLOAD_TRADEs.pending:
      return {
        ...state,
        uploading: true,
      };

    case UPLOAD_TRADEs.rejected:
    case UPLOAD_TRADEs.fulfilled:
      return {
        ...state,
        uploading: false,
      };

    default:
      return state;
  }
};
