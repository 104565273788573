import api from 'utils/api';
import {
  FETCH_TRADES,
  FETCH_TRADE_DETAILS,
  FETCH_TRADES_SUMMARY,
  SET_TRADE_STATUS,
} from './actionTypes';

/**
 * Type definitions
 *
 * @typedef { import('../types').GenericDispatchAction } GenericDispatchAction
 */

/**
 *
 * @param {string} queryString
 * @returns {{ type : string; payload : { queryString : string } }}
 */

export const fetchTrades = (queryString = '') => {
  return {
    type: FETCH_TRADES.default,
    payload: { queryString },
  };
};

/**
 *
 * @param {string} id
 * @returns { GenericDispatchAction }
 */

export const fetchTradeDetails = (id) => (dispatch) => {
  const payload = api.get(`/v1/trades/${id}`);
  return dispatch({ type: FETCH_TRADE_DETAILS.default, payload });
};

/**
 *
 * @param { Array<string> } assets
 * @returns { GenericDispatchAction }
 */
export const fetchTradesSummary = (assets) => (dispatch) => {
  const payload = Promise.all(
    assets.map((asset) => api.get(`/v1/trades/${asset}/summary`)),
  );
  return dispatch({ type: FETCH_TRADES_SUMMARY.default, payload });
};

/**
 *
 * @param {string} id
 * @param { boolean } status
 * @returns { GenericDispatchAction }
 */
export const setTradeStatus = (id, status) => (dispatch) => {
  const payload = api.put(`/v1/trades/${id}/status`, { status });
  return dispatch({ type: SET_TRADE_STATUS.default, payload }).then((res) => {
    dispatch(fetchTradeDetails(id));
    return res;
  });
};
