import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Page, Layout, Card, Loading } from '@shopify/polaris';
import LoadingPage from 'components/LoadingPage';
import PayoutBreakDown from './components/PayoutBreakDown';
import PayoutCustomer from './components/PayoutCustomer';
import PayoutReceiver from './components/PayoutReceiver';
import PayoutDestination from './components/PayoutDestination';
import PayoutProcessor from './components/PayoutProcessor';
import PayoutHash from './components/PayoutHash';
import CompletePayoutModal from './components/CompletePayoutModal';
import { renderStatusBadge } from 'utils/formatter';
import { formatDateToReadableString } from 'utils/date';
import {
  fetchPayout,
  setPayoutStatus,
  retryPayout,
  setAction,
} from 'redux/payouts/actions';
import { PreviousLocationContext } from 'providers/PreviousLocationProvider';

function ViewPayout({ match, location }) {
  const dispatch = useDispatch();

  const { previousLocation } = React.useContext(PreviousLocationContext);

  const {
    data: { [match.params.id]: payout },
    action,
    fetching,
    loading,
  } = useSelector((state) => state.payouts);

  const [showCompletePayoutModal, setShowCompletePayoutModal] =
    React.useState(false);

  const [, , app, id] = location.pathname.split('/');

  const onPayoutStatusChange = (values) => {
    if (values.status === 'cancelled') {
      dispatch(setAction('cancel'));
    }
    if (values.status === 'completed') {
      dispatch(setAction('complete'));
    }
    if (values.status === 'pending') {
      dispatch(setAction('unqueue'));
    }
    dispatch(setPayoutStatus(app, id, values));
  };

  const onRetryPayout = () => {
    dispatch(retryPayout(app, id));
  };

  const toggleModal = () => {
    setShowCompletePayoutModal((s) => !s);
  };

  React.useEffect(() => {
    dispatch(fetchPayout(app, id));
  }, [app, dispatch, id]);

  if (fetching && !payout) {
    return LoadingPage();
  }

  return (
    <>
      {loading && <Loading />}
      {payout && (
        <Page
          breadcrumbs={[
            {
              content: 'Payouts',
              url: previousLocation
                ? `${previousLocation.pathname}${previousLocation.search}`
                : `/payouts/${app}`,
            },
          ]}
          title={payout?.reference}
          subtitle={
            <>
              {payout?.created_at &&
                formatDateToReadableString(new Date(payout?.created_at))}
              {renderStatusBadge(payout?.status)}{' '}
              {payout?.fulfilment_status
                ? renderStatusBadge(payout?.fulfilment_status)
                : renderStatusBadge('Internal')}
            </>
          }
        >
          <Layout>
            <Layout.Section>
              <PayoutBreakDown
                {...{
                  payout,
                  loading,
                  onPayoutStatusChange,
                  onRetryPayout,
                  toggleModal,
                  action,
                }}
              />
            </Layout.Section>
            <Layout.Section secondary>
              <Card>
                <PayoutCustomer {...payout} />
                <PayoutReceiver {...payout} />
                <PayoutDestination {...payout} />
                <PayoutProcessor {...payout} />
                <PayoutHash {...payout} />
              </Card>
            </Layout.Section>
          </Layout>
          <CompletePayoutModal
            modalOpen={showCompletePayoutModal}
            currency={payout?.currency}
            loading={loading}
            handleClose={toggleModal}
            onPayoutStatusChange={onPayoutStatusChange}
            setAction={setAction}
          />
        </Page>
      )}
    </>
  );
}

export default ViewPayout;
