import React from 'react';
import { FormLayout, TextField, Avatar, Stack } from '@shopify/polaris';

import { getInitials } from 'utils/strings';

const StaffProfileDetailsForm = ({ form, profile }) => {
  return (
    <Stack vertical>
      <Avatar initials={getInitials(profile?.first_name, profile?.last_name)} />
      <form onSubmit={form.handleSubmit}>
        <FormLayout>
          <FormLayout.Group>
            <TextField
              value={form.values.first_name}
              onChange={(value) => form.setFieldValue('first_name', value)}
              label="First Name"
              name="first_name"
              type="text"
              error={form.errors.first_name}
            />
            <TextField
              value={form.values.last_name}
              onChange={(value) => form.setFieldValue('last_name', value)}
              label="Last Name"
              name="last_name"
              type="text"
              error={form.errors.last_name}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField
              value={form.values.email}
              onChange={(value) => form.setFieldValue('email', value)}
              label="Email"
              name="email"
              type="email"
              error={form.errors.email}
            />
            <TextField
              value={form.values.phone}
              onChange={(value) => form.setFieldValue('phone', value)}
              label="Phone number"
              name="phone"
              type="tel"
              error={form.errors.phone}
            />
          </FormLayout.Group>
          <TextField
            value={form.values.bio}
            onChange={(value) => form.setFieldValue('bio', value)}
            label="Bio"
            name="bio"
            multiline={3}
            error={form.errors.bio}
          />
        </FormLayout>
      </form>
    </Stack>
  );
};

export default StaffProfileDetailsForm;
