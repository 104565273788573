import React from 'react';

import { Modal, TextContainer } from '@shopify/polaris';

function StatusModal(props) {
  const { active, status, close, setStatus } = props;

  return (
    <div>
      <Modal
        open={active}
        onClose={() => close()}
        title={status ? 'Disable account' : 'Enable account'}
        primaryAction={{
          content: status ? 'Disable account' : 'Enable account',
          onAction: () => setStatus(),
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: () => close(),
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>
              {status
                ? 'Are you sure you want to disable the account for this customer? Disabled customers will not be able to login to their account.'
                : 'Are you sure you want to enable the account for the customer?'}
            </p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </div>
  );
}

export default StatusModal;
