import React, { useState } from 'react';
import {
  Button,
  Modal,
  Stack,
  DropZone,
  Thumbnail,
  Caption,
  TextStyle,
} from '@shopify/polaris';
import { NoteMinor } from '@shopify/polaris-icons';
import { useDispatch, useSelector } from 'react-redux';
import { uploadTrades } from 'redux/orders/actions';
import { useRouteMatch } from 'react-router-dom';

const CSV_SAMPLE_LINK =
  'https://res.cloudinary.com/dwoc5fknz/raw/upload/v1617614292/csv/trades.csv';

/**
 *
 * @param { { open : boolean, onClose : Function } }param0
 * @returns
 */

const ImportTradeByCSVModal = ({ open, onClose }) => {
  const [file, setFile] = useState();
  const { uploading } = useSelector((state) => state.orders);
  const dispatch = useDispatch();
  const {
    params: { app },
  } = useRouteMatch();

  const handleDropZoneDrop = (_files, acceptedFiles, _rejectedFiles) => {
    setFile(acceptedFiles[0]);
  };

  const handleUpload = () => {
    dispatch(uploadTrades(app, file)).then(() => onClose());
  };

  const uploadedFiles = file && (
    <Stack>
      <Stack alignment="center" key={file.name}>
        <Thumbnail size="small" alt={file.name} source={NoteMinor} />
        <div>
          {file.name} <Caption> {file.size} bytes </Caption>
        </div>
      </Stack>
    </Stack>
  );

  return (
    <Modal title="Import trades by CSV" open={open} onClose={onClose}>
      <Modal.Section>
        <Stack vertical>
          <TextStyle>
            Download a{' '}
            <a
              href={CSV_SAMPLE_LINK}
              download
              target="_blank"
              rel="noreferrer noopener"
            >
              {' '}
              sample CSV template{' '}
            </a>
            to see an example of the format required
          </TextStyle>
          <DropZone onDrop={handleDropZoneDrop} accept=".csv">
            {uploadedFiles}
            {!file && <DropZone.FileUpload />}
          </DropZone>
        </Stack>
      </Modal.Section>
      <Modal.Section>
        <Stack distribution="trailing" alignment="center">
          <Button onClick={onClose}>Cancel</Button>
          <Button
            type="submit"
            primary
            loading={uploading}
            onClick={handleUpload}
          >
            Upload and continue
          </Button>
        </Stack>
      </Modal.Section>
    </Modal>
  );
};

export default ImportTradeByCSVModal;
