import React from 'react';
import { Modal, TextContainer } from '@shopify/polaris';

const ConfirmDialog = ({
  open,
  onClose,
  onCancel,
  title,
  onSubmitText = 'Yes',
  onCancelText = 'Cancel',
  onSubmit,
  description,
  variant,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      title={title}
      primaryAction={{
        content: onSubmitText,
        onAction: onSubmit,
        destructive: !!(variant && variant === 'danger'),
      }}
      secondaryActions={[
        {
          content: onCancelText,
          onAction: onCancel
            ? () => {
                onCancel();
                onClose();
              }
            : onClose,
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          <p>{description}</p>
        </TextContainer>
      </Modal.Section>
    </Modal>
  );
};

export default ConfirmDialog;
