import React from 'react';
import {
  Sheet,
  Heading,
  Button,
  Scrollable,
  Stack,
  TextStyle,
} from '@shopify/polaris';
import { MobileCancelMajor } from '@shopify/polaris-icons';
import Pagination from 'components/Pagination';

export default function CustomerWhitelistedNamesSheet({
  sheetActive,
  toggleSheetActive,
  namesList,
  meta,
  deleting,
  handlePreviousPage,
  handleNextPage,
  handleDeleteName,
}) {
  return (
    <Sheet open={sheetActive} onClose={toggleSheetActive}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <div style={SectionStyle}>
          <Heading>Whitelisted names</Heading>
          <Button
            accessibilityLabel="Cancel"
            icon={MobileCancelMajor}
            onClick={toggleSheetActive}
            plain
          />
        </div>
        <Scrollable style={{ padding: '1.6rem', height: '100%' }}>
          <Stack vertical spacing="extraLoose">
            {namesList.map((listItem) => (
              <Stack
                key={listItem.id}
                distribution="equalSpacing"
                alignment="center"
              >
                <TextStyle>{listItem.name}</TextStyle>
                <Button
                  destructive
                  plain
                  disabled={deleting}
                  onClick={() => handleDeleteName(listItem.id)}
                >
                  Remove
                </Button>
              </Stack>
            ))}
          </Stack>
        </Scrollable>
        <Pagination
          resource={namesList}
          meta={meta}
          handlePreviousPage={handlePreviousPage}
          handleNextPage={handleNextPage}
        />
      </div>
    </Sheet>
  );
}

const SectionStyle = {
  alignItems: 'center',
  borderBottom: '1px solid #DFE3E8',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '1.6rem',
  width: '100%',
};
