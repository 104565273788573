import React from 'react';
import {
  Page,
  Card,
  ResourceList,
  ChoiceList,
  Filters,
  Badge,
  FormLayout,
  Select,
} from '@shopify/polaris';
import DatePicker from 'react-datepicker';

import useDeposit from './useDeposit';
import Pagination from 'components/Pagination';
import DepositListItem from './components/DepositListItem';
import AddDepositModal from './components/AddDepositModal';
import { ExportMinor } from '@shopify/polaris-icons';
import { filterPeriods } from 'utils/period';

const Deposits = () => {
  const {
    updateQueryParams,
    clearAllFilters,
    handlePreviousPage,
    handleNextPage,
    handleAddDeposit,
    toggleModal,
    base,
    app,
    queryParams,
    fetching,
    addingDeposit,
    meta,
    modals,
    deposits,
    assetData,
    handleChangeInSelectedPeriod,
    selectedPeriod,
    selectedDates,
    setSelectedDates,
  } = useDeposit();

  const dateFilter = {
    key: 'date',
    label: 'Date',
    filter: (
      <FormLayout>
        <Select
          options={filterPeriods}
          label="Date Range"
          onChange={handleChangeInSelectedPeriod}
          value={selectedPeriod}
        />
        {selectedPeriod === 'custom-range' && (
          <FormLayout.Group>
            <div>
              <label htmlFor="date_of_birth">Starting</label>
              <DatePicker
                selected={selectedDates.from}
                onChange={(date) =>
                  setSelectedDates((dates) => ({ ...dates, from: date }))
                }
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                dateFormat="dd-MM-yyyy"
              />
            </div>
            <div>
              <label htmlFor="date_of_birth">Ending</label>
              <DatePicker
                selected={selectedDates.to}
                onChange={(date) =>
                  setSelectedDates((dates) => ({ ...dates, to: date }))
                }
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                dateFormat="dd-MM-yyyy"
              />
            </div>
          </FormLayout.Group>
        )}
      </FormLayout>
    ),
  };

  const cryptoFilters = [
    {
      key: 'status',
      label: 'Deposit Status',
      filter: (
        <ChoiceList
          title="Status"
          titleHidden
          choices={[
            { label: 'Completed', value: 'completed' },
            { label: 'Processing', value: 'processing' },
            { label: 'Pending', value: 'pending' },
            { label: 'Cancelled', value: 'cancelled' },
            { label: 'Failed', value: 'failed' },
          ]}
          selected={queryParams.status || ''}
          onChange={(value) => updateQueryParams('status', value)}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: 'fulfilment',
      label: 'Fulfilment Status',
      filter: (
        <ChoiceList
          title={'Status'}
          titleHidden
          choices={[
            { label: 'Fulfilled', value: 'fulfilled' },
            { label: 'Unfulfilled', value: 'unfulfilled' },
            { label: 'Flagged', value: 'flagged' },
          ]}
          selected={queryParams.fulfilment || ''}
          onChange={(value) => updateQueryParams('fulfilment', value)}
        />
      ),
      shortcut: true,
    },
    {
      key: 'asset',
      label: 'Asset',
      filter: (
        <ChoiceList
          title="Asset"
          titleHidden
          choices={assetData.map((key) => ({ label: key, value: key }))}
          selected={queryParams.asset}
          onChange={(value) => updateQueryParams('asset', value)}
        />
      ),
      shortcut: true,
    },
    dateFilter,
  ];
  const cashFilters = [
    {
      key: 'status',
      label: 'Deposit Status',
      filter: (
        <ChoiceList
          title={'Status'}
          titleHidden
          choices={[
            { label: 'Success', value: 'success' },
            { label: 'Processing', value: 'processing' },
            { label: 'Pending', value: 'pending' },
            { label: 'Abandoned', value: 'abandoned' },
            { label: 'Failed', value: 'failed' },
          ]}
          selected={queryParams.status || ''}
          onChange={(value) => updateQueryParams('status', value)}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: 'fulfilment',
      label: 'Fulfilment Status',
      filter: (
        <ChoiceList
          title={'Status'}
          titleHidden
          choices={[
            { label: 'Fulfilled', value: 'fulfilled' },
            { label: 'Unfulfilled', value: 'unfulfilled' },
            { label: 'Flagged', value: 'flagged' },
          ]}
          selected={queryParams.fulfilment || ''}
          onChange={(value) => updateQueryParams('fulfilment', value)}
        />
      ),
      shortcut: true,
    },
    {
      key: 'provider',
      label: 'Provider',
      filter: (
        <ChoiceList
          title={'Provider'}
          titleHidden
          choices={[
            { label: 'Paystack', value: 'Paystack' },
            { label: 'Monnify', value: 'Monnify' },
            { label: 'Jochebed', value: 'Jochebed' },
          ]}
          selected={queryParams.provider || ''}
          onChange={(value) => updateQueryParams('provider', value)}
        />
      ),
      shortcut: true,
    },
    {
      key: 'channel',
      label: 'Channel',
      filter: (
        <ChoiceList
          title={'Channel'}
          titleHidden
          choices={[
            { label: 'Bank', value: 'bank' },
            { label: 'Card', value: 'card' },
            { label: 'USSD', value: 'ussd' },
          ]}
          selected={queryParams.channel || ''}
          onChange={(value) => updateQueryParams('channel', value)}
        />
      ),
      shortcut: true,
    },
    dateFilter,
  ];

  let filters = cryptoFilters;
  if (base === 'cash-deposits') {
    filters = cashFilters;
  }

  const appliedFilters = [];

  if (queryParams.status) {
    appliedFilters.push({
      key: 'status',
      label: `Status ${queryParams.status}`,
      onRemove: () => updateQueryParams('status', ''),
    });
  }
  if (queryParams.fulfilment) {
    appliedFilters.push({
      key: 'fulfilment',
      label: `Fulfilment ${queryParams.fulfilment}`,
      onRemove: () => updateQueryParams('fulfilment', ''),
    });
  }
  if (queryParams.asset && base === 'crypto-deposits') {
    appliedFilters.push({
      key: 'asset',
      label: `Asset ${queryParams.asset}`,
      onRemove: () => updateQueryParams('asset', ''),
    });
  }
  if (queryParams.channel && base === 'cash-deposits') {
    appliedFilters.push({
      key: 'channel',
      label: `Channel ${queryParams.channel}`,
      onRemove: () => updateQueryParams('channel', ''),
    });
  }
  if (queryParams.provider && base === 'cash-deposits') {
    appliedFilters.push({
      key: 'provider',
      label: `provider ${queryParams.provider}`,
      onRemove: () => updateQueryParams('provider', ''),
    });
  }

  return (
    <Page
      fullWidth
      title="Deposits"
      primaryAction={
        base === 'crypto-deposits' &&
        app === 'groot' && {
          content: 'Add Deposit',
          loading: addingDeposit,
          onAction: () => toggleModal('addDeposit'),
          primary: false,
        }
      }
      secondaryActions={[
        {
          content: 'Export',
          icon: ExportMinor,
        },
      ]}
    >
      <Card>
        <ResourceList
          loading={fetching}
          resourceName={{
            singular: 'deposit',
            plural: 'deposits',
          }}
          items={deposits}
          renderItem={(item) => <DepositListItem {...item} endpoint={base} />}
          filterControl={
            <>
              <span className="search-help-text">
                Enter search text, then press <Badge>ENTER</Badge> or{' '}
                <Badge>RETURN</Badge> to search
              </span>
              <Filters
                queryValue={queryParams.q}
                filters={filters}
                appliedFilters={appliedFilters}
                onQueryChange={(value) => updateQueryParams('q', value)}
                onQueryClear={() => updateQueryParams('q', '')}
                onClearAll={clearAllFilters}
              />
            </>
          }
        />
        <Pagination
          resource={deposits}
          meta={meta}
          handlePreviousPage={handlePreviousPage}
          handleNextPage={handleNextPage}
        />
      </Card>
      <AddDepositModal
        isOpen={modals.addDeposit}
        toggleModal={() => toggleModal('addDeposit')}
        loading={addingDeposit}
        handleAddDeposit={handleAddDeposit}
      />
    </Page>
  );
};

export default Deposits;
