import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Page, Layout } from '@shopify/polaris';
import LoadingPage from 'components/LoadingPage';

import ReferralDetails from './components/ReferralDetails';
import RefereeTransaction from './components/RefereeTransaction';
import ReferralUsers from './components/ReferralUsers';

import {
  fetchReferralsDetails,
  approveReferral,
} from 'redux/referrals/actions';
import {
  getReferralDetailsSelector,
  getReferralsEditLoadingSelector,
  getReferralsErrorSelector,
  getReferralsFetchingSelector,
} from 'redux/referrals/selectors';

import { formatISODateToDayMonthYear } from 'utils/date';
import { PreviousLocationContext } from 'providers/PreviousLocationProvider';

function ViewReferral({
  fetchReferralsDetails,
  approveReferral,
  referral,
  editing,
  fetching,
  error,
  match,
}) {
  const { previousLocation } = React.useContext(PreviousLocationContext);

  useEffect(() => {
    fetchReferralsDetails(match.params.id);
  }, [match.params.id, fetchReferralsDetails]);

  if (error) {
    return <Redirect to="/referrals" />;
  }
  if (fetching || !referral) {
    return <LoadingPage />;
  }

  const {
    referrer_user_profile,
    referee_user_profile,
    referrer_user_id,
    referee_user_id,
    transafer,
  } = referral;
  const refererName = `${referrer_user_profile.first_name} ${referrer_user_profile.last_name}`;
  const refereeName = `${referee_user_profile.first_name} ${referee_user_profile.last_name}`;
  const date = formatISODateToDayMonthYear(new Date(referral.created_at));
  return (
    <Page
      title={`# ${referral.id}`}
      breadcrumbs={[
        {
          content: 'Referrals',
          url: previousLocation
            ? `${previousLocation.pathname}${previousLocation.search}`
            : '/customers/referrals',
        },
      ]}
    >
      <Layout>
        <Layout.Section>
          <ReferralDetails
            editing={editing}
            refereeName={refereeName}
            refererName={refererName}
            id={referral.id}
            status={referral.status}
            date={date}
            approveReferral={approveReferral}
          />
          <RefereeTransaction transafer={transafer} refereeName={refereeName} />
        </Layout.Section>
        <Layout.Section secondary>
          <ReferralUsers
            refererName={refererName}
            referee_user_id={referee_user_id}
            referrer_user_id={referrer_user_id}
            refereeName={refereeName}
          />
        </Layout.Section>
      </Layout>
    </Page>
  );
}
const mapStateToProps = (state, ownProps) => {
  return {
    referral: getReferralDetailsSelector(state, ownProps.match.params.id),
    editing: getReferralsEditLoadingSelector(state),
    fetching: getReferralsFetchingSelector(state),
    error: getReferralsErrorSelector(state),
  };
};

const mapDispatchToProps = {
  fetchReferralsDetails,
  approveReferral,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewReferral);
