import api from '../../utils/api';
import storage from '../../utils/storage';
import { LOGIN_USER, LOGOUT_USER, CLEAR_ERROR } from './actionTypes';

/**
 *
 * @typedef { import('../types').GenericDispatchAction } GenericDispatchAction
 */

/**
 *
 * @param { {email : string; password: string} } formData
 * @description submits login credentials to the login endpoint
 * @returns { GenericDispatchAction }
 */
export const login = (formData) => (dispatch) => {
  return dispatch({
    type: LOGIN_USER.default,
    payload: api.post('/v1/auth/login', formData),
  }).then((res) => {
    storage.set('auth', res.value.data);
    return res;
  });
};

/**
 * @description logs user out and clears storage
 * @returns { GenericDispatchAction }
 */
export const logout = () => {
  const colorScheme = storage.get('colorScheme');
  storage.clear();
  if (colorScheme) storage.set('colorScheme', colorScheme);
  return (dispatch) =>
    dispatch({
      type: LOGOUT_USER.fulfilled,
    });
};

/**
 * @description clears state error
 * @returns { GenericDispatchAction }
 */

export const clearError = () => {
  const colorScheme = storage.get('colorScheme');
  storage.clear();
  if (colorScheme) storage.set('colorScheme', colorScheme);
  return (dispatch) =>
    dispatch({
      type: CLEAR_ERROR.fulfilled,
    });
};
