import React from 'react';
import { useLocation } from 'react-router-dom';
import { Avatar, ResourceList, TextStyle } from '@shopify/polaris';
import { formatNumberToMoney } from 'utils/money';

const getIcon = (code) =>
  `https://res.cloudinary.com/dwoc5fknz/image/upload/v1545995759/crypto_assets/${code}.svg`;

function WalletItem({
  wallet,
  hasFullAccess,
  toggleEditBalanceModal,
  setSelectedWalletCurrency,
}) {
  const location = useLocation();

  const { currency_code, hold, pending_balance, balance } = wallet;

  const url = `${location.pathname}/account/${currency_code}`;

  const shortcutActions = [
    {
      content: 'Edit Balance',
      onAction: () => {
        toggleEditBalanceModal();
        setSelectedWalletCurrency(currency_code);
      },
    },
  ];
  return (
    <ResourceList.Item
      id={currency_code}
      accessibilityLabel={`View details for ${currency_code}`}
      url={url}
      media={
        <Avatar
          size="small"
          name={currency_code}
          source={getIcon(currency_code)}
        />
      }
      shortcutActions={hasFullAccess ? shortcutActions : undefined}
    >
      <div className="Wallet_Main">
        <div className="Wallet_Code">
          <TextStyle variation="strong">{currency_code}</TextStyle>
        </div>
        <div className="Wallet_Hold">
          <TextStyle variation="subdued">
            {formatNumberToMoney(hold, {
              currencyCode: currency_code,
              codePosition: 'back',
              category: currency_code,
            })}
          </TextStyle>
        </div>
        <div className="Wallet_Pending">
          <TextStyle>
            {formatNumberToMoney(pending_balance, {
              currencyCode: currency_code,
              codePosition: 'back',
              category: currency_code,
            })}
          </TextStyle>
        </div>
        <div className="Wallet_Balance">
          <TextStyle variation="positive">
            {formatNumberToMoney(balance, {
              currencyCode: currency_code,
              codePosition: 'back',
              category: currency_code,
            })}
          </TextStyle>
        </div>
      </div>
    </ResourceList.Item>
  );
}

export default WalletItem;
