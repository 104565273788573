import React from 'react';
import {
  Stack,
  Card,
  TextStyle,
  TextContainer,
  DisplayText,
  SkeletonDisplayText,
} from '@shopify/polaris';

function CustomerAccountStatementSummary({ summary }) {
  return (
    <div className="account-statement-summary">
      <Stack distribution="fillEvenly">
        <Card>
          <Card.Section>
            <TextContainer>
              <TextStyle variation="subdued">Opening Balance</TextStyle>
              {summary?.opening_balance ? (
                <DisplayText size="large">
                  {summary.opening_balance}
                </DisplayText>
              ) : (
                <SkeletonDisplayText size="medium" />
              )}
            </TextContainer>
          </Card.Section>
        </Card>
        <Card>
          <Card.Section>
            <TextContainer>
              <TextStyle variation="subdued">Total Debit</TextStyle>
              {summary?.total_debit ? (
                <DisplayText size="large">{summary.total_debit}</DisplayText>
              ) : (
                <SkeletonDisplayText size="medium" />
              )}
            </TextContainer>
          </Card.Section>
        </Card>
        <Card>
          <Card.Section>
            <TextContainer>
              <TextStyle variation="subdued">Total Credit</TextStyle>
              {summary?.total_credit ? (
                <DisplayText size="large">{summary.total_credit}</DisplayText>
              ) : (
                <SkeletonDisplayText size="medium" />
              )}
            </TextContainer>
          </Card.Section>
        </Card>
        <Card>
          <Card.Section>
            <TextContainer>
              <TextStyle variation="subdued">Closing Balance</TextStyle>
              {summary?.closing_balance ? (
                <DisplayText size="large">
                  {summary.closing_balance}
                </DisplayText>
              ) : (
                <SkeletonDisplayText size="medium" />
              )}
            </TextContainer>
          </Card.Section>
        </Card>
        <Card>
          <Card.Section>
            <TextContainer>
              <TextStyle variation="subdued">Current Balance</TextStyle>
              {summary?.current_balance ? (
                <DisplayText size="large">
                  {summary.current_balance}
                </DisplayText>
              ) : (
                <SkeletonDisplayText size="medium" />
              )}
            </TextContainer>
          </Card.Section>
        </Card>
      </Stack>
    </div>
  );
}

export default CustomerAccountStatementSummary;
