import React from 'react';

import { Card, TextStyle, DisplayText } from '@shopify/polaris';
import { formatNumberToMoney } from 'utils/money';

function DepositProfit({ fee, feeDiscount, providerFee, currency }) {
  const profit = fee - (feeDiscount || 0) - (providerFee || 0);

  return (
    <Card.Section title={profit < 0 ? 'Loss' : 'Profit'}>
      <DisplayText element="h1" size="medium">
        <TextStyle variation={profit < 0 ? 'negative' : 'positive'}>
          {formatNumberToMoney(profit, {
            currencyCode: currency,
            category: currency,
            codePosition: 'back',
          })}
        </TextStyle>
      </DisplayText>
    </Card.Section>
  );
}

export default DepositProfit;
