/* eslint-disable react/jsx-key */
import React, { useState } from 'react';
import {
  Card,
  Button,
  Stack,
  Heading,
  TextStyle,
  DataTable,
} from '@shopify/polaris';
import Clickable from 'components/Clickable';
import CreateTradeModal from './CreateTradeModal';
import OrderTradeDetailsModal from './OrderTradeDetailsModal';
import { renderStatusBadge } from 'utils/formatter';
import { formatNumberToMoney } from 'utils/money';
import LoadingSpinner from 'components/LoadingSpinner';

export default function OrderTrades({
  trades = [],
  handleCreateTrade,
  handleDeleteTrade,
  handleCompleteOrderTrades,
  actionLoading,
  fetching,
  settled,
  base,
  counter,
}) {
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showCreateTradeModal, setShowCreateTradeModal] = useState(false);
  const [activeTrade, setActiveTrade] = useState(null);

  function handleToggleTradeModal(trade) {
    setActiveTrade(trade);
    setShowDetailsModal(!showDetailsModal);
  }

  const rows = trades.map((trade) => {
    return [
      <Clickable tabIndex="-1" onClick={() => handleToggleTradeModal(trade)}>
        <TextStyle>
          {`${formatNumberToMoney(trade.base, {
            category: base,
            currencyCode: base,
            maximumFractionDigits: 10,
          })}`}
        </TextStyle>
      </Clickable>,
      <Clickable tabIndex="-1" onClick={() => handleToggleTradeModal(trade)}>
        <TextStyle>
          {`${formatNumberToMoney(trade.price, {
            category: counter,
            currencyCode: counter,
            maximumFractionDigits: 10,
          })}`}
        </TextStyle>
      </Clickable>,
      <Clickable tabIndex="-1" onClick={() => handleToggleTradeModal(trade)}>
        <TextStyle>{renderStatusBadge(trade.status)}</TextStyle>
      </Clickable>,
      <Clickable tabIndex="-1" onClick={() => handleToggleTradeModal(trade)}>
        <TextStyle>
          {`${formatNumberToMoney(trade.counter, {
            category: counter,
            currencyCode: counter,
            maximumFractionDigits: 10,
          })}`}
        </TextStyle>
      </Clickable>,
    ];
  });

  if (actionLoading || fetching) {
    return (
      <Card>
        <Card.Section>
          <LoadingSpinner />
        </Card.Section>
      </Card>
    );
  }

  return (
    <>
      {trades.length < 1 ? (
        <Card>
          <Card.Section>
            <Stack
              vertical
              spacing="loose"
              alignment="center"
              distribution="center"
            >
              <TextStyle>This order doesn’t have any trades yet</TextStyle>
              {!settled && (
                <Stack>
                  <Button
                    size="slim"
                    onClick={() =>
                      setShowCreateTradeModal(!showCreateTradeModal)
                    }
                  >
                    Create Trade
                  </Button>
                  <Button
                    size="slim"
                    primary
                    loading={actionLoading}
                    disabled={actionLoading || fetching}
                    onClick={handleCompleteOrderTrades}
                  >
                    Complete Trades
                  </Button>
                </Stack>
              )}
            </Stack>
          </Card.Section>
        </Card>
      ) : (
        <>
          <Card
            title={
              <Stack distribution="equalSpacing" alignment="center">
                <Stack.Item>
                  <Heading>Trades</Heading>
                </Stack.Item>
                {!settled && (
                  <Stack alignment="center">
                    <Stack.Item>
                      <Button
                        size="slim"
                        onClick={() =>
                          setShowCreateTradeModal(!showCreateTradeModal)
                        }
                      >
                        Create Trade
                      </Button>
                    </Stack.Item>
                    <Stack.Item>
                      <Button
                        size="slim"
                        primary
                        loading={actionLoading}
                        disabled={actionLoading || fetching}
                        onClick={handleCompleteOrderTrades}
                      >
                        Complete Trades
                      </Button>
                    </Stack.Item>
                  </Stack>
                )}
              </Stack>
            }
          >
            <DataTable
              columnContentTypes={['text', 'text', 'text', 'numeric']}
              headings={['Base', 'Price', 'Status', 'Counter']}
              rows={rows}
              hideScrollIndicator={true}
              truncate={true}
            />
          </Card>
          {activeTrade && (
            <OrderTradeDetailsModal
              trade={activeTrade}
              open={showDetailsModal}
              onClose={() => setShowDetailsModal(!showDetailsModal)}
              handleDeleteTrade={handleDeleteTrade}
            />
          )}
        </>
      )}
      <CreateTradeModal
        open={showCreateTradeModal}
        onClose={() => setShowCreateTradeModal(!showCreateTradeModal)}
        base={base}
        counter={counter}
        handleCreateTrade={handleCreateTrade}
        loading={actionLoading}
      />
    </>
  );
}
