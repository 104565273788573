import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { AppProvider } from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import { Router, Switch, Route, useLocation } from 'react-router-dom';
import usePrevious from './hooks/usePrevious';
import store from './redux';
import AppLink from './components/AppLink';
import history from './utils/history';
import homeRoutes from './routes/home';
import ProtectedRoute from './containers/ProtectedRoute';
import { ConfirmationServiceProvider } from './utils/confirmationService';
import PreviousLocationProvider from './providers/PreviousLocationProvider';
import { Toaster } from 'utils/toast';
import "react-datepicker/dist/react-datepicker.css";
import './app.css';
import storage from 'utils/storage';

export const ThemeProvider = React.createContext({
  colorScheme: 'light',
});

function ScrollToTop({ children }) {
  const location = useLocation();

  const previousLocation = usePrevious(location.pathname);

  useEffect(() => {
    if (previousLocation !== location.pathname) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname, previousLocation]);

  return children;
}

const App = () => {
  const [colorScheme, setColorScheme] = React.useState(() => {
    const cachedColorScheme = storage.get('colorScheme');

    if (cachedColorScheme) return cachedColorScheme;

    const userPrefersDark =
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches;

    return userPrefersDark ? 'dark' : 'light';
  });

  const toggleColorScheme = () =>
    setColorScheme((s) => (s === 'light' ? 'dark' : 'light'));

  useEffect(() => {
    storage.set('colorScheme', colorScheme);
  }, [colorScheme]);

  const theme = {
    colors: {
      primary: '#1C2260',
    },
    colorScheme: colorScheme,
    logo: {
      width: 124,
      topBarSource:
        'https://res.cloudinary.com/dwoc5fknz/image/upload/v1566084350/Logos/busha-logo.svg',
      url: '/',
      accessibilityLabel: 'Busha Grey',
      contextualSaveBarSource:
        'https://cdn.shopify.com/s/files/1/0446/6937/files/jaded-pixel-logo-gray.svg?6215648040070010999',
    },
  };

  return (
    <Provider store={store}>
      <AppProvider theme={theme} linkComponent={AppLink} i18n={enTranslations}>
        <ThemeProvider.Provider value={{ colorScheme, toggleColorScheme }}>
          <ConfirmationServiceProvider>
            <Router history={history}>
              <PreviousLocationProvider>
                <ScrollToTop>
                  <Switch>
                    {homeRoutes.map((route) =>
                      !route.requiredAuth ? (
                        <Route
                          key={route.path}
                          path={route.path}
                          component={route.component}
                        />
                      ) : (
                        <ProtectedRoute
                          key={route.path}
                          path={route.path}
                          component={route.component}
                        />
                      ),
                    )}
                  </Switch>
                </ScrollToTop>
              </PreviousLocationProvider>
            </Router>
            <Toaster />
          </ConfirmationServiceProvider>
        </ThemeProvider.Provider>
      </AppProvider>
    </Provider>
  );
};

export default App;
