import { put, call, all, takeLatest } from 'redux-saga/effects';

import api from 'utils/api';
import { FETCH_TRADES } from './actionTypes';

function* fetchTradesSaga(action) {
  try {
    const { queryString } = action.payload;
    yield put({ type: FETCH_TRADES.pending });
    const res = yield call(api.get, `/v1/trades${queryString}`);
    yield put({ type: FETCH_TRADES.fulfilled, payload: res });
  } catch (error) {
    yield put({ type: FETCH_TRADES.rejected, error });
  }
}

export default function* allTradeSagas() {
  yield all([takeLatest(FETCH_TRADES.default, fetchTradesSaga)]);
}
