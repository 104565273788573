import React from 'react';
import { Form, FormLayout, TextField, Select } from '@shopify/polaris';

export default function EditWalletForm({
  values,
  handleInputChange,
  handleSubmit,
  errors,
  touched,
}) {
  const actionOptions = [
    { value: 'add', label: 'Credit Available Balance' },
    { value: 'deduct', label: 'Debit Available Balance' },
  ];

  return (
    <Form onSubmit={handleSubmit}>
      <FormLayout>
        <Select
          label="Action"
          name="action"
          placeholder="-- Pick an action --"
          options={actionOptions}
          onChange={(value) => {
            if (Array.isArray(value)) {
              handleInputChange('action')(value[0]);
            } else {
              handleInputChange('action')(value);
            }
          }}
          value={values.action}
          error={errors.action && touched.action ? errors.action : ''}
        />
        <TextField
          value={values.amount}
          onChange={handleInputChange('amount')}
          type="number"
          label="Amount"
          name="amount"
          error={errors.amount && touched.amount ? errors.amount : ''}
        />
      </FormLayout>
    </Form>
  );
}
