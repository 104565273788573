import React from 'react';
import { format } from 'date-fns';
import { ResourceList, TextStyle } from '@shopify/polaris';
import { renderStatusBadge } from 'utils/formatter';
import { formatNumberToMoney } from 'utils/money';
import './DepositListItem.css';

export default function DepositListItem(props) {
  const {
    id,
    reference,
    created_at,
    receiver_name,
    user,
    status,
    fulfilment,
    amount,
    currency,
    app,
    endpoint,
    ...rest
  } = props;

  return (
    <ResourceList.Item
      {...rest}
      id={id}
      reference={reference}
      created_at={created_at}
      sender={receiver_name}
      accessibilityLabel={`View details for ${reference}`}
      url={`/deposits/${endpoint}/${app}/${id}`}
    >
      <div className="DepositListItem_Main">
        <div className="DepositListItem_Ref">
          <TextStyle variation="code">{reference}</TextStyle>
        </div>
        <div className="DepositListItem_Date">
          <TextStyle
            variation={
              fulfilment.toLowerCase() === 'completed' &&
              status.toLowerCase() === 'completed'
                ? 'subdue'
                : ''
            }
          >
            {format(new Date(created_at), 'MMM d ')}
            at {format(new Date(created_at), ' hh:mm a').toLowerCase()}
          </TextStyle>
        </div>
        {receiver_name && (
          <div className="DepositListItem_Customer">{receiver_name}</div>
        )}

        {user && <div className="DepositListItem_Customer">{user.name}</div>}

        <div className="DepositListItem_Fulfilment">
          {renderStatusBadge(fulfilment)}
        </div>
        <div className="DepositListItem_Status">
          {renderStatusBadge(status)}
        </div>
        <div className="DepositListItem_Total">
          {formatNumberToMoney(amount, {
            currencyCode: currency,
            category: currency,
            codePosition: 'back',
          })}
        </div>
      </div>
    </ResourceList.Item>
  );
}
