import React from 'react';
import { Stack, Card, Link, Heading, TextStyle } from '@shopify/polaris';
import AvatarInitials from 'components/AvatarInitials';

export default function OrderCustomer({ user }) {
  return (
    <Card.Section
      title={
        <Stack distribution="equalSpacing">
          <Stack.Item>
            <Heading>Customer</Heading>
          </Stack.Item>
          <Stack.Item>
            {user?.name && (
              <Link url={`/customers/${user?.id}`}>
                <AvatarInitials firstName={user?.name} size="small" />
              </Link>
            )}
          </Stack.Item>
        </Stack>
      }
    >
      {user?.name ? (
        <Link url={`/customers/${user?.id}`}>{user?.name}</Link>
      ) : (
        <TextStyle variation="subdued">N/A</TextStyle>
      )}
    </Card.Section>
  );
}
