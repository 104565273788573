import {
  FETCH_ASSET,
  FETCH_ASSETS,
  UPDATE_ASSET,
  FETCH_PAYMENT_PROVIDERS,
} from './actionTypes';
import parseError from 'utils/parseError';
import { mapKeys } from 'lodash';

const INITIAL_STATE = {
  data: {},
  meta: {},
  providers: [],
  updating: false,
  fetching: false,
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_ASSETS.pending:
    case FETCH_ASSET.pending:
    case FETCH_PAYMENT_PROVIDERS.pending:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case FETCH_ASSETS.rejected:
    case FETCH_ASSET.rejected:
    case FETCH_PAYMENT_PROVIDERS.rejected:
      return {
        ...state,
        fetching: false,
        error: parseError(action.payload),
      };

    case FETCH_ASSETS.fulfilled:
      return {
        ...state,
        fetching: false,
        data: mapKeys(action.payload.data.currencies, 'code'),
        meta: action.payload.data.meta,
      };
    case FETCH_ASSET.fulfilled:
      return {
        ...state,
        fetching: false,
        data: {
          ...state.data,
          [action.payload.data.code]: action.payload.data,
        },
      };
    case FETCH_PAYMENT_PROVIDERS.fulfilled:
      return {
        ...state,
        fetching: false,
        providers: action.payload.data,
      };

    case UPDATE_ASSET.pending:
      return {
        ...state,
        updating: true,
      };

    case UPDATE_ASSET.rejected:
      return {
        ...state,
        updating: false,
      };

    case UPDATE_ASSET.fulfilled:
      return {
        ...state,
        updating: false,
      };

    default:
      return state;
  }
};
