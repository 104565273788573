import React from 'react';
import {
  Form,
  FormLayout,
  TextField,
  Button,
  TextStyle,
  Stack,
} from '@shopify/polaris';

const ChangeStaffPasswordForm = ({
  fullName,
  handleChangeStaffPassword,
  newPassword,
  onNewPasswordChange,
  loading,
}) => {
  return (
    <Stack vertical>
      <TextStyle variation="subdued">{`Change ${fullName}'s password`}</TextStyle>
      <Form onSubmit={handleChangeStaffPassword} preventDefault>
        <FormLayout>
          <TextField
            name="password"
            type="password"
            value={newPassword}
            onChange={onNewPasswordChange}
            label="New Password"
          />
          <Button disabled={loading || !newPassword} submit>
            Change password
          </Button>
        </FormLayout>
      </Form>
    </Stack>
  );
};

export default ChangeStaffPasswordForm;
