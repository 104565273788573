import React from 'react';
import { ResourceList, TextStyle } from '@shopify/polaris';

import MarketPlaceListContainer from 'components/MarketPlacecContainerList';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { formatNumberToMoney } from 'utils/money';

export default function TradeItem(item) {
  const {
    id,
    reference,
    buyer,
    seller,
    buyer_amount,
    ad,
    created_at,
    price,
  } = item;

  const date = format(new Date(created_at), "MMM d, yyyy 'at' h:mm aa");

  return (
    <ResourceList.Item id={id} url={`/marketplace/trades/${id}`}>
      <MarketPlaceListContainer>
        <TextStyle variation="strong">
          <Link to="">{reference}</Link>
        </TextStyle>
        <TextStyle>{date}</TextStyle>
        <TextStyle>{buyer.username}</TextStyle>
        <TextStyle>{ad.side}</TextStyle>
        <TextStyle>{buyer_amount}</TextStyle>

        <TextStyle>{ad.asset}</TextStyle>
        <TextStyle>{seller.username}</TextStyle>
        <TextStyle>
          {formatNumberToMoney(price)} {ad.currency}
        </TextStyle>
      </MarketPlaceListContainer>
    </ResourceList.Item>
  );
}
