import React, { Fragment } from 'react';
import { Card, TextStyle, Stack } from '@shopify/polaris';
import CustomerBanksSheet from './CustomerBanksSheet';
import CustomerCardSheet from './CustomerCardSheet';
import CustomerCyptoAddressesSheet from './CustomerCyptoAddressesSheet';
import LoadingSpinner from 'components/LoadingSpinner';
import CopiableText from 'components/CopiableText';

export default function CustomerBanksCardsAndCryptoAddresses({
  name,
  banks,
  banksFetching,
  cards,
  cardsFetching,
  addresses,
  addressesFetching,
}) {
  const [sheetsState, setSheetsState] = React.useState({
    cards: false,
    bankAccounts: false,
    cryptoAddresses: false,
  });

  const toggleSheetState = (modal) => () =>
    setSheetsState((previousState) => ({
      ...previousState,
      [modal]: !previousState[modal],
    }));

  return (
    <Fragment>
      <Card>
        <Card.Section
          title="Debit Cards"
          section
          actions={[
            {
              content: 'View more',
              onAction: toggleSheetState('cards'),
              disabled: cards.length < 2,
            },
          ]}
        >
          {cardsFetching ? (
            <LoadingSpinner />
          ) : (
            <Fragment>
              {cards.length > 0 ? (
                <Stack vertical spacing="tight">
                  <TextStyle>{cards[0]?.card_type?.toUpperCase()}</TextStyle>
                  <TextStyle>**** **** **** {cards[0]?.last4}</TextStyle>
                  <TextStyle>
                    {cards[0]?.bank}({cards[0]?.country_code})
                  </TextStyle>
                </Stack>
              ) : (
                <TextStyle>No cards available</TextStyle>
              )}
            </Fragment>
          )}
        </Card.Section>
        <Card.Section
          title="Bank Accounts"
          section
          actions={[
            {
              content: 'View more',
              onAction: toggleSheetState('bankAccounts'),
              disabled: banks.length < 2,
            },
          ]}
        >
          {banksFetching ? (
            <LoadingSpinner />
          ) : (
            <Fragment>
              {banks.length > 0 ? (
                <Stack vertical spacing="tight">
                  <TextStyle>{banks[0]?.account_name}</TextStyle>
                  <TextStyle>{banks[0]?.account_number}</TextStyle>
                  <TextStyle>
                    {banks[0]?.name}
                    {banks[0]?.country ? `(${banks[0]?.country})` : ''}
                  </TextStyle>
                </Stack>
              ) : (
                <TextStyle>No bank account available</TextStyle>
              )}
            </Fragment>
          )}
        </Card.Section>
        <Card.Section
          title="Crypto Addresses"
          section
          actions={[
            {
              content: 'View more',
              onAction: toggleSheetState('cryptoAddresses'),
              disabled: addresses.length < 2,
            },
          ]}
        >
          {addressesFetching ? (
            <LoadingSpinner />
          ) : (
            <Fragment>
              {addresses.length > 0 ? (
                <Stack vertical spacing="tight">
                  <TextStyle>{addresses[0].asset}</TextStyle>
                  <TextStyle>{addresses[0].network}</TextStyle>
                  <CopiableText
                    text={addresses[0].address}
                    variation="subdued"
                  />
                </Stack>
              ) : (
                <TextStyle>No addresses available</TextStyle>
              )}
            </Fragment>
          )}
        </Card.Section>
      </Card>
      <CustomerBanksSheet
        sheetActive={sheetsState.bankAccounts}
        toggleSheetActive={toggleSheetState('bankAccounts')}
        banks={banks}
      />
      <CustomerCardSheet
        sheetActive={sheetsState.cards}
        toggleSheetActive={toggleSheetState('cards')}
        cards={cards}
      />
      <CustomerCyptoAddressesSheet
        sheetActive={sheetsState.cryptoAddresses}
        toggleSheetActive={toggleSheetState('cryptoAddresses')}
        addresses={addresses}
      />
    </Fragment>
  );
}
