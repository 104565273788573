import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Page,
  Card,
  DataTable,
  Stack,
  TextStyle,
  Button,
} from '@shopify/polaris';
import LoadingSpinner from 'components/LoadingSpinner';
import EmptyTable from 'components/EmptyTable';
import {
  fetchCustomerRecurringOrders,
  deleteCustomerRecurringOrder,
} from 'redux/customers/actions';
import { formatDateToReadableString } from 'utils/date';
import { formatNumberToMoney } from 'utils/money';
import { capitalize } from 'utils/strings';

export default function CustomerRecurringOrders() {
  const params = useParams();
  const dispatch = useDispatch();

  const {
    recurringOrders: { [params.id]: orders },
    recurringOrdersFetching,
    updating,
  } = useSelector((state) => state.customers);

  const handleDeleteRecurringOrder = (orderId) => {
    dispatch(deleteCustomerRecurringOrder(params.id, orderId));
  };

  const recurringOrders = orders ? Object.values(orders) : [];

  const rows =
    recurringOrders.map((order) => [
      formatDateToReadableString(new Date(order.created_at)),
      `Buy ${order.quote} ${formatNumberToMoney(order.quote_amount)} worth of ${
        order.base
      }`,
      capitalize(order.frequency),
      <Button
        loading={updating}
        disabled={updating}
        onClick={() => handleDeleteRecurringOrder(order.id)}
        key={order.id}
      >
        Cancel
      </Button>,
    ]) || [];

  React.useEffect(() => {
    dispatch(fetchCustomerRecurringOrders(params.id));
  }, [dispatch, params.id]);

  return (
    <Page
      title="Recurring Purchases"
      breadcrumbs={[
        {
          content: 'Customer',
          url: `/customers/${params.id}`,
        },
      ]}
    >
      <Card>
        <Card.Section>
          <Stack alignment="center" distribution="equalSpacing">
            <TextStyle>
              {recurringOrdersFetching
                ? 'Loading orders'
                : `Showing ${recurringOrders?.length || 0} order(s)`}
            </TextStyle>
          </Stack>
        </Card.Section>
        {recurringOrdersFetching ? (
          <LoadingSpinner />
        ) : (
          <React.Fragment>
            {rows?.length > 0 ? (
              <DataTable
                columnContentTypes={['text', 'text', 'text', 'numeric']}
                headings={['Created', 'Action', 'Frequency', '']}
                rows={rows}
                truncate={true}
                verticalAlign="middle"
              />
            ) : (
              <Card.Section>
                <EmptyTable resourceName="recurring orders" />
              </Card.Section>
            )}
          </React.Fragment>
        )}
      </Card>
    </Page>
  );
}
