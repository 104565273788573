import React from 'react';
import { Stack, SkeletonDisplayText, SkeletonBodyText } from '@shopify/polaris';

function ReportCardPlaceHolder() {
  return (
    <Stack vertical spacing="loose">
      <SkeletonDisplayText />
      <br />
      <SkeletonBodyText lines={1} />
      <SkeletonBodyText lines={1} />
      <SkeletonBodyText lines={1} />
    </Stack>
  );
}

export default ReportCardPlaceHolder;
