import React from 'react';
import { Card, TextContainer } from '@shopify/polaris';

export default function PayoutDestination({
  category,
  address,
  bank_name,
  account_name,
  account_number,
  memo,
}) {
  if (category.toLowerCase() === 'fiat') {
    return (
      <Card.Section title="Bank" sectioned>
        <TextContainer>
          <p>{account_name}</p>
          <p>{bank_name}</p>
          <p>{account_number}</p>
        </TextContainer>
      </Card.Section>
    );
  }
  return (
    <>
      <Card.Section title="Address" sectioned>
        <TextContainer>
          <p>{address}</p>
        </TextContainer>
      </Card.Section>
      {memo && (
        <Card.Section title="Memo" sectioned>
          <TextContainer>
            <p>{memo}</p>
          </TextContainer>
        </Card.Section>
      )}
    </>
  );
}
