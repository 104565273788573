import React from 'react';

import {Modal, TextContainer} from '@shopify/polaris';

function PasswordModal(props) {
  const {active, close, resetPassword} = props;

  return (
    <div>
      <Modal
        open={active}
        onClose={() => close()}
        title="Reset password"
        primaryAction={{
          content: 'Reset password',
          onAction: () => resetPassword(),
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: () => close(),
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>
              Are you sure you want to reset the password for this customer? The
              customer will be sent an email with instructions to reset their
              password.
            </p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </div>
  );
}

export default PasswordModal;
