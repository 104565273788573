import React, { Fragment, useState } from 'react';
import { Thumbnail, Stack, Card, Loading } from '@shopify/polaris';
import DocumentModal from './DocumentModal';
import EmptyTable from 'components/EmptyTable';

export default function CustomerDocuments({
  documents,
  updateVerificationsDetails,
  onUpdateVerification,
  verificationEditloading,
  fetching,
}) {
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [showModal, setShowModal] = useState(false);

  function toggleModal() {
    setShowModal(!showModal);
  }

  return (
    <Fragment>
      <Card title="Uploaded Documents">
        <Card.Section>
          {fetching && <Loading />}
          {documents.length > 0 ? (
            <Stack horizontal spacing="extraTight" wrap={false}>
              {documents.map((document) => (
                <div
                  key={document._id}
                  onClick={() => {
                    setSelectedDocument(document);
                    toggleModal();
                  }}
                >
                  <Thumbnail source={document.images[0]} size="large" />
                </div>
              ))}
            </Stack>
          ) : (
            <EmptyTable resourceName="documents" />
          )}
        </Card.Section>
      </Card>
      {selectedDocument && (
        <DocumentModal
          document={selectedDocument}
          handleClose={toggleModal}
          active={showModal}
          approveVerification={() => {
            const payload = {
              accepted: true,
              note: '',
            };
            updateVerificationsDetails(selectedDocument._id, payload).then(
              () => {
                toggleModal();
                onUpdateVerification();
              },
            );
          }}
          loading={verificationEditloading}
        />
      )}
    </Fragment>
  );
}
