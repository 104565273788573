import React from 'react';
import { Badge } from '@shopify/polaris';
import { capitalize } from 'utils/strings';

const successStatuses = [
  'success',
  'accepted',
  'settled',
  'completed',
  'fulfilled',
  'active',
  'verified',
  'disputed',
  'created',
  'online',
];

const warningStatuses = ['pending', 'unfulfilled', 'unsettled', 'processing'];

const dangerStatuses = [
  'unverified',
  'rejected',
  'inactive',
  'cancelled',
  'failed',
  'abandoned',
  'flagged',
  'canceled',
  'expired',
  'expired quote',
  'stopped',
  'offline',
];

function StatusBadge({ status = '', plain = false }) {
  if (successStatuses.includes(status.toLowerCase())) {
    return (
      <Badge status={plain ? undefined : 'success'} progress="complete">
        {capitalize(status)}
      </Badge>
    );
  }

  if (warningStatuses.includes(status.toLowerCase())) {
    return (
      <Badge
        status={plain ? undefined : 'attention'}
        progress="partiallyComplete"
      >
        {capitalize(status)}
      </Badge>
    );
  }
  if (dangerStatuses.includes(status.toLowerCase())) {
    return (
      <Badge status={plain ? undefined : 'critical'} progress="incomplete">
        {capitalize(status)}
      </Badge>
    );
  }
  return <Badge status="new">{capitalize(status)}</Badge>;
}

export default StatusBadge;
