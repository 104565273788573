import React from 'react';
import { useFormik } from 'formik';
import { Modal, Stack, FormLayout, TextField } from '@shopify/polaris';

export default function CompletePayoutModal({
  modalOpen,
  currency,
  loading,
  handleClose,
  onPayoutStatusChange,
}) {
  const form = useFormik({
    initialValues: {
      fees: '',
      hash: '',
    },
    onSubmit(values) {
      onPayoutStatusChange({ ...values, status: 'completed' });
      handleClose();
    },
  });

  return (
    <Modal
      open={modalOpen}
      onClose={handleClose}
      title="Manually complete payout"
      primaryAction={{
        content: 'Mark as completed',
        onAction: form.handleSubmit,
        loading: loading,
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: handleClose,
        },
      ]}
    >
      <Modal.Section>
        <Stack vertical>
          <Stack.Item>
            <FormLayout>
              <TextField
                label="Payout Hash"
                value={form.values.hash}
                onChange={(v) => form.setFieldValue('hash', v)}
              />
              <TextField
                suffix={currency}
                type="number"
                label="Payout fees"
                value={form.values.fees}
                onChange={(v) => form.setFieldValue('fees', v)}
                helpText={
                  <span>
                    Enter the cost of manually processing this payout.
                  </span>
                }
              />
            </FormLayout>
          </Stack.Item>
        </Stack>
      </Modal.Section>
    </Modal>
  );
}
