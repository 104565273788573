import React from 'react';
import { TextStyle } from '@shopify/polaris';

import styled from 'styled-components';

const Container = styled.header`
  display: grid;
  grid-template-columns: repeat(3, 2fr) repeat(3, 1fr) 2fr;
  grid-gap: 10px;
  justify-items: start;
  align-items: start;
  border-top: 1px solid #dfe3e8;
  border-bottom: 1px solid #dfe3e8;
  padding: 1.2rem 2rem;
  & > * {
    white-space: nowrap;
    box-sizing: border-box;
  }
  & .right {
    justify-self: end;
  }
  &.chain {
    grid-template-columns: repeat(2, 2fr) 1fr 1fr 2fr 1fr 2fr;
  }
  @media only screen and (max-width: 900px) {
    display: none;
  }
`;

export default function OrderListHeader({ app }) {
  return (
    <Container role="row" className={app}>
      <TextStyle variation="strong">Reference</TextStyle>
      <TextStyle variation="strong">Date</TextStyle>
      {app !== 'chain' && <TextStyle variation="strong">Customer</TextStyle>}
      {app === 'chain' && <TextStyle>App</TextStyle>}
      <TextStyle variation="strong">Side</TextStyle>
      {app === 'chain' && <TextStyle>Base</TextStyle>}
      <TextStyle variation="strong">Status</TextStyle>
      {app !== 'chain' && <TextStyle variation="strong">Chain</TextStyle>}
      <div className="right">
        <TextStyle variation="strong">
          {app === 'chain' ? 'Counter' : 'Total'}
        </TextStyle>
      </div>
    </Container>
  );
}
