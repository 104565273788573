import React, { useEffect, useMemo } from 'react';
import { reduxForm, Field } from 'redux-form';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../redux/auth/actions';

import {
  Page,
  Form,
  Card,
  FormLayout,
  Layout,
  TextField,
  Button,
  Banner,
} from '@shopify/polaris';

const TextInput = ({ meta, input, ...rest }) => {
  return (
    <TextField
      {...input}
      {...rest}
      error={meta.touched && meta.error ? meta.error : ''}
    />
  );
};

function Login({ handleSubmit }) {
  const { error: errorMessage, loggingIn, data: authData } = useSelector(
    (state) => state.auth,
  );
  const { state } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const previousRouteState = useMemo(() => {
    return state;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderError = useMemo(() => {
    if (errorMessage) {
      return (
        <div style={{ marginBottom: '25px' }}>
          <Banner status="critical" onDismiss={() => {}}>
            <p> {errorMessage} </p>
          </Banner>
        </div>
      );
    }
    return '';
  }, [errorMessage]);

  const onSubmit = (formProps) => {
    dispatch(actions.login(formProps));
  };

  useEffect(() => {
    if (authData) {
      history.push(previousRouteState?.from || '/customers');
    }
  }, [authData, history, previousRouteState]);

  return (
    <div style={{ marginTop: '50px' }}>
      <Page fullWidth>
        <Layout>
          <Layout.Section oneThird>{} </Layout.Section>
          <Layout.Section oneThird>
            <Card title="Login to continue" sectioned subdued>
              {renderError}
              <Form onSubmit={handleSubmit(onSubmit)}>
                <FormLayout>
                  <Field
                    name="email"
                    label="Email Address"
                    type="email"
                    autoComplete="email"
                    component={TextInput}
                    autoFocus
                  />
                  <Field
                    name="password"
                    label="Password"
                    type="password"
                    autoComplete="current-password"
                    component={TextInput}
                  />

                  <Button submit primary fullWidth loading={loggingIn}>
                    Login
                  </Button>
                </FormLayout>
              </Form>
            </Card>
          </Layout.Section>
          <Layout.Section oneThird>{}</Layout.Section>
        </Layout>
      </Page>
    </div>
  );
}

const validate = (formValues) => {
  const errors = {};

  if (!formValues.email) {
    errors.email = 'Email address is required';
  }

  if (!formValues.password) {
    errors.password = 'Password is required';
  }

  return errors;
};

export default reduxForm({
  form: 'login',
  validate,
})(Login);
