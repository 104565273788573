import {
  FETCH_TRADES,
  FETCH_TRADE_DETAILS,
  FETCH_TRADES_SUMMARY,
  SET_TRADE_STATUS,
} from './actionTypes';
import parseError from 'utils/parseError';
import { mapKeys } from 'lodash';

const INITIAL_STATE = {
  summary: [],
  data: {},
  meta: {},
  fetching: false,
  editing: false,
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_TRADES.pending:
    case FETCH_TRADE_DETAILS.pending:
    case FETCH_TRADES_SUMMARY.pending:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case FETCH_TRADES.rejected:
    case FETCH_TRADE_DETAILS.rejected:
    case FETCH_TRADES_SUMMARY.rejected:
      return {
        ...state,
        fetching: false,
        error: parseError(action.payload),
      };

    case FETCH_TRADES.fulfilled:
      return {
        ...state,
        fetching: false,
        data: mapKeys(action.payload.data.data, 'id'),
        meta: action.payload.data.meta,
      };

    case FETCH_TRADE_DETAILS.fulfilled:
      return {
        ...state,
        fetching: false,
        data: {
          ...state.data,
          [action.payload.data.id]: action.payload.data,
        },
      };

    case FETCH_TRADES_SUMMARY.fulfilled:
      return {
        ...state,
        fetching: false,
        summary: action.payload.map((res) => res.data),
      };

    case SET_TRADE_STATUS.pending:
      return {
        ...state,
        editing: true,
      };

    case SET_TRADE_STATUS.rejected:
      return {
        ...state,
        editing: false,
      };

    case SET_TRADE_STATUS.fulfilled:
      return {
        ...state,
        editing: false,
      };

    default:
      return state;
  }
};
