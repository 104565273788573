import React from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import {
  Page,
  Card,
  DataTable,
  Select,
  Stack,
  TextStyle,
  Tabs,
} from '@shopify/polaris';
import LoadingSpinner from 'components/LoadingSpinner';
import Pagination from 'components/Pagination';
import EmptyTable from 'components/EmptyTable';
import {
  fetchCustomerDevices,
  fetchCustomerMobileApplications,
  fetchCustomerWebSessions,
} from 'redux/customers/actions';
import { formatDateToReadableString } from 'utils/date';
import usePrevious from 'hooks/usePrevious';

export default function CustomerDevices() {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [selectedTab, setSelectedTab] = React.useState(0);

  const [search, setSearch] = React.useState({
    applications_page: '',
    devices_page: '',
    sessions_page: '',
  });

  const queryValues = queryString.parse(location.search);
  const previousApplicationPage = usePrevious(queryValues.applications_page);
  const previousDevicePage = usePrevious(queryValues.devices_page);
  const previousSessionPage = usePrevious(queryValues.sessions_page);

  const {
    devices: { [params.id]: customerDevices },
    sessions: { [params.id]: customerSessions },
    applications,
    devicesFetching,
    sessionsFetching,
    applicationsFetching,
  } = useSelector((state) => state.customers);

  const handleTabChange = React.useCallback(
    (selectedTabIndex) => setSelectedTab(selectedTabIndex),
    [],
  );

  function fetchPreviousPage(item) {
    return function () {
      if (item === 'application') {
        const page = applications?.meta?.page
          ? applications?.meta?.page - 1
          : 1;

        setSearch((previousSearch) => ({
          ...previousSearch,
          applications_page: page,
        }));
      }
      if (item === 'device') {
        const page = customerDevices?.meta?.page
          ? customerDevices?.meta?.page - 1
          : 1;

        setSearch((previousSearch) => ({
          ...previousSearch,
          devices_page: page,
        }));
      }
      if (item === 'session') {
        const page = customerSessions?.meta?.page
          ? customerSessions?.meta?.page - 1
          : 1;

        setSearch((previousSearch) => ({
          ...previousSearch,
          sessions_page: page,
        }));
      }
    };
  }

  function fetchNextPage(item) {
    return function () {
      if (item === 'application') {
        const page = applications?.meta?.page
          ? applications?.meta?.page + 1
          : 1;

        setSearch((previousSearch) => ({
          ...previousSearch,
          applications_page: page,
        }));
      }
      if (item === 'device') {
        const page = customerDevices?.meta?.page
          ? customerDevices?.meta?.page + 1
          : 1;

        setSearch((previousSearch) => ({
          ...previousSearch,
          devices_page: page,
        }));
      }
      if (item === 'session') {
        const page = customerSessions?.meta?.page
          ? customerSessions?.meta?.page + 1
          : 1;

        setSearch((previousSearch) => ({
          ...previousSearch,
          sessions_page: page,
        }));
      }
    };
  }

  const _devices =
    customerDevices?.data.map((device) => [
      formatDateToReadableString(new Date(device.created_at)),
      device.device_name,
      device.ip,
      device.location,
    ]) || [];

  const _sessions =
    customerSessions?.data.map((session) => [
      formatDateToReadableString(new Date(session.created_at)),
      session.device_name,
      session.ip,
      session.location,
    ]) || [];

  const _applications =
    applications?.data?.map((application) => [
      formatDateToReadableString(new Date(application.created_at)),
      application.device_name,
      application.ip,
      application.location,
    ]) || [];

  const tabs = [
    {
      id: 'applications',
      content: 'Mobile Applications',
      body: (
        <React.Fragment>
          <Card.Section>
            <Stack alignment="center" distribution="equalSpacing">
              <TextStyle>
                {applicationsFetching
                  ? 'Loading applications'
                  : `Showing ${
                      applications?.meta?.current_entries_size || 0
                    } application(s)`}
              </TextStyle>
              <Select
                label="Sort by"
                labelInline
                options={[{ label: 'Created at', value: 'created_at' }]}
              />
            </Stack>
          </Card.Section>
          {applicationsFetching ? (
            <LoadingSpinner />
          ) : (
            <React.Fragment>
              {applications?.data?.length > 0 ? (
                <DataTable
                  columnContentTypes={['text', 'text', 'text', 'numeric']}
                  headings={['Date', 'Device', 'IP address', 'Location']}
                  rows={_applications}
                  truncate={true}
                  verticalAlign="middle"
                />
              ) : (
                <Card.Section>
                  <EmptyTable resourceName="applications" />
                </Card.Section>
              )}
            </React.Fragment>
          )}
          <Pagination
            resource={applications?.data}
            handleNextPage={fetchNextPage('application')}
            handlePreviousPage={fetchPreviousPage('application')}
            meta={applications?.meta}
          />
        </React.Fragment>
      ),
      accessibilityLabel: 'Customers Applications',
      panelID: 'customer-applications',
    },
    {
      id: 'sessions',
      content: 'Web Sessions',
      body: (
        <React.Fragment>
          <Card.Section>
            <Stack alignment="center" distribution="equalSpacing">
              <TextStyle>
                {sessionsFetching
                  ? 'Loading sessions'
                  : `Showing ${
                      customerSessions?.meta?.current_entries_size || 0
                    } session(s)`}
              </TextStyle>
              <Select
                label="Sort by"
                labelInline
                options={[{ label: 'Created at', value: 'created_at' }]}
              />
            </Stack>
          </Card.Section>
          {sessionsFetching ? (
            <LoadingSpinner />
          ) : (
            <React.Fragment>
              {customerSessions?.data?.length > 0 ? (
                <DataTable
                  columnContentTypes={['text', 'text', 'text', 'numeric']}
                  headings={['Date', 'Device', 'IP address', 'Location']}
                  rows={_sessions}
                  truncate={true}
                  verticalAlign="middle"
                />
              ) : (
                <Card.Section>
                  <EmptyTable resourceName="sessions" />
                </Card.Section>
              )}
            </React.Fragment>
          )}
          <Pagination
            resource={customerSessions?.data}
            handleNextPage={fetchNextPage('session')}
            handlePreviousPage={fetchPreviousPage('session')}
            meta={customerSessions?.meta}
          />
        </React.Fragment>
      ),
      accessibilityLabel: 'Customers Sessions',
      panelID: 'customer-sessions',
    },
    {
      id: 'devices',
      content: 'Confirmed Devices',
      body: (
        <React.Fragment>
          <Card.Section>
            <Stack alignment="center" distribution="equalSpacing">
              <TextStyle>
                {devicesFetching
                  ? 'Loading devices'
                  : `Showing ${
                      customerDevices?.meta?.current_entries_size || 0
                    } device(s)`}
              </TextStyle>
              <Select
                label="Sort by"
                labelInline
                options={[{ label: 'Created at', value: 'created_at' }]}
              />
            </Stack>
          </Card.Section>
          {devicesFetching ? (
            <LoadingSpinner />
          ) : (
            <React.Fragment>
              {customerDevices?.data?.length > 0 ? (
                <DataTable
                  columnContentTypes={['text', 'text', 'text', 'numeric']}
                  headings={['Date', 'Device', 'IP address', 'Location']}
                  rows={_devices}
                  truncate={true}
                  verticalAlign="middle"
                />
              ) : (
                <Card.Section>
                  <EmptyTable resourceName="devices" />
                </Card.Section>
              )}
            </React.Fragment>
          )}
          <Pagination
            resource={customerDevices?.data}
            handleNextPage={fetchNextPage('device')}
            handlePreviousPage={fetchPreviousPage('device')}
            meta={customerDevices?.meta}
          />
        </React.Fragment>
      ),
      accessibilityLabel: 'Customers Devices',
      panelID: 'customer-devices',
    },
  ];

  React.useEffect(
    React.useCallback(() => {
      const searchQuery = queryString.stringify(search);
      history.push({
        pathname: location.pathname,
        search: searchQuery,
      });
    }, [history, location.pathname, search]),
    [search],
  );

  React.useEffect(
    React.useCallback(() => {
      const queryValues = queryString.parse(location.search);
      const { applications_page, devices_page, sessions_page } = queryValues;

      if (applications_page !== previousApplicationPage) {
        dispatch(fetchCustomerMobileApplications(params.id, applications_page));
      }

      if (devices_page !== previousDevicePage) {
        dispatch(fetchCustomerDevices(params.id, devices_page));
      }

      if (sessions_page !== previousSessionPage) {
        dispatch(fetchCustomerWebSessions(params.id, sessions_page));
      }
    }, [
      location.search,
      dispatch,
      params.id,
      previousApplicationPage,
      previousDevicePage,
      previousSessionPage,
    ]),
    [location.search, params.id],
  );

  return (
    <Page
      title="Customer Devices"
      breadcrumbs={[
        {
          content: 'Customer',
          url: `/customers/${params.id}`,
        },
      ]}
    >
      <Card>
        <Tabs tabs={tabs} selected={selectedTab} onSelect={handleTabChange}>
          {tabs[selectedTab].body}
        </Tabs>
      </Card>
    </Page>
  );
}
