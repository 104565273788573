import React from 'react';
import { Card, Link, Stack, Heading, TextStyle } from '@shopify/polaris';
import AvatarInitials from 'components/AvatarInitials';

export default function TradeTraders({ buyer, seller }) {
  return (
    <>
      <Card.Section
        title={
          <Stack distribution="equalSpacing">
            <Stack.Item>
              <Heading>Buyer</Heading>
            </Stack.Item>
            <Stack.Item>
              <Link url={`/customers/${buyer.id}`}>
                <AvatarInitials firstName={buyer.firstname} size="small" />
              </Link>
            </Stack.Item>
          </Stack>
        }
      >
        <Stack spacing="tight" vertical>
          <Link url={`/customers/${buyer.id}`}>{buyer.username}</Link>
          <TextStyle>{`${buyer.firstname} ${buyer.lastname}`}</TextStyle>
          <TextStyle>{buyer.email}</TextStyle>
          <TextStyle>
            {buyer.trades_count} Trades, {buyer.average_rating} /5.0(
            {buyer.ratings_count}) Rating
          </TextStyle>
        </Stack>
      </Card.Section>
      <Card.Section
        title={
          <Stack distribution="equalSpacing">
            <Stack.Item>
              <Heading>Seller</Heading>
            </Stack.Item>
            <Stack.Item>
              <Link url={`/customers/${seller.id}`}>
                <AvatarInitials firstName={seller.firstname} size="small" />
              </Link>
            </Stack.Item>
          </Stack>
        }
      >
        <Stack spacing="tight" vertical>
          <Link url={`/customers/${seller.id}`}>{seller.username}</Link>
          <TextStyle> {`${seller.firstname} ${seller.lastname}`}</TextStyle>
          <TextStyle>{seller.email}</TextStyle>
          <TextStyle>
            {seller.trades_count} Trades, {seller.average_rating} /5.0(
            {seller.ratings_count}) Rating
          </TextStyle>
        </Stack>
      </Card.Section>
    </>
  );
}
