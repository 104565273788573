function getInitials(firstName, lastName) {
  if (firstName && lastName) {
    return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
  } else if (firstName) {
    const [_firstName, _lastName] = firstName.split(/\s+/);
    if (_firstName && _lastName) {
      return `${_firstName.charAt(0)}${_lastName.charAt(0)}`.toUpperCase();
    }
    return firstName.charAt(0);
  } else if (lastName) {
    return lastName.charAt(0);
  }
  return '';
}

function capitalize(str) {
  return `${str.charAt(0).toUpperCase()}${str.slice(1).toLowerCase()}`;
}

function getNamePronoun(name) {
  if (!name || typeof name !== 'string') {
    return '';
  }

  if (name.charAt(name.length - 1).toLowerCase() === 's') {
    return `${capitalize(name)}'`;
  }
  return `${capitalize(name)}'s`;
}

function formatBankAccountNumberString(acctNumber) {
  let formattedString = '';
  for (let i = 0; i < acctNumber.length; i++) {
    formattedString = formattedString + acctNumber.charAt(i);
    if ((i + 1) % 4 === 0 && i > 0 && i !== acctNumber.length - 1) {
      formattedString = formattedString + '  ';
    }
  }
  return formattedString;
}

/**
 *
 * @param {String } string
 * @param { String } character
 */
function splitString(string, character = ',') {
  return string.split(character);
}

export {
  getInitials,
  capitalize,
  formatBankAccountNumberString,
  getNamePronoun,
  splitString,
};
