import React, { useState } from 'react';
import { Modal, Button, Form, Select, Stack } from '@shopify/polaris';
import LoadingSpinner from 'components/LoadingSpinner';
import EmptyTable from 'components/EmptyTable';

function CustomerActivateInactiveWalletModal({
  open,
  handleClose,
  name,
  editing,
  inactiveWallets = [],
  inactiveWalletsFetching,
  handleActivateInactiveWallet,
}) {
  const walletOptions = inactiveWallets.map((wallet) => ({
    label: `${wallet.name} (${wallet.code})`,
    value: wallet.code,
  }));

  const [selectedInactiveWallet, setSelectedInactiveWallet] = useState('');

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={`Add new wallet for ${name}`}
    >
      {inactiveWalletsFetching ? (
        <Modal.Section>
          <LoadingSpinner />
        </Modal.Section>
      ) : inactiveWallets?.length > 0 ? (
        <Form>
          <Modal.Section>
            <Select
              label="Available wallets"
              placeholder="Select wallet"
              options={walletOptions}
              onChange={setSelectedInactiveWallet}
              value={selectedInactiveWallet}
            />
          </Modal.Section>
          <Modal.Section>
            <Stack distribution="trailing">
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                primary
                disabled={!selectedInactiveWallet}
                onClick={() =>
                  handleActivateInactiveWallet(selectedInactiveWallet)
                }
                loading={editing}
              >
                Add Wallet
              </Button>
            </Stack>
          </Modal.Section>
        </Form>
      ) : (
        <EmptyTable resourceName="wallets" />
      )}
    </Modal>
  );
}

export default CustomerActivateInactiveWalletModal;
