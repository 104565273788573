import Deposits from '../pages/Deposits';
import ViewDeposit from '../pages/Deposits/pages/ViewDeposit';
import Trades from '../pages/Trades';
import ViewTrade from '../pages/Trades/pages/ViewTrade';
import Ads from '../pages/Adverts';
import ViewAd from '../pages/Adverts/pages/ViewAd';
import MarketPlaceSummary from '../pages/MarketPlaceSummary';
import Payouts from '../pages/Payouts';
import ViewPayouts from '../pages/Payouts/pages/ViewPayouts';
import Orders from '../pages/Orders';
import ViewOrder from '../pages/Orders/pages/ViewOrder';
import Customers from '../pages/Customers';
import ViewCustomer from '../pages/Customers/pages/ViewCustomer';
import CustomerActivities from '../pages/Customers/pages/CustomerActivities';
import CustomerDevices from '../pages/Customers/pages/CustomerDevices';
import CustomerAccountStatement from '../pages/Customers/pages/CustomerAccountStatement';
import CustomerRecurringOrders from '../pages/Customers/pages/CustomerRecurringOrders';
import Verifications from '../pages/Verifications';
import ViewVerification from '../pages/Verifications/pages/ViewVerification';
import Documents from '../pages/Documents';
import ViewDocument from '../pages/Documents/pages/ViewDocument';
import Referrals from '../pages/Referrals';
import ViewReferral from '../pages/Referrals/pages/ViewReferral';
import Books from '../pages/Books';
import ViewBook from '../pages/Books/pages/ViewBook';
import Reports from '../pages/Reports';
import ViewReport from '../pages/Reports/pages/ViewReport';
import Settings from '../pages/Settings';
import AccountSettings from '../pages/Settings/pages/AccountSettings';
import AccountAssets from '../pages/Settings/pages/AccountAssets';
import ViewAsset from '../pages/Settings/pages/AccountAssets/pages/ViewAsset';
import CreateAccount from '../pages/Settings/pages/AccountSettings/pages/CreateAccount';
import ViewAccount from '../pages/Settings/pages/AccountSettings/pages/ViewAccount';

export default [
  {
    path: '/settings/accounts/new',
    component: CreateAccount,
    exact: true,
  },
  {
    path: '/settings/accounts/:id',
    component: ViewAccount,
  },
  {
    path: '/settings/accounts',
    component: AccountSettings,
    exact: true,
  },
  {
    path: '/settings/assets/:code',
    component: ViewAsset,
  },
  {
    path: '/settings/assets',
    component: AccountAssets,
    exact: true,
  },
  {
    path: '/settings',
    component: Settings,
    exact: true,
  },
  {
    path: '/marketplace/summary',
    component: MarketPlaceSummary,
    exact: true,
  },
  {
    path: '/marketplace/trades/:id',
    component: ViewTrade,
  },
  {
    path: '/marketplace/trades',
    component: Trades,
    exact: true,
  },
  {
    path: '/marketplace/ads/:id',
    component: ViewAd,
  },
  {
    path: '/marketplace/ads',
    component: Ads,
    exact: true,
  },
  {
    path: '/customers/referrals/:id',
    component: ViewReferral,
  },
  {
    path: '/customers/referrals',
    component: Referrals,
    exact: true,
  },
  {
    path: '/customers/documents/:customer_id/:document_id',
    component: ViewDocument,
  },
  {
    path: '/customers/documents',
    component: Documents,
    exact: true,
  },
  {
    path: '/customers/verifications/:id',
    component: ViewVerification,
  },
  {
    path: '/customers/verifications',
    component: Verifications,
    exact: true,
  },
  {
    path: '/customers/:id/account/:currency',
    component: CustomerAccountStatement,
  },
  {
    path: '/customers/:id/devices',
    component: CustomerDevices,
    exact: true,
  },
  {
    path: '/customers/:id/activities',
    component: CustomerActivities,
    exact: true,
  },
  {
    path: '/customers/:id/recurring-orders',
    component: CustomerRecurringOrders,
    exact: true,
  },
  {
    path: '/customers/:id',
    component: ViewCustomer,
    exact: true,
  },
  {
    path: '/customers',
    component: Customers,
    exact: true,
  },
  {
    path: '/deposits/:base/:app/:id',
    component: ViewDeposit,
    exact: true,
  },
  {
    path: '/deposits/:base/:app',
    component: Deposits,
    exact: true,
  },
  {
    path: '/payouts/:app/:id',
    component: ViewPayouts,
    exact: true,
  },
  {
    path: '/payouts/:app',
    component: Payouts,
    exact: true,
  },
  {
    path: '/books/:id',
    component: ViewBook,
  },
  {
    path: '/books',
    component: Books,
    exact: true,
  },
  {
    path: '/orders/reports/:product',
    component: ViewReport,
    exact: true,
  },
  {
    path: '/orders/reports',
    component: Reports,
    exact: true,
  },
  {
    path: '/orders/:app/:id',
    component: ViewOrder,
    exact: true,
  },
  {
    path: '/orders/:app',
    component: Orders,
    exact: true,
  },
];
