import {LOGIN_USER, LOGOUT_USER, CLEAR_ERROR} from './actionTypes';
import storage from '../../utils/storage';
import parseError from '../../utils/parseError';

const INITIAL_STATE = {
  data: storage.get('auth'),
  loggingIn: false,
  error: null,
};

export default function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGIN_USER.pending:
      return {
        ...state,
        error: null,
        loggingIn: true,
      };
    case LOGIN_USER.rejected:
      return {
        ...state,
        loggingIn: false,
        error: parseError(action.payload),
      };
    case LOGIN_USER.fulfilled:
      return {
        ...state,
        loggingIn: false,
        data: action.payload.data,
      };
    case LOGOUT_USER.fulfilled:
      return {
        ...state,
        data: null,
      };
    case CLEAR_ERROR.fulfilled:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
}
