import React from 'react';
import { Stack, TextStyle } from '@shopify/polaris';

function ReportsHeader() {
  return (
    <div style={{ padding: '1.2rem 2rem', border: '1px solid #E1E3E5' }}>
      <Stack distribution="equalSpacing" alignment="center">
        <TextStyle>Product</TextStyle>
        <div style={{ width: 150, paddingLeft: 10 }}>
          <Stack distribution="fillEvenly" alignment="center">
            <TextStyle>Buy</TextStyle>
            <TextStyle>Sell</TextStyle>
          </Stack>
        </div>
      </Stack>
    </div>
  );
}

export default ReportsHeader;
