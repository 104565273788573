import api from 'utils/api';
import {
  FETCH_REFERRALS,
  FETCH_REFERRALS_DETAILS,
  APPROVE_REFERRAL,
} from './actionTypes';

/**
 * Type definitions
 *
 * @typedef { import('../types').GenericDispatchAction } GenericDispatchAction
 */

/**
 *
 * @param {string=} queryString
 * @returns { {type : string; payload : { queryString: string } }}
 */

export const fetchReferrals = (queryString = '') => {
  return { type: FETCH_REFERRALS.default, payload: { queryString } };
};

/**
 *
 * @param {string} id
 * @returns { GenericDispatchAction }
 */

export const fetchReferralsDetails = (id) => (dispatch) => {
  const payload = api.get(`/v1.1/referral/${id}`);
  return dispatch({ type: FETCH_REFERRALS_DETAILS.default, payload });
};

/**
 *
 * @param {string} id
 * @returns { GenericDispatchAction }
 */
export const approveReferral = (id) => (dispatch) => {
  const payload = api.put(`/v1.1/referral/${id}`);
  return dispatch({ type: APPROVE_REFERRAL.default, payload }).then((res) => {
    dispatch(fetchReferralsDetails(id));
    return res;
  });
};
