import api from 'utils/api';
import {
  FETCH_PAYOUTS,
  FETCH_PAYOUT,
  UPDATE_PAYOUT_STATUS,
  RETRY_PAYOUT,
  SET_CURRENT_ACTION,
  CLEAR_MESSAGE,
} from './actionTypes';

/**
 * Type definitions
 *
 * @typedef { import('../types').GenericDispatchAction } GenericDispatchAction
 */

/**
 *
 * @param { string } app
 * @param { string } query
 * @returns { {type : string; payload : { app : string; query : string }} }
 */

export const fetchPayouts = (app, query) => {
  return { type: FETCH_PAYOUTS.default, payload: { app, query } };
};

/**
 *
 * @param {string} app
 * @param { string } id
 * @returns { GenericDispatchAction }
 */

export const fetchPayout = (app, id) => (dispatch) => {
  return dispatch({
    type: FETCH_PAYOUT.default,
    payload: api.get(`/v1.1/payouts/${app}/${id}`),
  });
};

/**
 *
 * @param {string} app
 * @param {string} id
 * @param {object} options
 * @param { boolean } options.status
 * @param { number } options.fees
 * @param { string } options.hash
 * @returns { GenericDispatchAction }
 */
export const setPayoutStatus = (app, id, { status, fees, hash }) => (
  dispatch,
) => {
  return dispatch({
    type: UPDATE_PAYOUT_STATUS.default,
    payload: api.post(`/v1.1/payouts/${app}/${id}/status`, {
      status,
      fees,
      hash,
    }),
  }).then((res) => {
    dispatch(fetchPayout(app, id));
    return res;
  });
};

/**
 *
 * @param {string} app
 * @param { string } id
 * @returns { GenericDispatchAction }
 */
export const retryPayout = (app, id) => (dispatch) => {
  dispatch(setAction('retry'));
  return dispatch({
    type: RETRY_PAYOUT.default,
    payload: api.post(`/v1.1/payouts/${app}/${id}/retry`),
  }).then((res) => {
    dispatch(fetchPayout(app, id));
    return res;
  });
};

/**
 *
 * @param { string } action
 * @returns { GenericDispatchAction }
 */
export const setAction = (action) => {
  return (dispatch) =>
    dispatch({
      type: SET_CURRENT_ACTION.fulfilled,
      payload: action,
    });
};

/**
 * @returns { GenericDispatchAction }
 */

export const clearMessage = () => {
  return (dispatch) =>
    dispatch({
      type: CLEAR_MESSAGE.fulfilled,
    });
};
