import React from 'react';
import queryString from 'query-string';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Page,
  Layout,
  Stack,
  Card,
  TextStyle,
  DataTable,
  Select,
} from '@shopify/polaris';
import useQueryParams from 'hooks/useQueryParams';
import LoadingSpinner from 'components/LoadingSpinner';
import Pagination from 'components/Pagination';
import EmptyTable from 'components/EmptyTable';
import CustomerAccountStatementSummary from './components/CustomerAccountStatementSummary';
import {
  fetchCustomerAccountStatementSummary,
  fetchCustomerAccountStatement,
  clearCustomerAccountStatement,
} from 'redux/customers/actions';
import { formatDateToReadableString } from 'utils/date';
import AccountStatementRanges from 'data/accountStatementRanges.json';
import AccountStatementCategories from 'data/accountStatementCategories.json';
import styled from 'styled-components';

function CustomerAccountStatement() {
  const dispatch = useDispatch();
  const params = useParams();

  const { currency, id: customerId } = params;

  const { queryParams, updateQueryParams } = useQueryParams(intialQueryParams);

  const handleNextPage = () =>
    updateQueryParams('page', meta?.page ? meta.page + 1 : 1);

  const handlePreviousPage = () =>
    updateQueryParams('page', meta?.page ? meta.page - 1 : 1);

  const {
    accountStatement: { data, meta, summary, fetching },
  } = useSelector((state) => state.customers);

  const statements =
    data?.map((statement) => [
      formatDateToReadableString(new Date(statement.created_at)),
      statement.description || 'N/A',
      statement.credit || '00.0',
      statement.debit || '00.0',
      statement.balance || '00.0',
    ]) || [];

  const currentPageStartingIndex =
    (meta?.per_page || 0) * (meta?.page ? meta?.page - 1 : 0);
  let currentPageClosingIndex = (meta?.per_page || 0) * (meta?.page || 0);

  currentPageClosingIndex =
    meta?.current_entries_size < meta?.per_page
      ? currentPageStartingIndex + meta?.current_entries_size
      : currentPageClosingIndex;

  const currentPageInfo = `Showing ${currentPageStartingIndex}-${currentPageClosingIndex} of ${meta?.total_entries_size} transaction(s)`;

  React.useEffect(
    React.useCallback(() => {
      dispatch(clearCustomerAccountStatement());
      return () => {
        dispatch(clearCustomerAccountStatement());
      };
    }, [dispatch]),
    [],
  );

  React.useEffect(
    React.useCallback(() => {
      const query = `?${queryString.stringify(queryParams)}`;
      dispatch(
        fetchCustomerAccountStatement({
          customerId,
          currency,
          query,
        }),
      );
      dispatch(
        fetchCustomerAccountStatementSummary({
          customerId,
          currency,
          query,
        }),
      );
    }, [currency, customerId, dispatch, queryParams]),
    [currency, customerId, queryParams],
  );

  return (
    <Container>
      <Page
        title={`
    ${currency} Account Statement
  `}
        fullWidth
        breadcrumbs={[{ content: 'Customer', url: `/customers/${customerId}` }]}
      >
        <Layout>
          <Layout.Section>
            <CustomerAccountStatementSummary {...{ summary }} />
          </Layout.Section>
          <Layout.Section>
            <Card sectioned={false}>
              <Card.Section>
                <Stack alignment="center" distribution="equalSpacing">
                  <TextStyle>
                    {fetching ? 'Loading transactions' : currentPageInfo}
                  </TextStyle>
                  <Stack spacing="tight">
                    <Select
                      label="Range"
                      labelInline
                      value={queryParams.range}
                      onChange={(value) => {
                        updateQueryParams('range', value);
                      }}
                      options={AccountStatementRanges}
                    />
                    <Select
                      label="Category"
                      labelInline
                      value={queryParams.category}
                      onChange={(value) => {
                        updateQueryParams('category', value);
                      }}
                      options={[
                        { label: 'All', value: '' },
                        ...AccountStatementCategories,
                      ]}
                    />
                  </Stack>
                </Stack>
              </Card.Section>
              {fetching ? (
                <LoadingSpinner />
              ) : (
                <React.Fragment>
                  {statements.length > 0 ? (
                    <DataTable
                      columnContentTypes={[
                        'text',
                        'text',
                        'text',
                        'text',
                        'numeric',
                      ]}
                      headings={[
                        'Date',
                        'Description',
                        'Credit',
                        'Debit',
                        'Balance',
                      ]}
                      rows={statements}
                      truncate={true}
                    />
                  ) : (
                    <EmptyTable resourceName="transactions" />
                  )}
                </React.Fragment>
              )}
              <Pagination
                resource={statements}
                meta={meta}
                handleNextPage={handleNextPage}
                handlePreviousPage={handlePreviousPage}
              />
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    </Container>
  );
}

const Container = styled.div`
  .Polaris-DataTable__Table thead tr {
    border-top: none !important;
  }
`;

const intialQueryParams = {
  page: 1,
  range: 'this_month',
  category: '',
};

export default CustomerAccountStatement;
