import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import authReducer from './auth';
import profileReducer from './profile';
import payoutsReducer from './payouts';
import depositsReducer from './deposits';
import ordersReducer from './orders';
import reportsReducer from './reports';
import customersReducer from './customers';
import verificationsReducer from './verifications';
import documentsReducer from './documents';
import adsReducer from './ads';
import tradesReducer from './trades';
import booksReducer from './books';
import referralsReducer from './referrals';
import staffReducer from './staff';
import permissionsReducer from './permissions';
import assetsReducer from './assets';
import inviteReducer from './invite';
import toastReducer from './toast';

const reducers = combineReducers({
  auth: authReducer,
  form: formReducer,
  profile: profileReducer,
  payouts: payoutsReducer,
  deposits: depositsReducer,
  orders: ordersReducer,
  reports: reportsReducer,
  customers: customersReducer,
  verifications: verificationsReducer,
  documents: documentsReducer,
  ads: adsReducer,
  trades: tradesReducer,
  books: booksReducer,
  referrals: referralsReducer,
  staff: staffReducer,
  permissions: permissionsReducer,
  assets: assetsReducer,
  invite: inviteReducer,
  toast: toastReducer,
});

export default reducers;
