import React, { useEffect } from 'react';
import {
  Page,
  Card,
  Layout,
  FormLayout,
  TextField,
  Checkbox,
  TextStyle,
  Stack,
  Button,
  Select,
} from '@shopify/polaris';
import { Formik } from 'formik';
import { useRouteMatch, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchBookDetails, updateBookDetails } from 'redux/books/actions';
import LoadingPage from 'components/LoadingPage';
import { capitalize } from 'utils/strings';
import { editBookDetailsSchema } from 'utils/validationSchemas';
import { PreviousLocationContext } from 'providers/PreviousLocationProvider';

const formatBookValues = (book) =>
  Object.fromEntries(
    Object.entries(book).map(([key, value]) =>
      typeof value === 'boolean' ? [key, value] : [key, String(value)],
    ),
  );

const orderExchangeOptions = [
  { value: 'luno', label: 'Luno' },
  { value: 'binance1', label: 'Binance 1' },
  { value: 'binance2', label: 'Binance 2' },
];

function ViewBook() {
  const match = useRouteMatch();
  const dispatch = useDispatch();

  const { previousLocation } = React.useContext(PreviousLocationContext);

  const {
    fetching,
    error,
    data: { [match.params.id]: book },
    editing,
  } = useSelector((state) => state.books);

  function handleSubmit({
    buy_mark_up,
    sell_mark_down,
    unliquidated_buy_allowance,
    unliquidated_sell_allowance,
    minimum_purchase,
    minimum_sale,
    buy,
    sell,
    limit_order_exchange,
    market_order_exchange,
    market_order_threshold,
  }) {
    dispatch(
      updateBookDetails(match.params.id, {
        buy_mark_up: String(buy_mark_up),
        sell_mark_down: String(sell_mark_down),
        unliquidated_buy_allowance: String(unliquidated_buy_allowance),
        unliquidated_sell_allowance: String(unliquidated_sell_allowance),
        minimum_purchase: String(minimum_purchase),
        minimum_sale: String(minimum_sale),
        buy,
        sell,
        limit_order_exchange,
        market_order_exchange,
        market_order_threshold: String(market_order_threshold),
      }),
    );
  }

  useEffect(() => {
    dispatch(fetchBookDetails(match.params.id));
  }, [match.params.id, dispatch]);

  if (error) {
    return <Redirect to="/books" />;
  }

  if (fetching && !book) {
    return <LoadingPage />;
  }

  return book ? (
    <Page
      title={book.product}
      breadcrumbs={[
        {
          content: 'Books',
          url: previousLocation
            ? `${previousLocation.pathname}${previousLocation.search}`
            : '/books',
        },
      ]}
    >
      <Formik
        initialValues={formatBookValues(book)}
        validationSchema={editBookDetailsSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          touched,
          errors,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => {
          const handleInputChange = (name) => (value) => {
            setFieldValue(name, value);
          };

          return (
            <form onSubmit={handleSubmit}>
              <Layout>
                <Layout.AnnotatedSection
                  title="EXCHANGE DETAILS"
                  description="Sadly you can’t edit any of this. If you really
want to change something here. contact 
one of the devs."
                >
                  <Card sectioned>
                    <div style={{ maxWidth: 400 }}>
                      <FormLayout>
                        <TextField
                          label="Product"
                          value={book.product}
                          readOnly
                        />
                        <TextField
                          label="Exchange"
                          value={capitalize(book.exchange)}
                          readOnly
                        />
                      </FormLayout>
                    </div>
                  </Card>
                </Layout.AnnotatedSection>
                <Layout.AnnotatedSection
                  title="MARK UP AND DOWN"
                  description="Mark up buy prices and markdown sell price.
The modified prices are the prices 
customers will buy or sell at."
                >
                  <Card sectioned>
                    <div style={{ maxWidth: 400 }}>
                      <FormLayout>
                        <TextField
                          label="Buy - Mark Up (%)"
                          type="number"
                          name="buy_mark_up"
                          id="buy_mark_up"
                          value={values.buy_mark_up}
                          onChange={handleInputChange('buy_mark_up')}
                          onBlur={handleBlur}
                          error={
                            errors.buy_mark_up && touched.buy_mark_up
                              ? errors.buy_mark_up
                              : ''
                          }
                        />
                        <TextField
                          label="Sell - Mark Down (%)"
                          type="number"
                          name="sell_mark_down"
                          id="sell_mark_down"
                          value={values.sell_mark_down}
                          onChange={handleInputChange('sell_mark_down')}
                          onBlur={handleBlur}
                          error={
                            errors.sell_mark_down && touched.sell_mark_down
                              ? errors.sell_mark_down
                              : ''
                          }
                        />
                      </FormLayout>
                    </div>
                  </Card>
                </Layout.AnnotatedSection>
                <Layout.AnnotatedSection
                  title="MINIMUM SALE & PURCHASES"
                  description="This is the minimum volume a customer can 
purchase or sell at a time."
                >
                  <Card sectioned>
                    <div style={{ maxWidth: 400 }}>
                      <FormLayout>
                        <TextField
                          label={`Minimum Purchase (${book.base})`}
                          type="number"
                          name="minimum_purchase"
                          id="minimum_purchase"
                          value={values.minimum_purchase}
                          onChange={handleInputChange('minimum_purchase')}
                          onBlur={handleBlur}
                          error={
                            errors.minimum_purchase && touched.minimum_purchase
                              ? errors.minimum_purchase
                              : ''
                          }
                        />
                        <TextField
                          label={`Minimum Sale (${book.base})`}
                          type="number"
                          name="minimum_sale"
                          id="minimum_sale"
                          value={values.minimum_sale}
                          onChange={handleInputChange('minimum_sale')}
                          onBlur={handleBlur}
                          error={
                            errors.minimum_sale && touched.minimum_sale
                              ? errors.minimum_sale
                              : ''
                          }
                        />
                      </FormLayout>
                    </div>
                  </Card>
                </Layout.AnnotatedSection>
                <Layout.AnnotatedSection
                  title="LIQUATION ALLOWANCE"
                  description="This is the maximum amount of of the base 
asset we can afford to hold unliquidated before
sale or purcahse of that asset is suspended."
                >
                  <Card sectioned>
                    <div style={{ maxWidth: 400 }}>
                      <FormLayout>
                        <TextField
                          label={`Purchase (${book.counter})`}
                          name="unliquidated_buy_allowance"
                          type="number"
                          id="unliquidated_buy_allowance"
                          value={values.unliquidated_buy_allowance}
                          onChange={handleInputChange(
                            'unliquidated_buy_allowance',
                          )}
                          onBlur={handleBlur}
                          error={
                            errors.unliquidated_buy_allowance &&
                            touched.unliquidated_buy_allowance
                              ? errors.unliquidated_buy_allowance
                              : ''
                          }
                        />
                        <TextField
                          label="Sale"
                          type="number"
                          name="unliquidated_sell_allowance"
                          id="unliquidated_sell_allowance"
                          value={values.unliquidated_sell_allowance}
                          onChange={handleInputChange(
                            'unliquidated_sell_allowance',
                          )}
                          onBlur={handleBlur}
                          error={
                            errors.unliquidated_sell_allowance &&
                            touched.unliquidated_sell_allowance
                              ? errors.unliquidated_sell_allowance
                              : ''
                          }
                        />
                      </FormLayout>
                    </div>
                  </Card>
                </Layout.AnnotatedSection>
                <Layout.AnnotatedSection
                  title="STATUS"
                  description="Activate or deactive both or a single side of
the book. Prices will still be updated but no
orders will be taken."
                >
                  <Card sectioned>
                    <Stack spacing="loose" vertical>
                      <Stack vertical spacing="tight">
                        <Checkbox
                          label="Buy"
                          checked={values.buy}
                          onChange={(newChecked) =>
                            handleInputChange('buy')(newChecked)
                          }
                        />
                        <TextStyle variation="subdued">
                          {`Allow customers buy ${book.base} at the current set price`}
                        </TextStyle>
                      </Stack>
                      <Stack vertical spacing="tight">
                        <Checkbox
                          label="Sell"
                          checked={values.sell}
                          onChange={(newChecked) =>
                            handleInputChange('sell')(newChecked)
                          }
                        />
                        <TextStyle variation="subdued">
                          {`Allow customers sell ${book.base} at the current set price`}
                        </TextStyle>
                      </Stack>
                    </Stack>
                  </Card>
                </Layout.AnnotatedSection>
                <Layout.AnnotatedSection title="ORDER EXCHANGE">
                  <Card sectioned>
                    <div style={{ maxWidth: 400 }}>
                      <FormLayout>
                        <Select
                          label="Limit Order Exchange"
                          name="limit_order_exchange"
                          placeholder="-- Pick an a Limit Order Exchange --"
                          options={orderExchangeOptions}
                          onChange={handleInputChange('limit_order_exchange')}
                          onBlur={handleBlur}
                          value={values.limit_order_exchange}
                          error={
                            errors.limit_order_exchange &&
                            touched.limit_order_exchange
                              ? errors.limit_order_exchange
                              : ''
                          }
                        />
                        <Select
                          label="Market Order Exchange"
                          name="market_order_exchange"
                          placeholder="-- Pick an a Market Order Exchange --"
                          options={orderExchangeOptions}
                          onChange={handleInputChange('market_order_exchange')}
                          onBlur={handleBlur}
                          value={values.market_order_exchange}
                          error={
                            errors.market_order_exchange &&
                            touched.market_order_exchange
                              ? errors.market_order_exchange
                              : ''
                          }
                        />
                        <TextField
                          label="Market Order Threshold"
                          type="number"
                          name="market_order_threshold"
                          id="market_order_threshold"
                          value={values.market_order_threshold}
                          onChange={handleInputChange('market_order_threshold')}
                          onBlur={handleBlur}
                          error={
                            errors.market_order_threshold &&
                            touched.market_order_threshold
                              ? errors.market_order_threshold
                              : ''
                          }
                        />
                      </FormLayout>
                    </div>
                  </Card>
                </Layout.AnnotatedSection>
                <Layout.AnnotatedSection>
                  <Stack distribution="trailing">
                    <Button
                      primary
                      loading={editing}
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                  </Stack>
                </Layout.AnnotatedSection>
              </Layout>
            </form>
          );
        }}
      </Formik>
    </Page>
  ) : null;
}

export default ViewBook;
