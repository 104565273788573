const getReferralsFetchingSelector = (state) => state.referrals.fetching;
const getReferralsEditLoadingSelector = (state) => state.referrals.editing;
const getReferralDetailsSelector = (state, id) => state.referrals.data[id];
const getReferralsSelector = (state) => Object.values(state.referrals.data);
const getReferralsMetaSelector = (state) => state.referrals.meta;
const getReferralsErrorSelector = (state) => state.referrals.error;

export {
  getReferralsFetchingSelector,
  getReferralsEditLoadingSelector,
  getReferralsSelector,
  getReferralsMetaSelector,
  getReferralDetailsSelector,
  getReferralsErrorSelector,
};
