import React from 'react';
import { Card } from '@shopify/polaris';

import AccountStaff from './AccountStaff';

export default function AccountStaffs({ staffs, path }) {
  return (
    <Card
      sectioned
      title="Staff accounts"
      actions={[
        { content: 'Log out all staff accounts' },
        { content: 'Add staff account', url: `${path}/new` },
      ]}
    >
      {staffs.map((staff) =>
        staff.account_owner ? null : (
          <AccountStaff staff={staff} key={staff.id} />
        ),
      )}
    </Card>
  );
}
