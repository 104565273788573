import React from 'react';
import { TextStyle, Stack, Card } from '@shopify/polaris';
import { capitalize } from 'utils/strings';
import { formatNumberToMoney } from 'utils/money';

export default function OrderDetails({ order, app }) {
  return (
    <Card title="Order details">
      <Card.Section>
        <Stack vertical spacing="tight">
          <Stack distribution="equalSpacing" alignment="center" spacing="none">
            <TextStyle>{capitalize(order?.side || '')}</TextStyle>
            <TextStyle>
              {formatNumberToMoney(
                app === 'chain' ? order?.volume : order?.base_amount,
                {
                  category: order?.base,
                  currencyCode: order?.base,
                  codePosition: 'back',
                  maximumFractionDigits: 10,
                },
              )}
            </TextStyle>
          </Stack>
          <Stack distribution="equalSpacing" alignment="center" spacing="none">
            <TextStyle>Fee</TextStyle>
            <TextStyle>
              {formatNumberToMoney(order?.fee, {
                category: order?.base,
                currencyCode: order?.base,
                codePosition: 'back',
                maximumFractionDigits: 10,
              })}
            </TextStyle>
          </Stack>
          <Stack distribution="equalSpacing" alignment="center" spacing="none">
            <TextStyle>Price</TextStyle>
            <TextStyle>
              {formatNumberToMoney(order?.price, {
                category: order?.counter,
                currencyCode: order?.counter,
                codePosition: 'back',
                maximumFractionDigits: 10,
              })}{' '}
            </TextStyle>
          </Stack>
          {app !== 'chain' && (
            <Stack
              distribution="equalSpacing"
              alignment="center"
              spacing="none"
            >
              <TextStyle variation="strong">Paid to customer</TextStyle>
              <TextStyle variation="strong">
                {formatNumberToMoney(order?.quote_amount, {
                  category: order?.counter,
                  currencyCode: order?.counter,
                  codePosition: 'back',
                  maximumFractionDigits: 10,
                })}
              </TextStyle>
            </Stack>
          )}
          {app === 'chain' && (
            <Stack
              distribution="equalSpacing"
              alignment="center"
              spacing="none"
            >
              <TextStyle variation="strong">Value</TextStyle>
              <TextStyle variation="strong">
                {formatNumberToMoney(order?.value, {
                  category: order?.counter,
                  currencyCode: order?.counter,
                  codePosition: 'back',
                  maximumFractionDigits: 10,
                })}
              </TextStyle>
            </Stack>
          )}
        </Stack>
      </Card.Section>
    </Card>
  );
}
