import React from 'react';
import { Card, TextContainer, TextStyle } from '@shopify/polaris';

function DepositAddress({ address }) {
  return (
    <Card.Section title="Receive Address" sectioned>
      <TextContainer>
        <p>
          {address ? (
            address
          ) : (
            <TextStyle variation="subdued">No Address</TextStyle>
          )}
        </p>
      </TextContainer>
    </Card.Section>
  );
}

export default DepositAddress;
