import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Page, Layout, Card, Stack, TextStyle } from '@shopify/polaris';
import LoadingPage from 'components/LoadingPage';
import StatusBadge from 'components/StatusBadge';

import AdDetails from './components/AdDetails';
import AdTrader from './components/AdTrader';
import AdBankDetails from './components/AdBankDetails';
import AdExchange from './components/AdExchange';

import { fetchAdDetails, setAdStatus } from 'redux/ads/actions';

import { formatDateToReadableString } from 'utils/date';
import { PreviousLocationContext } from 'providers/PreviousLocationProvider';

export default function ViewAdvert({ match }) {
  const dispatch = useDispatch();

  const { previousLocation } = React.useContext(PreviousLocationContext);

  const {
    fetching,
    editing,
    error,
    data: { [match.params.id]: ad },
  } = useSelector((state) => state.ads);

  const handleSetAdStatus = (status) => {
    dispatch(setAdStatus(ad.id, status));
  };

  React.useEffect(
    React.useCallback(() => {
      if (!ad) {
        dispatch(fetchAdDetails(match.params.id));
      }
    }, [match.params.id, dispatch, ad]),
    [],
  );

  if (error) {
    return <Redirect to="/marketplace/ads" />;
  }
  if (fetching && !ad) {
    return <LoadingPage />;
  }

  const date =
    ad?.created_at && formatDateToReadableString(new Date(ad.created_at));

  const idRef = ad?.id.split('-')?.[0];

  return (
    <Page
      title={idRef}
      breadcrumbs={[
        {
          content: 'Adverts',
          url: previousLocation
            ? `${previousLocation.pathname}${previousLocation.search}`
            : '/marketplace/ads',
        },
      ]}
      titleMetadata={
        <Stack>
          <TextStyle>{date}</TextStyle>
          {ad?.status && <StatusBadge status={ad.status} />}
        </Stack>
      }
    >
      {ad && (
        <Layout>
          <Layout.Section>
            <AdDetails {...{ handleSetAdStatus, ad, editing, fetching }} />
          </Layout.Section>
          <Layout.Section secondary>
            <Card>
              <AdTrader trader={ad.trader} />
              <AdBankDetails bank={ad.bank} />
              <AdExchange exchange={ad.reference_exchange} />
            </Card>
          </Layout.Section>
        </Layout>
      )}
    </Page>
  );
}
