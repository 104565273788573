import api from 'utils/api';
import {
  FETCH_VERIFICATIONS,
  FETCH_VERIFICATIONS_DETAILS,
  UPDATE_VERIFICATIONS_DETAILS,
} from './actionTypes';

/**
 * Type definitions
 *
 * @typedef { import('../types').GenericDispatchAction } GenericDispatchAction
 */

/**
 *
 * @param {string} queryString
 * @returns {{ type : string; payload : { queryString : string } }}
 */
export const fetchVerifications = (queryString = '') => {
  return { type: FETCH_VERIFICATIONS.default, payload: { queryString } };
};

/**
 *
 * @param {string} id
 * @returns { GenericDispatchAction }
 */

export const fetchVerificationsDetails = (id) => (dispatch) => {
  const payload = api.get(`/v1/verification/${id}`);
  return dispatch({ type: FETCH_VERIFICATIONS_DETAILS.default, payload });
};

/**
 *
 * @param {string} id
 * @param { { accepted : boolean }  } data
 * @returns { GenericDispatchAction }
 */

export const updateVerificationsDetails = (id, data) => (dispatch) => {
  const payload = api.post(`/v1/verification/${id}/status`, data);
  return dispatch({ type: UPDATE_VERIFICATIONS_DETAILS.default, payload }).then(
    (res) => {
      dispatch(fetchVerificationsDetails(id));
      return res;
    },
  );
};
