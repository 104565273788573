import {
  FETCH_REFERRALS,
  FETCH_REFERRALS_DETAILS,
  APPROVE_REFERRAL,
} from './actionTypes';
import parseError from 'utils/parseError';
import { mapKeys } from 'lodash';

const INITIAL_STATE = {
  data: {},
  meta: {},
  fetching: false,
  editing: false,
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_REFERRALS.pending:
    case FETCH_REFERRALS_DETAILS.pending:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case FETCH_REFERRALS.rejected:
    case FETCH_REFERRALS_DETAILS.rejected:
      return {
        ...state,
        fetching: false,
        error: parseError(action.payload),
      };

    case FETCH_REFERRALS.fulfilled:
      return {
        ...state,
        fetching: false,
        data: mapKeys(action.payload.data.data, 'id'),
        meta: action.payload.data.meta,
      };

    case FETCH_REFERRALS_DETAILS.fulfilled:
      return {
        ...state,
        fetching: false,
        data: {
          ...state.data,
          [action.payload.data.id]: action.payload.data,
        },
      };

    case APPROVE_REFERRAL.pending:
      return {
        ...state,
        editing: true,
      };

    case APPROVE_REFERRAL.rejected:
      return {
        ...state,
        editing: false,
      };

    case APPROVE_REFERRAL.fulfilled:
      return {
        ...state,
        editing: false,
      };

    default:
      return state;
  }
};
