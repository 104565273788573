import React from 'react';
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';

export default function useQueryParams(
  initialQueryParams,
  { initiateWithLocation = true } = {},
) {
  const location = useLocation();
  const history = useHistory();

  const [queryParams, setQueryParams] = React.useState(() => {
    if (initiateWithLocation) {
      return {
        ...initialQueryParams,
        ...queryString.parse(location.search),
      };
    }

    return initialQueryParams;
  });

  /**
   *
   * @param {string | {[key : string] : any} } key
   * @param { any} value
   * @returns
   */

  const updateQueryParams = (key, value = '') =>
    setQueryParams((previousParams) => {
      const defaultValues = {
        ...previousParams,
        page: initialQueryParams.page ? 1 : undefined,
      };

      if (typeof key === 'string') {
        return {
          ...defaultValues,
          [key]: value,
        };
      }

      return {
        ...defaultValues,
        ...key,
      };
    });

  const clearAllFilters = () => setQueryParams(initialQueryParams);

  const querySearch = React.useCallback(
    (event) => {
      if (event.keyCode === 13) {
        const search = queryString.stringify(queryParams, {
          skipEmptyString: true,
          skipNull: true,
        });
        history.push({
          pathname: location.pathname,
          search: `?${search}`,
        });
      }
    },
    [history, location.pathname, queryParams],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(
    React.useCallback(() => {
      const previousQuery = queryString.parse(location.search);

      const search = queryString.stringify(
        { ...queryParams, q: queryParams.q ? previousQuery.q : queryParams.q },
        {
          skipEmptyString: true,
          skipNull: true,
        },
      );
      history.push({
        pathname: location.pathname,
        search: `?${search}`,
      });
    }, [history, location.pathname, location.search, queryParams]),
    [queryParams],
  );

  /**
   * This effect runs to ensure that the search query param is only updated
   * in the page location when the enter key is pressed
   */
  React.useEffect(() => {
    document.addEventListener('keyup', querySearch, true);
    return () => {
      document.removeEventListener('keyup', querySearch, true);
    };
  }, [querySearch]);

  return { queryParams, updateQueryParams, clearAllFilters };
}
