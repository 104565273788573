import React from 'react';
import { updateVerificationsDetails } from 'redux/verifications/actions';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import useUpdateEffect from 'hooks/useUpdatedEffect';
import { useConfirmation } from 'utils/confirmationService';

import {
  fetchTransactions,
  fetchCustomerActivities,
  fetchWhitelistedNames,
  deleteWhitelistedName,
  editTransactionStatus,
  toggleCustomerFeature,
  editCustomerPhoneNumber,
  editCustomerProfile,
  editCustomerAddress,
  updateCustomerLevel,
  setCustomerStatus,
  resetCustomerPassword,
  resendCustomerVerificationEmail,
  editCustomerWalletBalance,
  updateCustomerBvn,
  editCustomerBvnDetails,
  fetchCustomer,
  resolveCustomerBvn,
  fetchWallets,
  fetchCustomerDocuments,
  fetchCustomerCards,
  fetchCustomerBanks,
  fetchCustomerCryptoAddresses,
  removeCustomerBvn,
  activateCustomerInactiveWallet,
  fetchCustomerInactiveWallets,
} from 'redux/customers/actions';

const INITIAL_STATE = {
  statusModal: false,
  passwordModal: false,
  verificationModal: false,
  editBalanceModal: false,
  editPhoneModal: false,
  editProfileModal: false,
  editAddressModal: false,
  updateBvnModal: false,
  activateInactiveWalletModal: false,
  selectedWalletCurrency: null,
};

/**
 *
 * @param { keyof INITIAL_STATE } state
 * @param {{ type : keyof INITIAL_STATE; value ?: any }  } action
 */

const reducer = (state, action) => {
  switch (action.type) {
    case 'selectedWalletCurrency':
      return { ...state, selectedWalletCurrency: action.value };
    case 'statusModal':
    case 'passwordModal':
    case 'verificationModal':
    case 'editBalanceModal':
    case 'editPhoneModal':
    case 'editProfileModal':
    case 'editAddressModal':
    case 'updateBvnModal':
    case 'activateInactiveWalletModal':
      return { ...state, [action.type]: !state[action.type] };
    default:
      return state;
  }
};

const useViewCustomer = ({ activities }) => {
  const { id } = useParams();
  const confirm = useConfirmation();
  const [state, internalDispatch] = React.useReducer(reducer, INITIAL_STATE);
  const {
    customer,
    transactions: { data: transactions, meta: transactionsMeta },
    wallets: { data: wallets, walletsFetching },
    ...reduxStateProperties
  } = useSelector((state) => ({
    ...state.customers,
    customer: state.customers.data[id],
    whitelistedNames: state.customers.whitelistedNames[id],
    inactiveWallets: state.customers.inactiveWallets[id],
  }));

  const dispatch = useDispatch();

  const handleTxnPreviousPage = () => {
    dispatch(fetchTransactions(id, transactionsMeta.page - 1));
  };

  const handleTxnNextPage = () => {
    dispatch(fetchTransactions(id, transactionsMeta.page + 1));
  };

  const handleActivitiesPreviousPage = () => {
    dispatch(fetchCustomerActivities(id, activities.meta.page - 1));
  };

  const handleActivitiesNextPage = () => {
    dispatch(fetchCustomerActivities(id, activities.meta.page + 1));
  };

  const handleWhitelistedNamesPreviousPage = () => {
    dispatch(fetchWhitelistedNames(id, activities.meta.page - 1));
  };

  const handleWhitelistedNamesNextPage = () => {
    dispatch(fetchWhitelistedNames(id, activities.meta.page + 1));
  };

  const handleDeleteWhitelistedName = (nameId) => {
    dispatch(deleteWhitelistedName(id, nameId));
  };

  const toggleFeature = (feature) => {
    const { id } = customer;
    dispatch(toggleCustomerFeature({ id, feature }));
  };

  const modifyTransactionStatus = (reference, status) => {
    confirm({
      title: `Complete transaction`,
      description: `Are you sure you want to confirm this transaction?`,
    }).then(() => {
      const customerId = customer.id;
      const { page } = transactionsMeta;
      dispatch(editTransactionStatus({ customerId, reference, status })).then(
        () => {
          dispatch(fetchTransactions(customerId, page));
        },
      );
    });
  };

  const handleEditCustomerPhone = ({ phone }) => {
    const customerId = customer.id;
    dispatch(editCustomerPhoneNumber({ customerId, phone })).then(() => {
      toggleModal('editPhoneModal');
    });
  };

  const handleEditCustomerProfile = (data) => {
    const { id } = customer;
    dispatch(editCustomerProfile({ customerId: id, data })).then(() => {
      toggleModal('editProfileModal');
    });
  };

  const handleEditCustomerAddress = (data) => {
    const { id } = customer;
    dispatch(editCustomerAddress({ customerId: id, data })).then(() => {
      toggleModal('editAddressModal');
    });
  };

  const handleUpdateCustomerLevel = (level) => {
    const { id: customerId } = customer;
    dispatch(updateCustomerLevel({ customerId, level }));
  };

  const handleSelectedWalletCurrency = (wallet) => {
    internalDispatch({ type: 'selectedWalletCurrency', value: wallet });
  };

  const handleCustomerStatus = () => {
    dispatch(setCustomerStatus(id)).then(() => {
      toggleModal('statusModal');
    });
  };

  const handleCustomerPassword = () => {
    dispatch(resetCustomerPassword(id)).then(() => {
      toggleModal('verificationModal');
    });
  };

  const handleCustomerVerification = () => {
    dispatch(resendCustomerVerificationEmail(id)).then(() => {
      toggleModal('verificationModal');
    });
  };

  const handleEditCustomerWalletBalance = (formValues) => {
    const { id: customerId } = customer;
    dispatch(
      editCustomerWalletBalance({
        currency: state.selectedWalletCurrency,
        customerId,
        ...formValues,
      }),
    ).then(() => {
      toggleModal('editBalanceModal');
    });
  };

  const handleUpdateCustomerBvn = ({ bvn }) => {
    const { id: customerId } = customer;
    dispatch(updateCustomerBvn(customerId, bvn)).then(() => {
      toggleModal('updateBvnModal');
    });
  };

  const handleEditCustomerBvnDetails = ({ bvn, mobile, dob }) => {
    dispatch(editCustomerBvnDetails({ bvn, mobile, dob })).then(() => {
      toggleModal('updateBvnModal');
    });
  };

  const handleActivateInactiveWallet = (walletCode) => {
    const { id: customerId } = customer;
    dispatch(activateCustomerInactiveWallet(customerId, [walletCode])).then(
      () => {
        toggleModal('activateInactiveWalletModal');
      },
    );
  };

  const handleRemoveCustomerBvn = () => {
    dispatch(removeCustomerBvn(id));
  };

  const handleUpdateVerificationsDetails = () => {
    dispatch(updateVerificationsDetails());
  };

  const handleOnUpdateVerification = () => {
    dispatch(fetchCustomer(id));
    dispatch(fetchCustomerDocuments(id));
  };

  /**
   *
   * @param {keyof INITIAL_STATE} key
   */
  const toggleModal = (key) => {
    internalDispatch({ type: key });
  };

  React.useEffect(
    React.useCallback(() => {
      dispatch(fetchCustomer(id));
      dispatch(fetchTransactions(id, 1));
      dispatch(fetchWallets(id, 1));
      dispatch(fetchCustomerDocuments(id));
      dispatch(fetchCustomerCards(id));
      dispatch(fetchCustomerBanks(id));
      dispatch(fetchCustomerCryptoAddresses(id));
      dispatch(fetchWhitelistedNames(id));
      dispatch(fetchCustomerInactiveWallets(id));
    }, [dispatch, id]),
    [id],
  );

  const bvnResolved = React.useRef(null);

  useUpdateEffect(
    React.useCallback(() => {
      if (customer?.profile && customer?.profile?.bvn && !bvnResolved.current) {
        bvnResolved.current = true;
        dispatch(resolveCustomerBvn(customer.profile.bvn));
      }
    }, [customer, dispatch]),
    [customer],
  );

  return {
    handleTxnNextPage,
    handleTxnPreviousPage,
    handleActivitiesNextPage,
    handleActivitiesPreviousPage,
    handleWhitelistedNamesNextPage,
    handleWhitelistedNamesPreviousPage,
    handleDeleteWhitelistedName,
    toggleFeature,
    modifyTransactionStatus,
    handleEditCustomerPhone,
    handleEditCustomerProfile,
    handleEditCustomerAddress,
    handleUpdateCustomerLevel,
    handleSelectedWalletCurrency,
    handleCustomerStatus,
    handleCustomerPassword,
    handleCustomerVerification,
    handleEditCustomerWalletBalance,
    handleUpdateCustomerBvn,
    handleEditCustomerBvnDetails,
    handleRemoveCustomerBvn,
    handleActivateInactiveWallet,
    transactions,
    transactionsMeta,
    wallets,
    walletsFetching,
    paramsId: id,
    customer,
    state,
    ...reduxStateProperties,
    toggleModal,
    updateVerificationsDetails: handleUpdateVerificationsDetails,
    onUpdateVerification: handleOnUpdateVerification,
  };
};

export default useViewCustomer;
