import React from 'react';
import { ResourceList, TextStyle, Avatar } from '@shopify/polaris';
import { formatNumberToMoney } from 'utils/money';
import { getInitials } from 'utils/strings';
import './CustomerListItem.css';

export default function CustomerListItem(props) {
  const {
    id,
    created_at,
    profile,
    email,
    status,
    total_spent,
    orders_count,
    ...rest
  } = props;
  const media = (
    <Avatar
      initials={getInitials(profile.first_name, profile.last_name)}
      size="medium"
      name={profile?.first_name}
    />
  );

  return (
    <ResourceList.Item
      id={id}
      media={media}
      created_at={created_at}
      orders_count={orders_count}
      total_spent={total_spent}
      accessibilityLabel={`View details for ${profile.first_name} ${profile.last_name}`}
      url={`/customers/${id}`}
      {...rest}
    >
      <div className="CustomerListItem_Main">
        <div className="CustomerListItem_Name">
          <h3>
            <TextStyle variation="strong">
              {profile.first_name} {profile.last_name}
            </TextStyle>
          </h3>
        </div>
        <div className="CustomerListItem_Email">
          <TextStyle>{email}</TextStyle>
        </div>
        <div className="CustomerListItem_Orders">
          <TextStyle> {orders_count} orders </TextStyle>
        </div>
        <div className="CustomerListItem_Spent">
          <TextStyle>
            {profile.currency_symbol} {formatNumberToMoney(total_spent)} spent
          </TextStyle>
        </div>
      </div>
    </ResourceList.Item>
  );
}
