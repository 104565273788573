import React from 'react';
import { Card, TextStyle } from '@shopify/polaris';
import { capitalize } from 'utils/strings';

export default function OrderChannel({ channel }) {
  return (
    <Card.Section title="Channel">
      <TextStyle variation="subdued">
        {channel ? capitalize(channel).replace('_', ' ') : 'N/A'}
      </TextStyle>
    </Card.Section>
  );
}
