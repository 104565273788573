import React from 'react';
import { Stack, DisplayText, TextStyle } from '@shopify/polaris';

export default function EmptyTable({ resourceName = 'data' }) {
  return (
    <Stack alignment="center" vertical spacing="extraLoose">
      <div style={{ marginTop: 50 }}>
        <img
          src="https://res.cloudinary.com/dwoc5fknz/image/upload/v1607941095/icons/empty-list.svg"
          alt={`No ${resourceName} available`}
        />
      </div>

      <div style={{ marginBottom: 50, textAlign: 'center' }}>
        <DisplayText size="small">No {resourceName} available</DisplayText>
        <TextStyle variation="subdued">
          Try changing the filters or search term
        </TextStyle>
      </div>
    </Stack>
  );
}
