import React from 'react';
import { Modal, Stack, Heading, TextStyle } from '@shopify/polaris';
import { renderStatusBadge } from 'utils/formatter';
import { formatDateToReadableString } from 'utils/date';
import { formatNumberToMoney } from 'utils/money';
import { capitalize } from 'utils/strings';

export default function OrderTradeDetailsModal({
  open,
  onClose,
  trade,
  handleDeleteTrade,
}) {
  const baseCurrency = trade.product.slice(0, 3);
  const currency = trade.product.slice(3).replace('-', '');
  const date = formatDateToReadableString(new Date(trade.created_at));

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={
        <Stack alignment="center">
          <Heading>Trade {trade.provider_order_id}</Heading>
          {renderStatusBadge(trade.status)}
        </Stack>
      }
      primaryAction={{
        content: 'Delete Trade',
        destructive: true,
        onAction: () => {
          handleDeleteTrade(trade.id);
          onClose();
        },
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        <Stack vertical spacing="tight">
          <Stack distribution="equalSpacing" alignment="center">
            <TextStyle>Created</TextStyle>
            <TextStyle>{date}</TextStyle>
          </Stack>
          <Stack distribution="equalSpacing" alignment="center">
            <TextStyle>
              {(trade.side.toLowerCase() === 'ask' ||
                trade.side.toLowerCase() === 'sell') &&
                'Sold'}
              {(trade.side.toLowerCase() === 'bid' ||
                trade.side.toLowerCase() === 'buy') &&
                'Bought'}
            </TextStyle>
            <TextStyle>
              {formatNumberToMoney(trade.base, {
                category: baseCurrency,
                currencyCode: baseCurrency,
                maximumFractionDigits: 10,
              })}
            </TextStyle>
          </Stack>
          <Stack distribution="equalSpacing" alignment="center">
            <TextStyle>Fee</TextStyle>
            <TextStyle>
              {formatNumberToMoney(trade.fee, {
                category: baseCurrency,
                currencyCode: baseCurrency,
                maximumFractionDigits: 10,
              })}
            </TextStyle>
          </Stack>
          <Stack distribution="equalSpacing" alignment="center">
            <TextStyle>Price</TextStyle>
            <TextStyle>
              {formatNumberToMoney(trade.price, {
                category: currency,
                currencyCode: currency,
                maximumFractionDigits: 10,
              })}
            </TextStyle>
          </Stack>
          <Stack distribution="equalSpacing" alignment="center">
            <TextStyle variation="strong">Value</TextStyle>
            <TextStyle variation="strong">
              {formatNumberToMoney(trade.counter, {
                category: currency,
                currencyCode: currency,
                maximumFractionDigits: 10,
              })}
            </TextStyle>
          </Stack>
          <Stack distribution="equalSpacing" alignment="center">
            <TextStyle variation="strong">Provider</TextStyle>
            <TextStyle variation="strong">
              {capitalize(trade.provider)}
            </TextStyle>
          </Stack>
          <TextStyle variation="subdued">{trade.comment}</TextStyle>
        </Stack>
      </Modal.Section>
    </Modal>
  );
}
