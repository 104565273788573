import React from 'react';
import { Card, TextContainer, TextStyle } from '@shopify/polaris';
import { capitalize } from 'utils/strings';

export default function PayoutProcessor({ processor }) {
  return (
    <Card.Section title="Processor" sectioned>
      <TextContainer>
        {processor ? (
          <p>{capitalize(processor)}</p>
        ) : (
          <TextStyle variation="subdued">No processor</TextStyle>
        )}
      </TextContainer>
    </Card.Section>
  );
}
