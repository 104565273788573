import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { Page, Layout } from '@shopify/polaris';
import CardThumbnailListSkeleton from 'components/CardThumbnailListSkeleton';

import AccountOwner from './components/AccountOwner';
import AccountStaffs from './components/AccountStaffs';

import { fetchStaffs } from 'redux/staff/actions';
import {
  getStaffsSelector,
  getAccountOwnerSelector,
  getStaffFetchingSelector,
} from 'redux/staff/selectors';

const AccountSettings = ({
  fetching,
  fetchStaffs,
  staffs,
  accountOwner,
  match: { path },
}) => {
  useEffect(() => {
    fetchStaffs();
  }, [fetchStaffs]);

  return (
    <Page
      breadcrumbs={[{ content: 'Settings', url: '/settings' }]}
      title="Account"
    >
      <Layout>
        <Layout.AnnotatedSection title="Account and Permissions">
          {fetching ? (
            <CardThumbnailListSkeleton />
          ) : (
            <Fragment>
              {accountOwner && <AccountOwner accountOwner={accountOwner} />}
              <AccountStaffs staffs={staffs} path={path} />
            </Fragment>
          )}
        </Layout.AnnotatedSection>
      </Layout>
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    staffs: getStaffsSelector(state),
    accountOwner: getAccountOwnerSelector(state),
    fetching: getStaffFetchingSelector(state),
  };
};

const mapDispatchToProps = {
  fetchStaffs,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings);
