import api from 'utils/api';
import {
  FETCH_ASSET,
  FETCH_ASSETS,
  UPDATE_ASSET,
  FETCH_PAYMENT_PROVIDERS,
} from './actionTypes';

/**
 * Type definitions
 *
 * @typedef { import('../types').GenericDispatchAction } GenericDispatchAction
 */

/**
 *
 * @param { string | number } page
 * @returns { GenericDispatchAction }
 */
export const fetchAssets = (page = 1) => (dispatch) => {
  const payload = api.get(`/v1/currencies?page=${page}&limit=500`);
  return dispatch({ type: FETCH_ASSETS.default, payload });
};

/**
 *
 * @param {string} code
 * @returns { GenericDispatchAction }
 */
export const fetchAsset = (code) => (dispatch) => {
  const payload = api.get(`/v1/currencies/${code}`);
  return dispatch({ type: FETCH_ASSET.default, payload });
};

/**
 *
 * @param {string} code
 * @param {object} payload
 * @returns { GenericDispatchAction }
 */
export const updateAsset = (
  code,
  {
    website,
    description,
    tag_name,
    empty_tag_message,
    status,
    minimum_trade_amount,
    deposit_status,
    deposit_fee,
    deposit_fee_description,
    payout_status,
    payout_provider,
    payout_fee,
    minimum_payout_amount,
    daily_payout_limit,
    payout_fee_description,
    marketplace_status,
    marketplace_fee,
    bushapay_status,
    bushapay_fee,
    vip_payout_status,
  },
) => (dispatch) => {
  const payload = api.put(`/v1/currencies/${code}`, {
    website,
    description,
    tag_name,
    empty_tag_message,
    status,
    minimum_trade_amount,
    deposit_status,
    deposit_fee,
    deposit_fee_description,
    payout_status,
    payout_provider,
    payout_fee,
    minimum_payout_amount,
    daily_payout_limit,
    payout_fee_description,
    marketplace_status,
    marketplace_fee,
    bushapay_status,
    bushapay_fee,
    vip_payout_status,
  });
  return dispatch({ type: UPDATE_ASSET.default, payload });
};

/**
 *
 * @param {string} code
 * @returns { GenericDispatchAction }
 */
export const fetchPaymentProviders = (code) => (dispatch) => {
  const payload = api.get(`/v1/currencies/${code}/payout_providers`);
  return dispatch({ type: FETCH_PAYMENT_PROVIDERS.default, payload });
};
