import React from 'react';
import { ResourceList, TextStyle } from '@shopify/polaris';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { capitalize } from 'utils/strings';
import { formatNumberToMoney } from 'utils/money';
import MarketPlaceListContainer from 'components/MarketPlacecContainerList';
import { format } from 'date-fns';

const AdListItem = (ad) => {
  const { pathname } = useLocation();
  return (
    <ResourceList.Item id={ad.id} url={`${pathname}/${ad.id}`} {...ad}>
      <MarketPlaceListContainer>
        <TextStyle variation="strong">
          <Link to="">{ad?.id?.split('-')?.[0]}</Link>
        </TextStyle>
        <TextStyle>
          {format(new Date(ad.created_at), "MMM d, yyyy 'at' h:mm aa")}
        </TextStyle>
        <TextStyle>{ad.trader.username}</TextStyle>
        <TextStyle>{ad.country_code}</TextStyle>
        <TextStyle>{capitalize(ad.side)}</TextStyle>
        <TextStyle>
          {ad.minimum} - {ad.maximum}
        </TextStyle>
        <TextStyle>{ad.asset}</TextStyle>
        <TextStyle>
          {formatNumberToMoney(ad.rate, { category: ad.currency })}{' '}
          {ad.currency}/{ad.base_currency}
        </TextStyle>
      </MarketPlaceListContainer>
    </ResourceList.Item>
  );
};

export default AdListItem;
