import React from 'react';
import { TextStyle, Stack, DisplayText, Avatar, Card } from '@shopify/polaris';
import { getAssetLogo } from 'utils/helpers';

export default function TradesSummary({ tradesSummary }) {
  return (
    <Stack distribution="center">
      {tradesSummary.map((trade, index) => (
        <div key={index} style={{ width: 320 }}>
          <Card sectioned>
            <Stack
              alignment="center"
              distribution="equalSpacing"
              spacing="none"
            >
              <DisplayText size="small">{trade.fees.currency}</DisplayText>
              <Avatar
                size="small"
                name={trade.fees.currency}
                source={getAssetLogo(trade.fees.currency)}
              />
            </Stack>
            <TextStyle variation="bold">METRICS</TextStyle>
            <Stack vertical spacing="loose">
              <Stack alignment="center" distribution="equalSpacing">
                <TextStyle variation="strong">Awaiting Payments</TextStyle>
                <TextStyle>
                  {trade.awaiting_payments.amount}{' '}
                  {trade.awaiting_payments.currency}
                </TextStyle>
              </Stack>
              <TextStyle variation="subdued">
                {trade.awaiting_payments.count} ads
              </TextStyle>
              <Stack alignment="center" distribution="equalSpacing">
                <TextStyle variation="strong">Awaiting Confirmations</TextStyle>
                <TextStyle>
                  {trade.awaiting_confirmations.amount}{' '}
                  {trade.awaiting_confirmations.currency}
                </TextStyle>
              </Stack>
              <TextStyle variation="subdued">
                {trade.awaiting_confirmations.count} ads
              </TextStyle>
              <Stack alignment="center" distribution="equalSpacing">
                <TextStyle variation="strong">Completed</TextStyle>
                <TextStyle>
                  {trade.completed.amount} {trade.completed.currency}
                </TextStyle>
              </Stack>
              <TextStyle variation="subdued">
                {trade.completed.count} ads
              </TextStyle>
              <Stack alignment="center" distribution="equalSpacing">
                <TextStyle variation="strong">Fees</TextStyle>
                <TextStyle>
                  {trade.fees.amount} {trade.fees.currency}
                </TextStyle>
              </Stack>
              <TextStyle variation="subdued">{trade.fees.count} ads</TextStyle>
            </Stack>
          </Card>
        </div>
      ))}
    </Stack>
  );
}
