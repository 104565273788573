import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Page, Layout, Card, Tabs } from '@shopify/polaris';

import { fetchAdsSummary, fetchAdProducts } from '../../redux/ads/actions';
import { fetchTradesSummary } from '../../redux/trades/actions';

import {
  getTradeSummarySelector,
  getTradesFetchingSelector,
} from '../../redux/trades/selectors';

import LoadingSpinner from 'components/LoadingSpinner';
import AdsSummary from './components/AdsSummary';
import TradesSummary from './components/TradesSummary';
import EmptyTable from 'components/EmptyTable';

function MarketPlaceSummary({
  fetchAdsSummary,
  fetchTradesSummary,
  fetchAdProducts,
  products,
  tradesSummary,
  adsSummary,
  adsFetching,
  tradesFetching,
}) {
  const [selected, setSelected] = useState(0);

  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelected(selectedTabIndex),
    [],
  );

  const tabs = [
    {
      id: 'ads',
      content: 'Ads',
    },
    {
      id: 'trades',
      content: 'Trades',
    },
  ];

  useEffect(() => {
    fetchAdProducts();
  }, [fetchAdProducts]);

  useEffect(() => {
    const assets = products.map((product) => product.code.toLowerCase());
    fetchAdsSummary(assets);
    fetchTradesSummary(assets);
  }, [products, fetchAdsSummary, fetchTradesSummary]);

  return (
    <Page title="Summary" fullWidth>
      <Layout>
        <Layout.Section sectioned>
          <Card>
            <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange} />
            {tabs[selected].id === 'ads' && (
              <Card.Section>
                {adsFetching ? (
                  <LoadingSpinner />
                ) : (
                  <AdsSummary adsSummary={adsSummary} />
                )}
                {!adsFetching && adsSummary.length < 1 && <EmptyTable />}
              </Card.Section>
            )}
            {tabs[selected].id === 'trades' && (
              <Card.Section>
                {tradesFetching ? (
                  <LoadingSpinner />
                ) : (
                  <TradesSummary tradesSummary={tradesSummary} />
                )}
                {!tradesFetching && tradesSummary.length < 1 && <EmptyTable />}
              </Card.Section>
            )}
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

const MapStateToProps = (state) => ({
  tradesSummary: getTradeSummarySelector(state),
  tradesFetching: getTradesFetchingSelector(state),
  adsSummary: state.ads.summary,
  adsFetching: state.ads.fetching,
  products: state.ads.products,
});

const MapDispatchToProps = {
  fetchAdsSummary,
  fetchTradesSummary,
  fetchAdProducts,
};

export default connect(MapStateToProps, MapDispatchToProps)(MarketPlaceSummary);
