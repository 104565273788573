import React from 'react';
import { ResourceList, TextStyle } from '@shopify/polaris';
import { renderStatusBadge } from 'utils/formatter';
import { formatDateToReadableString } from 'utils/date';
import { formatNumberToMoney } from 'utils/money';
import styled from 'styled-components';

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr) repeat(2, 0.5fr) 1fr;
  grid-gap: 10px;
  justify-items: start;
  align-items: start;
  & > * {
    white-space: nowrap;
    box-sizing: border-box;
  }
  & .right {
    justify-self: end;
  }
  @media only screen and (max-width: 1080px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    & .right {
      justify-self: start;
    }
  }
`;

export default function PayoutListItem(props) {
  const {
    id,
    reference,
    created_at,
    sender_name,
    status,
    fulfilment_status,
    amount,
    currency,
    app,
    ...rest
  } = props;

  return (
    <ResourceList.Item
      id={id}
      reference={reference}
      created_at={created_at}
      sender={sender_name}
      accessibilityLabel={`View details for ${reference}`}
      url={`/payouts/${app}/${id}`}
      {...rest}
    >
      <Container>
        <TextStyle variation="code">{reference}</TextStyle>
        <TextStyle
          variation={
            fulfilment_status.toLowerCase() === 'completed' &&
            status.toLowerCase() === 'completed'
              ? 'subdued'
              : ''
          }
        >
          {created_at && formatDateToReadableString(new Date(created_at))}
        </TextStyle>
        <TextStyle
          variation={
            fulfilment_status.toLowerCase() === 'completed' &&
            status.toLowerCase() === 'completed'
              ? 'subdued'
              : ''
          }
        >
          {sender_name}
        </TextStyle>
        {renderStatusBadge(status)}
        {fulfilment_status
          ? renderStatusBadge(fulfilment_status)
          : renderStatusBadge('Internal')}
        <div className="right">
          {formatNumberToMoney(amount, {
            currencyCode: currency,
            category: currency,
            codePosition: 'back',
          })}
        </div>
      </Container>
    </ResourceList.Item>
  );
}
