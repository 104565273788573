const STORAGE = localStorage;

export default {
  get(key) {
    const item = STORAGE.getItem(key);
    return item ? JSON.parse(item) : null;
  },
  set(key, value) {
    const val = JSON.stringify(value);
    STORAGE.setItem(key, val);
  },
  remove(key) {
    STORAGE.removeItem(key);
  },
  clear() {
    STORAGE.clear();
  },
};
