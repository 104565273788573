import React from 'react';
import FormModal from 'components/FormModal';
import EditPhoneForm from './EditPhoneForm';
import { editCustomerPhoneSchema } from 'utils/validationSchemas';

export default function EditPhoneModal({
  open,
  handleClose,
  onAction,
  phoneNumber,
  loading,
}) {
  return (
    <FormModal
      Form={EditPhoneForm}
      open={open}
      handleClose={handleClose}
      initialValues={{
        phone: phoneNumber,
      }}
      validationSchema={editCustomerPhoneSchema}
      onAction={onAction}
      loading={loading}
      title="Edit Customer Phone"
    />
  );
}
