import copy from 'clipboard-copy';
import queryString from 'query-string';

import { toast } from './toast';
import store from '../redux';

function getSelectedValueFromInput(value) {
  if (Array.isArray(value)) {
    return value[0];
  }
  return value;
}

const getAssetLogo = (asset) =>
  `https://res.cloudinary.com/dwoc5fknz/image/upload/v1545995759/crypto_assets/${asset}.svg`;

/**
 *
 * @param {string | number} text
 */
const copyToClipboard = (text) => {
  try {
    copy(text);
    toast('Text copied to clipboard');
  } catch (error) {
    toast('Failed to copy text', 'error');
  }
};

/**
 *
 * @param {string} currency
 * @param {string} hash
 */
function getPayoutHashLink(currency, hash) {
  switch (currency.toLowerCase()) {
    case 'btc':
      return `https://live.blockcypher.com/btc/tx/${hash}`;
    case 'eth':
    case 'ngnt':
      return `https://etherscan.io/tx/${hash}`;
    case 'ltc':
      return `https://live.blockcypher.com/ltc/tx/${hash}`;
    case 'rmt':
      return `https://stellarchain.io/tx/${hash}`;
    case 'xlm':
      return `https://stellarchain.io/tx/${hash}`;
    case 'trx':
    case 'usdt':
      return `https://tronscan.org/#/transaction/${hash}`;
    default:
      return undefined;
  }
}

async function getS3document(link) {
  const {
    auth: { data: authData },
  } = store.getState();

  const url = new URL(link);
  url.searchParams.set('jwt', authData?.access_token);

  return url.href;
}

function getS3DocumentFileType(link) {
  const url = new URL(link);
  const fileName = url.searchParams.get('key');
  const fileExtension = fileName.substr(fileName.lastIndexOf('.') + 1);
  return fileExtension;
}

function calculatePercentage(amount, total) {
  if (total === 0) return 0;
  return Number(parseFloat((amount / total) * 100).toFixed(2));
}

function isInitialQuerySetWithRange() {
  const URLSearch = new URLSearchParams(window.location.search);
  const initialURLQueryHasRangeSet =
    URLSearch.has('from') && URLSearch.has('to');

  return initialURLQueryHasRangeSet;
}

function isInitialQuerySetWithForValue() {
  const URLSearch = new URLSearchParams(window.location.search);
  const initialURLQueryHasForValueSet = URLSearch.has('for');

  return initialURLQueryHasForValueSet;
}

function getValueOfQueryStringKeyFromURL(key) {
  const searchQuery = queryString.parse(window.location.search);

  return searchQuery?.[key];
}

export {
  getSelectedValueFromInput,
  getAssetLogo,
  copyToClipboard,
  getPayoutHashLink,
  getS3document,
  getS3DocumentFileType,
  calculatePercentage,
  isInitialQuerySetWithRange,
  isInitialQuerySetWithForValue,
  getValueOfQueryStringKeyFromURL,
};
