import { all } from 'redux-saga/effects';

import customersSagas from './customers/saga';
import depositSagas from './deposits/saga';
import documentSagas from './documents/saga';
import orderSagas from './orders/saga';
import payoutSagas from './payouts/saga';
import referralSagas from './referrals/saga';
import tradeSagas from './trades/saga';
import verificationSagas from './verifications/saga';

export default function* root() {
  yield all([
    customersSagas(),
    depositSagas(),
    documentSagas(),
    orderSagas(),
    payoutSagas(),
    referralSagas(),
    tradeSagas(),
    verificationSagas(),
  ]);
}
