import React from 'react';
import { TextStyle, Stack, Card, Button, Link, List } from '@shopify/polaris';

export default function ChainOrders({ orders, loading, createChainOrder }) {
  const chainOrderExists =
    orders.length > 0 &&
    orders.map((order) => order?.trim()).filter(Boolean).length > 0;

  return (
    <Card>
      <Card.Section>
        <Stack distribution="equalSpacing" alignment="center">
          <TextStyle>
            {chainOrderExists
              ? 'Orders were successfully placed on chain.'
              : 'A chain order wasn’t created.'}
          </TextStyle>
          {!chainOrderExists && (
            <Button plain loading={loading} onClick={createChainOrder}>
              Create Order
            </Button>
          )}
        </Stack>
      </Card.Section>
      {chainOrderExists && (
        <Card.Section>
          <List type="bullet">
            {orders.map((order, index) => (
              <List.Item key={index}>
                <Link url={`/orders/chain/${order}`}>Order {index + 1}</Link>
              </List.Item>
            ))}
          </List>
        </Card.Section>
      )}
    </Card>
  );
}
