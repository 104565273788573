import React from 'react';
import { Card, TextContainer } from '@shopify/polaris';
import { capitalize } from 'utils/strings';

function DepositCardDetails({ card }) {
  const name =
    card?.type?.trim() === card?.name?.trim()
      ? 'No name provided'
      : card?.name || 'No name provided';

  return (
    <Card.Section title="Card Details" sectioned>
      <TextContainer>
        <p>{capitalize(card?.bank || '')}</p>
        <p>{name}</p>
        <p>{capitalize(card?.type || '')}</p>
        <p>•••• •••• •••• {card?.last_4 || card?.last4}</p>
      </TextContainer>
    </Card.Section>
  );
}

export default DepositCardDetails;
