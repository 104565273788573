import React from 'react';
import { Pagination as PolarisPagination } from '@shopify/polaris';
import IndexPagination from './IndexPagination';

export default function Pagination({
  resource,
  meta,
  handleNextPage,
  handlePreviousPage,
}) {
  if (resource && resource.length > 0) {
    return (
      <IndexPagination>
        <PolarisPagination
          hasPrevious={meta?.page > 1}
          hasNext={meta?.page < meta?.total_pages}
          onPrevious={handlePreviousPage}
          onNext={handleNextPage}
        />
      </IndexPagination>
    );
  }
  return null;
}
