import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Page, Layout, TextStyle, Stack, Card } from '@shopify/polaris';
import { useConfirmation } from 'utils/confirmationService';
import OrderDetails from './components/OrderDetails';
import OrderLiquidationDetails from './components/OrderLiquidationDetails';
import ChainOrders from './components/ChainOrders';
import OrderCustomer from './components/OrderCustomer';
import OrderCustomerDetails from './components/OrderCustomerDetails';
import OrderPaymentDetails from './components/OrderPaymentDetails';
import OrderTransferDetails from './components/OrderTransferDetails';
import OrderChannel from './components/OrderChannel';
import OrderApp from './components/OrderApp';
import OrderProfit from './components/OrderProfit';
import OrderComment from './components/OrderComment';
import OrderTrades from './components/OrderTrades';
import LoadingPage from 'components/LoadingPage';
import {
  fetchOrder,
  createChainOrder,
  createOrderTrade,
  deleteOrderTrade,
  completeOrderTrades,
} from 'redux/orders/actions';
import { formatDateToReadableString } from 'utils/date';
import { renderStatusBadge } from 'utils/formatter';
import { PreviousLocationContext } from 'providers/PreviousLocationProvider';

export default function ViewOrder({ history, match }) {
  const dispatch = useDispatch();
  const confirm = useConfirmation();
  const { previousLocation } = React.useContext(PreviousLocationContext);

  const { id, app } = match.params;
  const {
    fetching,
    error,
    actionLoading,
    data: { [id]: order },
  } = useSelector((state) => state.orders);

  function handleCreateTrade(values) {
    return dispatch(createOrderTrade(app, id, values));
  }

  function handleCompleteOrderTrades() {
    dispatch(completeOrderTrades(app, id));
  }

  function handleDeleteTrade(tradeId) {
    confirm({
      variant: 'danger',
      title: `Delete trade`,
      description: `Are you sure you want to delete this trade?`,
    }).then(() => dispatch(deleteOrderTrade(app, id, tradeId)));
  }

  useEffect(() => {
    dispatch(fetchOrder(app, id));
  }, [id, app, dispatch]);

  const { user, payment, transfer, analysis, trades } = order || {};

  if (error) {
    history.push(`/orders/${app}`);
  }

  if (fetching && !order) {
    return <LoadingPage />;
  }

  return (
    <Page
      breadcrumbs={[
        {
          content: 'Orders',
          url: previousLocation
            ? `${previousLocation.pathname}${previousLocation.search}`
            : `/orders/${app}`,
        },
      ]}
      title={order?.reference}
      titleMetadata={
        <Stack alignment="center" spacing="loose">
          {order?.created_at && (
            <TextStyle>
              {formatDateToReadableString(new Date(order.created_at))}
            </TextStyle>
          )}
          {app === 'chain'
            ? renderStatusBadge(order?.settled ? 'settled' : 'unsettled')
            : renderStatusBadge(order?.status)}
        </Stack>
      }
    >
      <Layout>
        <Layout.Section>
          <OrderDetails order={order} app={app} />
          {app === 'chain' && (
            <OrderLiquidationDetails
              analysis={order?.analysis}
              side={order?.side}
              base={order?.base}
              counter={order?.counter}
            />
          )}
          {app !== 'chain' && (
            <ChainOrders
              orders={order?.chain_order_id?.split(',') || []}
              loading={actionLoading}
              createChainOrder={() =>
                dispatch(createChainOrder(order.app, order.id))
              }
            />
          )}
          {app === 'chain' && (
            <OrderTrades
              {...{
                trades: trades || [],
                settled: order?.settled,
                handleCreateTrade,
                handleDeleteTrade,
                handleCompleteOrderTrades,
                actionLoading,
                fetching,
                base: order?.base,
                counter: order?.counter,
              }}
            />
          )}
        </Layout.Section>
        <Layout.Section secondary>
          {app !== 'chain' && (
            <Card>
              <OrderCustomer user={user} />
              <OrderCustomerDetails user={user} />
              {payment && (
                <OrderPaymentDetails payment={payment} app={order.app} />
              )}
              {transfer && (
                <OrderTransferDetails transfer={transfer} app={order.app} />
              )}
              <OrderChannel channel={order?.channel || ''} />
            </Card>
          )}
          {app === 'chain' && (
            <Card>
              <OrderApp app={order?.app || ''} />
              {analysis && (
                <OrderProfit analysis={analysis} currency={order?.counter} />
              )}
              {analysis && <OrderComment comment={analysis.comment} />}
            </Card>
          )}
        </Layout.Section>
      </Layout>
    </Page>
  );
}
