import React from 'react';
import { Modal, FormLayout, TextField } from '@shopify/polaris';

function CommentModal({ isOpen, toggleModal, loading, handleRejectDocument }) {
  const [comment, setComment] = React.useState('');

  return (
    <Modal
      open={isOpen}
      onClose={toggleModal}
      title="Reject submitted document"
      primaryAction={{
        content: 'Reject',
        disabled: !comment,
        loading,
        onAction: () => handleRejectDocument(comment),
      }}
    >
      <Modal.Section>
        <FormLayout>
          <TextField
            name="comment"
            label="Comment"
            multiline={3}
            value={comment}
            onChange={(value) => setComment(value)}
          />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}

export default CommentModal;
