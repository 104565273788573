import React from 'react';
import {
  Card,
  Stack,
  Heading,
  TextStyle,
  TextContainer,
} from '@shopify/polaris';
import formatDistance from 'date-fns/formatDistance';
import { formatNumberToMoney } from 'utils/money';

export default function CustomerSummary({ customer }) {
  const { created_at, profile, total_spent, orders_count } = customer;

  return profile && profile.first_name ? (
    <Card>
      <Card.Section sectioned>
        <TextContainer>
          <Heading>{`${profile.first_name} ${profile.last_name}`}</Heading>
          <p>
            <TextStyle variation="subdued">{profile.country}</TextStyle>
          </p>
          <p>
            <TextStyle variation="subdued">{`Customers for ${formatDistance(
              new Date(created_at),
              new Date(),
            )}`}</TextStyle>
          </p>
        </TextContainer>
      </Card.Section>
      <Card.Section sectioned>
        <Stack distribution="fillEvenly">
          <TextContainer>
            <TextStyle variation="subdued">Total Spent to date</TextStyle>
            <Heading element="h2">
              {total_spent
                ? `${profile.currency_symbol} ${formatNumberToMoney(
                    total_spent,
                  )}`
                : 'NGN 0.00'}
            </Heading>
          </TextContainer>
          <TextContainer>
            <TextStyle variation="subdued">Total orders count</TextStyle>
            <Heading element="h2">{orders_count || 0} orders</Heading>
          </TextContainer>
        </Stack>
      </Card.Section>
    </Card>
  ) : null;
}
