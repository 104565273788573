import React from 'react';
import { Card, Button, TextStyle, Stack } from '@shopify/polaris';
import { renderStatusBadge } from 'utils/formatter';

export default function ReferralDetails({
  editing,
  refereeName,
  refererName,
  id,
  status,
  date,
  approveReferral,
}) {
  return (
    <Card title="Referral Details" sectioned>
      <Stack spacing="loose" vertical>
        <TextStyle>
          {refererName} referred {refereeName}
        </TextStyle>
        <TextStyle>{date}</TextStyle>
        {renderStatusBadge(status)}
      </Stack>
      {status.toLowerCase() === 'pending' && (
        <Stack distribution="trailing">
          <Button primary onClick={() => approveReferral(id)} loading={editing}>
            Approve
          </Button>
        </Stack>
      )}
    </Card>
  );
}
