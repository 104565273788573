import React from 'react';
import TextInput from 'components/TextInput';
import {
  Modal,
  FormLayout,
  TextField,
  TextStyle,
  Button,
  Stack,
} from '@shopify/polaris';
import { Formik, Field, Form } from 'formik';
import { createOrderTradeSchema } from 'utils/validationSchemas';
import { formatNumberToMoney } from 'utils/money';

export default function CreateTradeModal({
  open,
  onClose,
  loading,
  handleCreateTrade,
  base,
  counter,
}) {
  return (
    <Formik
      initialValues={{
        price: '',
        base_volume: '',
        fee: '',
      }}
      validationSchema={createOrderTradeSchema}
      onSubmit={(values) => handleCreateTrade(values).then(() => onClose())}
    >
      {({ values, handleSubmit }) => (
        <Form>
          <Modal title="Create a new trade" open={open} onClose={onClose}>
            <Modal.Section>
              <FormLayout>
                <FormLayout.Group>
                  <Field
                    type="number"
                    label="Base"
                    placeholder="Base Volume"
                    name="base_volume"
                    id="base_volume"
                    suffix={base}
                    component={TextInput}
                  />
                  <Field
                    type="number"
                    label="Price"
                    placeholder="Price"
                    name="price"
                    id="price"
                    suffix={counter}
                    component={TextInput}
                  />
                </FormLayout.Group>
                <FormLayout.Group>
                  <Field
                    type="number"
                    label="Fee"
                    placeholder="Fee"
                    name="fee"
                    id="fee"
                    suffix={base}
                    component={TextInput}
                  />
                  <TextField
                    label="Counter"
                    placeholder="Counter Volume"
                    readOnly
                    value={formatNumberToMoney(
                      (values.base_volume || 0) * (values.price || 0),
                      { category: base, maximumFractionDigits: 10 },
                    )}
                    suffix={counter}
                  />
                </FormLayout.Group>
              </FormLayout>
              <br />
              <TextStyle variation="subdued">
                Did you pay any fees for trading this volume? Use 0 for OTC
                trades
              </TextStyle>
            </Modal.Section>
            <Modal.Section>
              <Stack distribution="trailing" alignment="center">
                <Button onClick={onClose}>Cancel</Button>
                <Button
                  type="submit"
                  primary
                  loading={loading}
                  onClick={handleSubmit}
                >
                  Create trade
                </Button>
              </Stack>
            </Modal.Section>
          </Modal>
        </Form>
      )}
    </Formik>
  );
}
