import React from 'react';
import { Card, Stack, TextStyle, Button } from '@shopify/polaris';
import { formatNumberToMoney } from 'utils/money';

function DepositBreakDown({
  fee,
  fee_discount,
  provider_fee,
  currency,
  amount,
  confirmations,
  required_confirmations,
  base,
  handleApproveDeposit,
  handleDeclineDeposit,
  handleFufillDeposit,
  approvingDeposit,
  fulfillingDeposit,
  decliningDeposit,
  status,
  fulfilment,
}) {
  const totalAmount = amount - fee + (fee_discount || 0);

  return (
    <Card title="Deposit Details">
      <Card.Section>
        <Stack vertical>
          <Stack distribution="equalSpacing">
            <TextStyle>Amount</TextStyle>
            <TextStyle>
              {formatNumberToMoney(amount, {
                currencyCode: currency,
                category: currency,
                codePosition: 'back',
              })}
            </TextStyle>
          </Stack>
           <Stack distribution="equalSpacing">
            <TextStyle>Fee</TextStyle>
            <TextStyle>
              {formatNumberToMoney(fee, {
                currencyCode: currency,
                category: currency,
                codePosition: 'back',
              })}
            </TextStyle>
          </Stack>
          {base === 'cash-deposits' && (
            <Stack distribution="equalSpacing">
              <TextStyle>Fee Discount</TextStyle>
              <TextStyle>
                {formatNumberToMoney(fee_discount, {
                  currencyCode: currency,
                  category: currency,
                  codePosition: 'back',
                })}
              </TextStyle>
            </Stack>
          )}
          {base === 'cash-deposits' && (
            <Stack distribution="equalSpacing">
              <TextStyle>Provider Fee</TextStyle>
              <TextStyle>
                {formatNumberToMoney(provider_fee, {
                  currencyCode: currency,
                  category: currency,
                  codePosition: 'back',
                })}
              </TextStyle>
            </Stack>
          )}
          {base === 'crypto-deposits' && (
            <Stack distribution="equalSpacing">
              <TextStyle variation="strong">Total</TextStyle>
              <TextStyle variation="strong">
                {formatNumberToMoney(amount + fee, {
                  currencyCode: currency,
                  category: currency,
                  codePosition: 'back',
                })}
              </TextStyle>
            </Stack>
          )}
        </Stack>
      </Card.Section>
      <Card.Section>
        {confirmations && (
          <Stack distribution="equalSpacing">
            <TextStyle> Confirmations </TextStyle>
            <TextStyle>
              {confirmations} / {required_confirmations}
            </TextStyle>
          </Stack>
        )}
        {base === 'cash-deposits' && (
          <Stack distribution="equalSpacing">
            <TextStyle> Received by customer </TextStyle>
            <TextStyle>
              {formatNumberToMoney(totalAmount, {
                currencyCode: currency,
                category: currency,
                codePosition: 'back',
              })}
            </TextStyle>
          </Stack>
        )}
      </Card.Section>
      {base === 'cash-deposits' && (
        <Card.Section>
          <Stack distribution="trailing" spacing="tight" alignment="center">
            {status?.toLowerCase() === 'success' &&
              fulfilment?.toLowerCase() === 'flagged' && (
                <Button
                  destructive
                  onClick={handleDeclineDeposit}
                  loading={decliningDeposit}
                >
                  Decline
                </Button>
              )}
            {(status?.toLowerCase() === 'success' ||
              status?.toLowerCase() === 'pending') &&
              fulfilment?.toLowerCase() === 'unfulfilled' && (
                <Button
                  primary
                  onClick={handleFufillDeposit}
                  loading={fulfillingDeposit}
                >
                  Fulfill
                </Button>
              )}
            {status?.toLowerCase() === 'success' &&
              fulfilment?.toLowerCase() === 'flagged' && (
                <Button
                  primary
                  onClick={handleApproveDeposit}
                  loading={approvingDeposit}
                >
                  Approve
                </Button>
              )}
          </Stack>
        </Card.Section>
      )}
    </Card>
  );
}

export default DepositBreakDown;
