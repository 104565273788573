import styled from 'styled-components';

export default styled.div`
  display: grid;
  grid-template-columns: repeat(2, 2fr) repeat(2, 1fr);
  grid-gap: 5px;
  justify-items: start;
  align-items: start;
  @media only screen and (max-width: 900px) {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
  & > div,
  & > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
