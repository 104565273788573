import {
  FETCH_COMPLETION_RATE_REPORT,
  FETCH_UNSETTLED_BUYS_REPORT,
  FETCH_UNSETTLED_SELLS_REPORT,
} from './actionTypes';

import parseError from 'utils/parseError';

const INITIAL_STATE = {
  data: {
    completionRates: {},
    unsettledSells: {},
    unsettledBuys: {},
  },
  completionRatesFetching: false,
  unsettledSellsFetching: false,
  unsettledBuysFetching: false,
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_COMPLETION_RATE_REPORT.pending:
      return {
        ...state,
        completionRatesFetching: true,
        error: null,
      };

    case FETCH_COMPLETION_RATE_REPORT.rejected:
      return {
        ...state,
        completionRatesFetching: false,
        error: parseError(action.payload),
      };

    case FETCH_COMPLETION_RATE_REPORT.fulfilled:
      return {
        ...state,
        completionRatesFetching: false,
        data: {
          ...state.data,
          completionRates: {
            ...state.data.completionRates,
            [action.meta.product]: action.payload.data,
          },
        },
      };

    case FETCH_UNSETTLED_BUYS_REPORT.pending:
      return {
        ...state,
        unsettledBuysFetching: true,
        error: null,
      };

    case FETCH_UNSETTLED_BUYS_REPORT.rejected:
      return {
        ...state,
        unsettledBuysFetching: false,
        error: parseError(action.payload),
      };

    case FETCH_UNSETTLED_BUYS_REPORT.fulfilled:
      return {
        ...state,
        unsettledBuysFetching: false,
        data: {
          ...state.data,
          unsettledBuys: {
            ...state.data.unsettledBuys,
            [action.meta.product]: action.payload.data,
          },
        },
      };

    case FETCH_UNSETTLED_SELLS_REPORT.pending:
      return {
        ...state,
        unsettledSellsFetching: true,
        error: null,
      };

    case FETCH_UNSETTLED_SELLS_REPORT.rejected:
      return {
        ...state,
        unsettledSellsFetching: false,
        error: parseError(action.payload),
      };

    case FETCH_UNSETTLED_SELLS_REPORT.fulfilled:
      return {
        ...state,
        unsettledSellsFetching: false,
        data: {
          ...state.data,
          unsettledSells: {
            ...state.data.unsettledSells,
            [action.meta.product]: action.payload.data,
          },
        },
      };

    default:
      return state;
  }
};
