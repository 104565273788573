import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import qs from 'qs';
import { pickBy } from 'lodash';

import {
  Page,
  ResourceList,
  Card,
  Stack,
  TextStyle,
  Icon,
  Layout,
} from '@shopify/polaris';
import { CircleDownMajor, CircleUpMajor } from '@shopify/polaris-icons';
import ReferralItem from './components/ReferralItem';
import Pagination from 'components/Pagination';

import { fetchReferrals } from 'redux/referrals/actions';
import {
  getReferralsSelector,
  getReferralsFetchingSelector,
  getReferralsMetaSelector,
} from 'redux/referrals/selectors';

function Referrals({
  fetchReferrals,
  fetching,
  referrals,
  meta,
  location,
  history,
}) {
  const updatePageHistory = useCallback(
    (key, value) => {
      const pathname = location.pathname;
      const searchParams = new URLSearchParams(location.search);
      searchParams.set(key, value);
      history.push({
        pathname: pathname,
        search: qs.stringify(pickBy(qs.parse(searchParams.toString()))),
      });
    },
    [history, location.pathname, location.search],
  );

  function handlePreviousPage() {
    updatePageHistory('page', meta.page - 1);
  }

  function handleNextPage() {
    updatePageHistory('page', meta.page + 1);
  }

  const resourceName = {
    singular: 'referral',
    plural: 'referrals',
  };

  useEffect(() => {
    fetchReferrals(location.search);
  }, [fetchReferrals, location.search]);

  return (
    <Page title="Referrals">
      <Layout>
        <Layout.Section>
          <Stack>
            <Icon source={CircleUpMajor} color="greenDark" />
            <TextStyle variation="strong">Referer</TextStyle>
            <Icon source={CircleDownMajor} color="purple" />
            <TextStyle variation="strong">Referee</TextStyle>
          </Stack>
          <br />
          <Card>
            <ResourceList
              resourceName={resourceName}
              items={referrals}
              renderItem={ReferralItem}
              loading={fetching}
              showHeader
              totalItemsCount={meta.total_entries_size}
            />
            <Pagination
              resource={referrals}
              meta={meta}
              handlePreviousPage={handlePreviousPage}
              handleNextPage={handleNextPage}
            />
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
const mapStateToProps = (state) => {
  return {
    fetching: getReferralsFetchingSelector(state),
    referrals: getReferralsSelector(state),
    meta: getReferralsMetaSelector(state),
  };
};

const mapDispatchToProps = {
  fetchReferrals,
};

export default connect(mapStateToProps, mapDispatchToProps)(Referrals);
