import React from 'react';
import { ResourceList, TextStyle, Link } from '@shopify/polaris';
import { renderStatusBadge } from '../../../utils/formatter';
import { capitalize } from 'utils/strings';
import { formatDateToReadableString } from 'utils/date';
import { formatNumberToMoney } from 'utils/money';

import styled from 'styled-components';

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 2fr) repeat(3, 1fr) 2fr;
  grid-gap: 10px;
  justify-items: start;
  align-items: start;
  & > * {
    white-space: nowrap;
    box-sizing: border-box;
  }
  & .right {
    justify-self: end;
  }
  &.chain {
    grid-template-columns: repeat(2, 2fr) 1fr 1fr 2fr 1fr 2fr;
  }
  @media only screen and (max-width: 900px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    &.chain {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
    & .right {
      justify-self: start;
    }
  }
`;

export default function OrderListItem({
  id,
  app,
  reference,
  created_at,
  price,
  status,
  bushaApp,
  side,
  user,
  quote_amount,
  net_base_amount,
  product,
  volume,
  value,
  analysis,
  settled,
  base,
  counter,
  chain_order_id,
  ...rest
}) {
  const grootOrDustOrderDisplay =
    bushaApp !== 'chain' &&
    status?.toLowerCase() === 'completed' &&
    chain_order_id
      ? 'subdued'
      : '';

  return (
    <ResourceList.Item
      id={id}
      accessibilityLabel={`View details for ${reference}`}
      url={`/orders/${bushaApp}/${id}`}
      {...rest}
    >
      <Container role="row" className={bushaApp}>
        <TextStyle
          variation={
            bushaApp !== 'chain' &&
            status?.toLowerCase() === 'completed' &&
            chain_order_id
              ? 'subdued'
              : 'strong'
          }
        >
          {bushaApp !== 'chain' &&
          status?.toLowerCase() === 'completed' &&
          chain_order_id ? (
            reference
          ) : (
            <Link url={`/orders/${bushaApp}/${id}`}>{reference}</Link>
          )}
        </TextStyle>

        <TextStyle variation={grootOrDustOrderDisplay}>
          {formatDateToReadableString(new Date(created_at))}
        </TextStyle>

        {bushaApp !== 'chain' && (
          <>
            <TextStyle variation={grootOrDustOrderDisplay}>
              {user?.name || 'N/A'}
            </TextStyle>
          </>
        )}

        {bushaApp === 'chain' && (
          <TextStyle variation={grootOrDustOrderDisplay}>
            {capitalize(app)}
          </TextStyle>
        )}

        <TextStyle variation={grootOrDustOrderDisplay}>
          {capitalize(side)}
        </TextStyle>

        {bushaApp === 'chain' && (
          <TextStyle variation={grootOrDustOrderDisplay}>
            {formatNumberToMoney(volume, {
              currencyCode: base,
              category: base,
              codePosition: 'back',
              maximumFractionDigits: 10,
            })}
          </TextStyle>
        )}

        {bushaApp === 'chain'
          ? renderStatusBadge(settled ? 'settled' : 'unsettled')
          : renderStatusBadge(status, {
              dark:
                bushaApp !== 'chain' && status?.toLowerCase() === 'completed',
            })}

        {bushaApp !== 'chain' &&
          renderStatusBadge(chain_order_id ? 'success' : 'pending', {
            dark: bushaApp !== 'chain' && chain_order_id,
          })}

        <div className="right">
          <TextStyle variation={grootOrDustOrderDisplay}>
            {formatNumberToMoney(bushaApp === 'chain' ? value : quote_amount, {
              currencyCode: counter,
              category: counter,
              codePosition: bushaApp === 'chain' ? 'back' : 'front',
              maximumFractionDigits: 10,
            })}{' '}
          </TextStyle>
        </div>
      </Container>
    </ResourceList.Item>
  );
}
