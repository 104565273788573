import React from 'react';

import { Modal, TextContainer } from '@shopify/polaris';

function VerificationModal({ active, close, sendVerification, loading }) {
  return (
    <div>
      <Modal
        open={active}
        onClose={() => close()}
        title="Verify customer"
        primaryAction={{
          content: 'Send email',
          onAction: () => sendVerification(),
          loading: loading,
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: () => close(),
          },
        ]}
      >
        <Modal.Section>
          <TextContainer>
            <p>
              Are you sure you want to re-send verification email to this
              customer? The customer will be sent an email with instructions to
              verify their account.
            </p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </div>
  );
}

export default VerificationModal;
