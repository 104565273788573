import React from 'react';
import { Form, FormLayout, TextField } from '@shopify/polaris';

export default function EditPhoneForm({
  values,
  handleInputChange,
  handleSubmit,
  errors,
  touched,
}) {
  return (
    <Form onSubmit={handleSubmit}>
      <FormLayout>
        <TextField
          value={values.phone}
          onChange={handleInputChange('phone')}
          type="tel"
          pattern="[0-9]{*}"
          label="Phone number"
          name="phone"
          error={errors.phone && touched.phone ? errors.phone : ''}
        />
      </FormLayout>
    </Form>
  );
}
