import React from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import {
  Page,
  Card,
  DataTable,
  Select,
  Stack,
  TextStyle,
} from '@shopify/polaris';
import LoadingSpinner from 'components/LoadingSpinner';
import Pagination from 'components/Pagination';
import EmptyTable from 'components/EmptyTable';
import { fetchCustomerActivities } from 'redux/customers/actions';
import { formatDateToReadableString } from 'utils/date';

export default function CustomerActivities() {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const {
    activities: { [params.id]: activity },
    activitiesFetching,
  } = useSelector((state) => state.customers);

  function fetchPreviousPage() {
    const page = activity?.meta?.page ? activity?.meta?.page - 1 : 1;
    history.push({
      pathname: location.pathname,
      search: `?page=${page}`,
    });
  }

  function fetchNextPage() {
    const page = activity?.meta?.page ? activity?.meta?.page + 1 : 1;
    history.push({
      pathname: location.pathname,
      search: `?page=${page}`,
    });
  }

  const rows =
    activity?.data.map((_activity) => [
      formatDateToReadableString(new Date(_activity.created_at)),
      _activity.action,
      _activity.agent,
      <Stack vertical spacing="extraTight" key={_activity.id}>
        <TextStyle>{_activity.location}</TextStyle>
        <TextStyle>{_activity.ip}</TextStyle>
      </Stack>,
    ]) || [];

  React.useEffect(() => {
    if (location.search) {
      const queryValues = queryString.parse(location.search);
      const { page } = queryValues;
      if (page) dispatch(fetchCustomerActivities(params.id, page.toString()));
    } else {
      dispatch(fetchCustomerActivities(params.id));
    }
  }, [location.search, dispatch, params.id]);

  return (
    <Page
      title="Account Activities"
      breadcrumbs={[
        {
          content: 'Customer',
          url: `/customers/${params.id}`,
        },
      ]}
    >
      <Card>
        <Card.Section>
          <Stack alignment="center" distribution="equalSpacing">
            <TextStyle>
              {activitiesFetching
                ? 'Loading activities'
                : `Showing ${
                    activity?.meta?.current_entries_size || 0
                  } activities`}
            </TextStyle>
            <Select
              label="Sort by"
              labelInline
              options={[{ label: 'Created at', value: 'created_at' }]}
            />
          </Stack>
        </Card.Section>
        {activitiesFetching ? (
          <LoadingSpinner />
        ) : (
          <React.Fragment>
            {rows?.length > 0 ? (
              <DataTable
                columnContentTypes={['text', 'text', 'text', 'numeric']}
                headings={['Date', 'Action', 'Device', 'Location']}
                rows={rows}
                truncate={true}
                verticalAlign="middle"
              />
            ) : (
              <Card.Section>
                <EmptyTable resourceName="activities" />
              </Card.Section>
            )}
          </React.Fragment>
        )}
        <Pagination
          resource={activity?.data}
          handleNextPage={fetchNextPage}
          handlePreviousPage={fetchPreviousPage}
          meta={activity?.meta}
        />
      </Card>
    </Page>
  );
}
