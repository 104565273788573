import React, { Fragment } from 'react';
import { Card, Button, TextStyle, Stack } from '@shopify/polaris';

const StaffAccountActions = ({
  profile,
  loading,
  toggleAccountStatus,
  deleteStaffAccount,
}) => {
  return (
    <Fragment>
      <Card sectioned>
        <Card.Section
          title={`${profile?.active ? 'DEACTIVATE' : 'ACTIVATE'} STAFF ACCOUNT`}
        >
          <Stack vertical>
            <TextStyle variation="subdued">
              {profile?.active
                ? ` Deactivated staff accounts will no longer have access to your
                    this platform. You can re-activate staff accounts at any time.`
                : `Activated staff accounts will have access to your
                    this platform. You can deactivate staff accounts at any time.`}
            </TextStyle>
            <Button
              disabled={loading}
              destructive={profile?.active}
              onClick={toggleAccountStatus}
            >
              {`${profile?.active ? 'Deactivate' : 'Activate'} staff account`}
            </Button>
          </Stack>
        </Card.Section>
      </Card>
      <Card sectioned>
        <Card.Section title="DELETE STAFF ACCOUNT">
          <Stack vertical>
            <TextStyle variation="subdued">
              Deleted staff accounts will be permanently removed from your
              store. This action cannot be reversed.
            </TextStyle>
            <Button destructive disabled={loading} onClick={deleteStaffAccount}>
              Delete staff account
            </Button>
          </Stack>
        </Card.Section>
      </Card>
    </Fragment>
  );
};

export default StaffAccountActions;
