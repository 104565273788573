import React from 'react';
import { Stack, Spinner } from '@shopify/polaris';

export default function LoadingSpinner() {
  return (
    <Stack alignment="center" vertical spacing="extraLoose">
      <div
        style={{
          padding: 50,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spinner
          accessibilityLabel="Spinner example"
          size="large"
          color="teal"
        />
      </div>
    </Stack>
  );
}
