import React from 'react';
import {
  SkeletonBodyText,
  SkeletonPage,
  TextContainer,
  Layout,
  Card,
  SkeletonDisplayText,
} from '@shopify/polaris';

const InvitePageSkeleton = () => {
  return (
    <SkeletonPage secondaryActions={2}>
      <Layout>
        <Layout.Section secondary>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );
};

export default InvitePageSkeleton;
