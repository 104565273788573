import React from 'react';
import { Card, TextStyle } from '@shopify/polaris';
import { getPayoutHashLink } from 'utils/helpers';

function DepositHash({ hash, currency }) {
  return (
    <Card.Section title="Hash" sectioned>
      <div className="break-text">
        {!!hash?.trim() ? (
          <a
            href={getPayoutHashLink(currency, hash)}
            external={true}
            className="anchor"
            target="_blank"
            rel="noopener noreferrer"
          >
            {hash}
          </a>
        ) : (
          <TextStyle variation="subdued">No hash</TextStyle>
        )}
      </div>
    </Card.Section>
  );
}

export default DepositHash;
