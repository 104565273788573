import React from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Page,
  Layout,
  Card,
  PageActions,
  FormLayout,
  TextField,
  Checkbox,
} from '@shopify/polaris';
import { addNewStaff } from 'redux/staff/actions';

function CreateAccount() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { updating: loading } = useSelector((state) => state.staff);

  const validate = (values) => {
    const errors = {};

    Object.keys(values).forEach((key) => {
      if (key === 'full_access') return;

      if (!values[key]) {
        errors[key] = 'Field is required';
      }
    });

    return errors;
  };

  const form = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      full_access: false,
    },
    onSubmit(values) {
      let payload = { ...values };
      if (!values.full_access) {
        // if the user is not to be given full access, set the permission as home
        payload = { ...values, full_access: undefined, permissions: ['home'] };
      }
      dispatch(addNewStaff(payload)).then(() => {
        history.push('/settings/accounts');
      });
    },
    validate,
    validateOnChange: false,
  });

  return (
    <Page
      breadcrumbs={[{ content: 'Account Settings', url: '/settings/accounts' }]}
      title="Add a new staff account"
    >
      <Layout>
        <Layout.AnnotatedSection
          title="Staff account"
          description="Give staff access to this platform by sending them an invitation"
        >
          <form onSubmit={form.handleSubmit}>
            <Card>
              <Card.Section>
                <FormLayout>
                  <FormLayout.Group>
                    <TextField
                      value={form.values.first_name}
                      onChange={(value) =>
                        form.setFieldValue('first_name', value)
                      }
                      label="First Name"
                      name="first_name"
                      type="text"
                      placeholder="John"
                      error={form.errors.first_name}
                    />
                    <TextField
                      value={form.values.last_name}
                      onChange={(value) =>
                        form.setFieldValue('last_name', value)
                      }
                      label="Last Name"
                      name="last_name"
                      type="text"
                      placeholder="Doe"
                      error={form.errors.last_name}
                    />
                  </FormLayout.Group>
                  <TextField
                    value={form.values.email}
                    onChange={(value) => form.setFieldValue('email', value)}
                    label="Email"
                    name="email"
                    type="email"
                    placeholder="john.doe@busha.co"
                    error={form.errors.email}
                  />
                </FormLayout>
              </Card.Section>
              <Card.Section title="Set Permissions">
                <Checkbox
                  label="Full access"
                  checked={form.values.full_access}
                  onChange={(checked) =>
                    form.setFieldValue('full_access', checked)
                  }
                  helpText="This gives the user full access to all the features on this platform"
                />
              </Card.Section>
            </Card>
          </form>
        </Layout.AnnotatedSection>
      </Layout>
      <PageActions
        primaryAction={{
          content: 'Send Invite',
          loading: loading,
          onAction: form.handleSubmit,
        }}
      />
    </Page>
  );
}

export default CreateAccount;
