import React, { Fragment } from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';

import { Page, Layout, Badge } from '@shopify/polaris';
import {
  CircleDisableMinor,
  ResetMinor,
  InviteMinor,
  CircleTickMajor,
} from '@shopify/polaris-icons';

import LoadingPage from 'components/LoadingPage';

import StatusModal from './components/StatusModal';
import PasswordModal from './components/PasswordModal';
import VerificationModal from './components/VerificationModal';
import EditWalletBalanceModal from './components/EditWalletBalanceModal';
import EditPhoneModal from './components/EditPhoneModal';
import EditAddressModal from './components/EditAddressModal';
import EditProfileModal from './components/EditProfileModal';
import UpdateBvnModal from './components/UpdateBvnModal';
import CustomerActivateInactiveWalletModal from './components/CustomerActivateInactiveWalletModal';
import CustomerSummary from './components/CustomerSummary';
import CustomerAddress from './components/CustomerAddress';
import CustomerWallets from './components/CustomerWallets';
import CustomerTransactions from './components/CustomerTransactions';
import CustomerProfile from './components/CustomerProfile';
import CustomerLevel from './components/CustomerLevel';
import CustomerActions from './components/CustomerActions';
import CustomerSecurity from './components/CustomerSecurity';
import CustomerDocuments from './components/CustomerDocuments';
import CustomerBanksCardsAndCryptoAddresses from './components/CustomerBanksCardsAndCryptoAddresses';
import CustomerActivitiesAndDevices from './components/CustomerActivitiesAndDevices';
import CustomerWhiteListedNames from './components/CustomerWhiteListedNames';
import CustomerRecurringOrder from './components/CustomerRecurringOrder';

import { withConfirmDialog } from 'utils/confirmationService';
import { PreviousLocationContext } from 'providers/PreviousLocationProvider';

import './Customer.css';
import useViewCustomer from './useViewCustomer';

const ViewCustomer = (props) => {
  const {
    customer,
    wallets,
    transactionsFetching,
    transactions,
    walletsFetching,
    inactiveWallets,
    inactiveWalletsFetching,
    handleActivateInactiveWallet,
    transactionsMeta,
    fetching,
    bvnDetailsDeleting,
    documents,
    cards,
    banks,
    documentsFetching,
    cardsFetching,
    banksFetching,
    editing,
    error,
    updateVerificationsDetails,
    verificationEditloading,
    bvnDetails,
    bvnDetailsError,
    bvnDetailsFetching,
    cryptoAddresses,
    cryptoAddressesFetching,
    whitelistedNames,
    whitelistedNamesFetching,
    state,
    toggleModal,
    onUpdateVerification,
    handleSelectedWalletCurrency,
    handleTxnNextPage,
    handleTxnPreviousPage,
    modifyTransactionStatus,
    handleUpdateCustomerLevel,
    toggleFeature,
    handleDeleteWhitelistedName,
    handleWhitelistedNamesNextPage,
    handleWhitelistedNamesPreviousPage,
    handleCustomerStatus,
    handleCustomerPassword,
    handleCustomerVerification,
    handleEditCustomerWalletBalance,
    handleEditCustomerPhone,
    handleEditCustomerAddress,
    handleEditCustomerProfile,
    handleUpdateCustomerBvn,
    handleEditCustomerBvnDetails,
    handleRemoveCustomerBvn,
  } = useViewCustomer({ activities: props.activities });
  const { path } = useRouteMatch();

  const { previousLocation } = React.useContext(PreviousLocationContext);

  if (error) {
    return <Redirect to="/customers" />;
  }

  return (
    <>
      {fetching && !customer && <LoadingPage />}
      {customer && (
        <Fragment>
          <Page
            breadcrumbs={[
              {
                content: 'Customers',
                url: previousLocation
                  ? `${previousLocation.pathname}${previousLocation.search}`
                  : path.split(':')[0],
              },
            ]}
            title={`${customer?.profile?.first_name || ''} ${
              customer?.profile?.last_name || ''
            }`}
            titleMetadata={
              <Fragment>
                <Badge
                  progress={
                    customer.level < 3 ? 'partiallyComplete' : 'complete'
                  }
                >{`Level ${customer.level}`}</Badge>
                <Badge
                  progress={customer.active ? 'complete' : 'incomplete'}
                  status={customer.active ? 'success' : 'attention'}
                >
                  {customer.active ? 'Active' : 'Inactive'}
                </Badge>
                <Badge
                  progress={customer.verified ? 'complete' : 'incomplete'}
                  status={customer.verified ? 'success' : 'warning'}
                >
                  {customer.verified ? 'Verified' : 'Not verified'}
                </Badge>
              </Fragment>
            }
            secondaryActions={[
              {
                content: customer.active ? 'Disable account' : 'Enable account',
                icon: customer.active ? CircleDisableMinor : CircleTickMajor,
                onAction: () => toggleModal('statusModal'),
              },
              {
                content: customer.vip
                  ? 'Disable VIP status'
                  : 'Enable VIP status',
                icon: customer.vip ? CircleDisableMinor : CircleTickMajor,
                onAction: () => toggleFeature('vip_status'),
                loading: editing,
              },
              {
                content: customer.merchant
                  ? 'Disable Merchant status'
                  : 'Enable Merchant status',
                icon: customer.merchant ? CircleDisableMinor : CircleTickMajor,
                onAction: () => toggleFeature('merchant_status'),
                loading: editing,
              },
              {
                content: 'Reset password',
                icon: ResetMinor,
                onAction: () => toggleModal('passwordModal'),
              },
              !customer.verified && {
                content: 'Resend verification email',
                icon: InviteMinor,
                onAction: () => toggleModal('verificationModal'),
              },
            ].filter(Boolean)}
          >
            <Layout>
              <Layout.Section>
                <CustomerSummary customer={customer} />
                <CustomerDocuments
                  documents={documents}
                  fetching={documentsFetching}
                  updateVerificationsDetails={updateVerificationsDetails}
                  verificationEditloading={verificationEditloading}
                  onUpdateVerification={onUpdateVerification}
                />
                <CustomerWallets
                  wallets={wallets}
                  walletsFetching={walletsFetching}
                  toggleEditBalanceModal={() => {
                    toggleModal('editBalanceModal');
                  }}
                  toggleAddWalletModal={() => {
                    toggleModal('activateInactiveWalletModal');
                  }}
                  setSelectedWalletCurrency={handleSelectedWalletCurrency}
                />
                <CustomerTransactions
                  transactionsFetching={transactionsFetching}
                  transactions={transactions}
                  transactionsMeta={transactionsMeta}
                  handleTxnNextPage={handleTxnNextPage}
                  handleTxnPreviousPage={handleTxnPreviousPage}
                  modifyTransactionStatus={modifyTransactionStatus}
                />
              </Layout.Section>
              <Layout.Section secondary>
                <CustomerProfile
                  customer={customer}
                  toggleEditProfileModal={() => {
                    toggleModal('editProfileModal');
                  }}
                />
                <CustomerLevel
                  currentLevel={customer.level}
                  updateLevel={handleUpdateCustomerLevel}
                  name={customer?.profile?.first_name || 'User'}
                  loading={editing}
                />
                <CustomerActions
                  payout={customer.payout}
                  deposit={customer.deposit}
                  trade={customer.trade}
                  p2p={customer.p2p}
                  loading={editing}
                  toggleAction={toggleFeature}
                />
                <CustomerSecurity
                  twofaStatus={customer.setting.two_fa_on_login}
                  deactivate2FA={() => toggleFeature('mfa')}
                  loading={editing}
                />
                <CustomerAddress
                  customer={customer}
                  toggleEditPhoneModal={() => toggleModal('editPhoneModal')}
                  toggleEditAddressModal={() => toggleModal('editAddressModal')}
                  toggleUpdateBvnModal={() => toggleModal('updateBvnModal')}
                  bvnDetails={bvnDetails}
                  bvnDetailsError={bvnDetailsError}
                  bvnDetailsFetching={bvnDetailsFetching}
                />
                <CustomerBanksCardsAndCryptoAddresses
                  addresses={cryptoAddresses}
                  cards={cards}
                  banks={banks}
                  cardsFetching={cardsFetching}
                  banksFetching={banksFetching}
                  addressesFetching={cryptoAddressesFetching}
                />
                <CustomerWhiteListedNames
                  {...{
                    whitelistedNames,
                    fetching: whitelistedNamesFetching,
                    deleting: editing,
                    handleDeleteName: handleDeleteWhitelistedName,
                    handlePreviousPage: handleWhitelistedNamesPreviousPage,
                    handleNextPage: handleWhitelistedNamesNextPage,
                  }}
                />
                <CustomerActivitiesAndDevices
                  name={customer?.profile?.first_name}
                />
                <CustomerRecurringOrder name={customer?.profile?.first_name} />
              </Layout.Section>
            </Layout>
          </Page>
          <StatusModal
            active={state.statusModal}
            status={customer && customer.active}
            close={() => toggleModal('statusModal')}
            setStatus={handleCustomerStatus}
          />
          <PasswordModal
            active={state.passwordModal}
            close={() => toggleModal('passwordModal')}
            resetPassword={handleCustomerPassword}
          />
          <VerificationModal
            active={state.verificationModal}
            close={() => toggleModal('verificationModal')}
            sendVerification={handleCustomerVerification}
            loading={editing}
          />
          <EditWalletBalanceModal
            open={state.editBalanceModal}
            handleClose={() => {
              toggleModal('editBalanceModal');
            }}
            currency={state.selectedWalletCurrency}
            loading={editing}
            onAction={handleEditCustomerWalletBalance}
          />
          <EditPhoneModal
            open={state.editPhoneModal}
            handleClose={() => toggleModal('editPhoneModal')}
            loading={editing}
            onAction={handleEditCustomerPhone}
            phoneNumber={
              customer && customer.phone && customer.phone.number
                ? customer.phone.number
                : ''
            }
          />
          <EditAddressModal
            open={state.editAddressModal}
            handleClose={() => {
              toggleModal('editAddressModal');
            }}
            loading={editing}
            onAction={handleEditCustomerAddress}
            initialValues={customer.profile}
          />
          <EditProfileModal
            open={state.editProfileModal}
            handleClose={() => toggleModal('editProfileModal')}
            loading={editing}
            onAction={handleEditCustomerProfile}
            customer={customer}
          />
          <UpdateBvnModal
            name={customer?.profile?.first_name || 'User'}
            open={state.updateBvnModal}
            bvnDetails={bvnDetails}
            handleClose={() => toggleModal('updateBvnModal')}
            loading={editing}
            handleUpdateBvn={handleUpdateCustomerBvn}
            handleEditBvn={handleEditCustomerBvnDetails}
            handleRemoveBvn={handleRemoveCustomerBvn}
            bvnDetailsDeleting={bvnDetailsDeleting}
          />
          <CustomerActivateInactiveWalletModal
            name={customer?.profile?.first_name || 'User'}
            open={state.activateInactiveWalletModal}
            handleClose={() => toggleModal('activateInactiveWalletModal')}
            {...{
              editing,
              inactiveWallets,
              inactiveWalletsFetching,
              handleActivateInactiveWallet,
            }}
          />
        </Fragment>
      )}
    </>
  );
};

export default withConfirmDialog(ViewCustomer);
