import { FETCH_PERMISSIONS } from './actionTypes';
import parseError from 'utils/parseError';

const INITIAL_STATE = {
  data: { general_permissions: [], settings_permissions: [] },
  fetching: false,
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_PERMISSIONS.pending:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case FETCH_PERMISSIONS.rejected:
      return {
        ...state,
        fetching: false,
        error: parseError(action.payload),
      };

    case FETCH_PERMISSIONS.fulfilled:
      return {
        ...state,
        fetching: false,
        data: action.payload.data,
      };

    default:
      return state;
  }
};
