import React from 'react';
import { Form, FormLayout, TextField } from '@shopify/polaris';

export default function EditNoteForm({
  values,
  handleInputChange,
  handleSubmit,
}) {
  return (
    <Form onSubmit={handleSubmit}>
      <FormLayout>
        <TextField
          value={values.note}
          onChange={handleInputChange('note')}
          label="Note"
          name="note"
        />
      </FormLayout>
    </Form>
  );
}
