import React, { Fragment } from 'react';
import { Card, Link, Stack, Heading } from '@shopify/polaris';
import AvatarInitials from 'components/AvatarInitials';

export default function ReferralUsers({
  refererName,
  referee_user_id,
  referrer_user_id,
  refereeName,
}) {
  return (
    <Fragment>
      <Card>
        <Card.Section
          title={
            <Stack distribution="equalSpacing">
              <Stack.Item>
                <Heading>Referrer</Heading>
              </Stack.Item>
              <Stack.Item>
                <Link url={`/customers/${referrer_user_id}`}>
                  <AvatarInitials firstName={refererName} size="small" />
                </Link>
              </Stack.Item>
            </Stack>
          }
        >
          <Link url={`/customers/${referee_user_id}`}>{refererName}</Link>
        </Card.Section>
        <Card.Section
          title={
            <Stack distribution="equalSpacing">
              <Stack.Item>
                <Heading>Referee</Heading>
              </Stack.Item>
              <Stack.Item>
                <Link url={`/customers/${referee_user_id}`}>
                  <AvatarInitials firstName={refereeName} size="small" />
                </Link>
              </Stack.Item>
            </Stack>
          }
          sectioned
        >
          <Link url={`/customers/${referee_user_id}`}>{refereeName}</Link>
        </Card.Section>
      </Card>
    </Fragment>
  );
}
