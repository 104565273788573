import React, { useState, useMemo } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useUpdatedEffect from 'hooks/useUpdatedEffect';
import useQueryParams from 'hooks/useQueryParams';
import { orderBy, debounce, mapKeys } from 'lodash';
import { capitalize } from 'utils/strings';

import {
  Page,
  ResourceList,
  Card,
  Filters,
  ChoiceList,
  Stack,
  TextStyle,
  Select,
  Layout,
  Badge,
  FormLayout,
} from '@shopify/polaris';
import DatePicker from 'react-datepicker';
import OrderListHeader from './components/OrderListHeader';
import OrderListItem from './components/OrderListItem';
import Pagination from 'components/Pagination';
import ImportTradeByCSVModal from './pages/components/ImportTradeByCSVModal';

import { fetchOrders } from '../../redux/orders/actions';
import { fetchBooks } from 'redux/books/actions';
import { formateDateToISOWithoutSSSZ, getStartOfDays } from 'utils/date';
import EmptyTable from 'components/EmptyTable';
import useDateFilter from 'hooks/useDateFilter';
import { isInitialQuerySetWithRange } from 'utils/helpers';

const filterPeriods = [
  {
    label: 'Today',
    value: 'today',
  },
  {
    label: 'Yesterday',
    value: 'yesterday',
  },
  {
    label: 'Last 7 days',
    value: 'last-7-days',
  },
  {
    label: 'Last 30 days',
    value: 'last-30-days',
  },
  {
    label: 'Custom Range',
    value: 'custom-range',
  },
];

const periodDatesAndLabels = {
  today: {
    label: 'Today',
    getStartAndEndDates: () => ({ from: new Date(), to: new Date() }),
  },
  yesterday: {
    label: 'Yesterday',
    getStartAndEndDates: () => ({
      from: getStartOfDays(1),
      to: getStartOfDays(1),
    }),
  },
  'last-7-days': {
    label: 'Last 7 days',
    getStartAndEndDates: () => ({
      from: getStartOfDays(7),
      to: new Date(),
    }),
  },
  'last-30-days': {
    label: 'Last 30 days',
    getStartAndEndDates: () => ({
      from: getStartOfDays(30),
      to: new Date(),
    }),
  },
};

function Orders() {
  const dispatch = useDispatch();
  const location = useLocation();
  const match = useRouteMatch();
  const intialQueryParams = useMemo(() => {
    const initialURLQueryHasRangeSet = isInitialQuerySetWithRange();

    return {
      app: '',
      q: '',
      chain: '',
      status: '',
      settled: '',
      side: '',
      page: 1,
      product: '',
      period: match.params.app === 'chain' ? 'day' : '',
      for:
        match.params.app === 'chain' && !initialURLQueryHasRangeSet
          ? formateDateToISOWithoutSSSZ(new Date())
          : '',
      from: '',
      to: '',
    };
  }, [match.params.app]);

  const { data, fetching, meta, products } = useSelector((state) => ({
    ...state.orders,
    products: Object.keys(mapKeys(Object.values(state.books.data), 'product')),
  }));

  const { queryParams, updateQueryParams, clearAllFilters } =
    useQueryParams(intialQueryParams);
  const {
    handleChangeInSelectedPeriod,
    selectedDates,
    selectedPeriod,
    setSelectedDates,
  } = useDateFilter();

  const [sortValue, setSortValue] = useState('newest');
  const [showImportTradeModal, setShowImportTradeModal] = useState(false);

  function openImportTradeModal() {
    setShowImportTradeModal(true);
  }

  function closeImportTradeModal() {
    setShowImportTradeModal(false);
  }

  function handlePreviousPage() {
    updateQueryParams('page', meta.page - 1);
  }

  function handleNextPage() {
    updateQueryParams('page', meta.page + 1);
  }

  const resourceName = {
    singular: 'order',
    plural: 'orders',
  };

  const filters = useMemo(() => {
    const statusOptions =
      match.params.app === 'chain'
        ? [
            { label: 'Settled', value: true },
            { label: 'Unsettled', value: false },
          ]
        : [
            { label: 'Completed', value: 'completed' },
            { label: 'Pending', value: 'pending' },
            { label: 'Expired Quote', value: 'expired quote' },
            { label: 'Abandoned', value: 'abandoned' },
          ];

    const pageFilters = [
      {
        key: 'date',
        label: 'Date',
        disabled: match.params.app !== 'chain',
        filter: (
          <FormLayout>
            <Select
              options={filterPeriods}
              label="Date range"
              onChange={handleChangeInSelectedPeriod}
              value={selectedPeriod}
            />
            {selectedPeriod === 'custom-range' && (
              <FormLayout.Group>
                <div>
                  <label htmlFor="date_of_birth">Starting</label>
                  <DatePicker
                    selected={selectedDates.from}
                    onChange={(date) =>
                      setSelectedDates((dates) => ({ ...dates, from: date }))
                    }
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
                <div>
                  <label htmlFor="date_of_birth">Ending</label>
                  <DatePicker
                    selected={selectedDates.to}
                    onChange={(date) =>
                      setSelectedDates((dates) => ({ ...dates, to: date }))
                    }
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
              </FormLayout.Group>
            )}
          </FormLayout>
        ),
      },
      {
        key: 'app',
        label: 'App',
        disabled: match.params.app !== 'chain',
        filter: (
          <ChoiceList
            title="App"
            titleHidden
            choices={[
              { label: 'Groot', value: 'groot' },
              { label: 'Dust', value: 'dust' },
            ]}
            selected={queryParams.app}
            onChange={(value) => updateQueryParams('app', value)}
          />
        ),
        shortcut: true,
      },
      {
        key: 'chain',
        label: 'Chain',
        disabled: match.params.app === 'chain',
        filter: (
          <ChoiceList
            title="Chain"
            titleHidden
            choices={[
              { label: 'Created', value: 'created' },
              { label: 'Pending', value: 'pending' },
            ]}
            selected={queryParams.chain}
            onChange={(value) => updateQueryParams('chain', value)}
          />
        ),
        shortcut: true,
      },
      {
        key: match.params.app === 'chain' ? 'settled' : 'status',
        label: match.params.app === 'chain' ? 'Settled' : 'Status',
        filter: (
          <ChoiceList
            title="Status"
            titleHidden
            choices={statusOptions}
            selected={
              match.params.app === 'chain'
                ? queryParams.settled
                : queryParams.status
            }
            onChange={(value) =>
              updateQueryParams(
                match.params.app === 'chain' ? 'settled' : 'status',
                value,
              )
            }
          />
        ),
        shortcut: true,
      },
      {
        key: 'side',
        label: 'Side',
        filter: (
          <ChoiceList
            title="Side"
            titleHidden
            choices={[
              { label: 'Buy', value: 'buy' },
              { label: 'Sell', value: 'sell' },
            ]}
            selected={queryParams.side}
            onChange={(value) => updateQueryParams('side', value)}
          />
        ),
        shortcut: true,
      },
      {
        key: 'product',
        label: 'Products',
        filter: (
          <ChoiceList
            title="Products"
            titleHidden
            choices={products.map((key) => ({
              label: `${key}`,
              value: `${key}`,
            }))}
            selected={queryParams.product}
            onChange={(value) => updateQueryParams('product', value)}
          />
        ),
      },
    ];
    return pageFilters;
  }, [
    match.params.app,
    handleChangeInSelectedPeriod,
    selectedPeriod,
    selectedDates.from,
    selectedDates.to,
    queryParams.app,
    queryParams.chain,
    queryParams.settled,
    queryParams.status,
    queryParams.side,
    queryParams.product,
    products,
    setSelectedDates,
    updateQueryParams,
  ]);

  const orders = useMemo(() => {
    const ordersList = orderBy(Object.values(data), ['created_at'], ['desc']);
    if (sortValue === 'oldest') {
      return ordersList.reverse();
    }
    return ordersList;
  }, [data, sortValue]);

  const shouldDisplayImportbutton = match.params.app === 'chain';

  const appliedFilters = [];

  if (queryParams.side) {
    appliedFilters.push({
      key: 'side',
      label: `Side ${queryParams.side}`,
      onRemove: () => updateQueryParams('side', ''),
    });
  }
  if (queryParams.app) {
    appliedFilters.push({
      key: 'app',
      label: `App ${queryParams.app}`,
      onRemove: () => updateQueryParams('app', ''),
    });
  }
  if (queryParams.status) {
    appliedFilters.push({
      key: 'status',
      label: `Status ${queryParams.status}`,
      onRemove: () => updateQueryParams('status', ''),
    });
  }

  if (queryParams.settled) {
    appliedFilters.push({
      key: 'settled',
      label: `Settled ${queryParams.settled}`,
      onRemove: () => updateQueryParams('settled', ''),
    });
  }

  if (queryParams.chain) {
    appliedFilters.push({
      key: 'chain',
      label: `Chain ${queryParams.chain}`,
      onRemove: () => updateQueryParams('chain', ''),
    });
  }

  if (queryParams.product) {
    appliedFilters.push({
      key: 'product',
      label: `Product ${queryParams.product}`,
      onRemove: () => updateQueryParams('product', ''),
    });
  }

  React.useEffect(
    React.useCallback(() => {
      debounce(() => {
        dispatch(fetchOrders(match.params.app, location.search));
      }, 500)();
    }, [dispatch, location.search, match.params.app]),
    [location.search, match.params.app],
  );

  React.useEffect(() => {
    dispatch(fetchBooks('?limit=500'));
  }, [dispatch]);

  useUpdatedEffect(() => {
    if (
      selectedDates.to !== selectedDates.from &&
      match.params.app === 'chain'
    ) {
      updateQueryParams({
        from: formateDateToISOWithoutSSSZ(selectedDates?.from),
        to: formateDateToISOWithoutSSSZ(selectedDates?.to),
        period: 'range',
        for: '',
      });
    }
  }, [selectedDates]);

  useUpdatedEffect(() => {
    switch (selectedPeriod) {
      case 'today':
      case 'yesterday':
        updateQueryParams({
          from: '',
          to: '',
          period: 'day',
          for: formateDateToISOWithoutSSSZ(
            periodDatesAndLabels[selectedPeriod]?.getStartAndEndDates().from,
          ),
        });
        break;
      case 'last-7-days':
      case 'last-30-days':
        updateQueryParams({
          period: 'range',
          for: '',
        });

        break;

      default:
        break;
    }
  }, [selectedPeriod]);

  const reset = () => {
    clearAllFilters();
    if (match.params.app === 'chain') {
      handleChangeInSelectedPeriod('day');
    }
  };

  useUpdatedEffect(() => {
    reset();
  }, [match.params.app]);

  return (
    <Page
      title={`${capitalize(match.params.app)} Orders`}
      fullWidth
      {...(shouldDisplayImportbutton && {
        secondaryActions: [
          {
            content: 'Import',
            onAction: openImportTradeModal,
          },
        ],
      })}
    >
      <Layout>
        <Layout.Section>
          <Card>
            <Card.Section>
              <span className="search-help-text">
                Enter search text, then press <Badge>ENTER</Badge> or{' '}
                <Badge>RETURN</Badge> to search
              </span>
              <Filters
                queryValue={queryParams.q}
                filters={filters}
                appliedFilters={appliedFilters}
                onQueryChange={(value) => updateQueryParams('q', value)}
                onQueryClear={() => updateQueryParams('q', '')}
                onClearAll={clearAllFilters}
              />
              <br />
              <Stack distribution="equalSpacing" alignment="center">
                <TextStyle variation="subdued">
                  {fetching
                    ? 'Loading orders...'
                    : `Showing ${meta?.current_entries_size || 0} orders(s)`}
                </TextStyle>
                <Select
                  label="Sort By"
                  options={[
                    { label: 'Newest', value: 'newest' },
                    { label: 'Oldest', value: 'oldest' },
                  ]}
                  onChange={setSortValue}
                  value={sortValue}
                  labelInline={true}
                />
              </Stack>
            </Card.Section>
            {!fetching && orders.length > 0 && (
              <OrderListHeader app={match.params.app} />
            )}
            <ResourceList
              loading={fetching}
              resourceName={resourceName}
              items={orders}
              renderItem={(item) => (
                <OrderListItem bushaApp={match.params.app} {...item} />
              )}
              showHeader={false}
              emptyState={
                <EmptyTable resourceName={`${match.params.app} order`} />
              }
            />
            <Pagination
              resource={orders}
              meta={meta}
              handlePreviousPage={handlePreviousPage}
              handleNextPage={handleNextPage}
            />
          </Card>
        </Layout.Section>
      </Layout>
      <ImportTradeByCSVModal
        onClose={closeImportTradeModal}
        open={showImportTradeModal}
      />
    </Page>
  );
}

export default Orders;
