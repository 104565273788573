import React from 'react';
import { useSelector } from 'react-redux';
import { Navigation } from '@shopify/polaris';
import {
  OutgoingMajor,
  IncomingMajor,
  OrdersMajor,
  PaymentsMajor,
  CustomersMajor,
  SettingsMajor,
  StoreMajor,
  ReportsMajor,
} from '@shopify/polaris-icons';

function SideBar() {
  const hasFullAccess = useSelector(
    (state) => state.profile?.data?.full_access,
  );

  return (
    <Navigation location={window.location.pathname}>
      <Navigation.Section items={TopNavItems} />
      <Navigation.Section items={BottomNavItems(hasFullAccess)} />
    </Navigation>
  );
}

const TopNavItems = [
  {
    label: 'Customers',
    icon: CustomersMajor,
    url: '/customers',
    subNavigationItems: [
      {
        url: '/customers',
        label: 'All Customers',
        matchPaths: [
          '/customers',
          '/customers/:id',
          '/customers/:id/activities',
          '/customers/:id/devices',
          '/customers/:id/account/:currency',
        ],
      },
      {
        url: '/customers/verifications',
        label: 'Verifications',
        matchPaths: [
          '/customers/verifications/',
          '/customers/verifications/:id',
        ],
      },
      {
        url: '/customers/documents',
        label: 'Documents',
        matchPaths: [
          '/customers/documents/',
          '/customers/documents/:customer_id/:document_id',
        ],
      },
      {
        url: '/customers/referrals',
        label: 'Referrals',
        matchPaths: ['/customers/referrals', '/customers/referrals/:id'],
      },
    ],
  },
  {
    url: '/orders/groot',
    label: 'Orders',
    icon: OrdersMajor,
    exactMatch: true,
    subNavigationItems: [
      {
        url: '/orders/groot',
        label: 'Groot',
        matchPaths: ['/orders/groot', '/orders/groot/:id'],
      },
      {
        url: '/orders/dust',
        label: 'Dust',
        matchPaths: ['/orders/dust', '/orders/dust/:id'],
      },
      {
        url: '/orders/chain',
        label: 'Chain',
        matchPaths: ['/orders/chain', '/orders/chain/:id'],
      },
      {
        url: '/orders/reports',
        label: 'Reports',
        matchPaths: ['/orders/reports', '/orders/reports/:product'],
      },
    ],
  },
  {
    url: '/books',
    label: 'Books',
    icon: ReportsMajor,
    matchPaths: ['/books', '/books/:id'],
  },
  {
    label: 'Payouts',
    icon: OutgoingMajor,
    url: '/payouts/groot',
    subNavigationItems: [
      {
        url: '/payouts/groot',
        label: 'Groot',
        matchPaths: ['/payouts/groot', '/payouts/groot/:id'],
      },
      {
        url: '/payouts/dust',
        label: 'Dust',
        matchPaths: ['/payouts/dust', '/payouts/dust/:id'],
      },
    ],
  },
  {
    label: 'Crypto Deposits',
    icon: IncomingMajor,
    url: '/deposits/crypto-deposits/groot',
    subNavigationItems: [
      {
        url: '/deposits/crypto-deposits/groot',
        label: 'Groot',
        matchPaths: [
          '/deposits/crypto-deposits/groot',
          '/deposits/crypto-deposits/groot/:id',
        ],
      },
      {
        url: '/deposits/crypto-deposits/dust',
        label: 'Dust',
        matchPaths: [
          '/deposits/crypto-deposits/dust',
          '/deposits/crypto-deposits/dust/:id',
        ],
      },
    ],
  },
  {
    label: 'Cash Deposits',
    icon: PaymentsMajor,
    url: '/deposits/cash-deposits/groot',
    subNavigationItems: [
      {
        url: '/deposits/cash-deposits/groot',
        label: 'Groot',
        matchPaths: [
          '/deposits/cash-deposits/groot',
          '/deposits/cash-deposits/groot/:id',
        ],
      },
    ],
  },
  {
    url: '/marketplace/ads',
    label: 'MarketPlace',
    icon: StoreMajor,
    subNavigationItems: [
      {
        label: 'Adverts',
        url: '/marketplace/ads',
        matchPaths: ['/marketplace/ads', '/marketplace/ads/:id'],
      },
      {
        label: 'Trades',
        url: '/marketplace/trades',
        matchPaths: ['/marketplace/trades', '/marketplace/trades/:id'],
      },
      {
        label: 'Summary',
        url: '/marketplace/summary',
        matchPaths: ['/marketplace/summary'],
      },
    ],
  },
];

const BottomNavItems = (hasFullAccess) => [
  {
    url: '/settings',
    label: 'Settings',
    disabled: !hasFullAccess,
    icon: SettingsMajor,
    matchPaths: ['/settings'],
  },
];

export default SideBar;
