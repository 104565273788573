// eslint-disable-next-line no-unused-vars
import { Dispatch } from 'redux';
import api from 'utils/api';

import {
  FETCH_ADS,
  FETCH_AD_DETAILS,
  FETCH_ADS_SUMMARY,
  SET_AD_STATUS,
  FETCH_AD_PRODUCTS,
  SET_MULTIPLE_ADS_STATUS,
} from './actionTypes';

/**
 *
 * @typedef { import('../types').GenericDispatchAction } GenericDispatchAction
 */

/**
 * @param {string=} queryString A string that determines what should be queried from the backend
 * @description Fetches ads of the queryString from the ads api endpoint
 * @returns {GenericDispatchAction}
 *
 */

export const fetchAds = (queryString = '') => (dispatch) => {
  const payload = api.get(`/v1/ads${queryString}`);
  return dispatch({ type: FETCH_ADS.default, payload });
};

/**
 *
 * @param {string} id A string that represents the details of the Ad to fetch
 * @description Fetches the details of the ad with the id paramter
 * @returns {GenericDispatchAction}
 */

export const fetchAdDetails = (id) => (dispatch) => {
  const payload = api.get(`/v1/ads/${id}`);
  return dispatch({ type: FETCH_AD_DETAILS.default, payload });
};

/**
 *
 * @param {string[]} assets - An array of string(assets) to fetch the summary of the each ad each string represents
 * @description Fetches the summary of each ad each string(elements of the array passed) represents
 * @returns {GenericDispatchAction}
 */

export const fetchAdsSummary = (assets) => (dispatch) => {
  const payload = Promise.all(
    assets.map((asset) => api.get(`/v1/ads/${asset}/summary`)),
  );
  return dispatch({ type: FETCH_ADS_SUMMARY.default, payload });
};

/**
 *
 * @param { string } id - A string that represents the id of the ad
 * @param { 'active' | 'inactive' } status
 * @returns {any} response
 */
export const setAdStatus = (id, status) => (dispatch) => {
  const payload = api.put(`/v1/ads/${id}/status`, { status });
  return dispatch({ type: SET_AD_STATUS.default, payload }).then((res) => {
    dispatch(fetchAdDetails(id));
    return res;
  });
};

export const setMultipleAdsStatus = (ad_ids, status) => (dispatch) => {
  const payload = api.post('/v1/ads/status', { status, ad_ids });
  return dispatch({ type: SET_MULTIPLE_ADS_STATUS.default, payload }).then(
    (res) => {
      const queryData = window.location.search;
      dispatch(fetchAds(queryData));
      return res;
    },
  );
};

/**
 * Fetches the product of the ad.
 * @returns {GenericDispatchAction}
 */
export const fetchAdProducts = () => (dispatch) => {
  const payload = api.get(`/v1/ads/products`);
  return dispatch({ type: FETCH_AD_PRODUCTS.default, payload });
};
