import React from 'react';
import { TextStyle } from '@shopify/polaris';
import styled from 'styled-components';

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr) repeat(2, 0.5fr) 1fr;
  grid-gap: 10px;
  justify-items: start;
  align-items: start;
  & > * {
    white-space: nowrap;
    box-sizing: border-box;
  }
  & .right {
    justify-self: end;
  }
  @media only screen and (max-width: 1080px) {
    display: none;
  }
`;

export default function PayoutListHeader() {
  return (
    <Container role="row">
      <TextStyle variation="strong">Reference</TextStyle>
      <TextStyle variation="strong">Date</TextStyle>
      <TextStyle variation="strong">Customer</TextStyle>
      <TextStyle variation="strong">Status</TextStyle>
      <TextStyle variation="strong">Fulfillment status</TextStyle>
      <div className="right">
        <TextStyle variation="strong">Amount</TextStyle>
      </div>
    </Container>
  );
}
