import isPromise from 'utils/isPromise';
import history from 'utils/history';
import parseError from 'utils/parseError';
import getResponseMessage from 'utils/getResponseMessage';
import { capitalize } from 'utils/strings';
import { toast } from 'utils/toast';
import { logout } from 'redux/auth/actions';

export default function asyncHandler(store) {
  return (next) => (action) => {
    if (!isPromise(action.payload)) {
      return next(action);
    }
    return next(action)
      .then((response) => {
        const message = getResponseMessage(response.action.payload);
        if (message) {
          toast(capitalize(message));
        }
        return response;
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          store.dispatch(logout());
          history.push('/login');
        } else {
          const errorMsg = parseError(error);
          if (typeof errorMsg === 'string' && error?.config?.method !== "get") {
            toast(errorMsg, 'error');
          }
        }
        throw error;
      });
  };
}
