import React from 'react';

import {
  TextContainer,
  SkeletonPage,
  SkeletonDisplayText,
  SkeletonBodyText,
  Layout,
  Card,
  Loading,
} from '@shopify/polaris';

const LoadingPage = () => {
  const loadingMarkup = <Loading />;

  const detailPageLoadingMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
        </Layout.Section>
        <Layout.Section secondary>
          <Card>
            <Card.Section>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </TextContainer>
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={1} />
            </Card.Section>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  return (
    <>
      {loadingMarkup} {detailPageLoadingMarkup}
    </>
  );
};

export default LoadingPage;
