import React from 'react';
import FormModal from 'components/FormModal';
import EditWalletForm from './EditWalletForm';
import { editWalletBalanceFormSchema } from 'utils/validationSchemas';

export default function EditWalletBalanceModal({
  open,
  handleClose,
  onAction,
  currency,
  loading,
}) {
  return (
    <FormModal
      Form={EditWalletForm}
      open={open}
      handleClose={handleClose}
      initialValues={{
        action: 'add',
      }}
      validationSchema={editWalletBalanceFormSchema}
      onAction={onAction}
      loading={loading}
      title={`Edit ${currency} balance`}
    />
  );
}
