import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import ImageGallery from 'components/ImageGallery';
import {
  Page,
  Card,
  Layout,
  Stack,
  Button,
  ButtonGroup,
  TextStyle,
  DescriptionList,
} from '@shopify/polaris';
import 'react-image-gallery/styles/css/image-gallery.css';
import { renderStatusBadge } from 'utils/formatter';
import LoadingPage from 'components/LoadingPage';
import FormSkeleton from 'components/FormSkeleton';
import EditPhoneModal from './components/EditNoteModal';

import {
  fetchVerificationsDetails,
  updateVerificationsDetails,
} from 'redux/verifications/actions';
import {
  getVericationsDetails,
  getVericationsFetching,
  getVerificationsError,
  getVericationsEditLoading,
} from 'redux/verifications/selectors';

import { fetchCustomer } from 'redux/customers/actions';

import { getDateWordsDistance, formatISODateToDayMonthYear } from 'utils/date';
import { PreviousLocationContext } from 'providers/PreviousLocationProvider';

function ViewVerification({
  match,
  details,
  customers,
  fetching,
  fetchVerificationsDetails,
  fetchCustomer,
  profileFetching,
  updateVerificationsDetails,
  error,
  editing,
}) {
  const { previousLocation } = React.useContext(PreviousLocationContext);

  const [showEditNoteModal, setShowEditNoteModal] = useState(false);
  const [profile, setProfile] = useState(null);

  function toggleModal() {
    setShowEditNoteModal(!showEditNoteModal);
  }

  function verifiyCustomer(id) {
    const payload = {
      accepted: true,
      note: '',
    };
    updateVerificationsDetails(id, payload);
  }

  const declineSubmission = (id) => (values) => {
    updateVerificationsDetails(id, { accepted: false, ...values });
    toggleModal();
  };

  useEffect(() => {
    if (!details) {
      fetchVerificationsDetails(match.params.id);
    }
    if (customers && details && !profile) {
      fetchCustomer(details.user_id);
    }
    if (customers && details) {
      setProfile(customers[details.user_id]);
    }
  }, [
    match.params.id,
    fetchVerificationsDetails,
    fetchCustomer,
    details,
    customers,
    profile,
  ]);

  if (error) {
    return <Redirect to="/customers/verifications" />;
  }
  if (fetching || !details) {
    return <LoadingPage />;
  }

  return (
    <Page
      fullWidth
      title={details.full_name}
      breadcrumbs={[
        {
          content: 'Verifications',
          url: previousLocation
            ? `${previousLocation.pathname}${previousLocation.search}`
            : '/customers/verifications',
        },
      ]}
    >
      <Layout>
        <Layout.Section>
          <Card>
            <Card.Header title={details.reference} sectioned>
              {renderStatusBadge(details.status.toLowerCase())}
            </Card.Header>
            <Card.Section>
              <Stack spacing="loose" vertical>
                <p>{`Submited ${getDateWordsDistance(
                  new Date(details.created_at),
                )} ago`}</p>
              </Stack>
            </Card.Section>
            {details.note && (
              <Card.Section title="Reason for Rejection">
                <TextStyle>{details.note}</TextStyle>
              </Card.Section>
            )}
            {details.status !== 'NOT REQUIRED' && (
              <Card.Section>
                <Stack spacing="loose" distribution="trailing">
                  <ButtonGroup>
                    {details.status !== 'REJECTED' && (
                      <Button destructive onClick={toggleModal}>
                        Decline
                      </Button>
                    )}
                    {details.status !== 'ACCEPTED' && (
                      <Button
                        primary
                        loading={editing}
                        onClick={() => verifiyCustomer(details._id)}
                      >
                        Mark as Accepted
                      </Button>
                    )}
                  </ButtonGroup>
                </Stack>
              </Card.Section>
            )}
          </Card>
          <Card title="Documents" sectioned>
            <ImageGallery images={details.images} />
          </Card>
        </Layout.Section>
        <Layout.Section secondary>
          {profileFetching || !profile ? (
            <FormSkeleton />
          ) : (
            <Card title="Customer Profile">
              <Card.Section>
                <DescriptionList
                  items={[
                    {
                      term: 'First Name',
                      description: profile.profile.first_name,
                    },
                    {
                      term: 'Last Name',
                      description: profile.profile.last_name,
                    },
                    {
                      term: 'Email',
                      description: profile.email,
                    },
                    {
                      term: 'Phone Number',
                      description: profile.profile.phone || 'N/A',
                    },
                    {
                      term: 'Level',
                      description: profile.level,
                    },
                    {
                      term: 'BVN',
                      description: profile.profile.bvn || 'N/A',
                    },
                    {
                      term: 'Date of Birth',
                      description: formatISODateToDayMonthYear(
                        new Date(profile.profile.date_of_birth),
                      ),
                    },
                    {
                      term: 'Street Address 1',
                      description: profile.profile.street_address_1 || 'N/A',
                    },
                    {
                      term: 'Street Address 2',
                      description: profile.profile.street_address_2 || 'N/A',
                    },
                    {
                      term: 'City',
                      description: profile.profile.city || 'N/A',
                    },
                    {
                      term: 'State',
                      description: profile.profile.state || 'N/A',
                    },
                    {
                      term: 'Country',
                      description: profile.profile.country || 'N/A',
                    },
                  ]}
                />
              </Card.Section>
            </Card>
          )}
        </Layout.Section>
      </Layout>
      <EditPhoneModal
        open={showEditNoteModal}
        handleClose={toggleModal}
        onAction={declineSubmission(details._id)}
        note={details.note}
        loading={editing}
      />
    </Page>
  );
}
const mapStateToProps = (state, ownProps) => {
  return {
    details: getVericationsDetails(state, ownProps.match.params.id),
    customers: state.customers.data,
    fetching: getVericationsFetching(state),
    profileFetching: state.customers.fetching,
    editing: getVericationsEditLoading(state),
    error: getVerificationsError(state),
  };
};

const mapDispatchToProps = {
  fetchVerificationsDetails,
  updateVerificationsDetails,
  fetchCustomer,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewVerification);
