import React from 'react';
import { TextStyle, Stack, Card, Link } from '@shopify/polaris';
import { formatNumberToMoney } from 'utils/money';

export default function OrderPaymentDetails({ payment, app }) {
  return (
    <Card.Section title="Payment details">
      <Stack vertical spacing="loose">
        <Stack vertical spacing="tight">
          {payment.card_type && (
            <TextStyle variation="subdued">
              {payment.card_type.toUpperCase()}
            </TextStyle>
          )}
          {payment.last4 && (
            <TextStyle variation="subdued">
              **** **** **** {payment.last4}
            </TextStyle>
          )}
        </Stack>
        <TextStyle variation="strong">
          {formatNumberToMoney(payment.amount, {
            category: payment.currency,
            currencyCode: payment.currency,
            maximumFractionDigits: 10,
          })}
        </TextStyle>
        <Link url={`/cash-deposits/${app}/${payment.id}`}>
          View Transaction
        </Link>
      </Stack>
    </Card.Section>
  );
}
