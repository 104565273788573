import React from 'react';
import { format } from 'date-fns/esm';
import { Card } from '@shopify/polaris';
import {
  Timeline,
  Container,
  YearContent,
  BodyContent,
  Section,
  Description,
} from 'vertical-timeline-component-react';
import { formatDateToReadableString } from 'utils/date';

const timelineTheme = {
  lineColor: '#000',
  dotColor: '#000',
};

export default function TradeEvents({ events = [] }) {
  return (
    <Card title="Events" sectioned>
      <div className="timeline">
        <Timeline theme={timelineTheme}>
          {events.map((event, index) => (
            <Container key={index}>
              <YearContent
                startDate={format(new Date(event.created_at), 'yyyy/MM/dd')}
              />
              <BodyContent>
                <Section title={event.event}>
                  <Description
                    text={formatDateToReadableString(
                      new Date(event.created_at),
                    )}
                  />
                </Section>
              </BodyContent>
            </Container>
          ))}
        </Timeline>
      </div>
    </Card>
  );
}
