import React from 'react';
import {
  Sheet,
  Heading,
  Button,
  Scrollable,
  Stack,
  TextStyle,
  TextField,
} from '@shopify/polaris';
import CopiableText from 'components/CopiableText';
import { MobileCancelMajor } from '@shopify/polaris-icons';

const SectionStyle = {
  alignItems: 'center',
  borderBottom: '1px solid #DFE3E8',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '1.6rem',
  width: '100%',
};

export default function CustomerCyptoAddressesSheet({
  addresses,
  sheetActive,
  toggleSheetActive,
}) {
  const [filter, setFilter] = React.useState('');

  const filteredAddresses = addresses.filter(
    (address) =>
      address.asset?.toLowerCase().includes(filter.toLowerCase()) ||
      address.network?.toLowerCase().includes(filter.toLowerCase()),
  );

  return (
    <Sheet open={sheetActive} onClose={toggleSheetActive}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <div style={SectionStyle}>
          <Heading>Crypto Addresses</Heading>
          <Button
            accessibilityLabel="Cancel"
            icon={MobileCancelMajor}
            onClick={toggleSheetActive}
            plain
          />
        </div>
        <div style={{ padding: 16 }}>
          <TextField
            placeholder="Filter Addresses"
            value={filter}
            onChange={setFilter}
          />
        </div>
        <Scrollable style={{ padding: '1.6rem', height: '100%' }}>
          <Stack vertical spacing="extraLoose">
            {filteredAddresses.length < 1 && (
              <TextStyle variation="subdued">No addresses</TextStyle>
            )}
            {filteredAddresses.map((address, index) => (
              <Stack vertical spacing="tight" key={index}>
                <TextStyle variation="strong">{address.asset}</TextStyle>
                <TextStyle>{address.network}</TextStyle>
                <CopiableText text={address.address} variation="subdued" />
              </Stack>
            ))}
          </Stack>
        </Scrollable>
      </div>
    </Sheet>
  );
}
