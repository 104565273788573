import api from 'utils/api';
import {
  FETCH_STAFF,
  FETCH_STAFF_PROFILE,
  ADD_NEW_STAFF,
  ACTIVATE_STAFF,
  DEACTIVATE_STAFF,
  UPDATE_STAFF,
  CHANGE_STAFF_PASSWORD,
  DELETE_STAFF,
} from './actionTypes';

/**
 * Type definitions
 *
 * @typedef { import('../types').GenericDispatchAction } GenericDispatchAction
 * @typedef { import('./types').AddNewStaffPayload } AddNewStaffPayload
 * @typedef { import('./types').UpdateStaffPayload } UpdateStaffPayload
 */

/**
 * @returns { GenericDispatchAction }
 */

const fetchStaffs = () => (dispatch) => {
  const payload = api.get(`v1/staff`);
  return dispatch({ type: FETCH_STAFF.default, payload });
};

/**
 *
 * @param {string} staffId
 * @returns { GenericDispatchAction }
 */

const fetchStaffProfile = (staffId) => (dispatch) => {
  const payload = api.get(`v1/staff/${staffId}`);
  return dispatch({ type: FETCH_STAFF_PROFILE.default, payload });
};

/**
 *
 * @param {AddNewStaffPayload} formData
 * @returns { GenericDispatchAction }
 */
const addNewStaff = (formData) => (dispatch) => {
  const payload = api.post(`v1/staff`, formData);
  return dispatch({ type: ADD_NEW_STAFF.default, payload });
};

/**
 *
 * @param {string} staffId
 * @returns { GenericDispatchAction }
 */

const activateStaff = (staffId) => (dispatch) => {
  const payload = api.put(`v1/staff/${staffId}/activate`);
  return dispatch({ type: ACTIVATE_STAFF.default, payload }).then(() =>
    dispatch(fetchStaffProfile(staffId)),
  );
};

/**
 *
 * @param {string} staffId
 * @returns { GenericDispatchAction }
 */
const deactivateStaff = (staffId) => (dispatch) => {
  const payload = api.put(`v1/staff/${staffId}/deactivate`);
  return dispatch({ type: DEACTIVATE_STAFF.default, payload }).then(() =>
    dispatch(fetchStaffProfile(staffId)),
  );
};

/**
 *
 * @param {string} staffId
 * @param { UpdateStaffPayload } newDetails
 * @returns { GenericDispatchAction }
 */
const updateStaff = (staffId, newDetails) => (dispatch) => {
  const payload = api.put(`v1/staff/${staffId}`, newDetails);
  return dispatch({ type: UPDATE_STAFF.default, payload }).then(() =>
    dispatch(fetchStaffProfile(staffId)),
  );
};

/**
 *
 * @param {string} staffId
 * @param { string } newPassword
 * @returns { GenericDispatchAction }
 */

const changeStaffPassword = (staffId, newPassword) => (dispatch) => {
  const payload = api.put(`v1/staff/${staffId}/password`, {
    new_password: newPassword,
  });
  return dispatch({ type: CHANGE_STAFF_PASSWORD.default, payload });
};

/**
 *
 * @param {string} staffId
 * @returns { GenericDispatchAction }
 */

const deleteStaff = (staffId) => (dispatch) => {
  const payload = api.delete(`v1/staff/${staffId}`);
  return dispatch({ type: DELETE_STAFF.default, payload });
};

export {
  fetchStaffs,
  fetchStaffProfile,
  addNewStaff,
  activateStaff,
  deactivateStaff,
  updateStaff,
  changeStaffPassword,
  deleteStaff,
};
