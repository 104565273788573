import React from 'react';
import FormModal from 'components/FormModal';
import EditProfileForm from './EditProfileForm';
import { editCustomerProfileSchema } from 'utils/validationSchemas';

export default function EditProfileModal({
  open,
  handleClose,
  onAction,
  loading,
  customer,
}) {
  return (
    <FormModal
      Form={EditProfileForm}
      open={open}
      handleClose={handleClose}
      initialValues={{
        email: customer.email,
        first_name: customer.profile.first_name,
        last_name: customer.profile.last_name,
        date_of_birth: customer.profile.date_of_birth,
        gender: customer.profile.gender,
      }}
      validationSchema={editCustomerProfileSchema}
      onAction={onAction}
      loading={loading}
      title="Edit Customer Profile"
    />
  );
}
