import React from 'react';
import { Card, Stack, RadioButton } from '@shopify/polaris';

export default function CustomerLevel({
  name,
  currentLevel,
  updateLevel,
  loading,
}) {
  const levels = ['0', '1', '2', '3'];

  return (
    <Card>
      <Card.Section title={`${name}'s level`} sectioned>
        <Stack vertical spacing="extraTight">
          {levels.map((level, index) => (
            <RadioButton
              key={index}
              label={`Level ${level}`}
              checked={level === currentLevel}
              name="level"
              disabled={loading}
              onChange={(_checked, newValue) => updateLevel(level)}
            />
          ))}
        </Stack>
      </Card.Section>
    </Card>
  );
}
