import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from 'utils/api';
import { FETCH_DOCUMENTS } from './actionTypes';

function* fetchDocumentsSaga(action) {
  try {
    yield put({ type: FETCH_DOCUMENTS.pending });
    const { queryString } = action.payload;
    const res = yield call(api.get, `/v1.1/customers/documents${queryString}`);
    yield put({ type: FETCH_DOCUMENTS.fulfilled, payload: res });
  } catch (error) {
    yield put({ type: FETCH_DOCUMENTS.rejected, payload: error });
  }
}

export default function* allDocumentsSaga() {
  yield all([takeLatest(FETCH_DOCUMENTS.default, fetchDocumentsSaga)]);
}
