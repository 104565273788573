import { all, put, call, takeLatest } from 'redux-saga/effects';
import { FETCH_CUSTOMERS } from './actionTypes';
import api from 'utils/api';

function* fetchCustomersSaga(action) {
  try {
    yield put({ type: FETCH_CUSTOMERS.pending });

    const { query } = action.payload;

    const res = yield call(api.get, `/v1.1/customers${query}`);
    yield put({ type: FETCH_CUSTOMERS.fulfilled, payload: res });
  } catch (error) {
    yield put({ type: FETCH_CUSTOMERS.rejected, payload: error });
  }
}

export default function* allSaga() {
  yield all([takeLatest(FETCH_CUSTOMERS.default, fetchCustomersSaga)]);
}
