import React from 'react';
import {
  Card,
  Form,
  FormLayout,
  TextField,
  ButtonGroup,
  Button,
  TextStyle,
} from '@shopify/polaris';
import { Formik } from 'formik';
const InviteForm = ({
  invite,
  handleAcceptInvite,
  schema,
  loading,
  history,
}) => {
  return (
    <Formik
      initialValues={{
        email: invite.email || '',
        first_name: invite.first_name || '',
        last_name: invite.last_name || '',
        phone: invite.phone || '',
        password: '',
        confirm_password: '',
      }}
      onSubmit={handleAcceptInvite}
      validationSchema={schema}
    >
      {(props) => {
        const { values, touched, errors, setFieldValue, handleSubmit } = props;

        const handleInputChange = (name) => (value) => {
          setFieldValue(name, value);
        };

        return (
          <Form onSubmit={handleSubmit}>
            <Card title="Acccount Profile" sectioned>
              <FormLayout>
                <FormLayout.Group>
                  <TextField
                    label="First name"
                    name="first_name"
                    onChange={handleInputChange('first_name')}
                    value={values.first_name}
                    error={
                      errors.first_name && touched.first_name
                        ? errors.first_name
                        : ''
                    }
                  />
                  <TextField
                    label="Last name"
                    name="last_name"
                    onChange={handleInputChange('last_name')}
                    value={values.last_name}
                    error={
                      errors.last_name && touched.last_name
                        ? errors.last_name
                        : ''
                    }
                  />
                </FormLayout.Group>
                <TextField
                  label="Email"
                  name="email"
                  onChange={handleInputChange('email')}
                  value={values.email}
                  error={errors.email && touched.email ? errors.email : ''}
                />
                <TextField
                  label="Phone"
                  name="phone"
                  type="tel"
                  onChange={handleInputChange('phone')}
                  value={values.phone}
                  error={errors.phone && touched.phone ? errors.phone : ''}
                  pattern="[0-9]{*}"
                />
                <FormLayout.Group>
                  <TextField
                    label="Password"
                    name="password"
                    onChange={handleInputChange('password')}
                    value={values.password}
                    type="password"
                    error={
                      errors.password && touched.password ? errors.password : ''
                    }
                  />
                  <TextField
                    label="Confirm Password"
                    name="confirm_password"
                    onChange={handleInputChange('confirm_password')}
                    value={values.confirm_password}
                    type="password"
                    error={
                      errors.confirm_password && touched.confirm_password
                        ? errors.confirm_password
                        : ''
                    }
                  />
                </FormLayout.Group>
              </FormLayout>
              <br />
              <TextStyle>
                By proceeding, you are agreeing to the Terms & Conditions
              </TextStyle>
              <br />
              <ButtonGroup>
                <Button onClick={() => history.push('/login')}>
                  Decline Invite
                </Button>
                <Button
                  primary
                  disabled={Object.values(errors).length > 0 || loading}
                  submit
                >
                  Finish
                </Button>
              </ButtonGroup>
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
};
export default InviteForm;
