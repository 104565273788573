import React from 'react';
import { Form, FormLayout, TextField, Select } from '@shopify/polaris';
import DatePicker from 'react-datepicker';
import { getSelectedValueFromInput } from 'utils/helpers';

export default function EditProfileForm({
  values,
  handleInputChange,
  handleSubmit,
  errors,
  touched,
}) {
  return (
    <Form onSubmit={handleSubmit}>
      <FormLayout>
        <FormLayout.Group>
          <TextField
            value={values.first_name}
            onChange={handleInputChange('first_name')}
            label="First Name"
            name="first_name"
            error={
              errors.first_name && touched.first_name ? errors.first_name : ''
            }
          />
          <TextField
            value={values.last_name}
            onChange={handleInputChange('last_name')}
            label="Last Name"
            name="last_name"
            error={
              errors.last_name && touched.last_name ? errors.last_name : ''
            }
          />
        </FormLayout.Group>
        <TextField
          value={values.email}
          onChange={handleInputChange('email')}
          label="Email"
          name="email"
          error={errors.email && touched.email ? errors.email : ''}
        />
        <Select
          label="Gender"
          options={['Male', 'Female']}
          onChange={(value) =>
            handleInputChange('gender')(getSelectedValueFromInput(value))
          }
          value={values.gender}
          placeholder="-- Pick a gender --"
          name="gender"
          error={errors.gender && touched.gender ? errors.gender : ''}
        />
        <label htmlFor="date_of_birth">Date of Birth</label>
        <DatePicker
          selected={new Date(values.date_of_birth)}
          onChange={(date) => handleInputChange('date_of_birth')(date)}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          dateFormat="dd-MM-yyyy"
        />
      </FormLayout>
    </Form>
  );
}
