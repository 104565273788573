import { put, call, all, takeLatest } from 'redux-saga/effects';

import api from 'utils/api';
import { FETCH_REFERRALS } from './actionTypes';

function* fetchReferralsSaga(actions) {
  try {
    const { queryString } = actions.payload;
    yield put({ type: FETCH_REFERRALS.pending });
    const res = yield call(api.get, `/v1.1/referral${queryString}`);
    yield put({ type: FETCH_REFERRALS.fulfilled, payload: res });
  } catch (error) {
    yield put({ type: FETCH_REFERRALS.rejected, payload: error });
  }
}

export default function* allReferralSaga() {
  yield all([takeLatest(FETCH_REFERRALS.default, fetchReferralsSaga)]);
}
