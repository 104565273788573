import React from 'react';
import { useSelector } from 'react-redux';
import { sortBy } from 'lodash';
import { formatDistanceToNow } from 'date-fns';
import { Launcher } from 'react-chat-window';

import firebase from 'utils/firebase';
import { toast } from 'utils/toast';

const DB = firebase.firestore();

const currentTimeInSeconds = Date.now();

const MessageItem = ({ name, content, created }) => {
  const timestamp = `${formatDistanceToNow(
    new Date(created.seconds * 1000),
  )} ago`;

  const openImage = (url) => {
    window.open(url, '_blank');
  };

  return (
    <>
      <span className="message-sender">{name}</span>
      <br />
      {content.startsWith('image-') ? (
        <img
          src={content.replace('image-', '')}
          alt="uploaded proof"
          className="message-image"
          onClick={() => openImage(content.replace('image-', ''))}
        />
      ) : (
        content
      )}
      <span className="message-date">{timestamp}</span>
    </>
  );
};

function ChatDialog({ channel, mute }) {
  const { data: user } = useSelector((state) => state.profile);

  const [messages, setMessages] = React.useState([]);

  const [newMessageCount, setNewMessageCount] = React.useState(0);
  const resetMessageCount = () => setNewMessageCount(0);
  const increaseMessageCount = React.useCallback(
    () => setNewMessageCount((previousCount) => previousCount + 1),
    [],
  );

  const [isOpen, setIsOpen] = React.useState(false);

  const toggleDialog = () => {
    resetMessageCount();
    setIsOpen(!isOpen);
  };

  const addLocalMessage = ({ author, type, data, created }) => {
    setMessages((previousMessages) => {
      const messageList = [
        ...previousMessages,
        {
          author,
          type,
          data,
          created,
        },
      ];
      const sortedMessageList = sortBy(messageList, 'created');
      return sortedMessageList;
    });
  };

  const addMessage = async ({ type, data }) => {
    const created = new Date();
    const payload = {
      content: data[type],
      type: 'text',
      created,
      senderID: user.id,
      senderName: `${user.first_name} ${user.last_name}`,
    };

    try {
      await DB.doc(channel).collection('thread').add(payload);
    } catch (error) {
      toast('Could not send message', 'error');
    }
  };

  const receiveMessage = React.useCallback(
    ({ content, created, senderID, senderName: name, type }) => {
      const author = user?.id === senderID ? 'me' : 'them';
      if (author === 'them' && created.seconds > currentTimeInSeconds) {
        increaseMessageCount();
      }
      if (
        !type &&
        (typeof content === 'string' || typeof content === 'number')
      ) {
        type = 'text';
      }
      if (type) {
        const data = {
          [['text', 'image'].includes(type) ? 'text' : type]: [
            'text',
            'image',
          ].includes(type) ? (
            <MessageItem
              {...{
                name,
                created,
                content: `${type === 'image' ? 'image-' + content : content}`,
              }}
            />
          ) : (
            content
          ),
        };
        addLocalMessage({
          author,
          created,
          type: ['text', 'image'].includes(type) ? 'text' : type,
          data,
        });
      }
    },
    [increaseMessageCount, user],
  );

  React.useEffect(() => {
    DB.doc(channel)
      .collection('thread')
      .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const message = change.doc.data();
          receiveMessage(message);
        });
      });
  }, [channel, receiveMessage]);

  return (
    <Launcher
      agentProfile={{
        teamName: `${user.first_name} ${user.last_name}`,
      }}
      onMessageWasSent={addMessage}
      handleClick={toggleDialog}
      isOpen={isOpen}
      messageList={messages}
      showEmoji
      newMessagesCount={newMessageCount}
      mute={mute}
    />
  );
}

export default ChatDialog;
