import React from 'react';
import { TextStyle, ResourceList, Icon, Stack } from '@shopify/polaris';
import ellipsize from 'ellipsize';
import { CircleDownMajor, CircleUpMajor } from '@shopify/polaris-icons';

import ResourceItemContainer from 'containers/ResourceItemContainer';
import { renderStatusBadge } from 'utils/formatter';
import { formatISODateToDayMonthYear } from 'utils/date';

export default function ReferralItem(item) {
  const {
    id,
    referee_user_profile,
    referrer_user_profile,
    status,
    created_at,
  } = item;

  const refererName = `${referrer_user_profile.first_name} ${referrer_user_profile.last_name}`.trim();
  const refereeName = `${referee_user_profile.first_name} ${referee_user_profile.last_name}`.trim();
  const date = formatISODateToDayMonthYear(new Date(created_at));
  const url = `/customers/referrals/${id}`;

  return (
    <ResourceList.Item
      id={id}
      url={url}
      accessibilityLabel={`View details for referal from ${refererName} to ${refereeName}`}
    >
      <ResourceItemContainer>
        <Stack alignment="center" wrap={false}>
          <Icon source={CircleUpMajor} color="greenDark" />
          <TextStyle variation="strong">
            {refererName
              ? ellipsize(refererName, 30, {
                  chars: [' '],
                })
              : 'N/A'}
          </TextStyle>
        </Stack>
        <Stack alignment="center" wrap={false}>
          <Icon source={CircleDownMajor} color="purple" />
          <TextStyle variation="strong">
            {refereeName
              ? ellipsize(refereeName, 30, {
                  chars: [' '],
                })
              : 'N/A'}
          </TextStyle>
        </Stack>
        {renderStatusBadge(status.toLowerCase())}
        <TextStyle>{date}</TextStyle>
      </ResourceItemContainer>
    </ResourceList.Item>
  );
}
