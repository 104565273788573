import React from 'react';
import { Card, TextStyle, Stack } from '@shopify/polaris';
import { formatBankAccountNumberString } from 'utils/strings';

function DepositBankDetails({ source_bank }) {
  return (
    <Card.Section title="Bank account details" sectioned>
      <Stack vertical spacing="tight">
        <TextStyle>{source_bank?.name || 'N/A'}</TextStyle>
        <TextStyle>
          {source_bank?.number
            ? formatBankAccountNumberString(source_bank.number)
            : 'N/A'}
        </TextStyle>
      </Stack>
    </Card.Section>
  );
}

export default DepositBankDetails;
