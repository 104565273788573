import React from 'react';
import { TextStyle, Stack, DisplayText, Avatar, Card } from '@shopify/polaris';
import { getAssetLogo } from 'utils/helpers';

export default function AdsSummary({ adsSummary }) {
  return (
    <Stack distribution="center" wrap>
      {adsSummary.map((ad, index) => (
        <div key={index} style={{ width: 320 }}>
          <Card sectioned>
            <Stack
              alignment="center"
              distribution="equalSpacing"
              spacing="none"
            >
              <DisplayText size="small">{ad.buys.currency}</DisplayText>
              <Avatar
                size="small"
                name={ad.buys.currency}
                source={getAssetLogo(ad.buys.currency)}
              />
            </Stack>
            <TextStyle variation="bold">METRICS</TextStyle>
            <Stack vertical spacing="loose">
              <Stack alignment="center" distribution="equalSpacing">
                <TextStyle variation="strong">Buy</TextStyle>
                <TextStyle>
                  {ad.buys.amount} {ad.buys.currency}
                </TextStyle>
              </Stack>
              <TextStyle variation="subdued">{ad.no_of_buys} ads</TextStyle>
              <Stack alignment="center" distribution="equalSpacing">
                <TextStyle variation="strong">Sell</TextStyle>
                <TextStyle>
                  {ad.sells.amount} {ad.sells.currency}
                </TextStyle>
              </Stack>
              <TextStyle variation="subdued">{ad.no_of_sells} ads</TextStyle>
              <Stack alignment="center" distribution="equalSpacing">
                <TextStyle variation="strong">Escrowed</TextStyle>
                <TextStyle>
                  {ad.ecrowed.amount} {ad.ecrowed.currency}
                </TextStyle>
              </Stack>
              <TextStyle variation="subdued">{ad.no_of_escrow} ads</TextStyle>
            </Stack>
          </Card>
        </div>
      ))}
    </Stack>
  );
}
