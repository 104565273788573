import React from 'react';
import { Card, TextContainer, TextStyle } from '@shopify/polaris';
import { capitalize } from 'utils/strings';

function DepositChannel({ channel }) {
  return (
    <Card.Section title="Channel" sectioned>
      <TextContainer>
        {channel ? (
          <p>{capitalize(channel)}</p>
        ) : (
          <TextStyle variation="subdued">No channel</TextStyle>
        )}
      </TextContainer>
    </Card.Section>
  );
}

export default DepositChannel;
