import TransactionTypes from 'data/transactionTypes.json';

function isType(type, ref) {
  for (let key of Object.keys(TransactionTypes)) {
    if (TransactionTypes[key] === type && ref.startsWith(key)) {
      return true;
    }
  }
  return false;
}

const isEscrow = (ref) => isType('Escrow', ref);
const isExchange = (ref) => isType('Exchange', ref);
const isCryptoPayout = (ref) => isType('Crypto Payout', ref);
const isFiatPayout = (ref) => isType('Fiat Payout', ref);
const isInternalTransfer = (ref) => isType('Internal Transfer', ref);
const isFiatDeposit = (ref) => isType('Fiat Deposit', ref);
const isCryptoDeposit = (ref) => isType('Crypto Deposit', ref);
const isOrder = (ref) => isType('Order', ref);

export {
  isEscrow,
  isExchange,
  isCryptoPayout,
  isFiatDeposit,
  isFiatPayout,
  isInternalTransfer,
  isCryptoDeposit,
  isOrder,
};
