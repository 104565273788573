import React from 'react';
import { Modal } from '@shopify/polaris';
import { Formik } from 'formik';

export default function FormModal({
  open,
  handleClose,
  onAction,
  initialValues,
  loading,
  validationSchema,
  Form,
  title,
  actionKeyText = 'Save',
}) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onAction}
    >
      {(props) => {
        const handleInputChange = (name) => (value) => {
          props.setFieldValue(name, value);
        };
        return (
          <Modal
            open={open}
            onClose={handleClose}
            title={title}
            primaryAction={{
              content: actionKeyText,
              onAction: () => onAction(props.values),
              disabled: Object.keys(props.errors).length > 0 || loading,
            }}
            secondaryActions={[
              {
                content: 'Cancel',
                onAction: handleClose,
              },
            ]}
          >
            <Modal.Section>
              <Form {...props} handleInputChange={handleInputChange} />
            </Modal.Section>
          </Modal>
        );
      }}
    </Formik>
  );
}
