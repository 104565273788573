import { useState } from 'react';
import queryString from 'query-string';

import { periodDatesAndLabels } from 'utils/period';
import { getStartOfDays, formateDateToISOWithoutSSSZAndTime } from 'utils/date';

const useDateFilter = () => {
  const [{ month, year }, setDate] = useState({
    month: 1,
    year: new Date().getFullYear(),
  });
  const [selectedDates, setSelectedDates] = useState({
    from: new Date('01/01/2018'),
    to: new Date(),
  });

  const [selectedPeriod, setSelectedPeriod] = useState(() => {
    const initialSearchQuery = queryString.parse(window.location.search);
    if (initialSearchQuery?.period === 'day' && initialSearchQuery?.for) {
      return formateDateToISOWithoutSSSZAndTime(initialSearchQuery.for) ===
        formateDateToISOWithoutSSSZAndTime(getStartOfDays(1))
        ? 'yesterday'
        : 'today';
    }

    const formatedRangeWithoutTime = initialSearchQuery?.from
      ? formateDateToISOWithoutSSSZAndTime(initialSearchQuery.from)
      : '';

    switch (formatedRangeWithoutTime) {
      case formateDateToISOWithoutSSSZAndTime(getStartOfDays(7)):
        return 'last-7-days';

      case formateDateToISOWithoutSSSZAndTime(getStartOfDays(90)):
        return 'last-90-days';

      default:
        return 'last-30-days';
    }
  });

  const handleChangeInSelectedPeriod = (value) => {
    setSelectedPeriod(value);
    if (value !== 'custom-range') {
      const startAndEndDates = periodDatesAndLabels[
        value
      ]?.getStartAndEndDates();
      if (startAndEndDates) {
        setSelectedDates(startAndEndDates);
      }
    }
  };

  const handleChangeInSelectedDates = ({ start, end }) => {
    setSelectedDates({ from: start, to: end });
  };

  const handleMonthChange = (month, year) => {
    setDate({ month, year });
  };

  return {
    month,
    year,
    selectedPeriod,
    handleChangeInSelectedDates,
    handleChangeInSelectedPeriod,
    handleMonthChange,
    selectedDates,
    setSelectedDates
  };
};

export default useDateFilter;
