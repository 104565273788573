import React from 'react';
import { ResourceList, Avatar, TextStyle } from '@shopify/polaris';

export default function WalletsHeader() {
  return (
    <div className="Wallet_Header">
      <ResourceList.Item media={<Avatar size="small" />}>
        <div className="Wallet_Main">
          <div className="Wallet_Code">
            <TextStyle variation="strong">Wallet</TextStyle>
          </div>
          <div className="Wallet_Hold">
            <TextStyle variation="strong">On Hold</TextStyle>
          </div>
          <div className="Wallet_Pending">
            <TextStyle variation="strong">Pending</TextStyle>
          </div>
          <div className="Wallet_Balance">
            <TextStyle variation="strong">Balance</TextStyle>
          </div>
        </div>
      </ResourceList.Item>
    </div>
  );
}
