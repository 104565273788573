import React from 'react';
import { TextStyle } from '@shopify/polaris';

import MarketPlaceListContainer from 'components/MarketPlacecContainerList';

const headings = [
  'Advert',
  'Date',
  'Trader',
  'Country',
  'Side',
  'Range',
  'Asset',
  'Rate',
];

const AdListHeader = () => {
  return (
    <MarketPlaceListContainer className="heading heading--with-checkbox">
      {headings.map((title) => (
        <TextStyle key={title} variation="strong">
          {title}{' '}
        </TextStyle>
      ))}
    </MarketPlaceListContainer>
  );
};

export default AdListHeader;
