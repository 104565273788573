import React from 'react';
import {
  Card,
  TextContainer,
  Avatar,
  Link,
  Stack,
  Heading,
} from '@shopify/polaris';
import { getInitials } from 'utils/strings';

function DepositUser({ user }) {
  return user && user.name ? (
    <Card.Section
      title={
        <Stack distribution="equalSpacing">
          <Stack.Item>
            <Heading>Customer</Heading>
          </Stack.Item>
          <Stack.Item>
            <Link url={`/customers/${user.id}`}>
              <Avatar
                initials={getInitials(user.name)}
                name={user.name}
                size="small"
              ></Avatar>
            </Link>
          </Stack.Item>
        </Stack>
      }
      sectioned
    >
      <TextContainer>
        <Link url={`/customers/${user.id}`}>{user.name}</Link>
      </TextContainer>
    </Card.Section>
  ) : null;
}

export default DepositUser;
