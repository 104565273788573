import React from 'react';
import { Card, Stack, TextStyle, Link } from '@shopify/polaris';
import { formatISODateToDayMonthYear } from 'utils/date';

function CustomerDetails({ customer }) {
  return (
    <>
      <Card>
        <Card.Section title="Profile">
          <Stack vertical>
            <Link url={`/customers/${customer?.id}`}>{customer?.email}</Link>
            {customer?.profile?.date_of_birth && (
              <TextStyle>
                Born{' '}
                {formatISODateToDayMonthYear(
                  new Date(customer?.profile?.date_of_birth),
                )}
              </TextStyle>
            )}
          </Stack>
        </Card.Section>
      </Card>
      <Card>
        <Card.Section title="Address">
          <Stack vertical>
            <TextStyle>
              {customer?.profile?.street_address_1 &&
                `${customer?.profile?.street_address_1}, `}
              {customer?.profile?.street_address_2 &&
                `${customer?.profile?.street_address_2}, `}
              {customer?.profile?.city && customer?.profile?.city}
            </TextStyle>
            {customer?.profile?.state && (
              <TextStyle>{customer?.profile?.state}</TextStyle>
            )}
            {customer?.profile?.country && (
              <TextStyle>{customer?.profile?.country}</TextStyle>
            )}
          </Stack>
        </Card.Section>
        <Card.Section title="Bank verification number">
          {customer?.profile?.bvn ? (
            <TextStyle>{customer.profile.bvn}</TextStyle>
          ) : (
            <TextStyle variation="subdued">No bvn</TextStyle>
          )}
        </Card.Section>
        <Card.Section title="Phone">
          {customer?.phone?.number ? (
            <TextStyle>{customer.phone.number}</TextStyle>
          ) : (
            <TextStyle variation="subdued">No phone number</TextStyle>
          )}
        </Card.Section>
      </Card>
    </>
  );
}

export default CustomerDetails;
