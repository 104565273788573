import api from 'utils/api';
import {
  FETCH_DEPOSITS,
  FETCH_DEPOSIT,
  ADD_DEPOSIT,
  CLEAR_MESSAGE,
  APPROVE_DEPOSIT,
  FULFILL_DEPOSIT,
  DECLINE_DEPOSIT,
  CHECK_DEPOSIT_STATUS,
  CLEAR_DEPOSIT_STATUS,
  FLAG_DEPOSIT,
} from './actionTypes';

/**
 * Type definitions
 *
 * @typedef { import('../types').GenericDispatchAction } GenericDispatchAction
 * @typedef { import('./types').FetchDepositsParameter } FetchDepositsParameter
 * @typedef { import('./types').ApproveCashDepositParameter } ApproveCashDepositParameter
 */

/**
 *
 * @param { FetchDepositsParameter } param0
 * @returns { { type : string; payload : { query : string; app : string; endpoint : string } } }
 */

export const fetchDeposits = ({ base, app, query }) => {
  let endpoint;

  if (base === 'cash-deposits') {
    endpoint = 'cash';
  }
  if (base === 'crypto-deposits') {
    endpoint = 'deposits';
  }

  return {
    type: FETCH_DEPOSITS.default,
    payload: { query, app, endpoint },
  };
};

/**
 *
 * @param {string} base
 * @param { string } app
 * @param { string } id
 * @returns { GenericDispatchAction }
 */

export const fetchDeposit = (base, app, id) => (dispatch) => {
  let endpoint = '';

  if (base === 'cash-deposits') {
    endpoint = 'cash';
  }
  if (base === 'crypto-deposits') {
    endpoint = 'deposits';
  }

  return dispatch({
    type: FETCH_DEPOSIT.default,
    payload: api.get(`/v1.1/${endpoint}/${app}/${id}`),
  });
};

/**
 *
 * @param { string } hash
 * @returns { GenericDispatchAction }
 */
export const addCryptoDeposit = (hash) => (dispatch) => {
  return dispatch({
    type: ADD_DEPOSIT.default,
    payload: api.post(`/v1.1/deposits/groot/add`, { hash }),
  });
};

/**
 *
 * @param {ApproveCashDepositParameter} param0
 * @returns { GenericDispatchAction }
 */

export const approveCashDeposit = ({ depositId, app, white_list = false }) => (
  dispatch,
) => {
  return dispatch({
    type: APPROVE_DEPOSIT.default,
    payload: api.put(`/v1.1/cash/groot/${depositId}/approve`, { white_list }),
  }).then((res) => {
    dispatch(fetchDeposit('cash-deposits', app, depositId));
    return res;
  });
};

/**
 *
 * @param { string } depositId
 * @param { string } app
 * @returns { GenericDispatchAction }
 */

export const fulfillCashDeposit = (depositId, app) => (dispatch) => {
  return dispatch({
    type: FULFILL_DEPOSIT.default,
    payload: api.put(`/v1.1/cash/groot/${depositId}/fulfill`),
  }).then((res) => {
    dispatch(fetchDeposit('cash-deposits', app, depositId));
    return res;
  });
};

/**
 *
 * @param { string } depositId
 * @param { string } app
 * @returns { GenericDispatchAction }
 */
export const declineCashDeposit = (depositId, app) => (dispatch) => {
  return dispatch({
    type: DECLINE_DEPOSIT.default,
    payload: api.put(`/v1.1/cash/groot/${depositId}/decline`),
  }).then((res) => {
    dispatch(fetchDeposit('cash-deposits', app, depositId));
    return res;
  });
};

/**
 *
 * @param {string} base
 * @param { string } app
 * @param { string } id
 * @returns { GenericDispatchAction }
 */

export const checkDepositStatus = (base, app, id) => (dispatch) => {
  let category = '';

  if (base === 'cash-deposits') {
    category = 'cash';
  }
  if (base === 'crypto-deposits') {
    category = 'deposits';
  }
  return dispatch({
    type: CHECK_DEPOSIT_STATUS.default,
    payload: api.get(`/v1.1/${category}/${app}/${id}/check_status`),
  }).then((res) => {
    dispatch(fetchDeposit(base, app, id));
    return res;
  });
};

/**
 *
 * @param {string} base
 * @param { string } app
 * @param { string } id
 * @returns { GenericDispatchAction }
 */

export const flagDeposit = (base, app, id) => (dispatch) => {
  let category = '';

  if (base === 'cash-deposits') {
    category = 'cash';
  }
  if (base === 'crypto-deposits') {
    category = 'deposits';
  }
  return dispatch({
    type: FLAG_DEPOSIT.default,
    payload: api.put(`/v1.1/${category}/${app}/${id}/flag`),
  }).then((res) => {
    dispatch(fetchDeposit(base, app, id));
    return res;
  });
};

/**
 * @returns { GenericDispatchAction }
 */
export const clearDepositStatus = () => {
  return (dispatch) =>
    dispatch({
      type: CLEAR_DEPOSIT_STATUS.fulfilled,
    });
};

/**
 * @returns { GenericDispatchAction }
 */
export const clearMessage = () => {
  return (dispatch) =>
    dispatch({
      type: CLEAR_MESSAGE.fulfilled,
    });
};
