import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';

import useUpdatedEffect from 'hooks/useUpdatedEffect';
import useQueryParams from 'hooks/useQueryParams';
import { fetchPayouts } from 'redux/payouts/actions';
import { fetchAssets } from 'redux/assets/actions';

const intialQueryParams = {
  status: '',
  category: '',
  q: '',
  page: 1,
  processor: '',
  asset: '',
};

const usePayouts = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();

  const { fetching, data, meta, assetData } = useSelector((state) => ({
    ...state.payouts,
    assetData: state.assets.data,
  }));

  const { queryParams, updateQueryParams, clearAllFilters } = useQueryParams(
    intialQueryParams,
  );

  function handlePreviousPage() {
    updateQueryParams('page', meta.page - 1);
  }

  function handleNextPage() {
    updateQueryParams('page', meta.page + 1);
  }

  React.useEffect(
    React.useCallback(() => {
      debounce(
        () => dispatch(fetchPayouts(params.app, location.search)),
        500,
      )();
    }, [dispatch, location.search, params.app]),
    [location.search, params.app],
  );

  React.useEffect(() => {
    dispatch(fetchAssets());
  }, [dispatch]);

  useUpdatedEffect(
    React.useCallback(() => {
      clearAllFilters();
    }, [clearAllFilters]),
    [params.app],
  );

  return {
    queryParams,
    updateQueryParams,
    clearAllFilters,
    handlePreviousPage,
    handleNextPage,
    fetching,
    meta,
    payouts: Object.values(data),
    assetData: Object.keys(assetData),
  };
};

export default usePayouts;
