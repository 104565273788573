import React from 'react';
import {
  Card,
  SkeletonBodyText,
  SkeletonDisplayText,
  TextContainer,
} from '@shopify/polaris';
import FadeIn from 'animations/FadeIn';

const FormSkeleton = () => {
  return (
    <FadeIn>
      <Card sectioned>
        <TextContainer>
          <SkeletonDisplayText size="small" fullWidth />
          <SkeletonBodyText lines={1} />
        </TextContainer>
        <br />
        <TextContainer>
          <SkeletonDisplayText size="small" fullWidth />
          <SkeletonBodyText lines={1} />
        </TextContainer>
        <br />
        <TextContainer>
          <SkeletonDisplayText size="small" fullWidth />
          <SkeletonBodyText lines={1} />
        </TextContainer>
      </Card>
    </FadeIn>
  );
};
export default FormSkeleton;
