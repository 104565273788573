import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Page, ResourceList, TextStyle } from '@shopify/polaris';

import useQueryParams from 'hooks/useQueryParams';
import Pagination from 'components/Pagination';
import IndexPagination from 'components/IndexPagination';
import ReportsHeader from './components/ReportsHeader';
import ReportItem from './components/ReportItem';
import { fetchBooks } from 'redux/books/actions';

const resourceName = {
  singular: 'book',
  plural: 'books',
};

const initialQueryParams = {
  page: 1,
};

function Reports({ location }) {
  const dispatch = useDispatch();

  const { fetching, data: books, meta } = useSelector((state) => state.books);
  const bookItems = Object.values(books);

  const { updateQueryParams } = useQueryParams(initialQueryParams);

  function handlePreviousPage() {
    updateQueryParams('page', meta.page - 1);
  }

  function handleNextPage() {
    updateQueryParams('page', meta.page + 1);
  }

  React.useEffect(() => {
    dispatch(fetchBooks(location.search));
  }, [dispatch, location.search]);

  return (
    <Page title="Chain Order Reports">
      <Card>
        <ReportsHeader />
        <ResourceList
          loading={fetching}
          resourceName={resourceName}
          items={bookItems}
          renderItem={ReportItem}
        />
        {!fetching && (
          <IndexPagination>
            <TextStyle variation="subdued">
              Showing {meta?.current_entries_size} of {meta?.total_entries_size}{' '}
              results
            </TextStyle>
          </IndexPagination>
        )}
        <Pagination
          resource={bookItems}
          meta={meta}
          handlePreviousPage={handlePreviousPage}
          handleNextPage={handleNextPage}
        />
      </Card>
    </Page>
  );
}

export default Reports;
