import React from 'react';
import FormModal from 'components/FormModal';
import EditAddressForm from './EditAddressForm';
import { editCustomerAddressSchema } from 'utils/validationSchemas';

export default function EditAddressModal({
  open,
  handleClose,
  onAction,
  initialValues,
  loading,
}) {
  return (
    <FormModal
      Form={EditAddressForm}
      open={open}
      handleClose={handleClose}
      initialValues={{
        city: initialValues.city,
        state: initialValues.state,
        country_code: initialValues.country_code,
        country: initialValues.country,
        postal_code: initialValues.postal_code,
        street_address_1: initialValues.street_address_1,
        street_address_2: initialValues.street_address_2,
        country_and_code: `${initialValues.country} - ${initialValues.country_and_code}`,
      }}
      validationSchema={editCustomerAddressSchema}
      onAction={onAction}
      loading={loading}
      title={`Edit customer address`}
    />
  );
}
