import React from 'react';
import { Card, TextStyle, Stack } from '@shopify/polaris';

export default function AdBankDetails({ bank }) {
  return (
    <Card.Section title="Bank details">
      <Stack vertical spacing="tight">
        <TextStyle variation="subdued">{bank.name}</TextStyle>
        <TextStyle variation="subdued">{bank.account_name}</TextStyle>
        <TextStyle variation="subdued">{bank.account_number}</TextStyle>
      </Stack>
    </Card.Section>
  );
}
