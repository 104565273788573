import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { debounce } from 'lodash';

import useQueryParams from 'hooks/useQueryParams';
import { fetchCustomers } from 'redux/customers/actions';

const intialQueryParams = {
  status: '',
  level: '',
  q: '',
  page: 1,
  order: '',
};

const useCustomer = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { fetching, data, meta } = useSelector((state) => state.customers);

  const { queryParams, updateQueryParams, clearAllFilters } = useQueryParams(
    intialQueryParams,
    true,
  );

  function handlePreviousPage() {
    updateQueryParams('page', meta.page - 1);
  }

  function handleNextPage() {
    updateQueryParams('page', meta.page + 1);
  }

  React.useEffect(
    React.useCallback(() => {
      debounce(() => dispatch(fetchCustomers(location.search)), 500)();
    }, [dispatch, location.search]),
    [location.search],
  );

  return {
    queryParams,
    updateQueryParams,
    clearAllFilters,
    handlePreviousPage,
    handleNextPage,
    fetching,
    meta,
    customers: Object.values(data),
  };
};

export default useCustomer;
