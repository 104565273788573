import { all, put, call, takeLatest } from 'redux-saga/effects';

import api from 'utils/api';
import { FETCH_DEPOSITS } from './actionTypes';

function* fetchDepositSaga(action) {
  try {
    yield put({ type: FETCH_DEPOSITS.pending });
    const { endpoint, app, query } = action.payload;
    const res = yield call(api.get, `/v1.1/${endpoint}/${app}${query}`);
    yield put({ type: FETCH_DEPOSITS.fulfilled, payload: res });
  } catch (error) {
    yield put({ type: FETCH_DEPOSITS.rejected, payload: error });
  }
}

export default function* allDepositSaga() {
  yield all([takeLatest(FETCH_DEPOSITS.default, fetchDepositSaga)]);
}
