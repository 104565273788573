import React from 'react';
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Page,
  Card,
  ResourceList,
  Avatar,
  TextStyle,
  Stack,
} from '@shopify/polaris';
import Pagination from 'components/Pagination';
import { fetchAssets } from 'redux/assets/actions';
import { renderStatusBadge } from 'utils/formatter';

export default function AccountAssets() {
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const { data: assets, fetching, meta } = useSelector((state) => state.assets);

  function handlePreviousPage() {
    history.push({
      pathname: location.pathname,
      search: `?page=${meta.page - 1}`,
    });
  }

  function handleNextPage() {
    history.push({
      pathname: location.pathname,
      search: `?page=${meta.page + 1}`,
    });
  }

  const getAssetImageUrl = (code) =>
    `https://res.cloudinary.com/dwoc5fknz/image/upload/v1543504221/crypto_assets/${code}.svg`;

  const _assets = Object.values(assets);

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const page = searchParams.page || 1;
    dispatch(fetchAssets(page));
  }, [dispatch, location.search]);

  return (
    <Page
      narrowWidth
      breadcrumbs={[{ content: 'Settings', url: '/settings' }]}
      title="Assets"
    >
      <Card title="Installed Assets" sectioned>
        <ResourceList
          resourceName={{ singular: 'asset', plural: 'assets' }}
          items={_assets}
          loading={fetching}
          renderItem={(item) => {
            const { code, name, status } = item;
            const media = (
              <Avatar
                customer
                initials={name.charAt(0)}
                size="small"
                name={name}
                source={getAssetImageUrl(code)}
              />
            );

            return (
              <ResourceList.Item
                id={code}
                url={`${match.url}/${code}`}
                media={media}
                accessibilityLabel={`View details for ${name}`}
              >
                <Stack distribution="equalSpacing" alignment="center">
                  <TextStyle variation="strong">{name}</TextStyle>
                  {renderStatusBadge(status)}
                </Stack>
              </ResourceList.Item>
            );
          }}
        />
        <Pagination
          resource={_assets}
          meta={meta}
          handlePreviousPage={handlePreviousPage}
          handleNextPage={handleNextPage}
        />
      </Card>
    </Page>
  );
}
