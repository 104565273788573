import actionTypes from '../actionTypes';

export const FETCH_CUSTOMERS = actionTypes('FETCH_CUSTOMERS');
export const FETCH_CUSTOMER = actionTypes('FETCH_CUSTOMER');

export const FETCH_CUSTOMER_ACTIVITIES = actionTypes(
  'FETCH_CUSTOMER_ACTIVITIES',
);
export const FETCH_CUSTOMER_DEVICES = actionTypes('FETCH_CUSTOMER_DEVICES');
export const REMOVE_CUSTOMER_DEVICE = actionTypes('REMOVE_CUSTOMER_DEVICE');
export const FETCH_CUSTOMER_WEB_SESSIONS = actionTypes(
  'FETCH_CUSTOMER_WEB_SESSIONS',
);
export const SIGN_OUT_CUSTOMER_WEB_SESSION = actionTypes(
  'SIGN_OUT_CUSTOMER_WEB_SESSION',
);
export const FETCH_CUSTOMER_MOBILE_APPLICATIONS = actionTypes(
  'FETCH_CUSTOMER_MOBILE_APPLICATIONS',
);
export const REMOVE_CUSTOMER_MOBILE_APPLICATION = actionTypes(
  'REMOVE_CUSTOMER_MOBILE_APPLICATION',
);

export const FETCH_CUSTOMER_DOCUMENTS = actionTypes('FETCH_CUSTOMER_DOCUMENTS');
export const FETCH_CUSTOMER_BANKS = actionTypes('FETCH_CUSTOMER_BANKS');
export const FETCH_CUSTOMER_CARDS = actionTypes('FETCH_CUSTOMER_CARDS');
export const FETCH_CUSTOMER_CRYPTO_ADDRESSES = actionTypes(
  'FETCH_CUSTOMER_CRYPTO_ADDRESSES',
);
export const CLEAR_MESSAGE = actionTypes('CLEAR_MESSAGE');
export const CUSTOMER_ACTION = actionTypes('CUSTOMER_ACTION');
export const FETCH_TRANSACTIONS = actionTypes('FETCH_TRANSACTIONS');
export const EDIT_TRANSACTION_STATUS = actionTypes('EDIT_TRANSACTION_STATUS');
export const FETCH_WALLETS = actionTypes('FETCH_WALLETS');
export const EDIT_CUSTOMER_WALLET_BALANCE = actionTypes(
  'EDIT_CUSTOMER_WALLET_BALANCE',
);
export const FETCH_CUSTOMER_ACCOUNT_STATEMENT = actionTypes(
  'FETCH_CUSTOMER_ACCOUNT_STATEMENT',
);
export const FETCH_CUSTOMER_ACCOUNT_STATEMENT_SUMMARY = actionTypes(
  'FETCH_CUSTOMER_ACCOUNT_STATEMENT_SUMMARY',
);
export const CLEAR_CUSTOMER_ACCOUNT_STATEMENT = actionTypes(
  'CLEAR_CUSTOMER_ACCOUNT_STATEMENT',
);

export const EDIT_CUSTOMER_PHONE = actionTypes('EDIT_CUSTOMER_PHONE');
export const EDIT_CUSTOMER_PROFILE = actionTypes('EDIT_CUSTOMER_PROFILE');
export const EDIT_CUSTOMER_ADDRESS = actionTypes('EDIT_CUSTOMER_ADDRESS');
export const UPDATE_CUSTOMER_LEVEL = actionTypes('UPDATE_CUSTOMER_LEVEL');

export const TOGGLE_CUSTOMER_FEATURE = actionTypes('TOGGLE_CUSTOMER_FEATURE');
export const RESOLVE_CUSTOMER_BVN = actionTypes('RESOLVE_CUSTOMER_BVN');
export const UPDATE_CUSTOMER_BVN = actionTypes('UPDATE_CUSTOMER_BVN');
export const EDIT_CUSTOMER_BVN = actionTypes('EDIT_CUSTOMER_BVN');
export const REMOVE_CUSTOMER_BVN = actionTypes('REMOVE_CUSTOMER_BVN');

export const FETCH_WHITELISTED_NAMES = actionTypes('FETCH_WHITELISTED_NAMES');
export const DELETE_WHITELISTED_NAME = actionTypes('DELETE_WHITELISTED_NAME');

export const FETCH_CUSTOMER_RECURRING_ORDERS = actionTypes(
  'FETCH_CUSTOMER_RECURRING_ORDERS',
);
export const DELETE_CUSTOMER_RECURRING_ORDER = actionTypes(
  'DELETE_CUSTOMER_RECURRING_ORDER',
);

export const FETCH_CUSTOMER_INACTIVE_WALLETS = actionTypes(
  'FETCH_CUSTOMER_INACTIVE_WALLETS',
);
export const ACTIVATE_CUSTOMER_INACTIVE_WALLET = actionTypes(
  'ACTIVATE_CUSTOMER_INACTIVE_WALLET',
);
