import React, { Fragment } from 'react';
import { Card, TextStyle, Stack, Button } from '@shopify/polaris';
import LoadingSpinner from 'components/LoadingSpinner';
import CustomerWhitelistedNamesSheet from './CustomerWhitelistedNamesSheet';

export default function CustomerWhiteListedNames({
  whitelistedNames,
  fetching,
  deleting,
  handleDeleteName,
  handlePreviousPage,
  handleNextPage,
}) {
  const [showSheet, setShowSheet] = React.useState(false);

  const toggleSheet = () => setShowSheet(!showSheet);

  let shortNamesList = [];
  let completeNamesList = [];
  let meta = {};

  if (whitelistedNames) {
    meta = whitelistedNames.meta;
    completeNamesList = whitelistedNames.data;
    shortNamesList = [...completeNamesList].slice(0, 2);
  }

  return (
    <>
      <Card>
        <Card.Section
          title="Whitelisted names"
          section
          actions={[
            {
              content: 'View more',
              onAction: toggleSheet,
              disabled: completeNamesList?.length < 3,
            },
          ]}
        >
          {fetching ? (
            <LoadingSpinner />
          ) : (
            <Fragment>
              {completeNamesList?.length > 0 ? (
                <Stack vertical>
                  {shortNamesList.map((listItem) => (
                    <Stack
                      key={listItem.id}
                      distribution="equalSpacing"
                      alignment="center"
                    >
                      <TextStyle>{listItem.name}</TextStyle>
                      <Button
                        destructive
                        plain
                        disabled={deleting}
                        onClick={() => handleDeleteName(listItem.id)}
                      >
                        Remove
                      </Button>
                    </Stack>
                  ))}
                </Stack>
              ) : (
                <TextStyle>No whitelisted names</TextStyle>
              )}
            </Fragment>
          )}
        </Card.Section>
      </Card>
      <CustomerWhitelistedNamesSheet
        {...{
          sheetActive: showSheet,
          toggleSheetActive: toggleSheet,
          namesList: completeNamesList,
          meta,
          deleting,
          handlePreviousPage,
          handleNextPage,
          handleDeleteName,
        }}
      />
    </>
  );
}
