import React from 'react';
import { getIn } from 'formik';
import { TextField } from '@shopify/polaris';

const TextInput = ({ field, form, ...props }) => {
  const errorText =
    getIn(form.touched, field.name) && getIn(form.errors, field.name);

  const handleInputChange = (name) => (value) => {
    form.setFieldValue(name, value);
  };

  return (
    <TextField
      {...field}
      {...props}
      onChange={handleInputChange(field.name)}
      error={errorText}
    />
  );
};

export default TextInput;
