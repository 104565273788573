import React from 'react';
import {
  Sheet,
  Heading,
  Button,
  Scrollable,
  Stack,
  TextStyle,
} from '@shopify/polaris';
import { MobileCancelMajor } from '@shopify/polaris-icons';

const SectionStyle = {
  alignItems: 'center',
  borderBottom: '1px solid #DFE3E8',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '1.6rem',
  width: '100%',
};

export default function CustomerBanksSheet({
  banks,
  sheetActive,
  toggleSheetActive,
}) {
  return (
    <Sheet open={sheetActive} onClose={toggleSheetActive}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <div style={SectionStyle}>
          <Heading>Bank Accounts</Heading>
          <Button
            accessibilityLabel="Cancel"
            icon={MobileCancelMajor}
            onClick={toggleSheetActive}
            plain
          />
        </div>
        <Scrollable style={{ padding: '1.6rem', height: '100%' }}>
          <Stack vertical spacing="extraLoose">
            {banks.map((bank, index) => (
              <Stack vertical spacing="tight" key={index}>
                <TextStyle variation="strong">{bank.account_number}</TextStyle>
                <TextStyle>{bank.account_name}</TextStyle>
                <TextStyle>
                  {bank.name}
                  {bank.country ? `(${bank.country})` : ''}
                </TextStyle>
              </Stack>
            ))}
          </Stack>
        </Scrollable>
      </div>
    </Sheet>
  );
}
